import { useRef, useEffect } from "react";
import "quill/dist/quill.snow.css"; // Make sure to import the necessary Quill styles
import Quill from "quill";
import "../../pages/Dashboard/Profile/EditorStyles.css"; // Import your custom CSS file here
import useTheme from "hooks/useTheme.hook";

interface TextDescProps {
  text: string; // Text or HTML content to be displayed
}

function TextDescLong({ text }: TextDescProps) {
  const editorRef = useRef<HTMLDivElement>(null); // Reference to the editor div
  const { theme } = useTheme();
  const isDark = theme === "dark";

  useEffect(() => {
    setDelta();
  }, [text]); // Re-run this effect when `text` changes

  const setDelta = () => {
    try {
      if (editorRef.current) {
        const quill = new Quill(editorRef.current, {
          theme: "snow", // You can choose another theme or leave it as 'bubble'
          readOnly: true, // Quill will be in read-only mode
          modules: {
            toolbar: false, // Disable the toolbar since it's read-only
          },
        });
        if (text) {
          const delta = JSON.parse(text); // Parse the text to a Delta object
          // Set the content in the Quill editor
          quill.setContents(delta as any); // Use clipboard to convert HTML/text content
        }
      }
    } catch (e) {}
  };

  return (
    <div className="mb-7">
      {/* This is where the Quill editor will be rendered */}
      <div
        ref={editorRef}
        className={`quill-editor p-4 ${
          isDark ? "editor-dark" : "editor-light"
        }`}
      ></div>
    </div>
  );
}

export default TextDescLong;
