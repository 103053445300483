import React from 'react';
import CountryDxccFlag from 'components/CountryDxccFlag';
import useTheme from 'hooks/useTheme.hook';

interface DXSummaryProps {
  dxCount: number;
  countries: Array<{
    name: string;
    count: number;
    flagCode: string;
  }>;
}

export const DXSummary: React.FC<DXSummaryProps> = ({ dxCount, countries }) => {
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';
  
  return (
    <div 
      className={`w-full rounded-sm px-4 pb-4 pt-2 ${
        isDarkMode 
          ? 'bg-gradient-to-l from-[#1a1d54] to-[#2e2e56]' 
          : 'bg-white'
      }`}
    >
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center">
          <span className={`text-xl font-bold font-['Barlow'] leading-loose ${
            isDarkMode ? 'text-white' : 'text-[#2b2e48]'
          }`}>
            {dxCount}{' '}
          </span>
          <span 
            className="text-xl font-bold font-['Barlow'] leading-loose ml-2"
            style={{
              background: 'linear-gradient(to right, #37A4BA, #496780, #CF0971)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text'
            }}
          >
            DX
          </span>
        </div>
      </div>
      <div className="flex flex-wrap gap-x-2">
        {countries.map((country, index) => (
          <div key={country.flagCode+index} className="flex items-center">
            <div className="flex items-center gap-2">
              <CountryDxccFlag countryCode={country.name} flagCode={country.flagCode}/>
              <span className={`text-sm ${
                isDarkMode ? 'text-[#adb9e1]' : 'text-[#2b2e48]'
              }`}>
                {country.name}({country.count})
              </span>
            </div>
            {index !== countries.length - 1 && (
              <span className={`text-sm mr-2 ${
                isDarkMode ? 'text-[#adb9e1]' : 'text-[#2b2e48]'
              }`}>,</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}; 