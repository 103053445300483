import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserProfileSelector } from 'store/Reducers/profile.reducer';
import { FaCopy, FaDownload, FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa6';
import useUpload from 'hooks/upload.hook';
import { getSelectedLogBookSelector } from 'store/Reducers/logBook.reducer';
import useTheme from 'hooks/useTheme.hook';
import { QSOMapShare } from 'types/Models';
import { getStatsModalStatus } from 'store/Reducers/config.reducer';
import { setStatsModalStatus } from 'store/Actions/config.actions';
import Images from 'assets/Images';
import Icons from 'assets/Icons';
import { serverTimestamp } from 'firebase/firestore';
import { showToast } from 'helpers/Toast';
import { ToastTypes } from 'types/Component';
import LogbookStatsTab from 'pages/Dashboard/LogBook/LogbookStatsTab';

export const ShareStatsModal = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector(getUserProfileSelector);
  const selectedLogbook = useSelector(getSelectedLogBookSelector);
  const modalStatus = useSelector(getStatsModalStatus);
  const { theme } = useTheme();
  const [shareId, setShareId] = useState("");
  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [isCapturing, setIsCapturing] = useState(false);
  const [isProcessing, setIsProcessing] = useState(true);
  const layoutRef = useRef<HTMLDivElement>(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [chartsReady, setChartsReady] = useState(0);
  
  const {
    captureSnapshot,
    saveQsoMapShareData,
    shareToSocials,
    onDownloadClick,
    setScreenshotImage
  } = useUpload();

  useEffect(() => {
    if (selectedLogbook?.id) {
      setIsDataLoaded(true);
    }
  }, [selectedLogbook]);

  useEffect(() => {
    if (selectedLogbook?.id) {
      setIsLayoutReady(true);
    }
  }, [selectedLogbook]);

  const handleChartReady = useCallback(() => {
    setTimeout(() => {
      setChartsReady(prev => prev + 1);
    }, 0);
  }, []);

  useEffect(() => {
    if (isLayoutReady && chartsReady >= 2 && !shareId && !isCapturing) {
      setIsCapturing(true);
      captureLayout();
    }
  }, [isLayoutReady, chartsReady]);

  const captureLayout = async () => {
    if (!isLayoutReady || !layoutRef.current) return;
    
    try {
      // Store reference to current DOM element
      const currentElement = layoutRef.current;
      
      // Store original styles
      const originalOverflow = currentElement.style.overflow;
      const originalHeight = currentElement.style.height;
      const originalScrollTop = currentElement.scrollTop;

      // Reset scroll position and expand container
      currentElement.style.overflow = 'visible';
      currentElement.style.height = 'auto';
      currentElement.scrollTop = 0;

      // Force DOM update
      await new Promise(resolve => requestAnimationFrame(resolve));

      const firebaseURl = await captureSnapshot(layoutRef, false);
      
      // Restore original styles after capture
      currentElement.style.overflow = originalOverflow;
      currentElement.style.height = originalHeight;
      currentElement.scrollTop = originalScrollTop;

      if (firebaseURl) {
        setImageUrl(firebaseURl);
        const data: QSOMapShare = {
          imageUrl: firebaseURl,
          uid: userProfile?.uid || "",
          callSign: userProfile?.callSign || "",
          timestamp: serverTimestamp(),
        };
        const docId = await saveQsoMapShareData(data);
        if (docId) {
          setShareId(docId);
        }
      }
    } catch (e) {
      showToast({
        message: "Sorry, something went wrong. Please try again.",
        type: ToastTypes.WARN,
      });
    } finally {
      setIsCapturing(false);
      setIsProcessing(false);
    }
  };

  const handleClose = () => {
    setScreenshotImage("");
    setIsProcessing(false);
    setIsCapturing(false);
    setIsLayoutReady(false);
    setImageUrl("");
    setShareId("");
    dispatch(setStatsModalStatus(false));
  };

  if (!modalStatus) return null;

  return (
    <section className="absolute w-screen h-screen top-0 left-0 z-[1000]">
      <img
        src={Images.SpaceBg}
        className="absolute top-0 left-0 z-0 h-full w-full object-cover"
        alt="background"
      />
      <div className="h-full w-full flex flex-col items-center glassmorphism py-2 px-[5%]">
        <div
          className="absolute top-4 right-5 cursor-pointer z-[1001]"
          onClick={handleClose}
        >
          <img
            src={Icons.GlowingClose}
            className={`h-8 w-8 ${theme !== 'dark' ? 'filter invert' : ''}`}
            alt="close"
          />
        </div>

        {isProcessing && (
          <div className="absolute top-4 right-20 flex items-center gap-2 bg-[#1A1C5F]/80 px-3 py-2 rounded-md z-50">
            <div className="w-4 h-4 border-2 border-[#17f9da] border-t-transparent rounded-full animate-spin" />
            <span className="text-[#17f9da] text-sm font-medium">
              {!chartsReady ? 'Loading charts...' : 'Preparing share options...'}
            </span>
          </div>
        )}

        <div className="mb-2 flex justify-center h-[30px]">
          {!isProcessing && imageUrl && (
            <div className="flex items-center px-4 rounded-[10px] w-full md:w-[500px] z-10 mt-3 mb-3 justify-center">
              <div
                className="w-[90px] h-[25px] rounded-[4px] bg-[#1A1C5F] flex items-center justify-center mr-2 cursor-pointer border-white border-[0.5px]"
                onClick={() => {
                  shareToSocials(
                    "twitter",
                    `${process.env.REACT_APP_SHARE_URL}stats/${shareId}`,
                    "Check out my QSO stats on World Radio League",
                    "#WorldRadioLeague"
                  );
                }}
              >
                <span className="text-[10px] mr-1 text-white">Twitter</span>
                <FaTwitter size={16} color="#fff" />
              </div>
              <div
                className="w-[90px] h-[25px] rounded-[4px] bg-[#1A1C5F] flex items-center justify-center mr-2 cursor-pointer border-white border-[0.5px]"
                onClick={() => {
                  shareToSocials(
                    "facebook",
                    `${process.env.REACT_APP_SHARE_URL}stats/${shareId}`,
                    "Check out my QSO stats on World Radio League",
                    "#WorldRadioLeague"
                  );
                }}
              >
                <span className="text-[10px] mr-1 text-white">Facebook</span>
                <FaFacebook size={16} color="#fff" />
              </div>
              <div
                className="w-[90px] h-[25px] rounded-[4px] bg-[#1A1C5F] flex items-center justify-center mr-2 cursor-pointer border-white border-[0.5px]"
                onClick={() => {
                  shareToSocials(
                    "linkedin",
                    `${process.env.REACT_APP_SHARE_URL}stats/${shareId}`,
                    "Check out my QSO stats on World Radio League",
                    "#WorldRadioLeague"
                  );
                }}
              >
                <span className="text-[10px] mr-1 text-white">LinkedIn</span>
                <FaLinkedin size={16} color="#fff" />
              </div>
              <div
                className="w-[90px] h-[25px] rounded-[4px] bg-[#1A1C5F] flex items-center justify-center mr-2 cursor-pointer border-white border-[0.5px]"
                onClick={() => {
                  shareToSocials(
                    "linkcopy",
                    `${process.env.REACT_APP_SHARE_URL}stats/${shareId}`,
                    "Check out my QSO stats on World Radio League",
                    "#WorldRadioLeague"
                  );
                }}
              >
                <span className="text-[10px] mr-1 text-white">Copy Link</span>
                <FaCopy size={16} color="#fff" />
              </div>
              <div
                className="w-[120px] h-[25px] rounded-[4px] bg-[#1A1C5F] flex items-center justify-center mr-2 cursor-pointer border-white border-[0.5px]"
                onClick={() => {
                  onDownloadClick(
                    `WorldRadioLeague_Stats_${userProfile?.callSign || ""}`
                  );
                }}
              >
                <span className="text-[10px] mr-1 text-white">
                  Download Image
                </span>
                <FaDownload size={16} color="#fff" />
              </div>
            </div>
          )}
        </div>

        <div className="relative w-full h-full flex justify-center items-center">
          <div 
            ref={layoutRef} 
            className="block w-[50%] h-full overflow-x-scroll carousel-scrollbar"
            style={{ overflow: 'hidden' }}
          >
            <div className="w-full mx-auto dark:bg-[#151541] bg-[#f2f3f4] rounded-sm">
              <LogbookStatsTab onBandModeChartReady={handleChartReady} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShareStatsModal; 