import { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "firebase-config";
import { useDispatch, useSelector } from "react-redux";
import { setWebConfig } from "store/Actions/config.actions";
import { getWebConfig } from "store/Reducers/config.reducer";

interface WebConfig {
  // Define the structure of your web_config document here
  [key: string]: any;
}

const useWebConfig = () => {
  const dispatch = useDispatch();
  const webConfig = useSelector(getWebConfig);

  const listenWebConfig = () => {
    try {
      const unsubscribe = onSnapshot(
        doc(db, "WRLConfig", "web_config"),
        (snapshot) => {
          if (snapshot.exists()) {
            dispatch(setWebConfig(snapshot.data() as WebConfig));
          } else {
            dispatch(setWebConfig(null));
          }
        },
        (error) => {
          console.error("Error fetching web_config:", error);
        }
      );
  
      return unsubscribe;
    } catch (error) {
      console.error("Error fetching web_config:", error);
    }
  };

  return {
    webConfig,
    listenWebConfig,
  };
};

export default useWebConfig;
