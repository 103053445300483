/* eslint-disable react/display-name */
import React, { useMemo, useEffect, useState, useRef } from "react";
import { GoogleMap, useLoadScript, PolylineF } from "@react-google-maps/api";
import { mapStylesDark, mapStylesLight } from "constants/MapStyles";
import RouteLoading from "components/Loading/RouteLoading";
import { SpottingMapProps } from "types/Component";
import { MarkerLines, MarkerPoint } from "types/State";
import SpotMarker from "./SpotMarker";
import moment from "moment";
import useTheme from "hooks/useTheme.hook";

const googleLibraries: any[] = ["geometry", "marker", "maps", "drawing"];

const CurvedLine = ({ start, end, lineData, setHoverArc }: any) => {
  const onLoad = (polyline: any) => {
    // console.log("polyline: ", polyline);
  };

  const options = {
    strokeColor: "#17F9DA",
    strokeOpacity: 0.8,
    strokeWeight: 1,
    fillColor: "#17F9DA",
    fillOpacity: 0.35,
    clickable: true,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
    geodesic: false,
  };

  const curvePath = [
    start,
    // { lat: curveMidPoint.lat(), lng: curveMidPoint.lng() },
    end,
  ];

  return (
    <PolylineF
      onLoad={onLoad}
      path={curvePath}
      options={options}
      onMouseOver={() => {
        if (setHoverArc) {
          setHoverArc(lineData);
        }
      }}
      onMouseOut={() => {
        if (setHoverArc) {
          setHoverArc(null);
        }
      }}
    />
  );
};

const SpottingMap = React.memo(
  ({ spotsData, spotLoading, onCopyPressed, mapConfig, onMapConfigChange }: SpottingMapProps) => {
    const [mapMarkers, setMapMarkers] = useState<MarkerPoint[]>([]);
    const [polylines] = useState<MarkerLines[]>([]);
    const [isLoading] = useState<boolean>(false);
    const mapRef = useRef<any>(null);
    
    // Use local state as fallback if no external config provided
    const [localMapConfig, setLocalMapConfig] = useState({
      center: { lat: 18.52043, lng: 73.856743 },
      zoom: 2
    });

    const currentConfig = mapConfig || localMapConfig;
    const handleConfigChange = onMapConfigChange || setLocalMapConfig;

    const { isLoaded } = useLoadScript({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API!,
      libraries: googleLibraries,
    });
    const { theme } = useTheme();

    const onMapLoad = (map: any) => {
      mapRef.current = map;
    };

    const onMapChanged = () => {
      if (mapRef.current) {
        const newCenter = mapRef.current.getCenter();
        const newZoom = mapRef.current.getZoom();
        
        handleConfigChange({
          center: { lat: newCenter.lat(), lng: newCenter.lng() },
          zoom: newZoom
        });
      }
    };

    useEffect(() => {
      const markers: any[] = [];
      // eslint-disable-next-line array-callback-return
      spotsData.forEach((contact) => {
        if (contact.coordinates) {
          const marker: any = {
            lat: contact.coordinates.latitude,
            lng: contact.coordinates.longitude,
            contactInfo: {
              image: contact?.profilePic,
              name: contact?.name,
              country: contact?.country,
              callSign: contact?.callSign,
              frequency: contact?.frequency,
              mode: contact?.mode,
              lastSpottedTimestamp: moment(
                contact.lastSpottedTimestamp
              ).fromNow(),
              distance: "--",
              logged: contact?.logged,
            },
          };

          if (onCopyPressed) {
            marker.payload = contact;
          }

          markers.push(marker);
        }
      });

      setMapMarkers(markers);
    }, [spotsData]);

    return (
      <div className="w-full h-[400px] relative rounded-2xl overflow-hidden mt-5 mb-4 px-5">
        {!isLoaded || isLoading || spotLoading ? (
          <RouteLoading />
        ) : (
          <GoogleMap
            mapContainerClassName="map-container no-close-button-on-info-windows"
            center={currentConfig.center}
            zoom={currentConfig.zoom}
            onLoad={onMapLoad}
            onDragEnd={onMapChanged}
            onZoomChanged={onMapChanged}
            options={{
              styles: theme === "dark" ? mapStylesDark : mapStylesLight,
              streetViewControl: false,
              rotateControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
              scrollwheel: true,
            }}
          >
            {mapMarkers
              ? mapMarkers.map((position, index) => {
                  return (
                    <>
                      <SpotMarker
                        key={`${position.lat}-${position.lng}-${index}`}
                        position={position}
                        index={index}
                        onCopyPressed={onCopyPressed}
                      />
                    </>
                  );
                })
              : null}
            {polylines.map((line: MarkerLines, index) => {
              return (
                <CurvedLine
                  key={`${line.start}-${line.end}-${index}`}
                  start={line.start}
                  end={line.end}
                  lineData={line}
                  setHoverArc={() => {}}
                />
              );
            })}
          </GoogleMap>
        )}
      </div>
    );
  },
  // custom comparison function to the memo to prevent unnecessary re-renders
  (prevProps, nextProps) => {
    return (
      prevProps.spotLoading === nextProps.spotLoading &&
      prevProps.spotsData.length === nextProps.spotsData.length &&
      JSON.stringify(prevProps.spotsData) === JSON.stringify(nextProps.spotsData) &&
      JSON.stringify(prevProps.mapConfig) === JSON.stringify(nextProps.mapConfig)
    );
  }
);

export default SpottingMap;
