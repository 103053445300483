import React, { useState, useEffect } from "react";
import DXStats from "components/DXStats";
import Icons from "assets/Icons";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import Images from "assets/Images";
import CustomGradientButton from "components/Button/CustomGradientButton";
import CustomMultiSelectDropdown from "components/DropDown/DropDown/CustomMultiSelectDropDown";
import { AllBandsOption, ModeCategories } from "constants/ArrayVariables";
import { convertBandValue } from "helpers/Utils";
import dxFeatures from "components/WorldMap/features.json";
import { useDispatch, useSelector } from "react-redux";

import { fetchAnalytics } from "store/Actions/analytics.actions";
import {
  getAnalyticsLoadingSelector,
  getAnalyticsErrorSelector,
  getDxAnalyticsSelector,
  getStatesAnalyticsSelector,
  getLastFetchedFiltersSelector,
} from "store/Reducers/analytics.reducer";
import {
  DXAnalyticsResult,
  StatesAnalyticsResult,
} from "types/Analytics.types";
import { getAuth } from "firebase/auth";
import { USStates } from "constants/USStates";
import CountryDxccFlag from "components/CountryDxccFlag";
import { dxccCountryList } from "constants/DXCCCountryList";
import { motion } from "framer-motion";
import {
  getUserProfileSelector,
  getUserAnalyticsQuotaSelector,
} from "store/Reducers/profile.reducer";
import { isEqual } from "lodash";
import useProfile from "hooks/profile.hook";
import { globalNavigate } from "routes/GlobalRoutes";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";

type ViewType = "ALL" | "CW" | "DIGITAL" | "PHONE";
type DashboardType = "dx" | "us-states";

interface AwardsDashboardProps {
  type: DashboardType;
  calculateAwards?: boolean;
}

interface StatsData {
  title: string;
  value: string;
}

const AwardsDashboard: React.FC<AwardsDashboardProps> = ({
  type,
  calculateAwards = false,
}) => {
  const [activeView, setActiveView] = useState<ViewType>("ALL");
  const [selectedBands, setSelectedBands] = useState<string[]>([]);
  const [shouldCalculate, setShouldCalculate] = useState(calculateAwards);
  const [lastFetchedType, setLastFetchedType] = useState<DashboardType | null>(
    null
  );
  const totalDXCCCountries = Object.keys(dxccCountryList).length;
  const dispatch = useDispatch();
  const auth = getAuth();
  const user = auth.currentUser;
  const { handleUpdateAnalyticsQuota } = useProfile();
  const userProfile = useSelector(getUserProfileSelector);
  const isFreeUser =
    !userProfile?.membershipStatus || userProfile?.membershipStatus === "free";
  const quota = useSelector(getUserAnalyticsQuotaSelector);

  const loading = useSelector(getAnalyticsLoadingSelector);
  const error = useSelector(getAnalyticsErrorSelector);
  const dxData = useSelector(getDxAnalyticsSelector);
  const statesData = useSelector(getStatesAnalyticsSelector);
  const lastFetchedFilters = useSelector(getLastFetchedFiltersSelector);

  const getModesForCategory = (category: ViewType): string[] => {
    if (category === "ALL") return [];

    const categoryMap = {
      PHONE: ModeCategories.phone,
      CW: ModeCategories.cw,
      DIGITAL: ModeCategories.digital,
    };

    return categoryMap[category] || [];
  };

  const isDefaultFilters = (filters: any) => {
    return (
      (!filters.modes || filters.modes.length === 0) &&
      (!filters.bands || filters.bands.length === 0)
    );
  };
  // This is temporary fix for the map, once we have dxcc map & correct geo names, we can remove this
  const COUNTRIES_MAP = {
    "Asiatic Russia": "Russia",
    "European Russia": "Russia",
  };

  // Static preview data with realistic numbers
  const STATIC_PREVIEW_DATA = {
    dx: {
      totalCountries: 16,
      confirmedCountries: 11,
      countryList: [
        {
          country: "United States",
          contactCount: 2451,
          confirmed: true,
          confirmedContactCount: 24,
        },
        {
          country: "Japan",
          contactCount: 1843,
          confirmed: true,
          confirmedContactCount: 18,
        },
        {
          country: "Germany",
          contactCount: 1654,
          confirmed: true,
          confirmedContactCount: 16,
        },
        {
          country: "Brazil",
          contactCount: 1354,
          confirmed: true,
          confirmedContactCount: 13,
        },
        {
          country: "Canada",
          contactCount: 1245,
          confirmed: true,
          confirmedContactCount: 12,
        },
        {
          country: "Spain",
          contactCount: 984,
          confirmed: true,
          confirmedContactCount: 9,
        },
        {
          country: "France",
          contactCount: 876,
          confirmed: true,
          confirmedContactCount: 8,
        },
        {
          country: "Italy",
          contactCount: 765,
          confirmed: true,
          confirmedContactCount: 7,
        },
        {
          country: "Australia",
          contactCount: 654,
          confirmed: true,
          confirmedContactCount: 6,
        },
        {
          country: "United Kingdom",
          contactCount: 543,
          confirmed: true,
          confirmedContactCount: 5,
        },
        {
          country: "Pakistan",
          contactCount: 75,
          confirmed: true,
          confirmedContactCount: 1,
        },
        {
          country: "Russia",
          contactCount: 432,
          confirmed: false,
          confirmedContactCount: 0,
        },
        {
          country: "China",
          contactCount: 321,
          confirmed: false,
          confirmedContactCount: 0,
        },
        {
          country: "India",
          contactCount: 234,
          confirmed: false,
          confirmedContactCount: 0,
        },
        {
          country: "South Africa",
          contactCount: 123,
          confirmed: false,
          confirmedContactCount: 0,
        },
        {
          country: "Argentina",
          contactCount: 98,
          confirmed: false,
          confirmedContactCount: 0,
        },
      ],
    } as DXAnalyticsResult,
    "us-states": {
      totalStates: 25,
      confirmedStates: 15,
      stateList: [
        {
          state: "CA",
          contactCount: 2345,
          confirmed: true,
          confirmedContactCount: 23,
        },
        {
          state: "TX",
          contactCount: 1987,
          confirmed: true,
          confirmedContactCount: 19,
        },
        {
          state: "FL",
          contactCount: 1756,
          confirmed: true,
          confirmedContactCount: 17,
        },
        {
          state: "NY",
          contactCount: 1654,
          confirmed: true,
          confirmedContactCount: 16,
        },
        {
          state: "IL",
          contactCount: 1543,
          confirmed: true,
          confirmedContactCount: 15,
        },
        {
          state: "PA",
          contactCount: 1432,
          confirmed: true,
          confirmedContactCount: 14,
        },
        {
          state: "OH",
          contactCount: 1321,
          confirmed: true,
          confirmedContactCount: 13,
        },
        {
          state: "GA",
          contactCount: 1234,
          confirmed: true,
          confirmedContactCount: 12,
        },
        {
          state: "NC",
          contactCount: 1123,
          confirmed: true,
          confirmedContactCount: 11,
        },
        {
          state: "MI",
          contactCount: 1087,
          confirmed: true,
          confirmedContactCount: 10,
        },
        {
          state: "NJ",
          contactCount: 987,
          confirmed: true,
          confirmedContactCount: 9,
        },
        {
          state: "VA",
          contactCount: 876,
          confirmed: true,
          confirmedContactCount: 8,
        },
        {
          state: "WA",
          contactCount: 765,
          confirmed: true,
          confirmedContactCount: 7,
        },
        {
          state: "AZ",
          contactCount: 654,
          confirmed: true,
          confirmedContactCount: 6,
        },
        {
          state: "MA",
          contactCount: 543,
          confirmed: true,
          confirmedContactCount: 5,
        },
        {
          state: "TN",
          contactCount: 432,
          confirmed: false,
          confirmedContactCount: 0,
        },
        {
          state: "IN",
          contactCount: 321,
          confirmed: false,
          confirmedContactCount: 0,
        },
        {
          state: "MO",
          contactCount: 234,
          confirmed: false,
          confirmedContactCount: 0,
        },
        {
          state: "MD",
          contactCount: 123,
          confirmed: false,
          confirmedContactCount: 0,
        },
        {
          state: "WI",
          contactCount: 98,
          confirmed: false,
          confirmedContactCount: 0,
        },
        {
          state: "CO",
          contactCount: 87,
          confirmed: false,
          confirmedContactCount: 0,
        },
        {
          state: "MN",
          contactCount: 76,
          confirmed: false,
          confirmedContactCount: 0,
        },
        {
          state: "SC",
          contactCount: 65,
          confirmed: false,
          confirmedContactCount: 0,
        },
        {
          state: "AL",
          contactCount: 54,
          confirmed: false,
          confirmedContactCount: 0,
        },
        {
          state: "LA",
          contactCount: 43,
          confirmed: false,
          confirmedContactCount: 0,
        },
      ],
      missingStates: [
        "AK",
        "AR",
        "CT",
        "DE",
        "HI",
        "IA",
        "ID",
        "KS",
        "KY",
        "ME",
        "MS",
        "MT",
        "ND",
        "NE",
        "NH",
        "NM",
        "NV",
        "OK",
        "OR",
        "RI",
        "SD",
        "UT",
        "VT",
        "WV",
        "WY",
      ],
    } as StatesAnalyticsResult,
  };

  useEffect(() => {
    if (isFreeUser && user?.uid) {
      if (!quota?.isLocked) {
        handleUpdateAnalyticsQuota(user.uid);
      }
    }
  }, [user?.uid, isFreeUser]);

  useEffect(() => {
    setLastFetchedType(type);
    if (user?.uid) {
      const currentFilters = {
        modes:
          activeView === "ALL" ? undefined : getModesForCategory(activeView),
        bands: selectedBands,
      };

      // Only fetch if not locked for free users
      const shouldFetch =
        (!isFreeUser || (isFreeUser && !quota?.isLocked)) &&
        (shouldCalculate || // First visit calculation
          !lastFetchedFilters || // No previous filters
          !isEqual(lastFetchedFilters, currentFilters) || // Filters changed
          (!calculateAwards && !isDefaultFilters(currentFilters)) || // Tab change with non-default filters
          (lastFetchedType === "us-states" &&
            type === "dx" &&
            isDefaultFilters(currentFilters))); // Tab change with default filters

      if (shouldFetch) {
        dispatch(
          fetchAnalytics({
            userId: user.uid,
            userCountry: userProfile?.country || "",
            type,
            filters: currentFilters,
            calculateAwards: shouldCalculate,
            userCallSign: userProfile?.callSign || "",
          })
        );
        setShouldCalculate(false);
      }
    }
  }, [
    dispatch,
    type,
    activeView,
    selectedBands,
    user?.uid,
    isFreeUser,
    quota?.isLocked,
  ]);

  // Get data based on user status and quota
  const getData = () => {
    // Only use static data for free users with locked quota
    if (isFreeUser && quota?.isLocked) {
      return STATIC_PREVIEW_DATA[type];
    }
    // Use real data for all other cases (paid users or free users with available quota)
    return type === "dx" ? dxData : statesData;
  };

  const analytics = getData();

  const getDashboardConfig = () => {
    const isDX = type === "dx";

    // Only cast if analytics exists
    const dxAnalytics =
      analytics && type === "dx" ? (analytics as DXAnalyticsResult) : null;
    const statesAnalytics =
      analytics && type === "us-states"
        ? (analytics as StatesAnalyticsResult)
        : null;

    const baseConfig = {
      title: isDX ? "Number of DX Contacts" : "50 States",
      statsTitle: {
        worked: isDX ? "Number of Countries Worked" : "Number of States Worked",
        confirmed: isDX
          ? "Number of Countries Confirmed"
          : "Number of States Confirmed",
        missing: isDX
          ? "Number of Countries Missing"
          : "Number of States Missing",
        notConfirmed: isDX
          ? "Number of Countries Worked Not Confirmed"
          : "Number of States Worked Not Confirmed",
      },
      tableColumns: isDX
        ? [
            { header: "Country", accessor: "name" },
            { header: "Flag", accessor: "flag" },
            { header: "# of QSOs", accessor: "qsos" },
            { header: "# Confirmed QSOs", accessor: "confirmedQsos" },
          ]
        : [
            { header: "State", accessor: "name" },
            { header: "# of QSOs", accessor: "qsos" },
            { header: "# Confirmed QSOs", accessor: "confirmedQsos" },
          ],
      mapFeatures: isDX
        ? dxFeatures
        : "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json",
      mapConfig: isDX
        ? {
            scale: 250,
            center: [0, 5],
          }
        : {
            scale: 1300,
            center: [-96, 38],
            projection: "geoAlbersUsa",
          },
      badgeImage: isDX ? Images.DX100 : Images.States50,
      minValue: isDX ? 1 : 0,
      maxValue: isDX ? 100 : 50,
      milestones: isDX ? [1, 10, 50, 100] : [10, 25, 50],
    };

    // Transform analytics data for table display with null checks
    const countryStateData = isDX
      ? (dxAnalytics?.countryList || []).map((item) => ({
          name: item.country,
          flag: item.country,
          qsos: item.contactCount,
          confirmedQsos: item.confirmed ? item?.confirmedContactCount : 0,
        }))
      : (statesAnalytics?.stateList || []).map((item) => ({
          name: item.state,
          qsos: item.contactCount,
          confirmedQsos: item.confirmed ? item.confirmedContactCount : 0,
        }));

    return {
      ...baseConfig,
      countryData: countryStateData,
    };
  };

  const config = getDashboardConfig();

  const statsData: StatsData[] = [
    {
      title: config.statsTitle.worked,
      value:
        type === "dx"
          ? ((analytics as DXAnalyticsResult)?.totalCountries || 0).toString()
          : ((analytics as StatesAnalyticsResult)?.totalStates || 0).toString(),
    },
    {
      title: config.statsTitle.confirmed,
      value:
        type === "dx"
          ? (
              (analytics as DXAnalyticsResult)?.confirmedCountries || 0
            ).toString()
          : (
              (analytics as StatesAnalyticsResult)?.confirmedStates || 0
            ).toString(),
    },
    {
      title: config.statsTitle.missing,
      value:
        type === "dx"
          ? (
              totalDXCCCountries -
              ((analytics as DXAnalyticsResult)?.totalCountries || 0)
            ).toString()
          : (
              (analytics as StatesAnalyticsResult)?.missingStates?.length || 50
            ).toString(),
    },
    {
      title: config.statsTitle.notConfirmed,
      value:
        type === "dx"
          ? (
              ((analytics as DXAnalyticsResult)?.totalCountries || 0) -
              ((analytics as DXAnalyticsResult)?.confirmedCountries || 0)
            ).toString()
          : (
              ((analytics as StatesAnalyticsResult)?.totalStates || 0) -
              ((analytics as StatesAnalyticsResult)?.confirmedStates || 0)
            ).toString(),
    },
  ];

  const handleBandChange = (selected: string[]) => {
    setShouldCalculate(false); // Ensure calculation is off for filter changes
    setSelectedBands(selected);
  };

  const handleViewChange = (view: ViewType) => {
    setShouldCalculate(false); // Ensure calculation is off for filter changes
    setActiveView(view);
  };

  const renderGeography = (geo: any) => {
    const geoName = geo.properties.name;
    const analyticsData = analytics as
      | DXAnalyticsResult
      | StatesAnalyticsResult
      | null;

    const isConfirmed =
      analyticsData && type === "dx"
        ? (analyticsData as DXAnalyticsResult)?.countryList?.find(
            (c) => (COUNTRIES_MAP[c.country as keyof typeof COUNTRIES_MAP] || c.country) === geoName
          )?.confirmed
        : (analyticsData as StatesAnalyticsResult)?.stateList?.find(
            (s) => USStates[s.state as keyof typeof USStates] === geoName
          )?.confirmed;

    const isWorked =
      analyticsData && type === "dx"
        ? (analyticsData as DXAnalyticsResult)?.countryList?.some(
            (c) =>
              (COUNTRIES_MAP[c.country as keyof typeof COUNTRIES_MAP] || c.country) === geoName
          )
        : (analyticsData as StatesAnalyticsResult)?.stateList?.some(
            (s) => USStates[s.state as keyof typeof USStates] === geoName
          );

    return (
      <Geography
        key={geo.rsmKey}
        geography={geo}
        style={{
          default: {
            fill: isConfirmed ? "#17F9DA" : isWorked ? "#ff6f46" : "#2e2e56",
            stroke: "#0B0B38",
            strokeWidth: 0.5,
            outline: "none",
          },
          hover: {
            fill: isConfirmed ? "#17F9DA" : isWorked ? "#ff6f46" : "#2e2e56",
            stroke: "#0B0B38",
            strokeWidth: 0.5,
            outline: "none",
          },
          pressed: {
            fill: isConfirmed ? "#17F9DA" : isWorked ? "#ff6f46" : "#2e2e56",
            stroke: "#0B0B38",
            strokeWidth: 0.5,
            outline: "none",
          },
        }}
      />
    );
  };

  const renderLoadingOverlay = (element: React.ReactNode) => {
    return (
      <div className="relative">
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: loading ? 0.6 : 1 }}
          transition={{ duration: 0.2 }}
        >
          {element}
        </motion.div>
        {loading && (
          <div className="absolute top-4 right-4 flex items-center gap-2">
            <div className="w-4 h-4 border-2 border-[#17F9DA] border-t-transparent rounded-full animate-spin" />
            <span className="text-[#17F9DA] text-sm">Updating...</span>
          </div>
        )}
      </div>
    );
  };

  if (error) {
    return (
      <div className="flex justify-center items-center h-96 text-red-500">
        {error}
      </div>
    );
  }

  const bands = AllBandsOption.map((itm) => ({
    label: convertBandValue(itm.name, itm.unit) + itm.unit,
    key: itm.name,
  }));

  // Skeleton loader for stats
  const renderStatsSkeleton = () => (
    <div className="animate-pulse bg-[#1a1d54]/50 h-6 w-24 rounded" />
  );

  // Update the stats card to handle loading state
  const renderStatsCard = (stat: StatsData, index: number) => (
    <motion.div
      key={index}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.1 * index }}
      className="dark:bg-gradient-to-l dark:from-[#1a1d54] dark:to-[#2e2e56] bg-white shadow-md rounded-lg p-4 flex flex-col justify-between"
    >
      <div className="flex justify-between items-start">
        <h3 className="dark:text-[#adb9e1] text-gray-600 text-base font-medium font-['Barlow'] mb-2">
          {stat.title}
        </h3>
        <button className="dark:text-[#8B8DBA] text-gray-400">...</button>
      </div>
      <p className="dark:text-white text-gray-900 text-3xl font-bold font-['Barlow']">
        {loading ? renderStatsSkeleton() : stat.value}
      </p>
    </motion.div>
  );

  // Table skeleton loader
  const renderTableRowSkeleton = () => (
    <tr className="animate-pulse">
      <td className="py-3 px-6">
        <div className="h-4 bg-[#1a1d54]/50 rounded w-32" />
      </td>
      {type === "dx" && (
        <td className="py-3 px-6">
          <div className="h-4 bg-[#1a1d54]/50 rounded w-8" />
        </td>
      )}
      <td className="py-3 px-6">
        <div className="h-4 bg-[#1a1d54]/50 rounded w-16" />
      </td>
      <td className="py-3 px-6">
        <div className="h-4 bg-[#1a1d54]/50 rounded w-16" />
      </td>
    </tr>
  );

  // Update the table to handle loading state
  const renderTable = () => (
    <table className="w-full border-collapse">
      <thead>
        <tr className="border-b dark:border-white/10 border-gray-200">
          {config.tableColumns.map((column, index) => (
            <th
              key={index}
              className="dark:text-white text-gray-900 font-['Barlow'] text-base font-bold py-4 px-6 text-left"
            >
              {column.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {loading
          ? Array(5)
              .fill(0)
              .map((_, index) => renderTableRowSkeleton())
          : config.countryData.map((row, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0
                    ? "dark:bg-[#2e2e56]/90 bg-gray-50"
                    : "bg-transparent"
                }`}
              >
                <td className="py-3 px-6 dark:text-white text-gray-900 font-['Barlow'] text-base">
                  {row.name}
                </td>
                {type === "dx" && (
                  <td className="py-3 px-6 text-left">
                    <CountryDxccFlag countryCode={row.name} flagCode={""} />
                  </td>
                )}
                <td className="py-3 px-6 dark:text-white text-gray-900 font-['Barlow'] text-base text-left">
                  {row.qsos.toLocaleString()}
                </td>
                <td className="py-3 px-6 dark:text-white text-gray-900 font-['Barlow'] text-base text-left">
                  {row.confirmedQsos?.toLocaleString()}
                </td>
              </tr>
            ))}
      </tbody>
    </table>
  );

  return (
    <div className="relative px-6">
      <div
        className={
          isFreeUser && quota?.isLocked ? "opacity-50 pointer-events-none" : ""
        }
      >
        <motion.div
          key="awards-dashboard"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          {/* Awards Section */}
          {renderLoadingOverlay(
            <div className="w-full h-[360px] relative dark:bg-gradient-to-l dark:from-[#1a1d54] dark:to-[#2e2e56] bg-gradient-to-l from-gray-100 to-white rounded-[7.01px] overflow-hidden shadow-md">
              {/* Header Section */}
              <div className="w-full h-[80px] px-5 pt-6 border-b-[1.5px] dark:border-white/10 border-gray-200">
                <div className="flex justify-between items-center">
                  <div className="dark:text-[#adb9e1] text-gray-700 text-base font-semibold font-['Barlow']">
                    Awards
                  </div>
                  <img
                    className="w-[23.50px] h-[21.77px]"
                    src={Icons.Warning}
                    alt="info"
                  />
                </div>
              </div>

              {/* Filter Section */}
              <div className="mt-4 flex gap-2.5 items-center px-5">
                <FilterButton
                  text="All"
                  active={activeView === "ALL"}
                  onClick={() => handleViewChange("ALL")}
                  loading={loading && activeView === "ALL"}
                />
                <FilterButton
                  text="CW"
                  active={activeView === "CW"}
                  onClick={() => handleViewChange("CW")}
                  loading={loading && activeView === "CW"}
                />
                <FilterButton
                  text="Digital"
                  active={activeView === "DIGITAL"}
                  onClick={() => handleViewChange("DIGITAL")}
                  loading={loading && activeView === "DIGITAL"}
                />
                <FilterButton
                  text="Phone"
                  active={activeView === "PHONE"}
                  onClick={() => handleViewChange("PHONE")}
                  loading={loading && activeView === "PHONE"}
                />

                {/* Space for Band Dropdown */}
                <div className="ml-10">
                  {/* Band dropdown will be added here */}
                  <CustomMultiSelectDropdown
                    options={bands.map((band) => ({
                      ...band,
                      visible: true,
                    }))}
                    label="Bands"
                    context="band"
                    selectedKeys={selectedBands}
                    onChange={handleBandChange}
                    className="w-full sm:w-auto"
                    closeAfterDebounce={true}
                  />
                </div>
              </div>

              {/* Main Content */}
              <div className="w-full left-[25px] top-[182px] absolute justify-start items-start gap-10 inline-flex">
                <div className="w-[90%]">
                  <DXStats
                    title={config.title}
                    totalWorked={
                      type === "dx"
                        ? (analytics as DXAnalyticsResult)?.totalCountries || 0
                        : (analytics as StatesAnalyticsResult)?.totalStates || 0
                    }
                    totalConfirmed={
                      type === "dx"
                        ? (analytics as DXAnalyticsResult)
                            ?.confirmedCountries || 0
                        : (analytics as StatesAnalyticsResult)
                            ?.confirmedStates || 0
                    }
                    needConfirmation={
                      type === "dx"
                        ? ((analytics as DXAnalyticsResult)?.totalCountries ||
                            0) -
                          ((analytics as DXAnalyticsResult)
                            ?.confirmedCountries || 0)
                        : ((analytics as StatesAnalyticsResult)?.totalStates ||
                            0) -
                          ((analytics as StatesAnalyticsResult)
                            ?.confirmedStates || 0)
                    }
                    minValue={config.minValue}
                    maxValue={config.maxValue}
                    milestones={config.milestones}
                  />
                </div>
                <div className="w-[10%] mr-8">
                  <img
                    className="w-[100px] h-[100px] mt-4"
                    src={config.badgeImage}
                    alt="Achievement Badge"
                  />
                </div>
              </div>
            </div>
          )}

          {/* Map Section */}
          {renderLoadingOverlay(
            <motion.div
              className="mt-8 dark:bg-[#161a53] bg-white rounded-lg p-6 shadow-md"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <div className="w-full h-[500px] relative">
                <ComposableMap
                  projection={config.mapConfig.projection}
                  projectionConfig={{
                    ...config.mapConfig,
                    center: [
                      config.mapConfig.center[0],
                      config.mapConfig.center[1],
                    ],
                  }}
                  style={{
                    background: "transparent",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Geographies geography={config.mapFeatures}>
                    {({ geographies }) =>
                      geographies.map((geo) => renderGeography(geo))
                    }
                  </Geographies>
                </ComposableMap>
              </div>
            </motion.div>
          )}

          {/* Stats Cards */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-6">
            {statsData.map((stat, index) => renderStatsCard(stat, index))}
          </div>

          {/* Country/State Table */}
          <motion.div
            className="mt-6 dark:bg-gradient-to-b dark:from-[#1a1d54] dark:to-[#2e2e56] bg-white rounded-lg overflow-hidden shadow-md"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            <div className="py-6">{renderTable()}</div>
          </motion.div>
        </motion.div>
      </div>

      {isFreeUser && quota?.isLocked && (
        <div className="absolute top-0 left-0 right-0 text-center p-8 dark:bg-gradient-to-b dark:from-[#0B0B39] bg-gradient-to-b from-gray-100 to-transparent">
          <h2 className="text-2xl font-bold dark:text-white text-gray-900 mb-4">
            This analytics dashboard is only available to Basic members and
            above.
          </h2>
          <p className="dark:text-[#adb9e1] text-gray-600 mb-4">
            Processing analytics is expensive, and support from our members
            allows us to continue this project!
          </p>
          <p className="dark:text-[#adb9e1] text-gray-600 mb-8">
            For just a few dollars a month, you can support this project and get
            access to advanced features.
          </p>

          <div className="w-[250px] h-[40px] mx-auto">
            <BorderButtonSecondary
              text="Become a Member Today!"
              className="font-semibold"
              onClick={() => globalNavigate("/membership")}
              height="h-[50px]"
              childHeight="h-[47px]"
            />
          </div>
        </div>
      )}
    </div>
  );
};

const FilterButton: React.FC<{
  text: string;
  active: boolean;
  onClick: () => void;
  loading?: boolean;
}> = ({ text, active, onClick, loading }) => {
  const buttonText = loading ? (
    <div className="flex items-center justify-center">
      <div className="w-4 h-4 border-2 border-[#17F9DA] border-t-transparent rounded-full animate-spin" />
    </div>
  ) : (
    text
  );

  return (
    <CustomGradientButton
      text={buttonText as string}
      textClass={`text-[12px] ${
        active ? "text-[#010a41]" : "dark:text-white text-gray-700"
      }`}
      className={`w-[80px] h-8 rounded-[3px] cursor-pointer border relative
        ${
          active
            ? "from-[#828aff] to-[#8ae9ff] border-[#505bf9]"
            : "dark:bg-[#1a1d54] bg-gray-100 dark:hover:from-[#828aff] dark:hover:to-[#8ae9ff] hover:bg-gray-200 dark:border-[#5a5a7a] border-gray-300"
        }`}
      onClick={onClick}
    />
  );
};

export default AwardsDashboard;
