import React, { useRef } from "react";
import { createRoot, Root } from "react-dom/client";
import { persistor, store } from "store/index";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import ThemeProvider from "contexts/theme.context";
import useTheme from "hooks/useTheme.hook";
import Images from "assets/Images";
// const { store, persistor } = configureStore();

type PopupRef = {
  window: Window | null;
  root: Root | null;
};

export const useProgrammaticPopup = () => {
  const popupRef = useRef<PopupRef>({ window: null, root: null });
  const { theme } = useTheme();
  
  const openPopup = (content: React.ReactNode, title = "Popup Window") => {
    // Reuse existing popup if already open
    if (popupRef.current.window && !popupRef.current.window.closed) {
      popupRef.current.window.focus();
      if (popupRef.current.root) {
        // Re-render the content if the popup is already open
        popupRef.current.root.render(
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ThemeProvider>
                <BrowserRouter>
                  <PopupContentWrapper>{content}</PopupContentWrapper>
                </BrowserRouter>
              </ThemeProvider>
            </PersistGate>
          </ReduxProvider>
        );
      }
      return;
    }

    // Open a new popup window
    const popupWindow = window.open(
      "",
      "_blank",
      "width=800,height=600,left=200,top=200,toolbar=no,location=no,status=no,menubar=no"
    );

    if (!popupWindow) {
      console.error("Failed to open popup window.");
      return;
    }

    popupWindow.document.title = title;

    // Add CSS link
    const link = popupWindow.document.createElement("link");
    link.rel = "stylesheet";
    link.href = "/index.css";
    popupWindow.document.head.appendChild(link);

    // Apply current theme to popup document
    applyThemeToPopup(popupWindow, theme);

    // Add Tailwind CSS
    const script = popupWindow.document.createElement("script");
    script.src = "https://cdn.tailwindcss.com"; // Tailwind CSS via CDN script
    script.defer = true;
    popupWindow.document.head.appendChild(script);

    // Create a container div in the popup's DOM
    const containerDiv = popupWindow.document.createElement("div");
    containerDiv.className = "w-full h-full";
    popupWindow.document.body.appendChild(containerDiv);
    
    // Set body and html to full height
    popupWindow.document.body.style.margin = "0";
    popupWindow.document.body.style.padding = "0";
    popupWindow.document.body.style.height = "100vh";
    popupWindow.document.body.style.width = "100vw";
    popupWindow.document.body.style.overflow = "hidden";
    popupWindow.document.documentElement.style.height = "100%";
    popupWindow.document.documentElement.style.width = "100%";

    // Create a React root for rendering
    const root = createRoot(containerDiv);

    // Store references
    popupRef.current = { window: popupWindow, root };

    // Wait for window to be ready before rendering
    setTimeout(() => {
      root.render(
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider>
              <BrowserRouter>
                <PopupContentWrapper>{content}</PopupContentWrapper>
              </BrowserRouter>
            </ThemeProvider>
          </PersistGate>
        </ReduxProvider>
      );
    }, 100);

    // Listen for theme changes in main window and sync to popup
    const themeObserver = new MutationObserver(() => {
      if (popupRef.current.window && !popupRef.current.window.closed) {
        const mainTheme = document.documentElement.className.includes("dark") ? "dark" : "";
        applyThemeToPopup(popupRef.current.window, mainTheme === "dark" ? "dark" : "light");
      }
    });
    
    themeObserver.observe(document.documentElement, { attributes: true, attributeFilter: ['class'] });

    // Cleanup
    popupWindow.addEventListener("beforeunload", () => {
      themeObserver.disconnect();
      popupRef.current.root?.unmount();
      popupRef.current.window = null;
      popupRef.current.root = null;
    });

    return null;
  };

  const closePopup = () => {
    if (popupRef.current.window && !popupRef.current.window.closed) {
      popupRef.current.root?.unmount(); // Clean up React rendering
      popupRef.current.window.close();
      popupRef.current.window = null;
      popupRef.current.root = null;
    }
  };

  return { openPopup, closePopup };
};

// Wrapper component to handle layout and loading states
const PopupContentWrapper: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [contentReady, setContentReady] = React.useState(false);
  
  React.useEffect(() => {
    // Give time for the popup window to fully initialize
    const timer = setTimeout(() => {
      setContentReady(true);
      
      // Force window resize event after content is ready
      window.dispatchEvent(new Event('resize'));
    }, 300);
    
    return () => clearTimeout(timer);
  }, []);
  
  // Show loading state initially
  if (!contentReady) {
    return (
      <div className="w-full h-full flex items-center justify-center" style={{ 
        backgroundImage: `url(${Images.SpaceBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
        <div className="animate-pulse text-lg">Loading...</div>
      </div>
    );
  }
  
  return <div style={{ 
    backgroundImage: `url(${Images.SpaceBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }}>{children}</div>;
};

// Apply current theme to popup document
const applyThemeToPopup = (popupWindow: Window, theme: string) => {
  popupWindow.document.documentElement.className = theme === "dark" ? "dark" : "";
  popupWindow.document.body.className = theme === "dark" 
    ? "dark bg-[#151541] text-white" 
    : "bg-[#f2f3f4] text-[#2b2e48]";
  
  // Add inline styles to ensure dark mode is properly applied
  if (theme === "dark") {
    popupWindow.document.body.style.backgroundColor = "#151541";
    popupWindow.document.body.style.color = "white";
  } else {
    popupWindow.document.body.style.backgroundColor = "#f2f3f4";
    popupWindow.document.body.style.color = "#2b2e48";
  }
};
