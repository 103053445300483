import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { HorizontalDivider } from "components/Divider";
import Header from "components/Header";
import TitledInputDefault from "components/Inputs/TitledInputDefault";
import { useSupport } from "hooks/useBugReport.hook";
import useTheme from "hooks/useTheme.hook";
import { useState } from "react";
import { globalNavigate } from "routes/GlobalRoutes";

export const ReportBug = () => {
  const { isLoading, reportBug, bugCount } = useSupport();
  const { theme } = useTheme();

  const [formValues, setFormValues] = useState({
    details: "",
  });

  const handleSubmit = async () => {
    reportBug(formValues, () => {
      setFormValues({
        details: "",
      });
      globalNavigate("/");
    });
  };

  return (
    <main className="relative w-full flex-grow overflow-x-auto h-full">
      <Header title="Report a Bug" />
      <div className="w-full flex items-center justify-center my-[30px]">
        <div
          className={`${
            theme === "dark" ? "glassmorphism" : "bg-white"
          } w-[90%] flex flex-col items-center gap-4 p-10 dark:text-white text-[#2b2e48]`}
        >
          <div className="flex flex-col w-full">
            <div className="text-3xl font-bold font-Play">Report a Bug 🐞</div>
            <div className="text-lg font-bold font-Play">
              You have reported <span className="dark:text-[#17F9DA] text-[#00D4FF]">{bugCount} bugs</span>! Thanks for the help!
            </div>
          </div>

          <HorizontalDivider />

          <div className="flex flex-col gap-4 w-full font-[Barlow] text-sm">
            <div className="font-bold">Instructions:</div>
            <div>
              Please write a description of the bug you are facing. Here are a
              few tips to help us solve the bug faster:
            </div>

            <ol className="list-decimal list-inside pl-2">
              <li>Add a good description of the bug with helpful details</li>
              <li>
                If possible, add any links that might help, such as a link to
                the affected contact or logbook
              </li>
              <li>
                If possible, you can also use{" "}
                <a
                  className="dark:text-[#17F9DA] text-[#00D4FF] underline"
                  target="_blank"
                  href="https://tinyurl.com/mr44mpjz"
                  rel="noreferrer"
                >
                  Loom
                </a>{" "}
                to record a video to help us understand the bug and add the link
                in the description - but don’t worry if you don’t have time to
                do this!
              </li>
            </ol>

            <div className="font-bold">
              If this is not a bug but instead a feature request or general
              question,{" "}
              <a
                href="https://community.worldradioleague.com"
                target="_blank"
                rel="noreferrer"
                className="underline text-[#00D4FF]"
              >
                please visit the community.
              </a>
            </div>
          </div>

          <TitledInputDefault
            setText={(e) => {
              setFormValues((prev: any) => {
                return {
                  ...prev,
                  details: e.target.value,
                };
              });
            }}
            title="Thank you so much for the help! 🙏"
            name="details"
            placeHolder="Details about the bug..."
            value={formValues?.details}
            width="w-full"
            className="bg-white bg-opacity-10 h-[200px]"
            tabindex={1}
            multiple
          />

          <div className="flex w-[60%]">
            <BorderButtonSecondary
              isLoading={isLoading}
              text="Submit Bug Report"
              onClick={handleSubmit}
              childClassName="text-sm"
              tabIndex={2}
            />
          </div>
        </div>
      </div>
    </main>
  );
};
