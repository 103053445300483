import { AllBandsOption, AllModesOption } from "constants/ArrayVariables";
import {
  arrlSections,
  CABRILLO_CATEGORY_ASSISTED_TYPES,
  CABRILLO_CATEGORY_OVERLAY,
  CABRILLO_CATEGORY_STATION,
  CABRILLO_CATEGORY_TIME,
  CABRILLO_CERTIFICATE,
  CABRILLO_OPERATOR_TYPES,
  CABRILLO_POWER_TYPES,
  CABRILLO_TRANSMITTER_TYPE,
  CabrilloAssistedMapping,
} from "../constants";
import { CABRILLO_FIELD_TYPES } from "../constants";
import { getARRLSection } from "helpers/Utils";
import { useState } from "react";
import { useSelector } from "react-redux";
import LogBookService from "services/logBook.service";
import { getExportCabrilloLogbookSelector } from "store/Reducers/logBook.reducer";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { SPOT_ACTION_TYPE } from "types/Functions";
import { CabrilloHeader, ContestModel } from "types/Models";
import ContestService from "services/contest.service";

export function useExportCabrillo() {
  const [loading, setLoading] = useState(false);
  const [editableText, setEditableText] = useState("");
  const userProfile = useSelector(getUserProfileSelector);
  const exportCabrilloLogbook = useSelector(getExportCabrilloLogbookSelector);

  const userCountry =
    exportCabrilloLogbook?.defaultLocation?.country ||
    userProfile?.country ||
    "";
  const userState =
    exportCabrilloLogbook?.defaultLocation?.state || userProfile?.state || "";
  const userGrid =
    exportCabrilloLogbook?.defaultLocation?.gridsquare ||
    userProfile?.gridSquare ||
    "";

  const getAssistedValue = () => {
    if (exportCabrilloLogbook?.assisted) {
      return (
        CabrilloAssistedMapping[`${exportCabrilloLogbook?.assisted}`] || ""
      );
    } else {
      return "NON-ASSISTED";
    }
  };

  const getPowerValue = () => {
    if (exportCabrilloLogbook?.contestPower) {
      try {
        const powerNumber = parseInt(exportCabrilloLogbook.contestPower);

        if (powerNumber <= 10) {
          return "QRP";
        }
        if (powerNumber <= 100) {
          return "LOW";
        }
        return "HIGH";
      } catch (e) {
        console.log("getPowerValue error:", e);
      }
    }
    return ""; // fallback if no valid input
  };

  const getTrasmitter = () => {
    if (exportCabrilloLogbook?.numberOfTransmitters) {
      try {
        const transmitterNumber = parseInt(
          exportCabrilloLogbook.numberOfTransmitters
        );
        if (transmitterNumber === 1) {
          return "ONE";
        }
        if (transmitterNumber === 2) {
          return "TWO";
        }
        if (transmitterNumber > 2) {
          return "UNLIMITED";
        }
      } catch (e) {}
    }

    return "ONE";
  };

  const getClub = () => {
    if (
      exportCabrilloLogbook?.callSignType &&
      exportCabrilloLogbook?.callSignType === "Club"
    ) {
      return exportCabrilloLogbook?.defaultCallSign || "";
    }
    return "";
  };

  const convertValueToDropdown = (dropdown: any) => {
    return dropdown.map((data: any) => {
      const label = `${data.name.toUpperCase()}${
        data.unit ? data.unit.toUpperCase() : ""
      }`;
      return {
        name: label,
        value: label,
      };
    });
  };

  const [headers, setHeaders] = useState<CabrilloHeader[]>([
    {
      name: "START-OF-LOG",
      value: "3.0",
      disabled: true,
      type: CABRILLO_FIELD_TYPES.TEXT,
    },
    {
      name: "CONTEST",
      value: "CQ-WPX-CW",
      disabled: true,
      type: CABRILLO_FIELD_TYPES.TEXT,
    },
    {
      name: "CALLSIGN",
      value:
        exportCabrilloLogbook?.defaultCallSign || userProfile?.callSign || "",
      type: CABRILLO_FIELD_TYPES.TEXT,
    },
    {
      name: "LOCATION",
      value: getARRLSection(userCountry, userState),
      type: CABRILLO_FIELD_TYPES.DROPDOWN,
      dropdownList: arrlSections,
    },
    {
      name: "CATEGORY-OPERATOR",
      value: "SINGLE-OP",
      type: CABRILLO_FIELD_TYPES.DROPDOWN,
      dropdownList: CABRILLO_OPERATOR_TYPES,
    },
    {
      name: "CATEGORY-ASSISTED",
      value: getAssistedValue(),
      type: CABRILLO_FIELD_TYPES.DROPDOWN,
      dropdownList: CABRILLO_CATEGORY_ASSISTED_TYPES,
    },
    {
      name: "CATEGORY-BAND",
      type: CABRILLO_FIELD_TYPES.DROPDOWN,
      value: "ALL",
      dropdownList: [
        { name: "ALL", value: "ALL" },
        ...convertValueToDropdown(AllBandsOption),
      ],
    },
    {
      name: "CATEGORY-POWER",
      value: getPowerValue(),
      type: CABRILLO_FIELD_TYPES.DROPDOWN,
      dropdownList: CABRILLO_POWER_TYPES,
    },
    {
      name: "CATEGORY-MODE",
      type: CABRILLO_FIELD_TYPES.DROPDOWN,
      value: "SSB",
      dropdownList: convertValueToDropdown(AllModesOption),
    },
    {
      name: "CATEGORY-TRANSMITTER",
      value: getTrasmitter(),
      type: CABRILLO_FIELD_TYPES.DROPDOWN,
      dropdownList: CABRILLO_TRANSMITTER_TYPE,
    },
    {
      name: "CATEGORY-OVERLAY",
      value: "",
      type: CABRILLO_FIELD_TYPES.DROPDOWN,
      dropdownList: CABRILLO_CATEGORY_OVERLAY,
    },
    {
      name: "CATEGORY-STATION",
      value: "",
      type: CABRILLO_FIELD_TYPES.DROPDOWN,
      dropdownList: CABRILLO_CATEGORY_STATION,
    },
    {
      name: "CATEGORY-TIME",
      value: "",
      type: CABRILLO_FIELD_TYPES.DROPDOWN,
      dropdownList: CABRILLO_CATEGORY_TIME,
    },
    {
      name: "CERTIFICATE",
      value: "YES",
      type: CABRILLO_FIELD_TYPES.DROPDOWN,
      dropdownList: CABRILLO_CERTIFICATE,
    },
    { name: "GRID-LOCATOR", value: userGrid, type: CABRILLO_FIELD_TYPES.TEXT },
    { name: "CLAIMED-SCORE", value: "0", type: CABRILLO_FIELD_TYPES.NUMBER },
    { name: "CLUB", value: getClub(), type: CABRILLO_FIELD_TYPES.TEXT },
    {
      name: "CREATED-BY",
      value: "World Radio League v1.0",
      disabled: true,
      type: CABRILLO_FIELD_TYPES.TEXT,
    },
    {
      name: "EMAIL",
      value: userProfile?.email || "",
      type: CABRILLO_FIELD_TYPES.TEXT,
    },
    {
      name: "NAME",
      value:
        `${userProfile?.firstName || ""} ${userProfile?.lastName || ""}` || "",
      type: CABRILLO_FIELD_TYPES.TEXT,
    },
    {
      name: "ADDRESS",
      value: userProfile?.address || "",
      type: CABRILLO_FIELD_TYPES.TEXT,
    },
    {
      name: "ADDRESS-CITY",
      value: userProfile?.city || "",
      type: CABRILLO_FIELD_TYPES.TEXT,
    },
    {
      name: "ADDRESS-STATE-PROVINCE",
      value: userState || "",
      type: CABRILLO_FIELD_TYPES.TEXT,
    },
    { name: "ADDRESS-POSTALCODE", value: "", type: CABRILLO_FIELD_TYPES.TEXT },
    {
      name: "ADDRESS-COUNTRY",
      value: userCountry || "",
      type: CABRILLO_FIELD_TYPES.TEXT,
    },
    {
      name: "OPERATORS",
      value:
        exportCabrilloLogbook?.defaultCallSign || userProfile?.callSign || "",
      type: CABRILLO_FIELD_TYPES.TEXT,
    },
    { name: "SOAPBOX", value: "", type: CABRILLO_FIELD_TYPES.TEXT },
  ]);

  const fetchLogbookContacts = async (logBookId: string) => {
    setLoading(true);

    try {
      const promises = [
        LogBookService.getAllLogbookContactsForCabrillo(logBookId),
        exportCabrilloLogbook?.contestId
          ? ContestService.fetchContestDetail(exportCabrilloLogbook.contestId)
          : Promise.resolve(null),
      ];

      const [bandAndModeData, contestDetails]: [any, ContestModel | null] =
        await Promise.all([
          LogBookService.getAllLogbookContactsForCabrillo(logBookId),
          exportCabrilloLogbook?.contestId
            ? ContestService.fetchContestDetail(exportCabrilloLogbook.contestId)
            : Promise.resolve(null),
        ]);

      if (contestDetails && contestDetails?.contestExportId) {
        setHeaders((prevHeaders) => {
          const updatedHeaders = [...prevHeaders];
          const contestIndex = updatedHeaders.findIndex(
            (header) => header.name === "CONTEST"
          );
          if (contestIndex > -1) {
            updatedHeaders[contestIndex] = {
              ...updatedHeaders[contestIndex],
              value: contestDetails.contestExportId,
            };
          }
          return updatedHeaders;
        });
      }

      if (bandAndModeData) {
        setHeaders((prevHeaders) => {
          const updatedHeaders = [...prevHeaders];
          console.log("bandAndModeData", bandAndModeData);
          // Update CATEGORY-BAND
          const bandIndex = updatedHeaders.findIndex(
            (header) => header.name === "CATEGORY-BAND"
          );
          if (
            bandIndex > -1 &&
            bandAndModeData.band &&
            bandAndModeData.band.length > 1
          ) {
            console.log("SET ALL");
            updatedHeaders[bandIndex] = {
              ...updatedHeaders[bandIndex],
              value: "ALL",
            };
          } else if (
            bandIndex > -1 &&
            bandAndModeData.band &&
            bandAndModeData.band.length === 1
          ) {
            const band = bandAndModeData.band[0];
            //check if band has decimal
            const bandHasDecimal = band.includes(".");
            updatedHeaders[bandIndex] = {
              ...updatedHeaders[bandIndex],
              value: bandHasDecimal
                ? bandAndModeData.band[0] + "CM"
                : bandAndModeData.band[0] + "M",
            };
          }

          // Update CATEGORY-MODE
          const modeIndex = updatedHeaders.findIndex(
            (header) => header.name === "CATEGORY-MODE"
          );
          if (
            modeIndex > -1 &&
            bandAndModeData.mode &&
            bandAndModeData.mode.length > 0
          ) {
            // check if all items are CW or SSB
            const allCW = bandAndModeData.mode.every(
              (mode: string) => mode === "CW"
            );
            const allSSB = bandAndModeData.mode.every(
              (mode: string) => mode === "SSB"
            );
            if (allCW) {
              updatedHeaders[modeIndex] = {
                ...updatedHeaders[modeIndex],
                value: "CW",
              };
            }
            if (allSSB) {
              updatedHeaders[modeIndex] = {
                ...updatedHeaders[modeIndex],
                value: "SSB",
              };
            }
          }

          return updatedHeaders;
        });
      }
    } catch (e) {
      console.log("fetchLogbookContacts ERROR:", e);
    }
    setLoading(false);
  };

  const headersToPlainText = (headers: CabrilloHeader[]): string => {
    return headers
      .filter((header) => header.name !== "QSO" && header.name !== "END-OF-LOG")
      .map((header) => {
        const value = Array.isArray(header.value)
          ? header.value.join(" ")
          : header.value ?? "";
        return `${header.name}: ${value}`;
      })
      .join("\n");
  };

  return {
    loading,
    fetchLogbookContacts,
    headers,
    setHeaders,
    headersToPlainText,
    editableText,
    setEditableText,
  };
}
