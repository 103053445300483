import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { getSelectedLogBookSelector } from "store/Reducers/logBook.reducer";
import { MemoizedBandModeStats } from "./BandModeStats";
import { DXSummary } from "./DXSummary";
import ContentHeader from "./ContentHeader";
import { QSOSpeedometer } from "./QSOSpeedometer";
import Icons from "assets/Icons";
import CountryDxccFlag from "components/CountryDxccFlag";
import { convertDistance, getActivityLogo, getActivityToActivityIcon, getShortDistanceUnit, getParkTypeName } from 'helpers/Utils';
import { LoadingSkeleton } from './LoadingSkeleton';
import { setStatsModalStatus } from 'store/Actions/config.actions';
import { getStatsModalStatus } from 'store/Reducers/config.reducer';
import BorderButtonSquare from 'components/Button/BorderButtonSquare';
import { useUserSettings } from 'hooks/useUserSettings.hook';
import { useLogBookStats } from 'hooks/useLogBookStats.hook';
import useTheme from 'hooks/useTheme.hook';

interface LogbookStatsTabProps {
  onBandModeChartReady?: () => void;
  isPopup?: boolean;
}

const LogbookStatsTab: React.FC<LogbookStatsTabProps> = ({ onBandModeChartReady, isPopup = false }) => {
  const dispatch = useDispatch();
  const { settings } = useUserSettings();
  const { theme } = useTheme();
  const selectedLogbook = useSelector(getSelectedLogBookSelector);
  const statsModalStatus = useSelector(getStatsModalStatus);
  const { getLogBookAnalytics, logBookAnalytics, loading } = useLogBookStats();
  const [chartsReady, setChartsReady] = useState(false);
  const chartReadyTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [popupInitialized, setPopupInitialized] = useState(false);
  
  // Ensure analytics are loaded
  useEffect(() => {
    if (selectedLogbook?.uid && selectedLogbook?.id) {
      getLogBookAnalytics(selectedLogbook?.id, selectedLogbook?.uid);
    }
  }, [selectedLogbook?.id, selectedLogbook?.uid, selectedLogbook?.contactCount]);
  
  // Handle resize and initialization
  useEffect(() => {
    const handleResize = () => {
      // Reset chart ready timeout on resize
      if (chartReadyTimeoutRef.current) {
        clearTimeout(chartReadyTimeoutRef.current);
      }
      
      setChartsReady(false);
      
      // Set a new timeout for chart readiness
      chartReadyTimeoutRef.current = setTimeout(() => {
        setChartsReady(true);
        if (onBandModeChartReady) {
          onBandModeChartReady();
        }
      }, 200);
    };
    
    // Initial chart preparation
    if (!loading && !chartsReady) {
      chartReadyTimeoutRef.current = setTimeout(() => {
        setChartsReady(true);
        if (onBandModeChartReady) {
          onBandModeChartReady();
        }
      }, 200);
    }
    
    // Setup resize listener
    window.addEventListener('resize', handleResize);
    
    // Force resize event after mounting to ensure proper layout
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
    
    return () => {
      window.removeEventListener('resize', handleResize);
      if (chartReadyTimeoutRef.current) {
        clearTimeout(chartReadyTimeoutRef.current);
      }
    };
  }, [loading, onBandModeChartReady]);
  
  // Ensure popup container is properly initialized
  useEffect(() => {
    // Only run initialization sequence when loading is complete
    if (isPopup && !loading) {
      // Sequence of delays to ensure popup is ready
      const initSequence = [300, 600, 1000, 1500];
      
      const initTimers = initSequence.map(delay => 
        setTimeout(() => {
          if (containerRef.current) {
            // Trigger a forceful resize only for popup initialization
            window.dispatchEvent(new Event('resize'));
            
            // Mark popup as initialized after last attempt
            if (delay === initSequence[initSequence.length - 1]) {
              setPopupInitialized(true);
            }
          }
        }, delay)
      );
      
      return () => {
        initTimers.forEach(timer => clearTimeout(timer));
      };
    } else if (!isPopup) {
      // For non-popup, mark as initialized immediately
      setPopupInitialized(true);
    }
  }, [isPopup, loading]);
  
  const activityName = selectedLogbook?.activitiesData?.[0]?.name ?? '';
  const primaryActivity = selectedLogbook?.primaryActivity || selectedLogbook?.activities?.[0] || '';
  const isActivity = selectedLogbook?.activitiesData?.length;

  const handleShareStats = () => {
    dispatch(setStatsModalStatus(true));
  };
  
  const handleChartReady = () => {
    // Only trigger onBandModeChartReady after a brief delay and when charts are marked ready
    if (chartsReady && onBandModeChartReady) {
      setTimeout(() => {
        onBandModeChartReady();
      }, 50);
    }
  };

  if (loading) {
    return <LoadingSkeleton />;
  }

  const analytics = logBookAnalytics;
  
  const isDarkMode = theme === 'dark';
  
  return (
    <div 
      className={`flex flex-col gap-2.5 p-4 w-full min-h-full ${isDarkMode && isPopup ? 'bg-[#151541] text-white' : ''}`}
      style={{
        height: 'auto',
        minHeight: '100%',
        ...(isDarkMode && isPopup ? { backgroundColor: '#151541' } : {})
      }}
      ref={containerRef}
    >
      {/* Only show share button when modal is closed */}
      {!statsModalStatus && !isPopup && (
        <div className="flex justify-end">
          <BorderButtonSquare
            text="Share Stats"
            onClick={handleShareStats}
            className="mb-0 sm:mb-0 md:mb-0 dark:bg-[#010A41] bg-[#17f9da]"
          />
        </div>
      )}

       {/* Only render charts when we're sure the container is ready */}
       {/* {(!isPopup || popupInitialized) && (
        <> */}

      {/* Stats Header Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <ContentHeader 
          className={`${isDarkMode ? 'bg-gradient-to-l from-[#1a1d54] to-[#2e2e56]' : 'bg-white'} w-full rounded-sm p-4`}
          titleClassName={`${isDarkMode ? 'text-white' : 'text-[#2b2e48]'} text-xl font-bold font-Play`}
          title={(primaryActivity || activityName ? primaryActivity + (' ') + activityName : selectedLogbook?.name || '').trim()}
          mainIcon={isActivity ? {
            src: getActivityLogo(primaryActivity),
            alt: "Activity Logo",
            className: "w-[80%] h-[80%] m-auto"
          } : undefined}
          subtitle= {isActivity ? `${analytics?.summary?.activityCount} ${getParkTypeName(primaryActivity)} to ${getParkTypeName(primaryActivity)}` : ''}
          secondaryIcon={isActivity ? {
            src: getActivityToActivityIcon(primaryActivity),
            alt: "Activity to Activity Icon",
            className: isDarkMode ? 'invert-0' : 'invert'
          } : undefined}
          subtitleClassName={`text-sm ${isDarkMode ? 'text-[#adb9e1]' : 'text-[#2b2e48]'}`}
        />

        <QSOSpeedometer
          currentRate={analytics?.summary?.averageQSOsPerHour || 0}
          maxRate={analytics?.summary?.maxQSORate || 0}
          isPopupWindow={isPopup}
        />

        <ContentHeader 
          className={`${isDarkMode ? 'bg-gradient-to-l from-[#1a1d54] to-[#2e2e56]' : 'bg-white'} w-full rounded-sm p-4`}
          titleClassName={`${isDarkMode ? 'text-white' : 'text-[#2b2e48]'} text-xl font-bold font-Play`}
          title={analytics?.summary?.duration || ''}
          subtitle={`${analytics?.summary?.startTime} → ${analytics?.summary?.endTime}`}
          mainIcon={{
            src: Icons.QsoPerMinIcon,
            alt: "Duration",
            className: `w-5 h-5 ${isDarkMode ? 'invert-0' : 'invert'}`
          }}
          containerClassName="gap-1"
          subtitleClassName={`text-sm ${isDarkMode ? 'text-[#adb9e1]' : 'text-[#2b2e48]'}`}
        />

        <ContentHeader
          className={`${isDarkMode ? 'bg-gradient-to-l from-[#1a1d54] to-[#2e2e56]' : 'bg-white'} w-full rounded-sm p-4`}
          titleClassName={`${isDarkMode ? 'text-white' : 'text-[#2b2e48]'} text-xl font-bold font-Play`}
          title={`${analytics?.summary?.totalQSOs || 0} QSOs`}
          subtitle="Total Contacts"
          subtitleClassName={`text-sm -ml-2 ${isDarkMode ? 'text-[#adb9e1]' : 'text-[#2b2e48]'}`}
          containerClassName="space-y-1.5"
        />
      </div>

      {/* Band/Mode Charts */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <MemoizedBandModeStats
          type="band"
          stats={{
            stats: analytics?.bands?.stats || {},
            topItem: analytics?.bands?.topBand || '',
            total: analytics?.bands?.total || 0
          }}
          onChartReady={handleChartReady}
          isPopupWindow={isPopup}
        />
        <MemoizedBandModeStats
          type="mode"
          stats={{
            stats: analytics?.modes?.stats || {},
            topItem: analytics?.modes?.topMode || '',
            total: analytics?.modes?.total || 0
          }}
          onChartReady={handleChartReady}
          isPopupWindow={isPopup}
        />
      </div>

      {/* DX Summary */}
      <DXSummary
        dxCount={analytics?.dx?.totalCount || 0}
        countries={analytics?.dx?.countries || []}
      />

      {/* Distance & States Summary */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <ContentHeader
          className={`${isDarkMode ? 'bg-gradient-to-l from-[#1a1d54] to-[#2e2e56]' : 'bg-white'} w-full rounded-sm p-4`}
          titleClassName={`${isDarkMode ? 'text-white' : 'text-[#2b2e48]'} text-xl font-bold font-Play`}
          title= {`${convertDistance(
                  analytics?.distance?.max?.distance ?? 0,
                  settings.distanceUnit
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                })} ${getShortDistanceUnit(settings.distanceUnit)}`}
          subtitle={
            <div className="flex items-center gap-1.5">
              <span>Longest Contact:</span>
              <CountryDxccFlag 
                countryCode={analytics?.distance?.max?.countryCode || ''}
                flagCode={analytics?.distance?.max?.countryCode || ''}
              />
              <span>{analytics?.distance?.max?.callsign || ''}</span>
            </div>
          }
          subtitleClassName={`text-sm -ml-1 ${isDarkMode ? 'text-[#adb9e1]' : 'text-[#2b2e48]'}`}
          containerClassName="space-y-1.5"
        />

        <ContentHeader
          className={`${isDarkMode ? 'bg-gradient-to-l from-[#1a1d54] to-[#2e2e56]' : 'bg-white'} w-full rounded-sm p-4`}
          titleClassName={`${isDarkMode ? 'text-white' : 'text-[#2b2e48]'} text-xl font-bold font-Play`}
          title={`${analytics?.states?.total || 0} States/Provinces`}
          subtitle={analytics?.states?.worked?.join(', ') || ''}
          subtitleClassName={`text-sm -ml-1 ${isDarkMode ? 'text-[#adb9e1]' : 'text-[#2b2e48]'}`}
          containerClassName="space-y-1.5"
        />
      </div>
      {/* </>
      )} */}
    </div>
  );
};

export default LogbookStatsTab;
