import { RootState } from "store/Reducers";
import { CallsignState } from "types/State";
import {
  CallsignActionPayload,
  SET_CALLSIGN_DATA,
  SET_CALLSIGN_LOADING,
  SET_CALLSIGN_SEARCH_KEY,
  SET_CALLSIGN_SUGGESTIONS_DATA,
  SET_QRZ_INTEGRATION_DATA,
  SET_QRZ_INTEGRATION_ERROR,
} from "store/Actions/callsign.actions";

const defaultState: CallsignState = {
  callsignData: null,
  callsignSuggestionsData: null,
  isCallsignDataLoading: false,
  callsignSearchKey: "",
  qrzIntegrationData: null,
  qrzError: "",
  qrzSessionKey: "",
};

export default function callsignReducer(
  state = defaultState,
  action: CallsignActionPayload
) {
  switch (action.type) {
    case SET_CALLSIGN_DATA:
      return {
        ...state,
        callsignData: action.payload,
      };
    case SET_CALLSIGN_SUGGESTIONS_DATA:
      return {
        ...state,
        callsignSuggestionsData: action.payload,
      };
    case SET_CALLSIGN_LOADING:
      return {
        ...state,
        isCallsignDataLoading: action.payload,
      };
    case SET_CALLSIGN_SEARCH_KEY:
      return {
        ...state,
        callsignSearchKey: action.payload,
      };
    case SET_QRZ_INTEGRATION_DATA:
      return {
        ...state,
        qrzIntegrationData: action.payload,
      };
    case SET_QRZ_INTEGRATION_ERROR:
      return {
        ...state,
        qrzError: action.payload,
      };
    case "SET_QRZ_SESSION_KEY":
      return {
        ...state,
        qrzSessionKey: action.payload,
      };
    default:
      return state;
  }
}

// GET STATE
export const getCallsignDataSelector = (state: RootState) => {
  return state.callsign.callsignData;
};

export const getCallsignDataLoadingSelector = (state: RootState) => {
  return state.callsign.isCallsignDataLoading;
};

export const getCallsignSearchKeySelector = (state: RootState) => {
  return state.callsign.callsignSearchKey;
}

export const getCallsignSuggestionsDataSelector = (state: RootState) => {
  return state.callsign.callsignSuggestionsData;
};

export const getQRZIntegrationDataSelector = (state: RootState) => {
  return state.callsign.qrzIntegrationData;
};

export const getQRZIntegrationErrorSelector = (state: RootState) => {
  return state.callsign.qrzError;
};

export const getQRZSessionKeySelector = (state: RootState) => {
  return state.callsign.qrzSessionKey;
};