import ContactMapControls from "components/ContactMapControls";
import FlatGoogleMap from "components/GoogleMap";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getContactLogBookDataAction } from "store/Actions/logBook.actions";
import { ContactMapProps } from "types/Component";
function  ContactMap({
  className,
  containerClassName,
  allContactMode,
  setHoverArc,
  captureSnapshot,
  isLoadingImg,
  screenShotImage,
  limit,
  contest,
  userContactMap,
  showControls,
  mapControls,
  contactDetail,
  captureInProgress,
  showShare,
  restrictBounds,
  shouldFitBounds,
  useCustomMarkers,
  curved,
  shareMapModalType = "",
  userId,
  onMapLoaded,
}: ContactMapProps) {
  const [showImported, setShowImported] = React.useState(true);
  const [showLimit, setShowLimit] = React.useState(200);
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();
  useEffect(() => {

      dispatch(
        getContactLogBookDataAction({
          logBookId: searchParams.get("logbook-id")!,
          limit: showLimit,
          showImported: showImported,
          uid: userId, // in case of guest mode this will be prioritized over the user profile uid
        })
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showImported, showLimit]);
  return (
    <div className={containerClassName}>
      {showControls ? (
        <ContactMapControls
          setShowImported={setShowImported}
          setShowLimit={setShowLimit}
          showLimit={showLimit}
          shareMapModalType={shareMapModalType}
        />
      ) : null}

      <FlatGoogleMap
        className={className}
        allContactMode={allContactMode ? allContactMode : false}
        contest={contest}
        setHoverArc={setHoverArc}
        captureSnapshot={captureSnapshot}
        isLoadingImg={isLoadingImg}
        screenShotImage={screenShotImage}
        limit={limit}
        userContactMap={userContactMap}
        mapControls={mapControls}
        contactDetail={contactDetail}
        captureInProgress={captureInProgress}
        showShare={showShare}
        restrictBounds={restrictBounds} // Only show a single map not repeated 
        shouldFitBounds={shouldFitBounds} // Fit bounds to the map to show region with markers
        useCustomMarkers={useCustomMarkers}
        curved={curved}
        onMapLoaded={onMapLoaded}
      />
    </div>
  );
}

export default ContactMap;
