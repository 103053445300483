import Icons from "assets/Icons";
import { MY_PROFILE_ROUTE } from "constants/Links";
import CustomValidation from "helpers/Validation";
import { globalNavigate } from "routes/GlobalRoutes";

const markerSvg = `
    <svg width="28.35" height="34.125" viewBox="0 0 54 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_3915_111137)">
        <path d="M47.6335 24.8167C47.6335 34.0682 34.1538 49.6128 28.8886 55.3316C27.7612 56.5561 25.8723 56.5561 24.7449 55.3316C19.4797 49.6128 6 34.0682 6 24.8167C6 13.32 15.32 4 26.8168 4C38.3135 4 47.6335 13.32 47.6335 24.8167Z" fill="white" shape-rendering="crispEdges"/>
        <path d="M47.6335 24.8167C47.6335 34.0682 34.1538 49.6128 28.8886 55.3316C27.7612 56.5561 25.8723 56.5561 24.7449 55.3316C19.4797 49.6128 6 34.0682 6 24.8167C6 13.32 15.32 4 26.8168 4C38.3135 4 47.6335 13.32 47.6335 24.8167Z" stroke="url(#paint0_linear_3915_111137)" stroke-width="2" shape-rendering="crispEdges"/>
      </g>
      <defs>
      <filter id="filter0_d_3915_111137" x="0" y="0" width="53.6335" height="64.25" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="2.5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3915_111137"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3915_111137" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_3915_111137" x1="49.1275" y1="-4.90625" x2="-8.57081" y2="36.359" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF2CF7"/>
        <stop offset="0.203125" stop-color="#FB047B" stop-opacity="0.796875"/>
        <stop offset="0.369792" stop-color="#FF7EBC" stop-opacity="0.81"/>
        <stop offset="0.578125" stop-color="white" stop-opacity="0"/>
        <stop offset="0.727396" stop-color="#49FFE9" stop-opacity="0.65"/>
        <stop offset="1" stop-color="#130EFF"/>
      </linearGradient>
      </defs>
    </svg>
  `;

export const htmlElementsData = (d: any) => {
  const el: any = document.createElement("div");
  el.innerHTML = `
                        <style>
                          .markerMembersContainer {
                            position: relative;
                            z-index: -1;
                          }
                          .markerMembersImage {
                            height: 18px; 
                            width: 18px; 
                            position: absolute; 
                            top: 4px; 
                            left: 5px; 
                            border-radius: 10px;
                          }
                          .markerMembersInfoWrapper {
                            background-image: linear-gradient(180deg, #8F25C3, #651FFF);
                            border-radius: 8px;
                            position: absolute;
                            left: 100%;
                            top: 0px;
                            display: none;
                            z-index: 1000
                          }
                          .markerMembersContainer:hover .markerMembersInfoWrapper  {
                            display: block
                          }
                        </style>
                        <div class="markerMembersContainer">
                          ${markerSvg}
                          <img class="markerMembersImage"
                          style="background-color: #D3D3D3" 
                          src="${
                            d.data.profilePic
                              ? d.data.profilePic
                              : Icons.User
                          }" />
                          <div class="markerMembersInfoWrapper">
                          <div className="bg-gradient-to-r from-[#8F25C3] to-[#651FFF]" style="padding: 4px">
                            <div style="padding: 16px; border-radius: 5px; background-color: #fff">
                                <div style="display: flex; justify-content: space-between; margin-bottom: 12px">
                                <div style="position: relative;">
                                <div style="width: 40px;">
                                </div>
                                    <img
                                    style="width: 40px; height: 40px; border-radius: 20px; background-color: #D3D3D3"
                                    src="${
                                      d.data.profilePic
                                        ? d.data.profilePic
                                        : Icons.User
                                    }"
                                    />
                                </div>
                                <div style="margin-left: 12px">
                                    <p style="font-family: Play; color: #010A41; font-size: 14px">
                                    ${d.data.firstName} - ${d.data.callSign}
                                    </p>
                                    <div style="width: 110px">
                                    </div>
                                </div>
                                </div>
                               
                                ${
                                  d.data.foundingMemberCount
                                    ? `
                                <div style="display: flex; justify-content: space-between;">
                                    <p style="font-family: Play; font-weight: 700; color: #010A41; font-size: 12px">
                                        Founding Member#
                                    </p>
                                    <p style="font-family: Play; font-weight: 400; color: #010A41; font-size: 12px">${d.data.foundingMemberCount}</p>
                                </div>
                                `
                                    : ``
                                }
                                
                            </div>
                            </div>
                          </div>
                        </div>
                      `;
  el.style.color = d?.color;

  el.style["pointer-events"] = "auto";
  el.style.cursor = "pointer";
  el.onclick = () => {
    globalNavigate(
      `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
        d.data?.callSign ? d.data?.callSign : ""
      )}?tab=Logbook&contact-tab=entries`,
      {
        state: d.data.uid,
      }
    );
  };
  return el;
};
