import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import SpottingFilters from "components/SpottingFilters";
import SpottingGridView from "components/SpottingGridView";
import SpottingTableView from "components/SpottingTableView";
import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAddSpotModal } from "store/Actions/logBook.actions";
import { getIsSpottingLoadingSelector } from "store/Reducers/loading.reducer";
import { getSpotSelector } from "store/Reducers/spotting.reducer";
import { ContactSpottingProps, ToastTypes } from "types/Component";
import { SpotFilters, SpotModel, ParkReferenceModel } from "types/Models";
import { showToast } from "helpers/Toast";

import ActivityIndicator from "components/Loading/ActivityIndicator";
import SpotRefresh from "components/SpotRefresh";
import SpottingMap from "components/GoogleMap/SpottingMap";
import CustomGradientButton from "components/Button/CustomGradientButton";
import IconTable from "components/icons/IconTable";
import IconGrid3x3 from "components/icons/IconGrid3x3";
import IconMap from "components/icons/IconMap";
import SpottingTableViewCompact from "components/SpottingTableView/compact";
import { setSpottingCopied } from "store/Actions/config.actions";
import { getDxDataFromCountry } from "helpers/Utils";

function ContactSpotting({
  callSignBlurPopupHandler,
  getUserFromHamApiData,
  setValues,
  setMode,
  setBand,
  setTheirPark,
  setSortConfig,
  filters,
  setFilters,
  applyChanges,
  showAddSpotCTA = true,
  debounceChangeFrequencyCATControl,
}: ContactSpottingProps) {
  const dispatch = useDispatch();
  const spotsData = useSelector(getSpotSelector);
  const spotLoading = useSelector(getIsSpottingLoadingSelector);
  const [activeView, setActiveView] = useState<"map" | "grid" | "table">(
    "table"
  );
  const [mapConfig, setMapConfig] = useState({
    center: { lat: 18.52043, lng: 73.856743 },
    zoom: 2
  });

  /**
   * Handles copying spotting data to the logbook form when a spot is selected.
   *
   * @remarks
   * This code appears to be duplicated in LogContacts.tsx. Consider refactoring this mapping logic
   * into a shared utility function to improve maintainability and reduce code duplication.
   *
   * @todo
   * Refactor shared mapping logic between this function and similar code in LogContacts.tsx
   * into a reusable utility function to avoid code duplication.
   */
  const onCopyPressed = (item: SpotModel) => {
    if (setValues) {
      let theirParks: any = {};
      if (item?.activityNumber) {
        const parkData: ParkReferenceModel = {
          entityId: 0,
          parkId: 0,
          parktypeDesc: "",
          referencePrefix: item?.activityNumber
            ? item?.activityNumber.split("-")[0]
              ? item?.activityNumber.split("-")[0]
              : ""
            : "",
          name: "",
          grid6: item?.grid || "",
          grid4: item?.grid || "",
          longitude: item?.coordinates?.longitude || 0,
          latitude: item?.coordinates?.latitude || 0,
          reference: item?.activityNumber || "",
          locationName: item.state || "",
          parktype: "",
          entityName: item?.country || "",
        };
        theirParks = { [`${item.activityNumber}`]: parkData };
      }

      setValues((prev: any) => {
        let data = {
          ...prev,
        };
        data.theirCallsign = item.callSign || "";
        data.operator = item.name || "";
        data.state = item.state || "";
        data.country = item.country || "";
        data.grid = item.grid || "";
        data.frequency = item.frequency || "";
        data.band = item.band || "";
        data.userMode = item.mode || "";
        data.theirName = item.name || "";
        data.spotSource = item.source || "";
        data.spottedContact = true;
        data.theirPark = item.activityNumber || "";
        data.theirParkKey = item.activityNumber || "";
        data.theirParks = theirParks;

        if (item.country){
          const dxccData = getDxDataFromCountry(item.country || "");
          data.dxccNumber = dxccData?.dxccNumber || "";
          data.continent = dxccData?.continent || "";
          data.flagCode = dxccData?.flagCode || "";
          data.countryCode = dxccData?.countryCode || "";
        }

        return data;
      });
      setMode && setMode(item.mode);
      setBand && setBand(item.band);
      debounceChangeFrequencyCATControl &&
        debounceChangeFrequencyCATControl(item.frequency);
      setTheirPark && setTheirPark(item.activityNumber);
      // Get user data from ham api and show in CallSign popup
      const searchString = item.callSign;
      if (searchString) {
        getUserFromHamApiData(searchString, setValues, true);
      }
      callSignBlurPopupHandler(searchString);

      dispatch(setSpottingCopied(true));
    }
  };

  const onFilterApply = (filter: SpotFilters[]) => {
    applyChanges(filter);
  };

  const refreshSpots = useCallback(() => {
    applyChanges();
  }, [dispatch]);

  const clearFilter = () => {
    applyChanges([]);
  };

  return (
    <main className="dark:bg-[#0B0B39] bg-white w-full flex flex-col items-start relative mt-4">
      <SpottingFilters
        applyChanges={applyChanges}
        filters={filters}
        setFilters={setFilters}
        updateUrl={false}
        className="!my-1"
      />

      {/* Divider */}
      <div className="h-[0.5px] w-full bg-[#1b1d63] mt-2 mb-3" />

      <div className="flex justify-between w-full mt-1 mb-3 px-5 gap-4">
        <div className="flex">
          <div className="flex items-center gap-4">
            {/* Table View Button */}
            <CustomGradientButton
              icon={IconTable}
              iconClass={`w-[15px] h-[15px] ${
                activeView !== "table" ? "text-white" : "text-[#000000]"
              }`}
              text="Table View"
              textClass={`text-[12px] ${
                activeView !== "table" ? "text-white" : "text-[#010a41]"
              } ml-2`}
              className={`w-[120px] h-8 rounded-[3px] cursor-pointer border ${
                activeView !== "table"
                  ? "bg-gradient-to-r from-[#3a3a5f] to-[#444467] hover:from-[#4c4c6d] hover:to-[#5a5a7a] border-[#5a5a7a] transition-all duration-150 ease-in-out shadow-inner"
                  : "from-[#828aff] to-[#8ae9ff] border-[#505bf9]"
              }`}
              onClick={() => setActiveView("table")}
            />

            {/* Map View Button */}
            <CustomGradientButton
              icon={IconMap}
              iconClass={`w-[15px] h-[15px] ${
                activeView !== "map" ? "text-white" : "text-[#000000]"
              }`}
              text="Map View"
              textClass={`text-[12px] ${
                activeView !== "map" ? "text-white" : "text-[#010a41]"
              } ml-2`}
              className={`w-[120px] h-8 rounded-[3px] cursor-pointer border ${
                activeView !== "map"
                  ? "bg-gradient-to-r from-[#3a3a5f] to-[#444467] hover:from-[#4c4c6d] hover:to-[#5a5a7a] border-[#5a5a7a] transition-all duration-150 ease-in-out shadow-inner"
                  : "from-[#828aff] to-[#8ae9ff] border-[#505bf9]"
              }`}
              onClick={() => setActiveView("map")}
            />

            {/* Grid View Button */}
            <CustomGradientButton
              icon={IconGrid3x3}
              iconClass={`w-[15px] h-[15px] ${
                activeView !== "grid" ? "text-white" : "text-[#000000]"
              }`}
              text="Grid View"
              textClass={`text-[12px] ${
                activeView !== "grid" ? "text-white" : "text-[#010a41]"
              } ml-2`}
              className={`w-[120px] h-8 rounded-[3px] cursor-pointer border ${
                activeView !== "grid"
                  ? "bg-gradient-to-r from-[#3a3a5f] to-[#444467] hover:from-[#4c4c6d] hover:to-[#5a5a7a] border-[#5a5a7a] transition-all duration-150 ease-in-out shadow-inner"
                  : "from-[#828aff] to-[#8ae9ff] border-[#505bf9]"
              }`}
              onClick={() => setActiveView("grid")}
            />
          </div>
        </div>
        {showAddSpotCTA ? (
          <div className="w-[220px]">
            <BorderButtonSecondary
              text={`+  Add Spot`}
              onClick={() => {
                dispatch(setAddSpotModal(true));
              }}
              className="sm:mb-0 cursor-pointer"
            />
          </div>
        ) : null}
      </div>
      <div className="ml-1">
        <SpotRefresh refreshSpots={refreshSpots} />
      </div>
      {spotLoading ? (
        <ActivityIndicator size={36} />
      ) : activeView === "grid" ? (
        <SpottingGridView spotsData={spotsData} onCopyPressed={onCopyPressed} />
      ) : activeView === "table" ? (
        <SpottingTableViewCompact
          spotsData={spotsData}
          onCopyPressed={onCopyPressed}
          setSortConfig={setSortConfig}
        />
      ) : (
        <SpottingMap
            spotsData={spotsData}
            spotLoading={spotLoading}
            onCopyPressed={onCopyPressed}
            mapConfig={mapConfig}
            onMapConfigChange={setMapConfig}
          />
      )}

      <div className="h-[100px]"></div>
    </main>
  );
}

export default ContactSpotting;
