import React, { useEffect, useState } from "react";
import Icons from "assets/Icons";

import { useDispatch, useSelector } from "react-redux";
import {
  getContactLogBookForAdifExportAction,
  setExportCabrilloLogbook,
} from "store/Actions/logBook.actions";
import { SliderButtonGroup } from "components/SelectableButtonsGroup/SliderButtonGroup";
import { SelectableButtonsGroup } from "components/SelectableButtonsGroup/SelectableButtonsGroup";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import EasyEditor from "./EasyEditor";
import { useExportCabrillo } from "hooks/useExportCabrillo";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import { getExportCabrilloLogbookSelector } from "store/Reducers/logBook.reducer";
import AdvancedEditor from "./AdvancedEditor";
import { getIsLogContactForAdifLoadingSelector } from "store/Reducers/loading.reducer";

export const ExportEditOptions = {
  EASY: "easy",
  ADVANCED: "advanced",
};
const CabrilloExportModal = () => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 450);
  const [selectedTab, setSelectedTab] = useState<string>(
    ExportEditOptions.EASY
  );
  const {
    loading,
    fetchLogbookContacts,
    headers,
    setHeaders,
    editableText,
    headersToPlainText,
  } = useExportCabrillo();
  const exportCabrilloLogbook = useSelector(getExportCabrilloLogbookSelector);
  const isLogContactForAdifLoading = useSelector(
      getIsLogContactForAdifLoadingSelector
    );

  useEffect(() => {
    fetchLogbookContacts(exportCabrilloLogbook?.id || "");
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 450);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="absolute w-screen h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[25%] lg:px-[25%] overflow-y-auto carousel-scrollbar">
      <div className="w-full min-h-fit">
        <div className="w-full">
          <div className="flex flex-col w-full font-[Play dark:bg-[#151541] bg-white px-6 py-5 dark:text-white text-[#2b2e48] font-[Play] relative mt-10 md:mt-0 border border-[#451836]">
            <div
              className="absolute -top-2 -right-3 md:-right-3 cursor-pointer z-[1001]"
              onClick={() => {
                dispatch(setExportCabrilloLogbook(false));
              }}
            >
              <img
                src={Icons.GlowingClose}
                className="h-8 w-8 invert bg-black dark:filter-none dark:invert-0 z-[1005] rounded-full"
                alt="close"
              />
            </div>
            <div className="ml-3">
              <h5 className="font-[Play] font-bold dark:text-white text-[#2b2e48] py-2  w-full text-lg">
                Cabrillo Export
              </h5>
              {loading ? (
                <div className="w-full flex justify-center items-center">
                  <ActivityIndicator size={20} />
                </div>
              ) : (
                <>
                  <h3 className="dark:text-white text-[#2b2e48] font-bold text-xs mt-3">
                    Validate The Header Information
                  </h3>

                  <div className="w-full h-[1px] bg-[#3F3F63] mt-2"></div>
                  <div className="md:w-[50%] sm:w-[70%] w-full mt-3">
                    {isMobile ? (
                      <SelectableButtonsGroup
                        initialSelected={selectedTab}
                        disabled={false}
                        actions={[
                          {
                            id: ExportEditOptions.EASY,
                            label: "Easy Editor",
                            tabIndex: 8,
                          },
                          {
                            id: ExportEditOptions.ADVANCED,
                            label: "Advanced Editor",
                            tabIndex: 8,
                          },
                        ]}
                        onButtonChange={(action) => {
                          if (action && action.id) {
                            setSelectedTab(action.id as string);
                          }
                        }}
                      />
                    ) : (
                      <SliderButtonGroup
                        initialSelected={selectedTab}
                        disabled={false}
                        sliderClass="w-[48.5%]"
                        buttonClass="w-1/2"
                        actions={[
                          {
                            id: ExportEditOptions.EASY,
                            label: "Easy Editor",
                            tabIndex: 8,
                            icon: Icons.Easy,
                          },
                          {
                            id: ExportEditOptions.ADVANCED,
                            label: "Advanced Editor",
                            tabIndex: 8,
                            icon: Icons.Advanced,
                          },
                        ]}
                        onButtonChange={(action) => {
                          if (action && action.id) {
                            setSelectedTab(action.id as string);
                          }
                        }}
                      />
                    )}
                  </div>

                  <div className="w-full h-[1px] bg-[#3F3F63] mt-2 mb-4"></div>
                  {selectedTab === ExportEditOptions.EASY ? (
                    <EasyEditor headers={headers} setHeaders={setHeaders} />
                  ) : (
                    <AdvancedEditor headers={headers} setHeaders={setHeaders} />
                  )}
                </>
              )}
            </div>

            <div className="flex w-full justify-center mt-6">
              <BorderButtonSecondary
                isLoading={loading || isLogContactForAdifLoading}
                text={"Export Cabrillo"}
                onClick={() => {

                  dispatch(
                    getContactLogBookForAdifExportAction(
                      exportCabrilloLogbook?.id || "",
                      {
                        headers:
                          selectedTab === ExportEditOptions.EASY
                            ? headersToPlainText(headers)
                            : editableText,
                        format: "cabrillo",
                      }
                    )
                  );
                }}
                childClassName="text-sm"
                className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3 cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CabrilloExportModal;
