import { useState, useRef } from "react";
import { createPortal } from "react-dom";

interface TooltipProps {
  message: string;
  children: React.ReactNode;
  showTooltip?: boolean;
  className?: string;
  usePortal?: boolean; // Flag to determine rendering method
}

export function Tooltip({
  message,
  children,
  showTooltip = true,
  className = "",
  usePortal = false, // Default to original behavior for backward compatibility
}: TooltipProps): JSX.Element {
  const [showTip, setShowTip] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  // Extract positioning adjustments from className
  const extractPositionAdjustments = () => {
    const topMatch = className.match(/(!?-?top-\d+)/);
    const leftMatch = className.match(/(!?-?left-\d+)/);

    const topClass = topMatch ? topMatch[0] : "";
    const leftClass = leftMatch ? leftMatch[0] : "";

    let topAdjustment = 0;
    let leftAdjustment = 0;

    // Parse top adjustment
    if (topClass) {
      const isNegative = topClass.includes("-top-");
      const isImportant = topClass.includes("!");
      const value = parseInt(topClass.match(/\d+/)?.[0] || "0");

      // Convert Tailwind spacing to pixels (approximate)
      const pixelValue = value * 4; // Tailwind's spacing scale is roughly 4px per unit
      topAdjustment = isNegative ? -pixelValue : pixelValue;

      // If it's an important override, we might want to apply it more aggressively
      if (isImportant) {
        topAdjustment = topAdjustment * 1.5;
      }
    }

    // Parse left adjustment
    if (leftClass) {
      const isNegative = leftClass.includes("-left-");
      const isImportant = leftClass.includes("!");
      const value = parseInt(leftClass.match(/\d+/)?.[0] || "0");

      // Convert Tailwind spacing to pixels
      const pixelValue = value * 4;
      leftAdjustment = isNegative ? -pixelValue : pixelValue;

      if (isImportant) {
        leftAdjustment = leftAdjustment * 1.5;
      }
    }

    return { topAdjustment, leftAdjustment };
  };

  const calculatePosition = () => {
    if (!containerRef.current) return { top: 0, left: 0 };

    const rect = containerRef.current.getBoundingClientRect();
    const { topAdjustment, leftAdjustment } = extractPositionAdjustments();

    // Apply the adjustments to the calculated position
    return {
      top: `${rect.bottom + window.scrollY + topAdjustment}px`,
      left: `${rect.left + rect.width / 2 + window.scrollX + leftAdjustment}px`,
      transform: "translateX(-50%)",
    };
  };

  const handleMouseEnter = () => setShowTip(true);
  const handleMouseLeave = () => setShowTip(false);

  // Common container for both rendering methods
  const container = (
    <div
      ref={containerRef}
      className="group relative flex"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}

      {/* Original tooltip rendering (no portal) */}
      {!usePortal && showTooltip && (
        <span
          className={`absolute z-[1000] top-10 scale-0 transition-all p-2 text-[12px] font-[Play] bg-[#524A6F] text-white group-hover:scale-100 ${className}`}
        >
          {message}
        </span>
      )}
    </div>
  );

  // Return early if we're not using a portal
  if (!usePortal) {
    return container;
  }

  // Portal-based rendering
  return (
    <>
      {container}

      {showTooltip &&
        showTip &&
        createPortal(
          <div
            style={{
              position: "absolute",
              zIndex: 9999,
              ...calculatePosition(),
            }}
            className={`p-2 text-[12px] font-[Play] bg-[#524A6F] text-white ${className.replace(
              /(!?-?top-\d+)|(!?-?left-\d+)/g,
              ""
            )}`}
          >
            {message}
          </div>,
          document.body
        )}
    </>
  );
}
