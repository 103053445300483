import React, { useEffect, useMemo, useRef, useState } from "react";
import * as echarts from "echarts/core";
import { GridComponent, GridComponentOption } from "echarts/components";
import { BarChart, BarSeriesOption } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import useTheme from "hooks/useTheme.hook";

echarts.use([GridComponent, BarChart, CanvasRenderer]);

type EChartsOption = echarts.ComposeOption<
  GridComponentOption | BarSeriesOption | any
>;

interface ChartConfig {
  horizontal?: boolean;
  height?: string;
  width?: string;
  showGrid?: boolean;
  gridColor?: string;
  labelColor?: string;
  valueColor?: string;
  barWidth?: number;
  maxValue?: number;
  valueFormatter?: (value: number) => string;
  axisFormatter?: (value: number) => string;
}

interface BarChartProps {
  labels: string[];
  data: number[];
  config?: ChartConfig;
  onChartReady?: () => void;
  isPopupWindow?: boolean;
}

const BarChartComponent: React.FC<BarChartProps> = ({ 
  labels, 
  data,
  config = {},
  onChartReady,
  isPopupWindow = false
}) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<echarts.ECharts | null>(null);
  const { theme } = useTheme();
  const isDark = theme === "dark";

  const {
    horizontal = false,
    height = '350px',
    width = '100%',
    showGrid = true,
    gridColor = isDark ? "rgba(255, 255, 255, 0.2)" : "rgba(0,0,0, 0.2)",
    labelColor = isDark ? "#FFFFFF" : "#2b2e48",
    valueColor = isDark ? "#FFFFFF" : "#2b2e48",
    barWidth = horizontal ? 20 : undefined,
    maxValue,
    valueFormatter = (value: number) => `${value}`,
    axisFormatter = (value: number) => `${value}`
  } = config;

  // Helper function to determine max value for axis
  const getMaxAxisValue = (values: number[]): number => {
    if (maxValue !== undefined) return maxValue;
    
    const max = Math.max(...values);
    const increaseAmount = max * 0.1;
    const adjustedMax = max + increaseAmount;
    const minBuffer = 2;
    const finalMax = adjustedMax < minBuffer ? minBuffer : adjustedMax;
    return Math.ceil(finalMax);
  };

  const option: any = useMemo(() => {
    // Add data validation and conversion
    const validData = data.map(value => {
      if (typeof value === 'object') {
        console.warn('Received object instead of number in data array:', value);
        return 0;
      }
      return Number(value);
    });
    
    const maxAxisValue = getMaxAxisValue(validData);

    const baseAxis = {
      type: "category",
      data: labels,
      axisLabel: {
        color: labelColor,
      },
      splitLine: {
        show: showGrid,
        lineStyle: {
          color: gridColor,
        },
      },
    };

    const valueAxis = {
      type: "value",
      max: maxAxisValue + (horizontal ? maxAxisValue * 0.25 : 0),
      axisLabel: {
        color: labelColor,
        formatter: axisFormatter,
      },
      splitLine: {
        show: showGrid,
        lineStyle: {
          color: gridColor,
        },
      },
    };

    return {
      xAxis: horizontal ? valueAxis : baseAxis,
      yAxis: horizontal ? baseAxis : valueAxis,
      grid: {
        show: showGrid,
        containLabel: true,
        left: horizontal ? '8%' : '3%',
        right: '4%',
        bottom: '3%',
        top: '3%',
      },
      
      // Completely disable animations for popup windows
      animation: !isPopupWindow,
      animationDuration: isPopupWindow ? 0 : 1000,
      animationEasing: 'linear',
      
      series: [
        {
          data: validData,
          type: "bar",
          barWidth: barWidth,
          // Disable series animation as well for popups
          animation: !isPopupWindow,
          animationDuration: isPopupWindow ? 0 : 1000,
          animationDelay: 0,
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 0,
              colorStops: [{
                offset: 0,
                color: '#4da1ff' // Start color
              }, {
                offset: 1,
                color: '#4dffde' // End color
              }]
            }
          },
          label: {
            show: true,
            position: horizontal ? 'right' : 'top',
            formatter: (params: any) => {
              return valueFormatter(Number(params.value));
            },
            color: valueColor,
            distance: horizontal ? 5 : undefined,
          },
        },
      ],
    };
  }, [labels, data, config, isDark, isPopupWindow]);

  useEffect(() => {
    if (chartRef.current) {
      if (!chartInstance.current) {
        chartInstance.current = echarts.init(chartRef.current);
      }
      
      chartInstance.current.setOption(option);
      
      // Use the finished event to notify parent
      chartInstance.current.on('finished', () => {
        if (data.length > 0) {
          onChartReady?.();
        }
      });

      const handleResize = () => {
        chartInstance.current?.resize();
      };
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        if (chartInstance.current) {
          chartInstance.current.off('finished');
          chartInstance.current.dispose();
        }
        chartInstance.current = null;
      };
    }
  }, [option, data, onChartReady]);

  return (
    <div 
      ref={chartRef} 
      style={{ 
        width: `${width}`, 
        height: `${height}` 
      }} 
    />
  );
};

export default BarChartComponent;
