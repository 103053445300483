import { createAction } from "@reduxjs/toolkit";
import { AnalyticsFilters, AnalyticsResult } from "types/Analytics.types";

export const FETCH_ANALYTICS = "FETCH_ANALYTICS";
export const FETCH_ANALYTICS_SUCCESS = "FETCH_ANALYTICS_SUCCESS";
export const FETCH_ANALYTICS_FAILURE = "FETCH_ANALYTICS_FAILURE";
export const CLEAR_ANALYTICS = "CLEAR_ANALYTICS";

export const fetchAnalytics = createAction<{
  userId: string;
  userCountry: string;
  type: "dx" | "us-states";
  filters?: AnalyticsFilters;
  calculateAwards: boolean;
  userCallSign?: string;
}>(FETCH_ANALYTICS);

export const fetchAnalyticsSuccess = createAction<AnalyticsResult>(
  FETCH_ANALYTICS_SUCCESS
);

export const fetchAnalyticsFailure = createAction<string>(
  FETCH_ANALYTICS_FAILURE
);

export const clearAnalytics = createAction(CLEAR_ANALYTICS);
