import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import LogBookService from "services/logBook.service";
import {
  FETCH_ANALYTICS,
  fetchAnalyticsSuccess,
  fetchAnalyticsFailure,
} from "../Actions/analytics.actions";
import { AnalyticsFilters, AnalyticsResult } from "types/Analytics.types";
import { getCountryFromCallSign } from "firebase-config";
import { CountryADIFData } from "types/Models";

interface FetchAnalyticsPayload {
  userId: string;
  userCountry: string;
  type: "dx" | "us-states";
  filters?: AnalyticsFilters;
  calculateAwards: boolean;
  userCallSign?: string;
}

function* fetchAnalyticsSaga(action: PayloadAction<FetchAnalyticsPayload>) {
  try {
    const {
      userId,
      userCountry,
      type,
      filters,
      calculateAwards,
      userCallSign,
    } = action.payload;

    const analyticsType = type === "dx" ? "DX" : "STATES";
    const analyticsFilters = {
      ...filters,
      mode: filters?.mode === "ALL" ? undefined : filters?.mode,
      band: filters?.bands?.length === 1 ? filters.bands[0] : undefined,
    };
    let userCallSignCountry = "";
    // if the user doesnt have country on their profile we try to get their country from theircallsign, which usually works
    // this is a temporary step until we have all users with country on their profile once we are done with the migration and data
    // correction work
    if (!userCountry && userCallSign) {
      const countryData: { data: CountryADIFData } = yield call(
        getCountryFromCallSign,
        userCallSign
      );
      if (countryData && countryData?.data?.Country) {
        userCallSignCountry = countryData.data?.Country;
      }
    }

    const response: AnalyticsResult = yield call(LogBookService.getAnalytics, {
      userId,
      userCountry: userCountry ? userCountry : userCallSignCountry,
      type: analyticsType,
      filters: analyticsFilters,
      calculateAwards,
    });

    yield put(fetchAnalyticsSuccess(response));
  } catch (error) {
    yield put(
      // we should not be showing any errors that get thrown from the functions we call
      fetchAnalyticsFailure(
        "Failed to fetch analytics, please contact support for further assistance."
      )
    );
  }
}

export function* watchAnalytics() {
  yield takeLatest(FETCH_ANALYTICS, fetchAnalyticsSaga);
}
