import { IoCreateOutline, IoEyeOutline } from "react-icons/io5";
import { AiOutlineDelete } from "react-icons/ai";
import { CONTACT, MY_PROFILE_ROUTE } from "constants/Links";
import { TableEntColProps } from "types/Component";
import { globalNavigate } from "routes/GlobalRoutes";
import useLogBook from "hooks/logBook.hook";
import useConfig from "hooks/config.hook";
import { useEffect } from "react";
import CustomValidation from "helpers/Validation";
import Icons from "assets/Icons";
import {
  convertDistance,
  correctUSCountryName,
  getShortDistanceUnit,
} from "helpers/Utils";
import ReactCountryFlag from "react-country-flag";
import { setContactDetails } from "store/Actions/logBook.actions";
import { useDispatch } from "react-redux";
import { UserAvatar } from "components/UserAvatar";
import { Templates } from "constants/Config";
import CountryDxccFlag from "components/CountryDxccFlag";
import { Activities } from "types/Models";
import { useUserSettings } from "hooks/useUserSettings.hook";

function TableEntColCompact({
  item,
  className,
  editable,
  missingFields,
  guestUser,
  showExchange,
}: TableEntColProps) {
  const { setNotes, deleteContactData, setEditContactModalData } = useLogBook();
  const { handlePrimaryModal } = useConfig();
  const dispatch = useDispatch();
  const { settings } = useUserSettings();
  useEffect(() => {
    setNotes(item.notes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openEditModal = (value: any) => {
    if (missingFields && !value) {
      setEditContactModalData(item);
    }
  };
  //some contacts seems to have theirPark with an "s" some dont, a temporary workaround until we fix the data and field names

  // Determine if the user has their park
  function hasTheirPark() {
    if (item?.theirPark && typeof item.theirPark === "object") {
      return Object.keys(item.theirPark).length > 0;
    }
    if (item?.theirParks && typeof item.theirParks === "object") {
      return Object.keys(item.theirParks).length > 0;
    }
    return item?.theirPark?.length > 0;
  }

  // Icons for each activity
  function getPotaIcon(hasPark: any) {
    return hasPark ? (
      <img
        src={Icons.parkToParkWhite}
        alt="pota icon"
        className="filter invert dark:invert-0"
      />
    ) : (
      <img
        src={Icons.parkWhite}
        alt="pota icon"
        className="filter invert dark:invert-0"
      />
    );
  }

  function getWwffIcon() {
    return item?.theirActivities?.includes(Activities.WWFF) ? (
      <img
        src={Icons.WtoW}
        alt="wwff icon"
        className="filter invert dark:invert-0"
      />
    ) : (
      <img
        src={Icons.WwffAutoSpot}
        alt="wwff icon"
        className="filter invert dark:invert-0"
      />
    );
  }

  function getSotaIcon() {
    return item?.theirActivities?.includes(Activities.SOTA) ? (
      <img
        src={Icons.StoS}
        alt="sota icon"
        className="filter invert dark:invert-0"
      />
    ) : (
      <img
        src={Icons.SotaAutoSpot}
        alt="sota icon"
        className="filter invert dark:invert-0"
      />
    );
  }

  function getIotaIcon() {
    return item?.theirActivities?.includes(Activities.IOTA) ? (
      <img
        src={Icons.ItoI}
        alt="iota icon"
        className="filter invert dark:invert-0"
      />
    ) : (
      <img
        src={Icons.IotaAutoSpot}
        alt="iota icon"
        className="filter invert dark:invert-0"
      />
    );
  }

  // Get icon based on primary activity
  function getPrimaryActivityIcon(hasPark: any) {
    const primary = item?.primaryActivity;

    switch (primary) {
      case Activities.POTA:
        return getPotaIcon(hasPark);

      case Activities.WWFF:
        return getWwffIcon();

      case Activities.SOTA:
        return getSotaIcon();

      case Activities.IOTA:
        return getIotaIcon();

      default:
        return null;
    }
  }

  // Fallback icon if there's no primary activity
  function getFallbackIcon(hasPark: any) {
    if (item?.tags?.includes(Templates.POTA)) {
      return hasPark ? (
        <img
          src={Icons.parkToParkWhite}
          alt="pota icon"
          className="filter invert dark:invert-0"
        />
      ) : (
        <img
          src={Icons.parkWhite}
          alt="pota icon"
          className="filter invert dark:invert-0"
        />
      );
    }
    return null;
  }
  const parkPresence = hasTheirPark();
  const activityIcon =
    getPrimaryActivityIcon(parkPresence) || getFallbackIcon(parkPresence);

  return (
    <tr className={`${className ? className : ""}`}>
      <td
        className="pl-2 py-2 dark:text-white text-[#2b2e48] text-xs font-[Barlow] font-normal cursor-pointer"
        onClick={() => {
          if (item.theirCallsign) {
            window.location.href = `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
              item?.theirCallsign
            )}?tab=Logbook&contact-tab=entries`;
          } else {
            if (missingFields && !item.theirCallsign) {
              setEditContactModalData(item);
            }
          }
        }}
      >
        <div className="flex items-center gap-2">
          {item?.theirProfilePic ? (
            <img
              className="w-4 h-4 rounded-full object-cover"
              src={item.theirProfilePic}
              alt=""
            />
          ) : (
            <UserAvatar className="w-4 h-4" />
          )}
          {missingFields && !item.theirCallsign ? (
            <p className="text-[#A16207]">Click to add</p>
          ) : (
            <p>{item?.theirCallsign}</p>
          )}

          <CountryDxccFlag
            countryCode={item?.theirCountry || item?.country || ""}
            flagCode={item?.flagCode || ""}
            imageClassName="w-[1.2rem] h-[1.2rem]"
          />
          {correctUSCountryName(item?.theirCountry) === "United States" ? (
            <p className=" max-w-[60px] dark:text-white text-[#2b2e48]">
              {item?.theirState || item?.state}
            </p>
          ) : null}
          {item.duplicate && !guestUser ? (
            <div className="bg-[#978a27] px-1 rounded-full font-[Play]">
              DUPE
            </div>
          ) : null}
        </div>
      </td>

      {showExchange ? (
        <>
          <td className="pl-4 text-xs dark:text-white text-[#2b2e48] font-[Barlow] py-3 ">
            <p className="">{item?.exchangeOne ? item?.exchangeOne : ""}</p>
          </td>
          <td className="pl-4 text-xs dark:text-white text-[#2b2e48] font-[Barlow] py-3 ">
            <p className="">{item?.exchangeTwo ? item?.exchangeTwo : ""}</p>
          </td>
        </>
      ) : (
        <>
          <td
            className="pl-2 text-xs text-[#6D6D8D] font-[Barlow] py-2 "
            onClick={() => openEditModal(item?.rstSent)}
          >
            {missingFields && !item.rstSent ? (
              <p className=" text-[#A16207] cursor-pointer">Click to add</p>
            ) : (
              <p className="">{item?.rstSent}</p>
            )}
          </td>
          <td
            className="pl-2 text-xs text-[#6D6D8D] font-[Barlow] py-2 "
            onClick={() => openEditModal(item?.rstRCVD)}
          >
            {missingFields && !item.rstRCVD ? (
              <p className=" text-[#A16207] cursor-pointer">Click to add</p>
            ) : (
              <p className="">{item?.rstRCVD}</p>
            )}
          </td>
        </>
      )}

      <td
        className="pl-2 text-xs dark:text-white text-[#2b2e48] font-[Barlow] py-2 "
        onClick={() => openEditModal(item?.frequency)}
      >
        {missingFields && !item?.frequency ? (
          <p className=" text-[#A16207] cursor-pointer">Click to add</p>
        ) : (
          <p className="">{item?.frequency}</p>
        )}
      </td>
      <td
        className="pl-2 text-xs dark:text-white text-[#2b2e48] font-[Barlow] py-2 "
        onClick={() => openEditModal(item?.userMode)}
      >
        {missingFields && !item?.userMode ? (
          <p className=" text-[#A16207] cursor-pointer">Click to add</p>
        ) : (
          <p className="">{item?.userMode}</p>
        )}
      </td>
      <td className="pl-2 text-xs dark:text-white text-[#2b2e48] font-[Barlow] py-2 ">
        <p className="">
          {item?.distance
            ? `${convertDistance(
                item?.distance,
                settings.distanceUnit
              ).toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              })} ${getShortDistanceUnit(settings.distanceUnit)}`
            : "--"}
        </p>
      </td>
      <td className="pl-2 text-xs dark:text-white text-[#2b2e48] font-[Barlow] py-2 ">
        {activityIcon}
      </td>
      <td className="pl-2 text-xs dark:text-white text-[#2b2e48] font-[Barlow] py-2 ">
        {item?.confirmed ? (
          <img src={Icons.Confirmed} alt="confirmed icon" />
        ) : (
          <p>--</p>
        )}
      </td>
      <td className="py-2">
        <div className="flex flex-row gap-2">
          <div
            className="flex justify-center items-center dark:text-white text-[#2b2e48]  cursor-pointer"
            onClick={(e) => {
              dispatch(setContactDetails({}));
              globalNavigate(`${CONTACT}?contactId=${item?.id}`, {
                state: item,
              });
            }}
          >
            <IoEyeOutline size={16} />
          </div>
          {editable && !guestUser ? (
            <>
              <div
                className="flex justify-center items-center dark:text-white text-[#2b2e48]  cursor-pointer"
                onClick={(e) => {
                  // globalNavigate(`${LOG_CONTACT_ROUTE}/edit-contact`, {
                  //   state: item,
                  // })
                  setEditContactModalData(item);
                }}
              >
                {/* <IoSettingsSharp size={20} /> */}
                <IoCreateOutline size={16} />
              </div>

              <div
                className="flex justify-center items-center dark:text-white text-[#2b2e48]  cursor-pointer"
                onClick={() => {
                  handlePrimaryModal({
                    title: "Are you sure you want to delete contact?",
                    type: "Delete Contact",
                    onCancelPressed: () => handlePrimaryModal(null),
                    onSubmitPressed: () => {
                      deleteContactData(item.id, item);
                      handlePrimaryModal(null);
                    },
                    submitText: "Yes",
                    cancelText: "No",
                  });
                }}
              >
                {/* <IoSettingsSharp size={20} /> */}
                <AiOutlineDelete size={16} />
              </div>
            </>
          ) : null}
        </div>
      </td>
    </tr>
  );
}

export default TableEntColCompact;
