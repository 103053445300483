import Icons from "assets/Icons";
import { fi } from "date-fns/locale";
import useTheme from "hooks/useTheme.hook";
import React from "react";
import { TopTabNavProps } from "types/Component";

function TopTabNav({
  selectedTabIndex,
  setSelectedTabIndex,
  firstIndexText,
  secondIndexText,
  thirdIndexText,
  fourthIndexText,
  fifthIndexText,
  openSpottingPopup,
  openMapPopup,
  openStatsPopup,
  showExternal = false,
  className,
}: TopTabNavProps) {

  const { theme } = useTheme();

  return (
    <div className={`${className ? className : ''} flex justify-between items-center w-full`}>
      <button
        className={`text-[#17F9DA] text-base cursor-pointer font-[Play] font-bold w-[50%] pl-4 py-2 ${
          selectedTabIndex === "entries" ? "dark:bg-[#151762] bg-[#1e40af] text-[#17F9DA]" : "dark:bg-[#151541] bg-[#94a3b883] dark:text-[#17F9DA] text-[#2b2e48]"
        }`}
        onClick={() => setSelectedTabIndex("entries")}
      >
        <div className="w-full flex items-center justify-start">
          {firstIndexText}
        </div>
      </button>
      <button
        className={`dark:text-[#17F9DA] text-base cursor-pointer font-[Play] font-bold w-[50%] pl-4 py-2 ${
          selectedTabIndex === "qso" ? "dark:bg-[#151762] bg-[#1e40af] text-[#17F9DA]" : "dark:bg-[#151541] bg-[#94a3b883] dark:text-[#17F9DA] text-[#2b2e48]"
        }`}
        onClick={() => setSelectedTabIndex("qso")}
      >
        <div className="w-full flex items-center justify-start gap-2">
          {secondIndexText}
          {/* { showExternal ? <img onClick={openMapPopup} className="h-5 w-5" src={Icons.External} alt="" /> : null} */}
        </div>
      </button>
      {thirdIndexText ? (
        <button
          className={`text-[#17F9DA] text-base cursor-pointer font-[Play] font-bold w-[50%] pl-4 py-2 ${
            selectedTabIndex === "spotting" ? "dark:bg-[#151762] bg-[#1e40af] text-[#17F9DA]" : "dark:bg-[#151541] bg-[#94a3b883] dark:text-[#17F9DA] text-[#2b2e48]"
          }`}
          onClick={() => setSelectedTabIndex("spotting")}
        >
          <div className="w-full justify-between flex items-center pr-4">
            <div className="w-full flex items-center justify-start gap-2">
              {thirdIndexText}
              { showExternal ? <img onClick={openSpottingPopup} className="h-5 w-5" src={ theme === 'dark' || selectedTabIndex === "spotting"  ? Icons.External: Icons.ExternalDark} alt="" /> : null}
            </div>
          </div>
        </button>
      ) : null}
      {fourthIndexText ? (
        <button
          className={`text-[#17F9DA] text-base cursor-pointer font-[Play] font-bold w-[50%] pl-4 py-2 ${
            selectedTabIndex === "stats" ? "dark:bg-[#151762] bg-[#1e40af] text-[#17F9DA]" : "dark:bg-[#151541] bg-[#94a3b883] dark:text-[#17F9DA] text-[#2b2e48]"
          }`}
          onClick={() => setSelectedTabIndex("stats")}
        >
          <div className="w-full justify-between flex items-center pr-4">
            <div className="w-full flex items-center justify-start gap-2">
              {fourthIndexText}
              { showExternal ? <img onClick={openStatsPopup} className="h-5 w-5" src={ theme === 'dark' || selectedTabIndex === "stats"  ? Icons.External: Icons.ExternalDark} alt="" /> : null}
            </div>
          </div>
        </button>
      ): null}
      {fifthIndexText ? (
        <button
          className={`text-[#17F9DA] text-base cursor-pointer font-[Play] font-bold w-[50%] pl-4 py-2 ${
            selectedTabIndex === "objectives" ? "dark:bg-[#151762] bg-[#1e40af] text-[#17F9DA]" : "dark:bg-[#151541] bg-[#94a3b883] dark:text-[#17F9DA] text-[#2b2e48]"
          }`}
          onClick={() => setSelectedTabIndex("objectives")}
        >
          <div className="w-full justify-between flex items-center pr-4">
            <div className="w-full flex items-center justify-start gap-2">
              {fifthIndexText}
            </div>
          </div>
        </button>
      ) : null}
    </div>
  );
}

export default TopTabNav;
