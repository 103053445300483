import { useDispatch, useSelector } from "react-redux";
import {
  addPotaAutoSpotAction,
  addWwffAutoSpotAction,
  getNearbyParkReferencesAction,
  getParkByReferenceAction,
  getParkReferenceSuggestionsAction,
  setAutoSpotDataAction,
  setNearbyParkReferencesAction,
  setParkByReferenceAction,
  setParkReferenceSuggestionsAction,
} from "store/Actions/pota.actions";
import {
  getAutoSpotDataSelector,
  getFindMyParkLoadingSelector,
  getNearbyParkReferencesDataSelector,
  getParkByReferenceDataSelector,
  getParkByReferenceLoadingSelector,
  getParkReferenceSuggestionsDataSelector,
} from "store/Reducers/pota.reducer";
import { ParkReferenceModel, PotaAutoSpotPayload } from "types/Models";

const usePota = () => {
  // Props
  const dispatch = useDispatch();
  const parkReferenceData = useSelector(getParkByReferenceDataSelector);
  const isParkReferenceDataLoading = useSelector(
    getParkByReferenceLoadingSelector
  );
  const isFindMyParkLoading = useSelector(getFindMyParkLoadingSelector);

  const parkReferenceSuggestionsData = useSelector(
    getParkReferenceSuggestionsDataSelector
  );

  const nearbyParkReferencesData = useSelector(
    getNearbyParkReferencesDataSelector
  );

  const autoSpotData = useSelector(getAutoSpotDataSelector);

  // Functions
  const getParkByReference = (
    parkReference: {
      value: string;
      key: string;
    },
    activityType?: string
  ) => {
    dispatch(getParkByReferenceAction(parkReference, activityType));
  };

  const setParkByReference = (
    payload: { [key: string]: string | null } | null
  ) => {
    dispatch(setParkByReferenceAction(payload));
  };

  const getParReferenceSuggestions = (
    parkReference: string,
    activityType?: string
  ) => {
    dispatch(getParkReferenceSuggestionsAction(parkReference, activityType));
  };

  const setParReferenceSuggestions = (payload: any) => {
    dispatch(setParkReferenceSuggestionsAction(payload));
  };

  const getNearbyParkReferences = (
    center: [number, number],
    activityType?: string
  ) => {
    dispatch(getNearbyParkReferencesAction(center, undefined, activityType));
  };

  const setNearbyParkReferences = (payload: any) => {
    dispatch(setNearbyParkReferencesAction(payload));
  };

  const addPotaAutoSpot = (
    payload: PotaAutoSpotPayload,
    logBookId?: string
  ) => {
    dispatch(addPotaAutoSpotAction(payload, logBookId));
  };
  const addWwffAutoSpot = (payload: any, logBookId?: string) => {
    dispatch(addWwffAutoSpotAction(payload, logBookId));
  };

  const setAutoSpotData = (payload: Partial<PotaAutoSpotPayload> | null) => {
    dispatch(setAutoSpotDataAction(payload));
  };

  return {
    // state
    parkReferenceData,
    isParkReferenceDataLoading,
    parkReferenceSuggestionsData,
    nearbyParkReferencesData,
    autoSpotData,
    isFindMyParkLoading,

    // functions
    getParkByReference,
    setParkByReference,
    getParReferenceSuggestions,
    setParReferenceSuggestions,
    getNearbyParkReferences,
    setNearbyParkReferences,
    addPotaAutoSpot,
    setAutoSpotData,
    addWwffAutoSpot,
  };
};

export default usePota;
