import { useState } from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { PasswordInputProps } from "types/Component";

function PasswordInput({
  password,
  setPassword,
  error,
  className,
}: PasswordInputProps) {
  const [passwordVisibile, setPasswordVisibile] = useState(false);

  return (
    <div
      className={`w-full h-10 dark:bg-transparent bg-[#f1f3f4] dark:text-white text-[#2b2e48] border  border-solid border-[#dcdcdd] dark:border-white relative flex ${
        className ? className : "mb-5"
      }`}
    >
      <input
        className={`w-full h-full px-3 bg-transparent dark:text-white text-[#2b2e48] ${
          error ? "border-red" : ""
        }`}
        placeholder="Password *"
        type={passwordVisibile ? "text" : "password"}
        name="password"
        id=""
        value={password}
        onChange={(e: any) => setPassword(e?.target?.value)}
      />
      <div
        className="h-full w-8 absolute right-0 flex items-center cursor-pointer"
        onClick={() => setPasswordVisibile(!passwordVisibile)}
      >
        {passwordVisibile ? (
          <IoMdEye className="dark:text-white text-[#2b2e48]" size={20} />
        ) : (
          <IoMdEyeOff className="dark:text-white text-[#2b2e48]" size={20} />
        )}
      </div>
    </div>
  );
}

export default PasswordInput;
