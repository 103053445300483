import Icons from "assets/Icons";
import Images from "assets/Images";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { HorizontalDivider } from "components/Divider";
import TitledInputDefault from "components/Inputs/TitledInputDefault";
import { useSupport } from "hooks/useBugReport.hook";
import useTheme from "hooks/useTheme.hook";
import { FC, useState } from "react";

export const ReportBug: FC<any> = ({ onClose }) => {
  const { isLoading, reportBugAnonymously } = useSupport();
  const { theme } = useTheme();

  const [formValues, setFormValues] = useState({
    details: "",
    email: "",
  });

  const handleSubmit = async () => {
    reportBugAnonymously(formValues, () => {
      setFormValues({
        details: "",
        email: "",
      });
      onClose();
    });
  };

  return (
    <section className="relative w-full h-full flex justify-center items-center dark:bg-[#000030] bg-[#f1f3f4]">
      <img
        src={theme === "dark" ? Images.WorldBg : Images.SpaceBg}
        className="absolute top-0 left-0 z-0 h-full w-full object-cover"
        alt="background"
      />
      <div className="relative flex-1 flex items-center justify-center">
        <div
          className={`${
            theme === "dark" ? "glassmorphism" : "bg-white"
          } w-[80%] flex flex-col items-center gap-4 p-10 dark:text-white text-[#2b2e48]`}
        >
          <div className="flex flex-col w-full">
            <div className="text-3xl font-bold font-Play">Report a Bug 🐞</div>
            <div className="text-lg font-bold font-Play">
              Thanks for the help!
            </div>
          </div>

          <HorizontalDivider />

          <div className="flex flex-col gap-4 w-full font-[Barlow] text-sm">
            <div className="font-bold">Instructions:</div>
            <div>
              Please write a description of the bug you are facing. Here are a
              few tips to help us solve the bug faster:
            </div>

            <ol className="list-decimal list-inside pl-2">
              <li>Add a good description of the bug with helpful details</li>
              <li>
                If possible, add any links that might help, such as a link to
                the affected contact or logbook
              </li>
              <li>
                If possible, you can also use{" "}
                <a
                  className="dark:text-[#17F9DA] text-[#00D4FF] underline"
                  target="_blank"
                  href="https://tinyurl.com/mr44mpjz"
                  rel="noreferrer"
                >
                  Loom
                </a>{" "}
                to record a video to help us understand the bug and add the link
                in the description - but don’t worry if you don’t have time to
                do this!
              </li>
            </ol>

            <div className="font-bold">
              If this is not a bug but instead a feature request or general
              question,{" "}
              <a
                href="https://community.worldradioleague.com"
                target="_blank"
                rel="noreferrer"
                className="underline text-[#00D4FF]"
              >
                please visit the community.
              </a>
            </div>
          </div>

          <TitledInputDefault
            setText={(e) => {
              setFormValues((prev: any) => {
                return {
                  ...prev,
                  email: e.target.value,
                };
              });
            }}
            // title="Email: "
            name="email"
            type="email"
            placeHolder="Enter your email *"
            value={formValues?.email}
            width="w-full"
            className="bg-white bg-opacity-10"
            tabindex={1}
          />
          <TitledInputDefault
            setText={(e) => {
              setFormValues((prev: any) => {
                return {
                  ...prev,
                  details: e.target.value,
                };
              });
            }}
            title="Please provide as much detail as possible: "
            name="details"
            placeHolder="details about the bug..."
            value={formValues?.details}
            width="w-full"
            className="bg-white bg-opacity-10 h-[140px]"
            tabindex={2}
            multiple
          />

          <div className="flex w-[60%]">
            <BorderButtonSecondary
              isLoading={isLoading}
              text="Submit Bug Report"
              onClick={handleSubmit}
              childClassName="text-sm"
              tabIndex={2}
            />
          </div>

          <div
            className="absolute top-2 right-2 cursor-pointer"
            onClick={onClose}
            tabIndex={55}
          >
            <img
              src={Icons.GlowingClose}
              className="h-8 w-8 bg-gray-300 rounded-full dark:bg-transparent"
              alt="close"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
