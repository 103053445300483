import Icons from "assets/Icons";
import BarChartComponent from "components/Charts/BarChart/BarChartBasic";
import { db } from "firebase-config";
import { doc, getDoc } from "firebase/firestore";
import { extractMode } from "helpers/Utils";
import useContest from "hooks/contest.hook";
import { useEffect, useState } from "react";

function ContestStatistics() {
  const { contestDetail, contestContacts } = useContest();
  const [userBandsLabels, setUserBandsLabels] = useState<string[]>([]);
  const [userBandsData, setUserBandsData] = useState<any[]>([]);
  const [userModesLabels, setUserModesLabels] = useState<string[]>([]);
  const [userModesData, setUserModesData] = useState<any[]>([]);
  const [userContactByHourLabels, setUserContactByHourLabels] = useState<string[]>([]);
  const [userContactByHourData, setUserContactByHourData] = useState<any[]>([]);

  useEffect(() => {

    const isValidContest = contestDetail?.isWFDContest || contestDetail?.isCQContest;

    if (!isValidContest){
      return () => {};
    }

    const fetchContestSummary = async (contestId: string | undefined) => {
      if (!contestId) return;
      try {
        const docRef = doc(
          db,
          "Analytics",
          "Contest",
          contestId,
          "ContestSummary"
        );
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const bandsLabels = data?.bandsLabels || [];
          const bandsData = data?.bandsData || [];
          const modesLabels = data?.modesLabels || [];
          const modesData = data?.modesData || [];
          const contactByHourLabels = data?.contactByHourLabels || [];
          const contactByHourData = data?.contactByHourData || [];

          setUserBandsLabels(bandsLabels);
          setUserBandsData(bandsData);
          setUserModesLabels(modesLabels);
          setUserModesData(modesData);
          setUserContactByHourLabels(contactByHourLabels);
          setUserContactByHourData(contactByHourData);
        }
      } catch (err) {
        console.error("Error fetching contest summary:", err);
      }
    };

    // Fetch immediately when `contestDetail.id` changes
    fetchContestSummary(contestDetail?.id);

    const intervalId = setInterval(() => {
      fetchContestSummary(contestDetail?.id);
    }, 60 * 1000); // 1.5 minutes in milliseconds

    return () => {
      clearInterval(intervalId);
    };
  }, [contestDetail]);

  useEffect(() => {
    const isValidContest = contestDetail?.isWFDContest || contestDetail?.isCQContest;

    if (isValidContest){
      return () => {};
    }

    if (contestContacts?.length) {
      const bands: any = {};
      const modes: any = {};
      const contactByHour: any = {};
      contestContacts.forEach((contact: any) => {
        // Handle both Timestamp and string formats for contactTimeStamp
        let contactDate: Date | null;

        if (contact.contactTimeStamp?.seconds) {
          contactDate = new Date(contact.contactTimeStamp.seconds * 1000); // Convert seconds to milliseconds
        } else if (typeof contact.contactTimeStamp === "string") {
          contactDate = new Date(contact.contactTimeStamp);
        } else {
          contactDate = null;
        }

        if (contactDate) {
          // Format the date (e.g., "2024-12-31") to track by day
          const formattedDate = contactDate.toISOString().split("T")[0]; // Get the date in "YYYY-MM-DD" format

          // Get the UTC hour
          const utcHour = contactDate.getUTCHours();

          // Initialize the day in contactByDay if it doesn't exist yet
          if (!contactByHour[formattedDate]) {
            contactByHour[formattedDate] = {};
          }

          // Increment the contact count for the specific hour on the correct date
          if (!contactByHour[formattedDate][utcHour]) {
            contactByHour[formattedDate][utcHour] = 0;
          }

          contactByHour[formattedDate][utcHour]++;
        }

        const band = extractMode(contact.band);

        if (band) {
          if (bands[band]) {
            bands[band]++;
          } else {
            bands[band] = 1;
          }
        }

        const mode = contact.userMode;

        if (mode) {
          if (modes[mode.toUpperCase()]) {
            modes[mode.toUpperCase()]++;
          } else {
            modes[mode.toUpperCase()] = 1;
          }
        }
      });

      const bandsLabels = Object.keys(bands);
      const bandsData = Object.values(bands);

      const modesLabels = Object.keys(modes);
      const modesData = Object.values(modes);

      // Combine all data into a single array
      const combinedData: { hour: number; count: number; date: string }[] = [];

      Object.keys(contactByHour)
        .sort()
        .forEach((date: any) => {
          const hoursData = contactByHour[date];
          for (let hour = 0; hour < 24; hour++) {
            if (hoursData[hour]) {
              combinedData.push({ hour, count: hoursData[hour], date });
            }
          }
        });

      const contactByHourLabels = combinedData.map((data) =>
        data.hour.toString()
      );
      const contactByHourData = combinedData.map((data) => data.count);

      setUserBandsLabels(bandsLabels);
      setUserBandsData(bandsData);
      setUserModesLabels(modesLabels);
      setUserModesData(modesData);
      setUserContactByHourLabels(contactByHourLabels);
      setUserContactByHourData(contactByHourData);
    }
  }, [contestContacts, contestDetail]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row items-center dark:text-white text-[#2b2e48] gap-4 text-xl font-bold font-Play uppercase">
        <img src={Icons.StatisticsIcon} alt="" />
        Statistics
      </div>

      <div className="mt-4 overflow-y-hidden">
        <div className="w-full">
          <div className="flex flex-col w-full p-10 dark:bg-[#151541] bg-white mt-2.5">
            <div className="flex flex-col md:flex-row w-full gap-4">
              <div className="flex-1">
                <div className="dark:text-white text-[#2b2e48] font-Barlow text-xl ml-10">
                  Bands
                </div>
                <BarChartComponent
                  labels={userBandsLabels}
                  data={userBandsData}
                />
              </div>
              <div className="flex-1">
                <div className="dark:text-white text-[#2b2e48] font-Barlow text-xl ml-10">
                  Modes
                </div>
                <BarChartComponent
                  labels={userModesLabels}
                  data={userModesData}
                />
              </div>
            </div>
            <div className="flex flex-col w-full items-start justify-start">
              <div className="dark:text-white text-[#2b2e48] font-Barlow text-xl ml-10">
                QSO by Hour
              </div>
              <BarChartComponent
                labels={userContactByHourLabels}
                data={userContactByHourData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContestStatistics;
