import React, { useEffect, useRef, useMemo, useState } from "react";
import * as echarts from 'echarts';
import useTheme from "hooks/useTheme.hook";

interface ChartStats {
  stats: Record<string, number>;
  topItem: string;
  total: number;
}

interface BandModeStatsProps {
  type: "band" | "mode";
  stats: ChartStats;
  onChartReady?: () => void;
  isPopupWindow?: boolean;
}

export const BandModeStats = React.memo<BandModeStatsProps>(({
  type,
  stats,
  onChartReady,
  isPopupWindow = false
}) => {
  const { theme } = useTheme();
  const isDarkMode = theme === "dark";
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<echarts.ECharts | null>(null);
  const [isMounted, setIsMounted] = useState(false);
  const [initAttempts, setInitAttempts] = useState(0);

  const chartData = useMemo(() => {
    const sortedEntries = Object.entries(stats.stats)
      .sort(([, a], [, b]) => b - a)
      
    return {
      labels: sortedEntries.map(([label]) => label),
      data: sortedEntries.map(([, count]) => count)
    };
  }, [stats.stats]);

  // Initialize chart on mount
  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  // Handle chart initialization and updates
  useEffect(() => {
    if (!chartRef.current || !isMounted) return;

    // For popup windows, we need to give the DOM more time to stabilize
    const initDelay = isPopupWindow ? 300 : 50;
    
    const initChart = () => {
      if (!chartRef.current) return;
      
      if (chartInstance.current) {
        chartInstance.current.dispose();
      }

      // Ensure the container has dimensions before initializing
      const containerWidth = chartRef.current.clientWidth;
      const containerHeight = chartRef.current.clientHeight;
      
      if (containerWidth <= 0 || containerHeight <= 0) {
        // If container doesn't have dimensions yet and we haven't tried too many times,
        // schedule another attempt
        if (initAttempts < 5) {
          setTimeout(() => {
            setInitAttempts(prev => prev + 1);
          }, 200);
        }
        return;
      }

      chartInstance.current = echarts.init(chartRef.current);
      
      const option: echarts.EChartsOption = {
        // Completely disable animations for popup windows
        animation: !isPopupWindow,
        animationDuration: isPopupWindow ? 0 : 1000,
        animationEasing: 'linear',
        grid: {
          left: '5%',
          right: '15%',
          top: '5%',
          bottom: '5%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            color: isDarkMode ? '#8f91ab' : '#666',
            fontSize: containerWidth < 350 ? 10 : 12,
            formatter: (value: number) => {
              if (containerWidth < 300) {
                return value > 0 ? value.toString() : '0';
              }
              return value.toString();
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: isDarkMode ? '#363870' : '#f0f0f0'
            }
          }
        },
        yAxis: {
          type: 'category',
          data: chartData.labels,
          axisLabel: {
            color: isDarkMode ? '#fff' : '#2b2e48',
            fontSize: containerWidth < 350 ? 10 : 12,
            width: containerWidth < 350 ? 40 : 60,
            overflow: 'truncate',
            formatter: (value: string) => {
              if (containerWidth < 300 && value.length > 5) {
                return value.substring(0, 4) + '...';
              }
              return value;
            }
          },
          axisTick: { show: false }
        },
        
        series: [{
          // Disable series animation as well for popups
          animation: !isPopupWindow,
          animationDuration: isPopupWindow ? 0 : 1000,
          animationDelay: 0,
          type: 'bar',
          data: chartData.data,
          barWidth: '40%',
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 0,
              colorStops: [{
                offset: 0,
                color: '#4da1ff' // Start color
              }, {
                offset: 1,
                color: '#4dffde' // End color
              }]
            }
          },
          label: {
            show: containerWidth > 250,
            position: 'right',
            color: isDarkMode ? '#fff' : '#2b2e48',
            fontSize: containerWidth < 350 ? 10 : 12,
            formatter: (params: any) => {
              if (containerWidth < 350) {
                return params.value;
              }
              return `${params.value} QSOs`;
            }
          }
        }]
      };

      chartInstance.current.setOption(option);
      
      // For popup windows, set up multiple resize attempts to ensure proper rendering
      if (isPopupWindow) {
        [100, 300, 500, 800].forEach(delay => {
          setTimeout(() => {
            if (chartInstance.current) {
              chartInstance.current.resize();
            }
          }, delay);
        });
      } else {
        // For normal rendering, just one resize after a short delay
        setTimeout(() => {
          if (chartInstance.current) {
            chartInstance.current.resize();
          }
        }, 100);
      }
      
      if (onChartReady) {
        setTimeout(onChartReady, 150);
      }
    };

    // Initialize chart with delay
    const initTimer = setTimeout(initChart, initDelay);

    // Setup resize handler
    const handleResize = () => {
      if (chartInstance.current) {
        chartInstance.current.resize();
        handleScreenSizeChange();
      }
    };

    // Add a handler specifically for small screen size changes
    const handleScreenSizeChange = () => {
      if (chartRef.current && chartInstance.current) {
        const newWidth = chartRef.current.clientWidth;
        
        // If width changed significantly, update options for responsive layout
        if (newWidth > 0) {
          // Create a new option object instead of trying to modify the existing one
          const updatedOption: echarts.EChartsOption = {
            xAxis: {
              axisLabel: {
                fontSize: newWidth < 350 ? 10 : 12,
                formatter: (value: number) => {
                  if (newWidth < 300) {
                    return value > 0 ? value.toString() : '0';
                  }
                  return value.toString();
                }
              }
            },
            yAxis: {
              axisLabel: {
                fontSize: newWidth < 350 ? 10 : 12,
                width: newWidth < 350 ? 40 : 60,
                overflow: 'truncate',
                formatter: (value: string) => {
                  if (newWidth < 300 && value.length > 5) {
                    return value.substring(0, 4) + '...';
                  }
                  return value;
                }
              }
            },
            series: [
              {
                label: {
                  show: newWidth > 250,
                  fontSize: newWidth < 350 ? 10 : 12,
                  formatter: (params: any) => {
                    if (newWidth < 350) {
                      return params.value;
                    }
                    return `${params.value} QSOs`;
                  }
                }
              }
            ]
          };
          
          // Apply the updated options
          chartInstance.current.setOption(updatedOption, false, false);
          chartInstance.current.resize();
        }
      }
    };

    window.addEventListener('resize', handleResize);

    // Setup visibility change handler
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        initChart();
      }
    };
    
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearTimeout(initTimer);
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (chartInstance.current) {
        chartInstance.current.dispose();
        chartInstance.current = null;
      }
    };
  }, [chartData, isDarkMode, isMounted, isPopupWindow, initAttempts, onChartReady]);

  return (
    <div className={`w-full ${isDarkMode ? 'bg-gradient-to-l from-[#1a1d54] to-[#2e2e56]' : 'bg-white'} rounded-sm p-4`}>
      <div className="flex items-center justify-between mb-4">
        <div className="flex flex-wrap items-center gap-2">
          <h3 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-[#2b2e48]'}`}>
            {stats.total} {type === "band" ? "Bands" : "Modes"}
          </h3>
          <span className="text-[#17F9DA] text-sm sm:text-base">
            | Top {type === "band" ? "Band" : "Mode"}: {stats.topItem.toUpperCase()}
          </span>
        </div>
      </div>
      <div className="h-[0.5px] w-full-max bg-[#4a4c74] mb-2 ml-[-1rem] mr-[-1rem]"></div>
      <div 
        ref={chartRef} 
        className="w-full"
        style={{ 
          height: '200px',
          minHeight: '200px', 
          visibility: isMounted ? 'visible' : 'hidden',
          overflow: 'hidden'
        }}
      />
    </div>
  );
}, (prevProps, nextProps) => {
  return (
    prevProps.type === nextProps.type &&
    prevProps.stats.total === nextProps.stats.total &&
    prevProps.stats.topItem === nextProps.stats.topItem &&
    JSON.stringify(prevProps.stats.stats) === JSON.stringify(nextProps.stats.stats)
  );
});

BandModeStats.displayName = 'BandModeStats';

export const MemoizedBandModeStats = BandModeStats;