import { call, put, select, take } from "redux-saga/effects";
import { authErrorHandler } from "helpers/ErrorHandler/auth";
import PotaService from "services/pota.service";
import {
  Activities,
  ActivitiesMapData,
  ParkReferenceModel,
  PotaAutoSpotPayload,
  UserProfile,
} from "types/Models";
import {
  ADD_POTA_AUTO_SPOT,
  FETCH_ACTIVITIES_MAP,
  GET_NEARBY_PARK_REFERENCES,
  GET_PARK_BY_REFERENCE,
  GET_PARK_REFERENCE_SUGGESTIONS,
  SET_ACTIVITIES_MAP,
  SET_FIND_MY_PARK_LOADING,
  SET_NEARBY_PARK_REFERENCES,
  SET_PARK_BY_REFERENCE_DATA,
  SET_PARK_BY_REFERENCE_LOADING,
  SET_PARK_REFERENCE_SUGGESTIONS,
  FETCH_ACTIVITIES_LIST_VIEW,
  SET_ACTIVITIES_LIST_VIEW,
  SEARCH_ACTIVITY_LIST_VIEW,
  GET_SINGLE_ACTIVITY_DETAIL,
  SET_SINGLE_ACTIVITY_DETAIL,
  ADD_WWFF_AUTO_SPOT,
} from "store/Actions/pota.actions";
import LogBookService from "services/logBook.service";
import { Timestamp } from "firebase/firestore";
import { prioritizeExactParkMatchesAndFlipUSParksOrder } from "helpers/Utils";
import {
  ACTIVITIES_MAP_LOADING,
  SINGLE_ACTIVITY_LOADING,
} from "store/Actions/loading.action";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import { sendWwffAutoSpot } from "firebase-config";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";

export function* workerGetParkByReference(payload: {
  parkReference: any;
  activityType: string;
}) {
  const { parkReference, activityType } = payload;

  try {
    yield put({
      type: SET_PARK_BY_REFERENCE_LOADING,
      payload: { key: parkReference.key, value: true },
    });

    const parkReferenceData: ParkReferenceModel = yield call(() =>
      PotaService.getParkByReference(parkReference.value, activityType)
    );

    if (parkReferenceData) {
      yield put({
        type: SET_PARK_BY_REFERENCE_DATA,
        payload: { key: parkReference.key, value: parkReferenceData },
      });
    }

    yield put({
      type: SET_PARK_BY_REFERENCE_LOADING,
      payload: { key: parkReference.key, value: false },
    });
  } catch (e: any) {
    authErrorHandler(e);
    yield put({
      type: SET_PARK_BY_REFERENCE_LOADING,
      payload: { key: parkReference.key, value: false },
    });
  }
}

export function* watchGetParkByReference() {
  while (true) {
    const { payload } = yield take(GET_PARK_BY_REFERENCE);
    yield call(workerGetParkByReference, payload);
  }
}

export function* workerGetParkReferenceSuggestions(payload: {
  parkReference: any;
  activityType: string;
}) {
  try {
    const { parkReference, activityType } = payload;
    const parkReferenceData: ParkReferenceModel[] = yield call(() =>
      PotaService.getParkReferenceSuggestions(
        parkReference.value,
        activityType || "POTA"
      )
    );
    if (parkReferenceData && parkReferenceData.length) {
      let prioritizedSearchResults;
      if (activityType === Activities.POTA) {
        prioritizedSearchResults =
          prioritizeExactParkMatchesAndFlipUSParksOrder(
            parkReference.value,
            parkReferenceData
          );
      } else {
        prioritizedSearchResults = parkReferenceData;
      }
      yield put({
        type: SET_PARK_REFERENCE_SUGGESTIONS,
        payload: { key: parkReference.key, value: prioritizedSearchResults },
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
}

export function* watchGetParkReferenceSuggestions() {
  while (true) {
    const { payload } = yield take(GET_PARK_REFERENCE_SUGGESTIONS);
    yield call(workerGetParkReferenceSuggestions, payload);
  }
}

export function* workerGetNearbyParkReferences(payload: {
  center: [number, number];
  radiusInKm?: number;
  activityType?: string;
}) {
  try {
    yield put({
      type: SET_FIND_MY_PARK_LOADING,
      payload: true,
    });

    const parkReferenceData: ParkReferenceModel = yield call(() =>
      PotaService.getNearbyParks(
        payload.center,
        payload.radiusInKm,
        payload.activityType
      )
    );

    if (parkReferenceData && parkReferenceData.length) {
      yield put({
        type: SET_NEARBY_PARK_REFERENCES,
        payload: parkReferenceData,
      });
    } else {
      showToast({
        type: ToastTypes.ERROR,
        message:
          "No parks found in this area, try searching for a park by name or reference",
      });
    }
    yield put({
      type: SET_FIND_MY_PARK_LOADING,
      payload: false,
    });
  } catch (e: any) {
    yield put({
      type: SET_FIND_MY_PARK_LOADING,
      payload: false,
    });
    authErrorHandler(e);
  }
}

export function* watchGetNearbyParkReferences() {
  while (true) {
    const { payload } = yield take(GET_NEARBY_PARK_REFERENCES);
    yield call(workerGetNearbyParkReferences, payload);
  }
}

export function* workderAddPotaAutoSpot(payload: any) {
  try {
    const { spotData, logBookId } = payload;
    yield call(() => PotaService.addPotaAutoSpot(spotData));
    if (logBookId) {
      yield call(() =>
        LogBookService.editUserLogBookData(
          {
            lastSpottedAt: Timestamp.now(),
            lastSpottedFrequency: spotData?.frequency
              ? `${parseFloat(spotData?.frequency) / 1000}`
              : "",
            lastSpottedMode: spotData?.mode || "",
            lastSpottedBand: spotData?.band || "",
            lastSpottedComment: spotData?.comments || "",
          },
          logBookId
        )
      );
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
}

export function* workerAddWwffAutoSpot(payload: any) {
  try {
    const { spotData, logBookId } = payload;
    const response: boolean = yield call(() => sendWwffAutoSpot({ spotData }));
    console.log("WWFF RESPONSE : ", response);
    if (logBookId) {
      yield call(() =>
        LogBookService.editUserLogBookData(
          {
            lastSpottedAt: Timestamp.now(),
            lastSpottedFrequency: spotData?.yqrg
              ? `${parseFloat(spotData?.yqrg) / 1000}`
              : "",
            lastSpottedMode: spotData?.ymode || "",
            lastSpottedBand: spotData?.band || "",
            lastSpottedComment: spotData?.ycomment || "",
          },
          logBookId
        )
      );
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
}

export function* workerFetchActivitiesMap(payload: any) {
  try {
    yield put({
      type: ACTIVITIES_MAP_LOADING,
      payload: true,
    });
    const { bound, filters, mapZoomLevel } = payload;
    const activitiesMApData: ActivitiesMapData[] = yield call(() =>
      PotaService.fetchActivitiesMap(bound, filters, mapZoomLevel)
    );

    yield put({
      type: SET_ACTIVITIES_MAP,
      payload: activitiesMApData,
    });
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: ACTIVITIES_MAP_LOADING,
    payload: false,
  });
}

export function* workerFetchActivitiesListView(payload: any) {
  try {
    yield put({
      type: ACTIVITIES_MAP_LOADING,
      payload: true,
    });
    const { filters, lastDoc, userCoordinate } = payload;

    const activitiesMApData: ActivitiesMapData[] = yield call(() =>
      PotaService.fetchActivitiesListView(filters, userCoordinate, lastDoc)
    );

    yield put({
      type: SET_ACTIVITIES_LIST_VIEW,
      payload: activitiesMApData,
    });
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: ACTIVITIES_MAP_LOADING,
    payload: false,
  });
}

export function* workerFetchSearchActivitiesListView(payload: any) {
  try {
    yield put({
      type: ACTIVITIES_MAP_LOADING,
      payload: true,
    });
    const { filters, searchQuery, userCoordinate } = payload;

    const activitiesMApData: ActivitiesMapData[] = yield call(() =>
      PotaService.fetchSearchActivitiesListView(
        searchQuery,
        filters,
        userCoordinate
      )
    );

    yield put({
      type: SET_ACTIVITIES_LIST_VIEW,
      payload: activitiesMApData,
    });
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: ACTIVITIES_MAP_LOADING,
    payload: false,
  });
}
export function* workerGetSingleActivityDetail(payload: any) {
  try {
    const { reference } = payload;
    const userProfile: UserProfile = yield select(getUserProfileSelector);

    yield put({
      type: SINGLE_ACTIVITY_LOADING,
      payload: true,
    });
    const activitiesMapData: ActivitiesMapData[] = yield call(() =>
      PotaService.fetchSingleActivityView(reference, userProfile?.uid || "")
    );

    yield put({
      type: SET_SINGLE_ACTIVITY_DETAIL,
      payload: activitiesMapData || {},
    });
  } catch (e: any) {
    console.log("workerGetSingleActivityDetail : ", e);
  }
  yield put({
    type: SINGLE_ACTIVITY_LOADING,
    payload: false,
  });
}

export function* watchAddPotaAutoSpot() {
  while (true) {
    const { payload } = yield take(ADD_POTA_AUTO_SPOT);
    yield call(workderAddPotaAutoSpot, payload);
  }
}
export function* watchAddWwffAutoSpot() {
  while (true) {
    const { payload } = yield take(ADD_WWFF_AUTO_SPOT);
    yield call(workerAddWwffAutoSpot, payload);
  }
}
export function* watchfetchActivitiesMap() {
  while (true) {
    const { payload } = yield take(FETCH_ACTIVITIES_MAP);
    yield call(workerFetchActivitiesMap, payload);
  }
}
export function* watchfetchActivitiesListView() {
  while (true) {
    const { payload } = yield take(FETCH_ACTIVITIES_LIST_VIEW);
    yield call(workerFetchActivitiesListView, payload);
  }
}

export function* watchfetchSearchActivitiesListView() {
  while (true) {
    const { payload } = yield take(SEARCH_ACTIVITY_LIST_VIEW);
    yield call(workerFetchSearchActivitiesListView, payload);
  }
}

export function* watchGetSingleActivityDetail() {
  while (true) {
    const { payload } = yield take(GET_SINGLE_ACTIVITY_DETAIL);
    yield call(workerGetSingleActivityDetail, payload);
  }
}
