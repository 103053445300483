import React, { useState, useEffect, useRef } from "react";
import ReactECharts from "echarts-for-react";
import useTheme from "hooks/useTheme.hook";
import { EChartsOption, GaugeSeriesOption } from "echarts";

interface QSOSpeedometerProps {
  currentRate: number;
  maxRate: number;
  isPopupWindow?: boolean;
}

export const QSOSpeedometer: React.FC<QSOSpeedometerProps> = ({
  currentRate,
  maxRate,
  isPopupWindow = false,
}) => {
  const { theme } = useTheme();
  const isDarkMode = theme === "dark";
  const [isVisible, setIsVisible] = useState(false);
  const [chartInstance, setChartInstance] = useState<echarts.ECharts | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<ReactECharts>(null);

  // Initialize visibility
  useEffect(() => {
    if (containerRef.current) {
      // Add slight delay for popup windows to ensure DOM is ready
      const delay = isPopupWindow ? 300 : 50;
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, delay);
      
      return () => clearTimeout(timer);
    }
  }, [isPopupWindow]);

  // Handle chart instance and resize events
  useEffect(() => {
    const handleResize = () => {
      if (chartInstance) {
        chartInstance.resize();
      }
    };

    // Add resize listener
    window.addEventListener('resize', handleResize);
    
    // Additional resize triggers for popup windows
    if (isPopupWindow && chartInstance) {
      // Force multiple resize checks to ensure proper rendering in popup
      const resizeTimers = [100, 300, 500, 1000].map(delay => 
        setTimeout(() => chartInstance.resize(), delay)
      );
      
      return () => {
        window.removeEventListener('resize', handleResize);
        resizeTimers.forEach(timer => clearTimeout(timer));
      };
    }
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [chartInstance, isPopupWindow]);

  const option: EChartsOption = {
    series: [
      {
        type: "gauge" as const,
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: maxRate,
        splitNumber: 1,
        progress: {
          show: false,
        },
        pointer: {
          length: "75%",
          width: 4,
          offsetCenter: [0, 0],
          itemStyle: {
            color: isDarkMode ? "#FFFFFF" : "#000000",
          },
        },
        axisLine: {
          roundCap: false,
          lineStyle: {
            width: 14,
            color: [
              [0.3, "#FDB777"], // Light orange for first half
              [0.7, "#fb923c"], //
              [1, "#FF6B3D"], // Dark orange for second half
            ],
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        title: {
          show: false,
        },
        detail: {
          show: false,
        },
        data: [
          {
            value: currentRate,
            name: "QSO Rate",
          },
        ],
        animation: true,
        animationDurationUpdate: 1500,
        animationEasingUpdate: "bounceOut",
      } as GaugeSeriesOption
    ],
  };

  return (
    <div
      ref={containerRef}
      className={`w-full rounded-sm p-4 ${
        isDarkMode ? "bg-gradient-to-l from-[#1a1d54] to-[#2e2e56]" : "bg-white"
      }`}
    >
      <div className="flex flex-row justify-between">
        {/* Speedometer Chart - adjusted with min-height */}
        <div className="h-[100px] min-h-[100px] w-full max-w-[200px] -mb-6">
          {isVisible && (
            <ReactECharts
              ref={chartRef}
              option={option}
              style={{ height: "100%", width: "100%" }}
              opts={{ renderer: "canvas" }}
              onChartReady={(instance) => {
                setChartInstance(instance);
                // Force resize after chart is ready
                setTimeout(() => instance.resize(), 100);
              }}
            />
          )}
        </div>
        {/* QSO Rate Display */}
        <div className="flex flex-col justify-center gap-3">
          <h2 className="text-xl font-bold font-Play text-[#FF6B3D]">
            {currentRate.toFixed(2)} QSO/hr
          </h2>
          <p className="text-sm font-Play text-[#FDB777]">
            Fastest: {maxRate.toFixed(2)} QSO/hr
          </p>
        </div>
      </div>
    </div>
  );
};
