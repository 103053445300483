/* eslint-disable react/no-children-prop */
import { MY_PROFILE_ROUTE } from "constants/Links";
import { TableEntColProps } from "types/Component";
import { globalNavigate } from "routes/GlobalRoutes";

import useConfig from "hooks/config.hook";

import ReactCountryFlag from "react-country-flag";
import { findCountryCode } from "helpers/Utils";
import { convertSecondToDate } from "helpers/Date/DareConverter";
import CustomBorderButton from "components/Button/CustomBorderButton";
import CustomValidation from "helpers/Validation";
import { UserAvatar } from "components/UserAvatar";
import CountryDxccFlag from "components/CountryDxccFlag";
import { generateImpersonationToken, auth } from "firebase-config";
import { signInWithCustomToken } from "firebase/auth";
import { useDispatch } from "react-redux";
import { setImpersonationData } from "store/Actions/profile.actions";
import AccessControl from "components/AccessControl";
import { PERMISSIONS } from "constants/Permissions";
function TableCol({
  item,
  className,
  editable,
  onImpersonateClick,
}: TableEntColProps) {
  const dispatch = useDispatch();
  const {
    getSubscriptionLevelButtonColor,
    getSubscriptionLevelButtonBgColor,
    getMemberStatusText,
    handlePrimaryModal,
  } = useConfig();

  return (
    <tr
      className={`${className ? className : ""} cursor-pointer`}
      onClick={() => {
        globalNavigate(
          `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
            item?.callSign ? item?.callSign : ""
          )}?tab=Logbook&contact-tab=entries`,
          {
            state: item?.uid,
          }
        );
      }}
    >
      <td className="w-[60px]">
        <div className="w-[60px] h-full flex justify-center items-center">
          {item?.profilePic ? (
            <img
              className="w-9 h-9 rounded-full object-cover"
              src={item.profilePic}
              alt=""
            />
          ) : (
            <UserAvatar className="w-9 h-9" />
          )}
        </div>
      </td>
      <td className="pl-4 py-3.5 dark:text-white text-[#2b2e48] text-xs font-[Barlow] font-normal w-[140px]">
        <p className="w-[140px]">{item?.callSign}</p>
      </td>
      <td className="pl-4 py-3.5 dark:text-white text-[#2b2e48] text-xs font-[Barlow] font-normal w-[210px]">
        <p className="w-[210px]">
          {item?.firstName} {item?.lastName}
        </p>
      </td>
      <td className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-3.5 w-[100px]">
        {/* <p className="pl-4 w-[228px]">{item?.date}</p> */}
        <div className="w-[100px] flex justify-center">
          <CountryDxccFlag
            countryCode={item?.country || ""}
            flagCode={item?.flagCode || ""}
          />
        </div>
      </td>
      <td className="pl-4 py-3.5 dark:text-white text-[#2b2e48] text-xs font-[Barlow] font-normal w-[100px]">
        <p className="w-[100px]">{item?.state}</p>
      </td>
      <td className="pl-4 py-3.5 dark:text-white text-[#2b2e48] text-xs font-[Barlow] font-normal w-[110px]">
        {/* <p className="w-[190px] text-center">{item?.membershipStatus}</p> */}
        <div className="w-[110px]">
          <CustomBorderButton
            text={getMemberStatusText(item.membershipStatus)}
            onClick={() => {}}
            height="h-[24px]"
            className=""
            childHeight="h-[57px]"
            childClassName="text-[12px] font-bold px-2"
            fromBg={getSubscriptionLevelButtonColor(
              "from",
              getMemberStatusText(item.membershipStatus)
            )}
            viaBg={getSubscriptionLevelButtonColor(
              "via",
              getMemberStatusText(item.membershipStatus)
            )}
            toBg={getSubscriptionLevelButtonColor(
              "to",
              getMemberStatusText(item.membershipStatus)
            )}
            childBgColor={getSubscriptionLevelButtonBgColor(
              getMemberStatusText(item.membershipStatus)
            )}
          />
        </div>
      </td>
      <td className="pl-4 text-xs text-[#6D6D8D] font-[Barlow] py-3.5 w-[90px]">
        <p className="w-[90px]">
          {item?.timestamp?.seconds
            ? convertSecondToDate(item.timestamp.seconds)
            : "not registered"}
        </p>
      </td>
      {/* <td className="pl-4 text-xs text-[#6D6D8D] font-[Barlow] py-3.5 w-[70px]">
        <p className="w-[70px] text-center">{item?.numberOfContacts}</p>
      </td> */}
      <AccessControl
        permission={PERMISSIONS.superAdmin}
        children={
          <td className="pl-4 text-xs  font-[Barlow] py-3.5 w-[70px]">
            <button
              className="px-4 py-2 rounded-full font-bold bg-[#FF0000] text-white"
              onClick={(event) => {
                event.stopPropagation();
                onImpersonateClick && onImpersonateClick(item);
              }}
            >
              IMPERSONATE
            </button>
          </td>
        }
      />
    </tr>
  );
}

export default TableCol;
