import React, { Fragment, useEffect, useState } from "react";

import Header from "components/Header";

import useTheme from "hooks/useTheme.hook";
import IntegrationItem from "components/IntegrationItem";
import Images from "assets/Images";
import Icons from "assets/Icons";

const integrations = [
  {
    name: "WRL CAT Control (BETA) - 1.0.2",
    downloadLink: "https://www.wrl.com/",
    guideLink: "https://worldradioleague.com/cat-control/",
    image: Icons.MainLogo,
    description: "Control your radio from your browser",
    type: "download",
  },
  {
    name: "WSJT-X",
    downloadLink: "https://www.wrl.com/",
    guideLink: "https://worldradioleague.com/wsjt-x-integration/",
    image: Images.WSJTX,
    description:
      "WSJT-X is a computer program used for weak-signal radio communication between amateur radio operators.",
  },
  {
    name: "N1MM",
    downloadLink: "https://www.wrl.com/",
    guideLink: "https://worldradioleague.com/n1mm-integration/",
    image: Images.N1MM,
    description:
      "N1MM Logger is the world's most popular ham radio contest logging program.",
  },
  {
    name: "N3FJP",
    downloadLink: "https://www.wrl.com/",
    guideLink: "https://worldradioleague.com/n3fjp-integration/",
    image: Images.N3FJP,
    description:
      "N3FJP Software - Affordable & Feature Packed Logging Software",
  },
  {
    name: "QRZ Callsign Lookup",
    downloadLink: "",
    guideLink: "",
    image: Images.QRZ,
    description:
      "Automatically fetches detailed information from QRZ Callsign Database",
    type: "qrz",
  },
];

function Integrations() {
  const { theme } = useTheme();

  const [os, setOs] = useState<"Windows" | "Mac" | "Other">("Other");

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (userAgent.includes("win")) {
      setOs("Windows");
    } else if (userAgent.includes("mac")) {
      setOs("Mac");
    }
  }, []);

  const downloadApp = (platform: string, intelArch?: boolean) => {
    // const macLink =
    //   "https://storage.googleapis.com/world-radio-league-prod.appspot.com/CatControl/WRL%20Cat%20Control.dmg";
    // const macLink =
    //   "https://storage.googleapis.com/world-radio-league-prod.appspot.com/CatControl/WRL%20Cat%20Control_1.0.1.dmg";
    const macLink =
      "https://storage.googleapis.com/world-radio-league-prod.appspot.com/CatControl/WRL%20Cat%20Control_1.0.2.dmg";
    // const macLinkX64 =
    //   "https://storage.googleapis.com/world-radio-league-prod.appspot.com/CatControl/WRL%20Cat%20Control_1.0.1_x64.dmg";
    const macLinkX64 =
      "https://storage.googleapis.com/world-radio-league-prod.appspot.com/CatControl/WRL%20Cat%20Control_1.0.2_x64.dmg";

    // const windowsLink =
    //   "https://storage.googleapis.com/world-radio-league-prod.appspot.com/CatControl/WRL.exe";
    // const windowsLink =
    //   "https://storage.googleapis.com/world-radio-league-prod.appspot.com/CatControl/WRL_1.0.1_win.exe";
    const windowsLink =
      "https://storage.googleapis.com/world-radio-league-prod.appspot.com/CatControl/WRL_1.0.2_win.exe";

    if (platform === "Mac") {
      if (intelArch) {
        window.open(macLinkX64);
      } else {
        window.open(macLink);
      }
    } else if (platform === "Windows") {
      window.open(windowsLink);
    }
  };

  return (
    <main className="relative w-full h-full">
      <Header title="Logbook" />
      <div className="w-full flex p-3.5 md:p-7 dashboardWrapper overflow-x-hidden">
        <div className="md:px-14 md:py-10 px-3 py-3">
          <h1 className="dark:text-white text-[#2b2e48] text-[30px] font-bold font-[Barlow]">
            Integrations
          </h1>
          <span className="dark:text-white text-[#2b2e48]">
            Select and connect tools you use to integrate with your workflow
          </span>
          <div className="flex flex-wrap mt-10 w-full">
            {integrations.map((integration) => (
              <IntegrationItem
                data={integration}
                os={os}
                key={integration.name}
                downloadApp={downloadApp}
              />
            ))}
          </div>
        </div>
      </div>
    </main>
  );
}

export default Integrations;
