import React from 'react';

interface ContentHeaderProps {
  // Main content
  title: string;
  mainIcon?: {
    src: string;
    alt: string;
    className?: string;
  };
  mainIconBgColor?: string;

  // Secondary content
  subtitle?: React.ReactNode | string; // this can be a string or a React component
  secondaryIcon?: {
    src: string;
    alt: string;
    className?: string;
  };
  
  // Optional custom classes
  className?: string;
  containerClassName?: string;
  titleClassName?: string;
  subtitleClassName?: string;
}

const ContentHeader: React.FC<ContentHeaderProps> = ({
  title,
  mainIcon,
  mainIconBgColor = "",
  subtitle,
  secondaryIcon,
  className = "w-full bg-white dark:bg-gradient-to-l dark:from-[#1a1d54] dark:to-[#2e2e56] rounded-sm p-4",
  containerClassName = '',
  titleClassName = "text-xl font-bold text-[#2b2e48] dark:text-white font-Play",
  subtitleClassName = "text-lg text-[#2b2e48]/60 dark:text-[#8487a1] font-Play",
}) => {
  const hasAdditionalContent = mainIcon || subtitle || secondaryIcon;
  
  return (
    <div className={className}>
      <div className={`flex flex-col ${containerClassName}`}>
        {/* Main Header Row */}
        <div className={`flex items-center ${hasAdditionalContent ? 'gap-2' : 'justify-center'}`}>
          {mainIcon && (
            <div className={`w-10 h-10 rounded-full flex items-center justify-center ${mainIconBgColor}`}>
              <img 
                src={mainIcon.src}
                alt={mainIcon.alt}
                className={`w-9 h-9 ${mainIcon.className || 'invert dark:invert-0'}`}
              />
            </div>
          )}

          <div className={`relative ${!hasAdditionalContent ? 'text-center w-full' : ''}`}>
            <h1 className={`
              ${titleClassName}
              ${!hasAdditionalContent ? 
                'text-4xl font-bold tracking-tight ' +
                'text-transparent bg-clip-text ' +
                'bg-gradient-to-r from-[#17f9da] to-[#1a73e8] ' +
                'dark:from-[#17f9da] dark:to-[#1a73e8] ' +
                'pb-2 ' +
                'relative' : 
                ''}
            `}>
              {title}
              {!hasAdditionalContent && (
                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 
                  w-4/5 h-[2px] bg-gradient-to-r from-[#17f9da]/20 to-[#1a73e8]/20" />
              )}
            </h1>
          </div>
        </div>

        {/* Subtitle Row */}
        {(subtitle || secondaryIcon) && (
          <div className="flex items-center gap-4 ml-2">
            <div className="flex items-center gap-2 mt-1">
              {secondaryIcon && (
                <img 
                  src={secondaryIcon.src}
                  alt={secondaryIcon.alt}
                  className={`w-4 h-4 ${secondaryIcon.className || 'invert dark:invert-0'}`}
                />
              )}
              {subtitle && (
                <span className={subtitleClassName}>
                  {subtitle}
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentHeader; 