import React, { Fragment, useEffect, useState } from "react";

import Header from "components/Header";

import useTheme from "hooks/useTheme.hook";
import IntegrationItem from "components/IntegrationItem";
import Images from "assets/Images";
import Icons from "assets/Icons";

function IntegrationsArchive() {
  const { theme } = useTheme();

  const [os, setOs] = useState<"Windows" | "Mac" | "Other">("Other");

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (userAgent.includes("win")) {
      setOs("Windows");
    } else if (userAgent.includes("mac")) {
      setOs("Mac");
    }
  }, []);

  const downloadLinks: any = {
    "1.0.0": {
      Mac: "https://storage.googleapis.com/world-radio-league-prod.appspot.com/CatControl/WRL%20Cat%20Control.dmg",
      Windows:
        "https://storage.googleapis.com/world-radio-league-prod.appspot.com/CatControl/WRL.exe",
    },
    "1.0.1": {
      Mac: "https://storage.googleapis.com/world-radio-league-prod.appspot.com/CatControl/WRL%20Cat%20Control_1.0.1.dmg",
      Windows:
        "https://storage.googleapis.com/world-radio-league-prod.appspot.com/CatControl/WRL_1.0.1_win.exe",
      MacIntel:
        "https://storage.googleapis.com/world-radio-league-prod.appspot.com/CatControl/WRL%20Cat%20Control_1.0.1_x64.dmg",
    },
  };

  const downloadApp = (
    platform: string,
    version: string,
    intelArch?: boolean
  ) => {
    const macLink = downloadLinks[version].Mac;
    const macLinkX64 = downloadLinks[version].MacIntel;
    const windowsLink = downloadLinks[version].Windows;

    if (platform === "Mac") {
      if (intelArch) {
        window.open(macLinkX64);
      } else {
        window.open(macLink);
      }
    } else if (platform === "Windows") {
      window.open(windowsLink);
    }
  };

  return (
    <main className="relative w-full h-full text-white">
      <Header title="Logbook" />
      <div className="w-full flex p-3.5 md:p-7 dashboardWrapper overflow-x-hidden">
        <div className="md:px-14 md:py-10 px-3 py-3">
          <h1 className="dark:text-white text-[#2b2e48] text-[30px] font-bold font-[Barlow]">
            Archives
          </h1>
          <span className="dark:text-white text-[#2b2e48]">
            Download older version of WRL CAT Control.
          </span>
          <div className="mt-10">
            <h1 className="font-[Play] font-bold text-xl">
              Beta Version: 1.0.0
            </h1>
            <ul className="mt-3 ml-8">
              <li
                className="cursor-pointer underline"
                onClick={() => downloadApp("Windows", "1.0.0")}
              >
                Windows: Download (1.0.0)
              </li>
              <li
                className="cursor-pointer mt-2 underline"
                onClick={() => downloadApp("Mac", "1.0.0")}
              >
                MacOS: Download (1.0.0)
              </li>
            </ul>
          </div>
          <div className="mt-10">
            <h1 className="font-[Play] font-bold text-xl">
              Beta Version: 1.0.1
            </h1>
            <ul className="mt-3 ml-8">
              <li
                className="cursor-pointer underline"
                onClick={() => downloadApp("Windows", "1.0.1")}
              >
                Windows: Download (1.0.1)
              </li>
              <li
                className="cursor-pointer mt-2 underline"
                onClick={() => downloadApp("Mac", "1.0.1")}
              >
                MacOS Apple Silicon: Download (1.0.1)
              </li>
              <li
                className="cursor-pointer mt-2 underline"
                onClick={() => downloadApp("Mac", "1.0.1", true)}
              >
                MacOS Intel: Download (1.0.1)
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  );
}

export default IntegrationsArchive;
