import { image } from "@uiw/react-md-editor";
import { LOOKUP_PROVIDERS } from "constants/Callsign";
import { XMLParser } from "fast-xml-parser";
import { manageCallsign, auth, db } from "firebase-config";
import { doc, getDoc, setDoc } from "firebase/firestore";

const QRZ_ENDPOINT = "https://xmldata.qrz.com/xml/current";
const parser = new XMLParser();
let sessionKey = "";

async function authenticate(
  username: string,
  password: string
): Promise<string> {
  if (!username || !password) {
    sessionKey = "";
    throw new Error("Missing username or password");
  }
  const response = await fetch(
    `${QRZ_ENDPOINT}?username=${encodeURIComponent(
      username
    )}&password=${encodeURIComponent(password)}`,
  );
  const xmlData = await response.text();
  const parsedXml = parseXmlResponse(xmlData);

  handleErrors(parsedXml);

  if (hasSessionKey(parsedXml)) {
    sessionKey = getSessionKey(parsedXml);
  }
  return sessionKey;
}

function normalizeCallsignQuery(callsign: string): string {
  const [prefix, suffix] = callsign.toUpperCase().split("/");
  return suffix && ["P", "M", "1", "2", "3", "4", "5"].includes(suffix)
    ? prefix
    : encodeURIComponent(callsign);
}

async function lookupCallsign(callsign: string): Promise<any> {

  try {
    const normalizedCallsign = normalizeCallsignQuery(callsign);
    const response = await fetch(
      `${QRZ_ENDPOINT}?s=${sessionKey}&callsign=${normalizedCallsign}`
    );
    const xmlData = await response.text();
    const parsedXml = parseXmlResponse(xmlData);
  
    // handleErrors(parsedXml);
    return normalizeResponse(parsedXml);
  } catch (error) {
    return null;
  }
}

function parseXmlResponse(xmlData: string) {
  return parser.parse(xmlData);
}

function handleErrors(response: any): any {
  if (response?.QRZDatabase?.Session?.Error) {
    handleQrzError(response.QRZDatabase.Session.Error);
  }
}

function hasSessionKey(response: any): boolean {
  return !!getSessionKey(response);
}

function getSessionKey(response: any): string {
  return response?.QRZDatabase?.Session?.Key;
}

function handleQrzError(errorMessage: string): Error {
  if (errorMessage.includes("Not found:")) {
    throw new Error(errorMessage);
  }
  switch (errorMessage) {
    case "Username / password required":
    case "Username/password incorrect":
      throw new Error(errorMessage);
    case "Invalid session key":
    case "Session Timeout":
      throw new Error(errorMessage);
    default:
      throw new Error(errorMessage);
  }
}

function QrzResponseTransformer(response: any) {
  const lookup = response.QRZDatabase.Callsign;
  
  let responseMapping = {};
  
  if (response?.QRZDatabase?.Session?.Error){
    responseMapping ={
      hamdb: {
        messages: {
          status: "NOT_FOUND"
        },
      }
    }
  } else{        
    responseMapping ={
      hamdb: {
        callsign:{
          country: lookup?.country ?? "NOT_FOUND",
          ituZone: lookup?.ituzone ?? undefined,
          cqZone: lookup?.cqzone ?? undefined,
          dxcc: lookup?.dxcc ?? "",
          state: lookup?.state ?? "NOT_FOUND",
          lat: lookup?.lat ?? "NOT_FOUND",
          lon: lookup?.lon ?? "NOT_FOUND",
          fullname: lookup?.name_fmt ?? `${lookup?.fname ?? ""} ${lookup?.name ?? ""}`,
          fname: lookup?.fname ?? "",
          name: lookup?.name ?? "",
          addr1: lookup?.addr1 ?? "",
          addr2: lookup?.addr2 ?? "",
          call: lookup?.call ?? "",
          class: lookup?.class ?? "",
          expires: lookup?.expdate ?? "",
          grid: lookup?.grid ?? "NOT_FOUND",
          zip: lookup?.zip ?? "",
          provider: LOOKUP_PROVIDERS.QRZ,
          image: lookup?.image ?? "",
        },
        messages: {
          status: "FOUND"
        },
        version: "1"
      },
    }

    try {
      manageCallsign(lookup);
    } catch (error) {
      console.log("Failed to manage callsign");
    }

  }
  
  return responseMapping;
}

function normalizeResponse(response: any) {
  return QrzResponseTransformer(response);
}

// firebase functions

async function storeQRZIntegrationData(payload: any): Promise<void> {
  try {
    const userId = auth.currentUser?.uid;
    // Define the document reference
    const qrzIntegrationRef = doc(db, `UserProfile/${userId}/integrations/qrz`);

    // Store the payload in Firestore
    await setDoc(qrzIntegrationRef, payload, { merge: true });
  } catch (error) {
    throw new Error("Failed to store QRZ integration data");
  }
}

// fetch the QRZ integration data from Firestore using getDoc
async function fetchQRZIntegrationData(id: string): Promise<any> {
  try {
    const userId = id || auth.currentUser?.uid;
    // Define the document reference
    const qrzIntegrationRef = doc(db, `UserProfile/${userId}/integrations/qrz`);

    // Fetch the document from Firestore
    const docSnap = await getDoc(qrzIntegrationRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  } catch (error) {
    throw new Error("Failed to fetch QRZ integration data");
  }
}

const QrzService = {
  authenticate,
  lookupCallsign,
  storeQRZIntegrationData,
  fetchQRZIntegrationData,
};

export default QrzService;
