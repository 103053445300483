import Hint from "components/Hint";
import CountrySelector from "components/Inputs/CountrySelector";
import TitledDefaultRegionPicker from "components/Inputs/TitledDefaultRegionPicker";
import TitledInputDefault from "components/Inputs/TitledInputDefault";
import { getStateLongName, isValidMaidenheadGrid } from "helpers/Utils";
import { FC } from "react";
import { SavedLocation } from "types/Models";
import { useDebouncedCallback } from "use-debounce";

export const GridLocation: FC<{
  locationForm: SavedLocation;
  setLocationForm: React.Dispatch<React.SetStateAction<SavedLocation>>;
  callback: (arg: string) => void;
  addressLoading?: boolean;
  mode?: string;
  resetForm?: () => void;
}> = ({
  locationForm,
  setLocationForm,
  callback,
  addressLoading,
  mode,
  resetForm,
}) => {
  const fieldPrefixColor =
    mode === "light" ? "bg-[#ffffff] text-slate-900" : "bg-blue-600 text-white";

    const debouncedCallback = useDebouncedCallback((searchString) => {
      if (isValidMaidenheadGrid(searchString)) {
        resetForm && resetForm();
        setLocationForm((prev) => ({
          ...prev,
          gridsquare: searchString,
        }));

        callback(searchString);
      }
    }, 500);

  return (
    <div className="flex flex-col">
      <div className="w-full flex flex-row flex-wrap gap-3">
        <TitledInputDefault
          setText={(e) => {
            setLocationForm((prev) => ({
              ...prev,
              gridsquare: e.target.value,
            }));

            if (!e.target.value && resetForm) {
              resetForm();
            }

            debouncedCallback(e.target.value);
          }}
          name="gridsquare"
          title="Grid"
          placeHolder="Enter gridsquare"
          value={locationForm.gridsquare}
          width={"w-full sm:w-[49%] md:w-[20.5%] xl:w-[30%]"}
          className={"h-[36px] mt-[12px]"}
          tabindex={20}
          error={null}
          containerClass=""
          isLoading={addressLoading}
          props={{
            onFocus: () => {},
          }}
        />

        <Hint
          text={
            "Enter the Grid, and the other details will be pulled automatically"
          }
        />
      </div>

      <div className="flex flex-row flex-wrap">
        <CountrySelector
          setText={(val) => {
            setLocationForm((prev) => ({
              ...prev,
              country: val,
            }));
          }}
          name="country"
          title="Country"
          placeHolder="Country"
          value={locationForm.country}
          className={"h-[36px] dark:!bg-[#23234C] dark:!text-white"}
          tabindex={8}
          width={"w-full sm:w-[49%] md:w-[20.5%] xl:w-[30%]"}
          error={undefined}
          containerClass="mr-0 md:mr-3 sm:mr-3 mb-3"
        />
        {locationForm?.country ? (
          <TitledDefaultRegionPicker
            setText={(val) => {
              setLocationForm((prev) => ({
                ...prev,
                state: val,
              }));
            }}
            name="state"
            title="State"
            placeHolder="State"
            tabindex={9}
            width="w-full sm:w-[23.5%] md:w-[18.7%] xl:w-[30%]"
            className={"mt-3 h-[36px] dark:!bg-[#23234C] dark:!text-white"}
            value={getStateLongName(locationForm.state || "")}
            error={undefined}
            country={locationForm.country}
            containerClass="mr-0 md:mr-3 sm:mr-3 mb-3"
          />
        ) : null}

        <TitledInputDefault
          setText={(e) => {
            setLocationForm((prev) => ({
              ...prev,
              city: e.target.value,
            }));
          }}
          name="city"
          title="City"
          placeHolder="Enter City"
          value={locationForm.city}
          width={"w-full sm:w-[49%] md:w-[20.5%] xl:w-[30%]"}
          className={"h-[36px] mt-[12px] mr-0 md:mr-3 sm:mr-3"}
          tabindex={20}
          error={null}
          containerClass="mr-0 md:mr-5"
        />
      </div>

      <div className="flex items-center mt-4 justify-between flex-wrap">
        <div className="flex flex-wrap font-[Play] text-sm gap-3">
          <div className="flex items-center mb-2">
            <div className={`${fieldPrefixColor} px-2 h-[36px] pt-1.5`}>
              Lat.
            </div>
            <input
              className="dark:bg-[#23234C] bg-[#f1f3f4] dark:text-white text-[#2b2e48] border border-[#dcdcdd] dark:border-none px-2 h-[36px] md:w-[100px]"
              value={locationForm.lat}
              disabled
              onChange={(e) => {
                setLocationForm((prev) => ({
                  ...prev,
                  lat: e.target.value,
                }));
              }}
            />
          </div>
          <div className="flex items-center mb-2">
            <div className={`${fieldPrefixColor} px-2 h-[36px] pt-1.5`}>
              Lon.
            </div>
            <input
              className="dark:bg-[#23234C] bg-[#f1f3f4] dark:text-white text-[#2b2e48] border border-[#dcdcdd] dark:border-none px-2 h-[36px] md:w-[100px]"
              value={locationForm.lng}
              disabled
              onChange={(e) => {
                setLocationForm((prev) => ({
                  ...prev,
                  lng: e.target.value,
                }));
              }}
            />
          </div>
          <div className="flex items-center mb-2">
            <div className={`${fieldPrefixColor} px-2 h-[36px]  pt-1.5`}>
              ITU
            </div>
            <input
              className="dark:bg-[#23234C] bg-[#f1f3f4] dark:text-white text-[#2b2e48] border border-[#dcdcdd] dark:border-none px-2 h-[36px] md:w-[100px]"
              value={locationForm.ituZone}
              disabled
              onChange={(e) => {
                setLocationForm((prev) => ({
                  ...prev,
                  ituZone: e.target.value,
                }));
              }}
            />
          </div>
          <div className="flex items-center mb-2">
            <div className={`${fieldPrefixColor} px-2 h-[36px]  pt-1.5`}>
              CQ
            </div>
            <input
              className="dark:bg-[#23234C] bg-[#f1f3f4] dark:text-white text-[#2b2e48] border border-[#dcdcdd] dark:border-none px-2 h-[36px] md:w-[100px]"
              value={locationForm.cqZone}
              disabled
              onChange={(e) => {
                setLocationForm((prev) => ({
                  ...prev,
                  cqZone: e.target.value,
                }));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
