import { CallsignCollection, UserDataTable } from "constants/Collections";
import { db } from "firebase-config";
import {
  and,
  collection,
  DocumentData,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  QueryDocumentSnapshot,
  SnapshotOptions,
  where,
} from "firebase/firestore";
import { CallSignLookUpData, CallsignModel } from "types/Models";

interface CallsignServiceType {
  fetchCallsignFromHamDB(callSign: string): Promise<CallSignLookUpData | null>;
  fetchCallsignFromFirestoreDB(
    callSign: string
  ): Promise<Partial<CallsignModel>[] | null>;
  getCallsign(callSign: string): Promise<any | null>;
}

const CallsignService: CallsignServiceType = {
  fetchCallsignFromHamDB: async (callSign) => {
    try {
      const [baseCallSign] = callSign.split("/");
      const data = await fetch(`https://api.hamdb.org/v1/${baseCallSign}/json`);
      let rawResponse = await data.text(); // Get raw text response

      // Sanitize JSON to remove unescaped double quotes within values
      rawResponse = rawResponse.replace(
        /:(\s*?)"(.*?)"(?=[,}])/gs,
        (match, p1, p2) => {
          const sanitizedValue = p2.replace(/(?<!\\)"/g, ""); // Remove all unescaped double quotes
          return `:${p1}"${sanitizedValue}"`;
        }
      );

      const response = JSON.parse(rawResponse);
      if (response?.hamdb?.messages?.status === "OK") {
        return response;
      }
      return null;
    } catch (e) {
      return null;
    }
  },
  async fetchCallsignFromFirestoreDB(callsign: string) {
    try {
      const allowedStatus = ["A", "1"];
      const orderByField = "callsign";
      const limitCount = 5;
      const orderByDirection = "asc";

      let q = query(
        collection(db, CallsignCollection),
        and(
          where("status", "in", allowedStatus),
          or(
            where(
              "callsignSearchIndex",
              "array-contains",
              callsign.toLowerCase().trim()
            ),
            where(
              "nameSearchIndex",
              "array-contains",
              callsign.toLowerCase().trim()
            )
          )
        ),
        orderBy(orderByField, orderByDirection),
        limit(limitCount)
      );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const responseData: Partial<CallsignModel>[] = [];
        querySnapshot.forEach((doc) => {
          let data = {
            id: doc.id,
            ...doc.data(),
          };
          responseData.push(data);
        });
        if (responseData.length) {
          return responseData;
        }
      } else {

        const dataConverter = {
          toFirestore(data: any): DocumentData {
            return data;
          },
          fromFirestore(
            snapshot: QueryDocumentSnapshot,
            options: SnapshotOptions
          ){
            const data = snapshot.data(options)!;
            
            return {
              id: snapshot.id,
              callsign: data.callSign,
              fullName: data.firstName + " " +data.lastName,
              city: data?.city || "",
              state: data?.state || "",
              country: data?.country || "",
            };
          }
        };

        q = query(
          collection(db, UserDataTable),
          or(
            where(
              "callsignSearchIndex",
              "array-contains",
              callsign.toLowerCase().trim()
            ),
            where(
              "nameSearchIndex",
              "array-contains",
              callsign.toLowerCase().trim()
            )
          ),
          orderBy("callSign", orderByDirection),
          limit(limitCount),
        ).withConverter(dataConverter);

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const responseData: Partial<CallsignModel>[] = [];
          querySnapshot.forEach((doc) => {
            if (doc.data().callsign) {
              responseData.push(doc.data());
            }
          });
          if (responseData.length) {
            return responseData;
          }
        }
      }

      return null;
    } catch (error) {      
      return null;
    }
  },
  getCallsign: async (callSign) => {
    try {
      const q = query(
        collection(db, CallsignCollection),
        where("callsign", "==", callSign)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.size === 0) {
        return null;
      }

      const userProfile = querySnapshot.docs[0].data();

      return normalizeResponse(userProfile);
    } catch (e) {
      return null;
    }
  },
};

export default CallsignService;

function normalizeResponse(response: any) {
  return CallsignResponseTransformer(response);
}

function CallsignResponseTransformer(response: any) {
  const lookup = response;

  let responseMapping = {};

  responseMapping = {
    hamdb: {
      callsign: {
        country: lookup?.country ?? "",
        ituZone: lookup?.ituZone ?? undefined,
        cqZone: lookup?.cqZone ?? undefined,
        dxcc: lookup?.dxcc ?? "",
        state: lookup?.stateCode ?? "",
        lat: lookup?.latitude ?? undefined,
        lon: lookup?.longitude ?? undefined,
        fullname: `${lookup?.firstName ?? ""} ${lookup?.lastName ?? ""}`,
        fname: lookup?.firstName ?? "",
        name: lookup?.lastName ?? "",
        addr1: lookup?.address ?? "",
        addr2: lookup?.city ?? "",
        call: lookup?.callsign ?? "",
        grid: lookup?.grid ?? "",
      },
      messages: {
        status: "FOUND",
      },
      version: "1",
    },
  };

  return responseMapping;
}