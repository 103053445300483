import { getRandomAvatar } from "helpers/Utils";
import useProfile from "hooks/profile.hook";
import useTheme from "hooks/useTheme.hook";
import React, { useEffect, useState } from "react";
import { BsPersonCircle } from "react-icons/bs";

interface UserAvatarProps {
  className?: string;
  isCurrentUser?: boolean;
  variant?: "dark" | "light";
}

export const UserAvatar: React.FC<UserAvatarProps> = ({
  className,
  isCurrentUser = false,
  variant,
}) => {
  const { theme } = useTheme();
  return (<BsPersonCircle className={className} color={ theme === "dark" ? variant ? "#9aa5ba": "#fff": "#9aa5ba"} />)
};
