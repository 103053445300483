import {
  LogBookContactTable,
  LogBookTable,
  ParkReference,
} from "constants/Collections";
import { auth, db, fetchActivitiesBasedOnMapBounds, fetchSingleActivityDetails } from "firebase-config";
import {
  collection,
  endAt,
  getCountFromServer,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
} from "firebase/firestore";
import {
  Activities,
  LogBookContactModel,
  ParkReferenceModel,
  PotaAutoSpotPayload,
} from "types/Models";
import { geohashQueryBounds, distanceBetween, Geopoint } from "geofire-common";
import { calculateRadius, getGridGeohashBounds } from "helpers/Utils";
import axios from "axios";
import moment from "moment";

interface PotaServiceType {
  getParkByReference(
    parkReference: string,
    activityType?: string
  ): Promise<Partial<ParkReferenceModel> | null>;
  getParkReferenceSuggestions(
    parkReference: string,
    activityType?: string
  ): Promise<Array<Partial<ParkReferenceModel>> | null>;
  getNearbyParks(
    center: [number, number],
    radiusInKm?: number,
    activityType?: string
  ): Promise<Array<Partial<ParkReferenceModel>> | null>;
  addPotaAutoSpot(payload: PotaAutoSpotPayload): Promise<void>;
  fetchActivitiesMap(
    bounds: google.maps.LatLngBounds,
    filters: string[],
    mapZoomLevel: number
  ): Promise<any[]>;
  fetchActivityDetail(reference: string): Promise<any>;
  fetchMyActivityDetail(userId: string, reference: string): Promise<any>;
  fetchActivitiesListView(
    filters: string[],
    userCoordinates: [number, number],
    lastDoc?: any
  ): Promise<any[]>;
  fetchSearchActivitiesListView(
    searchQuery: string,
    filters: string[],
    userCoordinate?: any
  ): Promise<any[]>;
  fetchSingleActivityView(reference: string, userId: string): Promise<any>;
  addWWFFAutoSpot(payload: any): Promise<boolean>;
}

const PotaService: PotaServiceType = {
  async getParkByReference(parkReference: string, activityType) {
    try {
      let q = query(
        collection(db, ParkReference),
        where("reference", "==", parkReference.toUpperCase())
      );

      q = query(q, where("type", "==", activityType));

      const querySnapshot = await getDocs(q);

      if (querySnapshot) {
        const responseData: Partial<ParkReferenceModel>[] = [];
        querySnapshot.forEach((doc) => {
          let data: Partial<ParkReferenceModel> = {
            uid: doc.id,
            ...doc.data(),
          };
          responseData.push(data);
        });
        if (responseData.length) {
          return responseData[0];
        }

        return null;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  },
  async getParkReferenceSuggestions(
    parkReference: string,
    activityType: string
  ) {
    try {
      // First query: where 'type' matches and 'referenceSearchIndex' contains 'parkReference'
      let query1 = query(
        collection(db, ParkReference),
        where("referenceSearchIndex", "array-contains", parkReference),
        orderBy("reference", "desc"),
        limit(30)
      );
      console.log("ACTIVITY TYPE SERVICE : ", activityType);

      query1 = query(query1, where("type", "==", activityType));

      // Second query: where 'type' matches and 'nameSearchIndex' contains 'parkReference.toLowerCase()'
      let query2 = query(
        collection(db, ParkReference),

        where("nameSearchIndex", "array-contains", parkReference.toLowerCase()),
        orderBy("reference", "desc"),
        limit(30)
      );

      query2 = query(query2, where("type", "==", activityType));

      // Execute both queries concurrently
      const [querySnapshot1, querySnapshot2] = await Promise.all([
        getDocs(query1),
        getDocs(query2),
      ]);

      // Combine results from both queries
      const responseDataMap = new Map();

      // Process first query results
      querySnapshot1.forEach((doc) => {
        const data = {
          uid: doc.id,
          ...doc.data(),
        };
        responseDataMap.set(doc.id, data);
      });

      // Process second query results
      querySnapshot2.forEach((doc) => {
        const data = {
          uid: doc.id,
          ...doc.data(),
        };
        responseDataMap.set(doc.id, data);
      });

      // Convert the Map to an array
      const combinedResults = Array.from(responseDataMap.values());

      if (combinedResults.length > 0) {
        return combinedResults;
      } else {
        return null;
      }
    } catch (error) {
      console.log("ERROR:", error);
      return null;
    }
  },
  async getNearbyParks(
    center: [number, number] = [44.333765, -68.27342],
    radiusInKm: number = 40,
    activityType?: string
  ) {
    const radiusInM = radiusInKm * 1000;
    // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
    // a separate query for each pair. There can be up to 9 pairs of bounds
    // depending on overlap, but in most cases there are 4.
    const bounds = geohashQueryBounds(center, radiusInM);
    const promises = [];
    for (const b of bounds) {
      let q = query(
        collection(db, "ParkReference"),
        orderBy("geohash"),
        startAt(b[0]),
        endAt(b[1])
      );
      console.log("ACTIVITY TYPE SERVICE : ", activityType);

      q = query(q, where("type", "==", activityType));

      promises.push(getDocs(q));
    }

    // Collect all the query results together into a single list
    const querySnapshot = await Promise.all(promises);
    const matchingDocs: Array<ParkReferenceModel> = [];

    for (const snap of querySnapshot) {
      for (const doc of snap.docs) {
        const lat = doc.get("latitude");
        const lng = doc.get("longitude");

        // We have to filter out a few false positives due to GeoHash
        // accuracy, but most will match
        const distanceInKm = distanceBetween([lat, lng], center);
        const distanceInM = distanceInKm * 1000;
        if (distanceInM <= radiusInM) {
          matchingDocs.push({
            uid: doc.id,
            ...doc.data(),
            distanceInKm,
            type: doc.get("type") ? doc.get("type") : activityType, // workaround until we have type in all data eg: POTA
          } as any);
        }
      }
    }

    if (matchingDocs.length === 0) return null;

    // Sort by distance
    matchingDocs.sort((a, b) => a.distanceInKm! - b.distanceInKm!);
    return matchingDocs;
  },
  async addPotaAutoSpot(payload: PotaAutoSpotPayload) {
    try {
      const response = await fetch("https://api.pota.app/spot", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...payload,
          source: "Web",
        }),
      });
      if (response.status === 200) {
        // const body = await response.text()
        // console.log(body)
      } else {
        const body = await response.text();
        console.log("POTA Spotter http error", response, body);
      }
    } catch (error) {
      console.log("POTA Spotter error", error);
    }
  },

  async addWWFFAutoSpot(payload: any) {
    try {
      const url = "https://www.cqgma.org/wwff/spotwwff.php";

      const response = await axios.post(url, new URLSearchParams(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "User-Agent": "WorldRadioLeague/Web",
        },
        timeout: 5000, // Set a timeout to prevent hanging requests
      });

      console.log("WWFF SPOTTER :", response);

      if (response.status === 200 || response.status === 201) {
        return true;
      } else {
        console.error("Error in WWFF Spot", response.data);
        return false;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(
          "WWFF Spotter Axios error",
          error.response?.data || error.message
        );
      } else {
        console.error("WWFF Spotter unknown error", error);
      }
      return false;
    }
  },
  async fetchActivitiesMap(
    bounds: google.maps.LatLngBounds,
    filters: string[],
    mapZoomLevel: number
  ) {
    try {
      const sw = bounds?.getSouthWest();
      const ne = bounds?.getNorthEast();
      const promises = [];
      const parkList: any = [];
      //map is zoomed in so we can fetch all activities inside the visible area
      if (mapZoomLevel >= 10) {
        const centerLat = (sw.lat() + ne.lat()) / 2;
        const centerLng = (sw.lng() + ne.lng()) / 2;
        const center: Geopoint = [centerLat, centerLng];
        // Calculate the radius (in meters) from the center to the northeast corner
        const radiusInM = calculateRadius(sw, ne);
        const geohashBounds = geohashQueryBounds(center, radiusInM);
        for (const b of geohashBounds) {
          // Build the query using the new modular syntax
          let q = query(
            collection(db, ParkReference),
            orderBy("geohash"),
            startAt(b[0]),
            endAt(b[1])
            // limit(200)
          );
          if (filters && filters.length > 0) {
            q = query(q, where("type", "in", filters));
          }
          // Use getDocs instead of q.get()
          promises.push(getDocs(q));
        }
        const snapshots = await Promise.all(promises);
        for (const snap of snapshots) {
          for (const doc of snap.docs) {
            parkList.push({ id: doc.id, ...doc.data() });
          }
        }
      } else {
        // map is zoomed out so we devide the visible area into 4 parts and fetch activities in each part
        // we use a cloud function because we need to fetch activities in each part in parallel leading to multiple promises
        const gridGeohashBounds = getGridGeohashBounds(sw, ne, 4);

        const parkResponse: any = await fetchActivitiesBasedOnMapBounds({
          gridGeohashBounds,
          filters,
        });
        if (parkResponse && parkResponse.data && parkResponse.data.length > 0) {
          parkList.push(...parkResponse.data);
        }
      }
      const matchingDocs = [];
      for (const park of parkList) {
        matchingDocs.push({
          id: park.id,
          reference: park?.reference || "",
          country: park.entityName || park.associationName || "",
          dxccNumber: park.entityId || "",
          lastActivationCall: park.activationCall || "",
          lastActivationDate: park.activationDate || "",
          grid4: park.grid4 || "",
          grid6: park.grid6 || "",
          altitude: park.altitudeFeet || "",
          latitude: park.latitude || "",
          longitude: park.longitude || "",
          name: park.name,
          type: park.type || "POTA",
          website: park.website || "",
          state: park.state || park.locationName || "",
        });
      }
      return matchingDocs;
    } catch (error) {
      console.log("fetchActivitiesMap error", error);
      return [];
    }
  },
  fetchActivityDetail: async (reference) => {
    try {
      const contactCollectionRed = collection(db, LogBookContactTable);
      const q = query(
        contactCollectionRed,
        where("myActivityReferences", "array-contains", reference),
        orderBy("contactTimeStamp", "desc"),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      const contacts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log("CONTACTS : ", contacts);
      return contacts;
    } catch (e) {
      console.log("Error fetching GENERAL activity detail: ", e);
    }
  },
  fetchMyActivityDetail: async (userId, reference) => {
    try {
      const contactCollectionRed = collection(db, LogBookContactTable);
      const logbookCollectionRef = collection(db, LogBookTable);

      // Define the queries
      const myActivationsQuery = query(
        logbookCollectionRef,
        where("uid", "==", userId),
        where("activitiesReferences", "array-contains", reference)
      );

      const wrlActivationsQuery = query(
        logbookCollectionRef,
        where("activitiesReferences", "array-contains", reference)
      );

      const huntQuery = query(
        contactCollectionRed,
        where("uid", "==", userId),
        where("theirActivityReferences", "array-contains", reference)
      );
      // Execute all queries in parallel
      const [myActivationsSnapshot, wrlActivationsSnapshot, huntQuerySnapshot] =
        await Promise.all([
          getCountFromServer(myActivationsQuery),
          getCountFromServer(wrlActivationsQuery),
          getCountFromServer(huntQuery),
        ]);

      // Retrieve counts
      const activationsCount = myActivationsSnapshot.data().count || 0;
      const huntsCount = huntQuerySnapshot.data().count || 0;
      const wrlActivationsCount = wrlActivationsSnapshot.data().count || 0;

      return {
        activationsCount,
        huntsCount,
        wrlActivationsCount,
      };
    } catch (e) {
      console.log("Error fetching MY activity detail: ", e);
    }
  },
  fetchActivitiesListView: async (
    filters: string[],
    userCoordinates: [number, number],
    lastDoc
  ) => {
    try {
      const [userLat, userLng] = userCoordinates;
      const radiusInM = 70000;
      const bounds = geohashQueryBounds([userLat, userLng], radiusInM);
      let parkList: any[] = [];
      const colRef = collection(db, ParkReference);

      for (const b of bounds) {
        // Construct the base query for this bounding box
        let q = query(colRef, orderBy("geohash"), startAt(b[0]), endAt(b[1]));

        if (filters && filters.length > 0) {
          // Firestore "in" operator can take up to 10 values
          q = query(q, where("type", "in", filters));
        }

        // If we have a lastDoc (for pagination), use it
        if (lastDoc) {
          q = query(q, startAfter(lastDoc));
        }

        // Limit the results for each bounding box query
        q = query(q, limit(200));

        const snapshot = await getDocs(q);

        snapshot.forEach((doc) => {
          const data = doc.data();
          parkList.push({
            id: doc.id,
            ...data,
          });
        });
      }
      const filteredWithDistance = parkList
        .filter((park) => {
          if (
            typeof park.latitude !== "number" ||
            typeof park.longitude !== "number"
          ) {
            return false;
          }
          const distInKm = distanceBetween(
            [userLat, userLng],
            [park.latitude, park.longitude]
          );
          return distInKm * 1000 <= radiusInM;
        })
        .map((park) => {
          // distanceBetween returns distance in kilometers; convert to meters
          const distInKm = distanceBetween(
            [userLat, userLng],
            [park.latitude!, park.longitude!]
          );
          const distanceInMeters = distInKm * 1000;
          return {
            ...park,
            distanceInMeters,
          };
        });

      // Sort by ascending distance
      filteredWithDistance.sort((a, b) => {
        return a.distanceInMeters - b.distanceInMeters;
      });

      const userId = auth.currentUser?.uid || "";
      const logbookRef = collection(db, LogBookTable);
      const myActivatedActivities = query(
        logbookRef,
        where("uid", "==", userId),
        where("activities", "array-contains-any", [
          "POTA",
          "SOTA",
          "IOTA",
          "WWFF",
        ]),
        limit(500) // just a precaution not to fetch more than we have to, this would support upto 500 different activations in different logbooks
      );
      const myActivatedActivitiesSnapshot = await getDocs(
        myActivatedActivities
      );
      const myActivatedActivitiesReferences =
        myActivatedActivitiesSnapshot.docs.map(
          (doc) => doc.data().activitiesReferences
        );

      const matchingDocs = filteredWithDistance.map((park) => {
        return {
          id: park.id,
          reference: park.reference || "",
          country: park.entityName || park.associationName || "",
          dxccNumber: park.entityId || "",
          lastActivationCall: park.activationCall || "",
          lastActivationDate: park.activationDate || "",
          grid4: park.grid4 || "",
          grid6: park.grid6 || "",
          altitude: park.altitudeFeet || "",
          latitude: park.latitude || "",
          longitude: park.longitude || "",
          name: park.name,
          type: park.type || "POTA",
          website: park.website || "",
          state: park.state || park.locationName || "",
          distanceInMiles: Math.round(park.distanceInMeters / 1609.34),
          activated: myActivatedActivitiesReferences.some((ref) =>
            ref.includes(park.reference)
          ),
        };
      });
      console.log("MATCHING DOCS : ", matchingDocs);
      return matchingDocs;
    } catch (error) {
      console.log("fetchActivitiesListView error", error);
      return [];
    }
  },
  fetchSearchActivitiesListView: async (
    searchQuery,
    filters,
    userCoordinate
  ) => {
    const [userLat, userLng] = userCoordinate || [];

    // First query: where 'type' matches and 'referenceSearchIndex' contains 'parkReference'
    let query1 = query(
      collection(db, ParkReference),
      where("referenceSearchIndex", "array-contains", searchQuery),
      orderBy("reference", "desc"),
      limit(30)
    );

    // Add filters if present
    if (filters && filters.length > 0) {
      query1 = query(query1, where("type", "in", filters));
    }

    // Second query: where 'type' matches and 'nameSearchIndex' contains 'parkReference.toLowerCase()'
    let query2 = query(
      collection(db, ParkReference),

      where("nameSearchIndex", "array-contains", searchQuery.toLowerCase()),
      orderBy("reference", "desc"),
      limit(30)
    );

    // Add filters if present
    if (filters && filters.length > 0) {
      query2 = query(query2, where("type", "in", filters));
    }

    // Execute both queries concurrently
    const [querySnapshot1, querySnapshot2] = await Promise.all([
      getDocs(query1),
      getDocs(query2),
    ]);

    const combinedDocs = [...querySnapshot1.docs, ...querySnapshot2.docs];
    const parkMap = new Map(); // key: doc.id, value: Firestore doc data
    combinedDocs.forEach((doc) => {
      parkMap.set(doc.id, doc.data());
    });

    const userId = auth.currentUser?.uid || "";
    const logbookRef = collection(db, LogBookTable);
    const myActivatedActivities = query(
      logbookRef,
      where("uid", "==", userId),
      where("activities", "array-contains-any", [
        "POTA",
        "SOTA",
        "IOTA",
        "WWFF",
      ]),
      limit(500) // just a precaution not to fetch more than we have to, this would support upto 500 different activations in different logbooks
    );
    const myActivatedActivitiesSnapshot = await getDocs(myActivatedActivities);
    const myActivatedActivitiesReferences =
      myActivatedActivitiesSnapshot.docs.map(
        (doc) => doc.data().activitiesReferences
      );

    const parks = Array.from(parkMap, ([id, park]) => {
      let distanceInMiles = 0;
      if (userLat && userLng) {
        const distanceKm = distanceBetween(
          [park.latitude, park.longitude],
          [userLat, userLng]
        );
        distanceInMiles = Math.round(distanceKm * 0.621371); // approx conversion
      }

      return {
        id: id,
        reference: park.reference || "",
        country: park.entityName || park.associationName || "",
        dxccNumber: park.entityId || "",
        lastActivationCall: park.activationCall || "",
        lastActivationDate: park.activationDate || "",
        grid4: park.grid4 || "",
        grid6: park.grid6 || "",
        altitude: park.altitudeFeet || "",
        latitude: park.latitude || "",
        longitude: park.longitude || "",
        name: park.name || "",
        type: park.type || "POTA",
        website: park.website || "",
        state: park.state || park.locationName || "",
        distanceInMiles, // Calculated above,
        activated: myActivatedActivitiesReferences.some((ref) =>
          ref.includes(park.reference)
        ),
      };
    });
    return parks;
  },
  fetchSingleActivityView: async (reference, userId) => {
    try {
      const parkData = await fetchSingleActivityDetails({reference, userId});
      console.log("PARK DATA : ", parkData);
      if(parkData) {
        return parkData.data || null;
      }else{
        return null;
      }
    } catch (e) {
      console.log("Error fetching single activity detail: ", e);
    }
  },
};

export default PotaService;
