import Quill from "quill";

const BlockEmbed: any = Quill.import("blots/block/embed");

class IframeBlot extends BlockEmbed {
  static create(value: { url: string; width: string; height: string; alignment: string }) {
    const node = super.create();

    // Create the wrapper div with flexbox for alignment
    const wrapperDiv = node;
    wrapperDiv.style.width = "100%";
    wrapperDiv.style.display = "flex";
    wrapperDiv.style.justifyContent = value.alignment || "center"; // Default to center if not specified

    // Create the iframe element
    const iframe = document.createElement("iframe");
    iframe.setAttribute("src", value.url);
    iframe.setAttribute("width", value.width || "600"); // Default width if not specified
    iframe.setAttribute("height", value.height || "400"); // Default height if not specified
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allowfullscreen", 'true');

    // Append the iframe to the wrapper div
    wrapperDiv.appendChild(iframe);

    return wrapperDiv;
  }

  static value(node: HTMLElement) {
    const iframe = node.querySelector("iframe");
    return {
      url: iframe?.getAttribute("src") || "",
      width: iframe?.getAttribute("width") || "600",
      height: iframe?.getAttribute("height") || "400",
      alignment: node.querySelector("div")?.style?.justifyContent || node?.style?.justifyContent || "center", // Default alignment is center
    };
  }
}

// Register the custom blot with Quill
IframeBlot.blotName = "iframe";
IframeBlot.tagName = "div"; // The wrapper div is now the primary element

Quill.register(IframeBlot);

export default IframeBlot;
