import PasswordInput from "components/Inputs/PasswordInput";
import TextInput from "components/Inputs/TextInput";
import BorderButtonPrimary from "components/Button/BorderButtonPrimary";
import { useEffect, useState } from "react";
import useTheme from "hooks/useTheme.hook";
import useQRZLookup from "hooks/useQRZLookup.hook";

function QrzForm() {
  const {
    qrzIntegrationData,
    storeQRZIntegrationData,
    qrzError,
    qrzSessionKey,
    unlinkQRZIntegration,
    loading,
    showConfirmation,
    setShowConfirmation
  } = useQRZLookup();
  const [form, setForm] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    setForm({
      username: qrzIntegrationData?.username || "",
      password: qrzIntegrationData?.password || "",
    });
  }, [qrzIntegrationData]);

  const submitForm = () => {
    storeQRZIntegrationData(form);
  };

  const isLinked =
    qrzIntegrationData?.username &&
    qrzIntegrationData?.password &&
    qrzSessionKey &&
    !qrzError;

  return (
    <section className="relative w-full h-full flex">
      <div className="relative flex-1 flex items-center">
        <div className="flex flex-col relative w-full">
          {isLinked ? (
            <>
              {showConfirmation ? (
                <>
                  <div className="text-red-500 text-lg font-[Play] mb-3">
                    Are you sure ? This action will remove the QRZ
                    integration from your account and you will not be able to
                    use QRZ lookup anymore.
                  </div>
                  <BorderButtonPrimary
                    text={"Yes"}
                    isLoading={loading}
                    onClick={() => {
                      unlinkQRZIntegration();
                    }}
                  />
                  <BorderButtonPrimary
                    text={"No"}
                    isLoading={loading}
                    onClick={() => {
                      setShowConfirmation(false);
                    }}
                  />
                </>
              ) : (
                <>
                  <div className="text-green-500 text-lg font-[Play] mb-3">
                    QRZ Linked with {qrzIntegrationData?.username}
                  </div>
                  <BorderButtonPrimary
                    text={"Unlink"}
                    isLoading={loading}
                    onClick={() => {
                      setShowConfirmation(true);
                    }}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <TextInput
                text={form.username}
                setText={(text) => setForm({ ...form, username: text })}
                placeHolder="Username *"
                name="username"
              />
              <PasswordInput
                password={form.password}
                setPassword={(text) => setForm({ ...form, password: text })}
              />

              {qrzError && (
                <div className="text-red-500 text-sm mb-3">{qrzError}</div>
              )}

              <BorderButtonPrimary
                text={"Submit"}
                isLoading={loading}
                onClick={() => {
                  submitForm();
                }}
              />
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default QrzForm;
