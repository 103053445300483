import { ParkReferenceModel, PotaAutoSpotPayload } from "types/Models";

export const GET_PARK_BY_REFERENCE = "GET_PARK_BY_REFERENCE";
export const SET_PARK_BY_REFERENCE_LOADING = "SET_PARK_BY_REFERENCE_LOADING";
export const SET_FIND_MY_PARK_LOADING = "SET_FIND_MY_PARK_LOADING";
export const SET_PARK_BY_REFERENCE_DATA = "SET_PARK_BY_REFERENCE_DATA";
export const GET_PARK_REFERENCE_SUGGESTIONS = "GET_PARK_REFERENCE_SUGGESTIONS";
export const SET_PARK_REFERENCE_SUGGESTIONS = "SET_PARK_REFERENCE_SUGGESTIONS";
export const GET_NEARBY_PARK_REFERENCES = "GET_NEARBY_PARK_REFERENCES";
export const SET_NEARBY_PARK_REFERENCES = "SET_NEARBY_PARK_REFERENCES";
export const ADD_POTA_AUTO_SPOT = "ADD_POTA_AUTO_SPOT";
export const ADD_WWFF_AUTO_SPOT = "ADD_WWFF_AUTO_SPOT";

export const SET_AUTO_SPOT_DATA = "SET_AUTO_SPOT_DATA";
export const FETCH_ACTIVITIES_MAP = "FETCH_ACTIVITIES_MAP";
export const SET_ACTIVITIES_MAP = "SET_ACTIVITIES_MAP";
export const SET_ACTIVITIES_LIST_VIEW = "SET_ACTIVITIES_LIST_VIEW";
export const FETCH_ACTIVITIES_LIST_VIEW = "FETCH_ACTIVITIES_LIST_VIEW";
export const SEARCH_ACTIVITY_LIST_VIEW = "SEARCH_ACTIVITY_LIST_VIEW";

export const GET_SINGLE_ACTIVITY_DETAIL = "GET_SINGLE_ACTIVITY_DETAIL";
export const SET_SINGLE_ACTIVITY_DETAIL = "SET_SINGLE_ACTIVITY_DETAIL";

export interface PotaActionPayload {
  type: string;
  payload?: any;
}

export function getParkByReferenceAction(
  parkReference: {
    value: string;
    key: string;
  },
  activityType?: string
): PotaActionPayload {
  return {
    type: GET_PARK_BY_REFERENCE,
    payload: { parkReference, activityType },
  };
}

export function setParkByReferenceAction(
  payload: { [key: string]: string | null } | null
): PotaActionPayload {
  return {
    type: SET_PARK_BY_REFERENCE_DATA,
    payload,
  };
}

export function getParkReferenceSuggestionsAction(
  parkReference: string,
  activityType?: string
): PotaActionPayload {
  return {
    type: GET_PARK_REFERENCE_SUGGESTIONS,
    payload: { parkReference, activityType },
  };
}

export function setParkReferenceSuggestionsAction(
  parkReference: ParkReferenceModel[] | null
): PotaActionPayload {
  return {
    type: SET_PARK_REFERENCE_SUGGESTIONS,
    payload: parkReference,
  };
}

export function getNearbyParkReferencesAction(
  center: [number, number],
  radiusInKm?: number,
  activityType?: string
): PotaActionPayload {
  return {
    type: GET_NEARBY_PARK_REFERENCES,
    payload: { center, radiusInKm, activityType },
  };
}

export function setNearbyParkReferencesAction(
  parkReferences: ParkReferenceModel[] | null
): PotaActionPayload {
  return {
    type: SET_NEARBY_PARK_REFERENCES,
    payload: parkReferences,
  };
}

export function addPotaAutoSpotAction(
  payload: PotaAutoSpotPayload,
  logBookId?: string
): PotaActionPayload {
  return {
    type: ADD_POTA_AUTO_SPOT,
    payload: { spotData: payload, logBookId },
  };
}
export function addWwffAutoSpotAction(
  payload: any,
  logBookId?: string
): PotaActionPayload {
  return {
    type: ADD_WWFF_AUTO_SPOT,
    payload: { spotData: payload, logBookId },
  };
}

export function setAutoSpotDataAction(
  payload: Partial<PotaAutoSpotPayload> | null
): PotaActionPayload {
  return {
    type: SET_AUTO_SPOT_DATA,
    payload,
  };
}

export function fetchActivitiesMap(
  payload: any,
  filters?: string[],
  mapZoomLevel?: number
): PotaActionPayload {
  return {
    type: FETCH_ACTIVITIES_MAP,
    payload: { bound: payload, filters, mapZoomLevel },
  };
}

export function fetchActivitiesListView(
  filters?: string[],
  userCoordinate?: [number, number],
  lastDoc?: any
): PotaActionPayload {
  return {
    type: FETCH_ACTIVITIES_LIST_VIEW,
    payload: { filters, lastDoc, userCoordinate },
  };
}

// set SEARCH_ACTIVITY_LIST_VIEW action
export function searchActivityListView(
  searchQuery: string,
  filter: string[],
  userCoordinate?: any
): PotaActionPayload {
  return {
    type: SEARCH_ACTIVITY_LIST_VIEW,
    payload: { searchQuery, userCoordinate, filter },
  };
}
export function getSingleActivity(reference: string): PotaActionPayload {
  return {
    type: GET_SINGLE_ACTIVITY_DETAIL,
    payload: { reference },
  };
}
