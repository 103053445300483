import { hasAccessTo } from "helpers/Utils";
import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface SkeletonLoadingPropd {
  baseColor?: string;
  highlightColor?: string;
  loading: boolean;
  className?: string;
  borderRadius?: number;
}

function SkeletonLoading({
  baseColor,
  highlightColor,
  loading,
  className,
  borderRadius,
}: SkeletonLoadingPropd) {
  if (loading) {
    return (
      <div className="absolute -top-1 left-0 w-full h-full">
        <Skeleton
          className={className}
          baseColor={baseColor}
          highlightColor={highlightColor}
          borderRadius={borderRadius}
        />
      </div>
    );
  } else {
    return null;
  }
}

export default SkeletonLoading;
