import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import useProfile from "./profile.hook";
import { useState } from "react";
import SupportService from "services/support.service";
import { doc, updateDoc, increment } from "firebase/firestore";
import { db } from "firebase-config";
import { UserDataTable } from "constants/Collections";

export const useSupport = () => {
  const { userProfile, memberStatus } = useProfile();
  const [isLoading, setIsLoading] = useState(false);

  const incrementBugCount = async () => {
    try {
      if (!userProfile?.uid) return;

      const docRef = doc(db, UserDataTable, userProfile?.uid);
      await updateDoc(docRef, {
        bugCount: increment(1),
      });
    }
    catch (error) {
      // handle error
    }
  };


  const reportBug = async (formValues: { details: string }, callback: any) => {
    if (formValues.details === "") {
      showToast({
        message: "Please provide details about the bug",
        type: ToastTypes.ERROR,
      });
      return;
    }

    setIsLoading(true);
    try {
      const requestPayload = {
        email: userProfile?.email,
        callSign: userProfile?.callSign,
        memberStatus: memberStatus,
        source: "WRL Web",
        ...formValues,
        timestamp: new Date().toISOString(),
        fullstorySessionURL:
          (window as any)?.[`FS`]?.getCurrentSessionURL?.(true) ||
          "no fullstory session available",
      };

      // send data to custom webhook
      const response = await SupportService.reportBug(requestPayload);

      if (response.status === 200) {
        // increment bug count when bug is reported
        incrementBugCount();

        showToast({
          message:
            "Thank you for reporting the bug! We will look into it soon.",
          type: ToastTypes.SUCCESS,
        });

        callback();
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const reportBugAnonymously = async (
    formValues: { details: string; email: string },
    callback: any
  ) => {
    if (!formValues.details.trim()) {
      showToast({
        message: "Please provide details about the bug",
        type: ToastTypes.ERROR,
      });
      return;
    }

    if (!formValues.email.trim()) {
      showToast({
        message: "Please enter your email",
        type: ToastTypes.ERROR,
      });
      return;
    }

    // validate if its valid email address using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formValues.email)) {
      showToast({
        message: "Please provide a valid email",
        type: ToastTypes.ERROR,
      });
      return;
    }

    setIsLoading(true);
    try {
      const requestPayload = {
        callSign: "anonymous",
        memberStatus: "anonymous",
        source: "WRL Web",
        ...formValues,
        timestamp: new Date().toISOString(),
        fullstorySessionURL:
          (window as any)?.[`FS`]?.getCurrentSessionURL?.(true) ||
          "no fullstory session available",
      };

      // send data to custom webhook
      const response = await SupportService.reportBug(requestPayload);

      if (response.status === 200) {
        showToast({
          message:
            "Thank you for reporting the bug! We will look into it soon.",
          type: ToastTypes.SUCCESS,
        });

        callback();
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const sendInvite = async (
    formValues: { theirEmail: string; theirCallsign: string },
    callback: any
  ) => {
    if (formValues.theirEmail === "") {
      showToast({
        message: "Please provide their email",
        type: ToastTypes.ERROR,
      });
      return;
    }

    setIsLoading(true);
    try {
      const requestPayload = {
        senderEmail: userProfile?.email,
        callSign: userProfile?.callSign,
        source: "WRL Web",
        ...formValues,
        timestamp: new Date().toISOString(),
      };
      // send data to custom webhook
      const response = await SupportService.sendInvite(requestPayload);

      if (response.status === 200) {
        showToast({
          message: "Your invite has been sent successfully!",
          type: ToastTypes.SUCCESS,
        });

        callback();
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return {
    reportBug,
    isLoading,
    sendInvite,
    reportBugAnonymously,
    incrementBugCount,
    bugCount : userProfile?.bugCount || 0
  };
};
