/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { UserAvatar } from "components/UserAvatar";
import { CABRILLO_FIELD_TYPES } from "../../../constants";

import { globalNavigate } from "routes/GlobalRoutes";
import { CabrilloHeader } from "types/Models";
import { useEffect, useState } from "react";
import { FaCaretDown } from "react-icons/fa6";
import { RiArrowDownSLine } from "react-icons/ri";
import { useExportCabrillo } from "hooks/useExportCabrillo";

interface AdvancedEditorProps {
  headers: CabrilloHeader[];
  setHeaders: (headers: CabrilloHeader[]) => void;
}

function AdvancedEditor({ headers, setHeaders }: AdvancedEditorProps) {
  const { headersToPlainText, editableText, setEditableText } =
    useExportCabrillo();

  useEffect(() => {
    const newText = headersToPlainText(headers);
    setEditableText(newText);
  }, [headers]);

  return (
    <div className="w-full  overflow-x-auto">
      <textarea
        className="w-full h-[400px] bg-[#fff] dark:bg-[#1F1F48] bg-[#e7e7f7] text-sm font-[Barlow] text-left py-2 pl-4 m-1 dark:text-[#17F9DA] text-blue-900"
        value={editableText}
        onChange={(e) => {
          const updatedText = e.target.value;
          setEditableText(updatedText);

          // Convert text into key-value map
          const lines = updatedText.split("\n");
          const lineMap: Record<string, string> = {};
          lines.forEach((line) => {
            const [key, ...rest] = line.split(":");
            if (key && rest.length > 0) {
              lineMap[key.trim()] = rest.join(":").trim();
            }
          });

          // Update headers
          const newHeaders = headers.map((header) => {
            const updatedValue = lineMap[header.name];
            if (updatedValue !== undefined) {
              if (header.type === CABRILLO_FIELD_TYPES.NUMBER) {
                return {
                  ...header,
                  value: updatedValue.replace(/\D/g, ""),
                };
              }
              if (header.multiSelect) {
                return {
                  ...header,
                  value: updatedValue.split(" ").filter((v) => v.trim() !== ""),
                };
              }
              return {
                ...header,
                value: updatedValue,
              };
            }
            return header;
          });

          setHeaders(newHeaders);
        }}
        placeholder="Click to enter..."
      />
    </div>
  );
}

export default AdvancedEditor;
