import { FieldValue, Timestamp } from "firebase/firestore";
import { Coordinates } from "types/State";
import { SPOT_SOURCE, SPOT_STATUS, SPOT_TYPE } from "./Functions";

export enum DistanceUnit {
  MILES = "MILES",
  KILOMETERS = "KILOMETERS",
}

export interface UserSettings {
  distanceUnit: DistanceUnit;
}

export type UserProfile = {
  gridSquare?: string;
  firstName?: string;
  lastName?: string;
  bio?: string;
  longBio?: string;
  callSign?: string;
  userName?: string;
  phoneNumber?: string;
  email?: string;
  uid?: string;
  provideId?: string;
  timestamp?: FieldValue;
  profilePic?: string;
  address?: string;
  country?: string;
  state?: string;
  stateLongName?: string;
  city?: string;
  facebook?: string;
  linkedin?: string;
  twitter?: string;
  instagram?: string;
  coordinates?: Coordinates;
  numberOfContacts?: number;
  modes?: any;
  bands?: any;
  foundingMemberCount?: number;
  membershipStatus?: string;
  stripeId?: string;
  stripeLink?: string;
  subscriptionCancelOnPeriodEnd?: boolean;
  subscriptionCanceledAt?: Timestamp;
  subscriptionCreatedAt?: Timestamp;
  subscriptionStatus?: string;
  myRadio?: string;
  myAntenna?: string;
  numberOfContactsImported?: number;
  firstAdiImport?: boolean;
  contests?: string[];
  numberOfPoints?: number;
  distance?: number;
  leaderBoardCount?: number;
  spotsCreated?: number | FieldValue;
  admin?: boolean;
  savedLocationId?: string;
  needsLocationOnboarding?: boolean;
  ituZone?: string | number;
  cqZone?: string | number;
  dxccNumber?: number;
  continent?: string;
  flagCode?: string;
  countryCode?: string;
  callsignSearchIndex?: string[];
  nameSearchIndex?: string[];
  defaultLogbookSettings?: LogbookSettingsForm;
  settings?: UserSettings;
  quota?: UserQuota;
  bugCount?: number;
};
export interface FeaturedMember extends UserProfile {
  message: string;
}

export interface PendingEmailUpdate {
  email: string;
  userData: UserProfile;
  profilePic: File | null;
}

export type Award = {
  id?: string;
  awardId?: string;
  name: string;
  image: string;
  pdf: string;
  type?: string;
  description: string;
  requirements: string;
  timestamp?: {
    seconds: number;
    nanoseconds: number;
  };
};

export type UserAwards = {
  id?: string;
  name: string;
  image: string;
  pdf: string;
  description: string;
  requirements: string;
  featured?: boolean;
  timestamp?: {
    seconds: number;
    nanoseconds: number;
  };
  type?: string;
  uid?: string;
};

export type UserNotification = {
  title: string;
  body: string;
  image: string;
  seen: boolean;
  uid: string;
  timestamp: number;
  seenOn?: number;
  id: string;
  buttonTitle?: string;
  buttonLink?: string;
  type?: string;
  data?: {
    fileUrl?: string;
    fileName?: string;
  };
  activityId?: string;
};

export type LogBookModel = {
  uid?: string;
  id?: string;
  name?: string;
  template?: string;
  timestamp?: Timestamp;
  contactCount?: number;
  myRadio?: string;
  myAntenna?: string;
  contestId?: string;
  contest?: {
    name: string;
    image: string;
    startDate?: Timestamp;
    endDate?: Timestamp;
    shortDescription?: string;
  };
  coordinates?: {
    latitude: number;
    longitude: number;
  };
  gridSquare?: string;
  adiFile?: string;
  importStatus?: string | null;
  fileName?: string;
  importDate?: Timestamp;
  errorCode?: string | null;
  failedContacts?: number;
  successContacts?: number;
  totalImportContacts?: number;
  duplicateContacts?: number;
  errorLogs?: string[];
  adiImported?: boolean;
  firstImport?: boolean;
  deleteInProgress?: boolean;
  logbookStyle?: string;
  defaultLocation?: SavedLocation | null;
  radio?: Radios | null;
  antenna?: Antenna | null;
  defaultCallSign?: string;
  callSignType?: string;
  defaultPower?: string;
  description?: string;
  contestPower?: string;
  numberOfTransmitters?: string;
  contestBand?: string;
  assisted?: string;
  operator?: string;
  myParks?: Record<string, ParkReferenceModel>;
  lastSpottedAt?: Timestamp;
  lastSpottedFrequency?: string;
  lastSpottedMode?: string;
  lastSpottedBand?: string;
  lastSpottedComment?: string;
  exportInProgress?: boolean;
  primaryActivity?: string;
  activities?: string[];
  activitiesData?: any[];
  lastContactTimestamp?: Timestamp;
  activityId?: string;
  // WFD specific fields
  contestObjectives?: any[];
  contestTransmitters?: string;
  contestClass?: string;
  contestSection?: string;
  contestTotalPoints?: number;
  isWFDLogBook?: boolean;
  isWFD?: boolean;

  // lookup service
  defaultLookup?: string;
  serialNumber?: number; //TEMP FOR CQ CONTEST
};

export type ArcType = {
  color: string[];
  startLat?: number;
  startLng?: number;
  endLat?: number;
  endLng?: number;
  theirCallsign?: string;
  theirCountry?: string;
  theirState?: string;
  myCallSign?: string;
  myCountry?: string;
  state?: string;
  country?: string;
  flagCode?: string;
  myFlagCode?: string;
};

export type SubscriptionStatus = {
  stripeRole?: string | null;
  cardProvider?: string;
  cardLast4Digits?: string;
  startDate?: { seconds: number; nanoseconds: number };
  renewalDate?: { seconds: number; nanoseconds: number };
  amount?: number;
  name?: string;
  image?: string;
  description?: string;
  status?: string;
};

export type PortalResponse = {
  data: { url: string | null };
};

export type CallSignLookUpData = {
  hamdb: {
    callsign: {
      addr1: string;
      addr2: string;
      call: string;
      class: string;
      country: string;
      expires: string;
      fname: string;
      grid: string;
      lat: string;
      lon: string;
      mi: string;
      name: string;
      state: string;
      status: string;
      suffix: string;
      zip: string;
    };
    messages: {
      status: string;
    };
    version: string;
  };
  callSignMatch?: boolean;
};

export type LogBookContactModel = {
  theirProfilePic?: string;
  myProfilePic?: string;
  id?: string;
  logBookId?: string | null;
  logBook?: LogBookModel;
  uid?: string;
  theirCallsign?: string;
  distance?: number;
  time?: string;
  date?: string;
  operator?: string;
  qth?: string;
  rstSent?: string;
  rstRCVD?: string;
  notes?: string;
  state?: string;
  stateLongName?: string;
  country?: string;
  myCountry?: string;
  grid?: string;
  frequency?: string;
  band?: string;
  userQth?: string;
  userMode?: string;
  myCallSign?: string;
  timestamp?: Timestamp;
  contactTimeStamp?: Timestamp;
  updatedAt?: Timestamp;
  theirCoordinates?: {
    latitude: string;
    longitude: string;
  };
  theirCountry?: string;
  theirState?: string;
  theirGrid?: string;
  myCoordinates?: {
    latitude: number;
    longitude: number;
  };
  userGrid?: string;
  power?: string;
  theirName?: string;
  theirPark?: string | Record<string, ParkReferenceModel>;
  parkNumber?: string;
  parkName?: string;
  myRadio?: string;
  myAntenna?: string;
  profileCallSign?: string;
  adiImported?: boolean;
  adiFileName?: string;
  contactString?: string;
  active?: boolean;
  adiError?: boolean;
  confirmed?: boolean;
  contactId?: string;
  duplicate?: boolean;
  myName?: string;
  confirmationData?: LogBookContactModel;
  shareImage?: string;
  callSignSearchIndex?: string[];
  nameSearchIndex?: string[];
  myNameSearchIndex?: string[];
  exchangeTwo?: string;
  exchangeOne?: string;
  exchangeThree?: string;
  exchangeFour?: string;
  myState?: string;
  myStateLongName?: string;
  myPark?: string;
  myParkData?: LogBookPark;
  theirParkData?: LogBookPark;
  theirParks?: Record<string, ParkReferenceModel>;
  myParks?: Record<string, ParkReferenceModel>;
  // contestId: string | null | undefined;
  tags?: string[];
  theirCity?: string;
  myRadioId?: string;
  myAntennaId?: string;
  mySavedLocationId?: string;
  contestId?: string;
  contestName?: string;
  dxccNumber?: number;
  continent?: string;
  flagCode?: string;
  countryCode?: string;
  myFlagCode?: string;
  myCity?: string;

  primaryActivity?: string;
  activities?: string[];
  activitiesData?: any[];
  myActivityReferences?: any[];
  theirActivityReferences?: any[];
  theirActivitiesData?: any[];
  theirActivities?: string[];
  activitiesReferences?: any[];
  serialNumber?: number; //TEMP FOR CONTEST
};

export type LogBookPark = {
  reference?: string;
  name?: string;
  parkTypeDesc?: string;
  parkType?: string;
  grid?: string;
  latitude?: number;
  longitude?: number;
};

export type FunctionResponse = {
  data: { redirectUrl: string };
};

export type ContestDescription = {
  title: string;
  description: string;
};

export type ContestModel = {
  id?: string;
  name: string;
  contestExportId?: string;
  streamVideoId?: string;
  image: string;
  shortDescription?: string;
  description: ContestDescription[];
  startDate: Timestamp;
  endDate: Timestamp;
  membershipLevel: string[];
  bands: string[];
  modes: string[];
  geo: string;
  multiplier: number;
  exchangeOne: string;
  exchangeTwo: string;
  exchangeThree?: string;
  exchangeFour?: string;
  numberOfCompetitors?: number;
  contestantCount?: number;
  timestamp: Timestamp;
  loading?: boolean;
  leader?: UserProfile;
  awards?: UserAwards[];
  objectives?: any[];
  isWFDContest?: boolean;
  isCQContest?: boolean; // TEMP
};

export type EnrollSuccessData = {
  contestId: string;
  contestName: string;
  logbookName?: string;
  logbookId?: string;
  contestStatus: string | null;
  startDate: Timestamp;
  endDate: Timestamp;
};

export interface Contestant extends UserProfile {
  logBookId: string;
  logbookName: string;
  enrolledTimestamp: Timestamp;
}

export type ContestantDetail = {
  contestantCount: number;
  qsos: number;
  isUserEnrolled: boolean;
  contestants: UserProfile[];
  enrolledUser: Contestant;
};

export type ContestLeader = {
  [contestId: string]: UserProfile | null;
};

export interface ContestLeadrBoard extends UserProfile {
  numberOfPoints: number;
  contestContactCount: number;
  contestTotalPoints: number;
  contestMultiplier: number;
  contestClass?: string;
  defaultCallSign?: string;
}

export type ContestantUserDetail = {
  user: UserProfile;
  userLogs: LogBookContactModel[];
  totalQso: number;
  contestRanking: number;
  contestContactCount: number;
  contestTotalPoints: number;
  contestMultiplier: number;
  contestObjectivesTotalPoints: number;
  contestUserObjectivesTotalPoints: number;
  contestTotalObjectives: number;
  options: any[];
  selectedOptions: string[];
  userBands: Record<string, number>;
  userModes: Record<string, number>;
  userModePoints: Record<string, number>;
  contactByHour: Array<any>;
};

export type LeaderData = {
  title: string;
  titleColor: string;
  image?: string;
  firstText?: string;
  secondText: string;
  state?: string;
  band?: string;
  country?: string;
  link?: string;
  distance?: boolean;
  fromCountry?: string;
  toCountry?: string;
};

export interface SpotterUser {
  uid: string;
  callSign: string;
  timestamp: Timestamp;
  coordinates?: Coordinates;
}

export interface SpotModel {
  id?: string;
  uid?: string; // User ID of the user who has been spotted
  callSign: string; // Call sign of the user who has been spotted
  name?: string; // Name of the user who has been spotted
  profilePic?: string; // Profile picture of the user who has been spotted
  frequency: string; // Frequency of the user
  band: string; // Band of the frequency
  mode: string; // Mode of the user
  lastSpottedTimestamp: Timestamp; // Timestamp of last spotted time
  timestamp: Timestamp; // Timestamp the spot was created
  source: SPOT_SOURCE; // Source of the spot (e.g., 'WRL LogBook', 'POTA')
  spotType: SPOT_TYPE; // Who spotted the user
  spotterCount?: number; // Number of users who have spotted this call sign. It isn't necessary that spotterList length is equal to spotterCount because spotterList is being set in WRL case only not POTA!
  comments?: string; // Comments added to the spot
  spotterList: SpotterUser[]; // List of users who have spotted this contact
  logBookContactIDs?: string[]; // List of logbook contact IDs associated with this spot
  activityNumber?: string; // If this is an activity location, the ID of the activity site
  activityName?: string; // It this is an activity location, the name of the activity site
  spotterNearYou?: boolean; // If the user has been spotted by someone close to you
  grid?: string; // Grid square of the user who has been spotted
  coordinates?: Coordinates; // coordinates where the user is operating
  country?: string; // Country where the user is operating
  dxccNumber?: number; // DXCC ID of the location
  flagCode?: string; // Flag code of the country
  state?: string; // State or territory
  status: SPOT_STATUS; // Status of the spot
  deactivatedBy?: string; // Call sign of the user who deactivated the spot,
  logged?: boolean; // If the user has been logged in the current user's logbook
}

export type SpotFilters = {
  band?: any;
  mode?: any;
  myCountry?: string;
  theirState?: string;
  theirCountry?: string;
  theirRegion?: string;
  operatingNearMe?: boolean;
  dxOnly?: boolean;
  spottedNearMe?: boolean;
  potaOnly?: boolean;
  wrlOnly?: boolean;
  logged?: boolean;
};

export type QSOMapShare = {
  imageUrl: string;
  uid: string;
  timestamp: any;
  callSign: string;
};

export type SavedLocation = {
  id?: string;
  uid?: string;
  timestamp?: any;
  name: string;
  locationType: string;
  country?: string;
  state?: string;
  stateLongName?: string;
  qth?: string;
  default?: boolean;
  lat?: number | string;
  lng?: number | string;
  gridsquare?: string;
  isCustom?: boolean;
  locationSource?: string;
  city?: string;
  ituZone?: string;
  cqZone?: string;
  setAsHome?: boolean;
  locationMethod?: "address" | "grid" | "map";
  isOnboarding?: boolean;
};

export type Radios = {
  id?: string;
  uid?: string;
  timestamp?: any;
  name?: string;
  brand: string;
  model: string;
  photoUrl: string;
  description: string;
  yearPurchased?: string;
  yearManufactured?: string;
  numberOfQSO?: number;
};

export type Stations = {
  id?: string;
  uid?: string;
  timestamp?: any;
  name?: string;
  photoUrl: string;
  description: string;
  brand?: string;
  model?: string;
  numberOfQSO?: number;
};

export type Antenna = {
  id?: string;
  uid?: string;
  timestamp?: any;
  name: string;
  brand: string;
  model: string;
  photoUrl: string;
  type: string;
  description: string;
  yearPurchased?: string;
  yearManufactured?: string;
  numberOfQSO?: number;
};

export type ParkReferenceModel = {
  uid?: string;
  accessMethods?: string;
  accessibility?: null | string;
  activationMethods?: string;
  active?: number;
  agencies?: string;
  agencyURLs?: string;
  createdByAdmin?: null | string;
  entityDeleted?: number;
  entityId: number;
  entityName: string;
  firstActivationDate?: string;
  firstActivator?: string;
  grid4: string;
  grid6: string;
  latitude: number;
  locationDesc?: string;
  locationName?: string;
  longitude: number;
  name: string;
  parkComments?: string;
  parkId: number;
  parkURLs?: string;
  parktypeDesc: string;
  parktypeId?: number;
  reference: string;
  referencePrefix: string;
  referenceSearchIndex?: string[];
  sensitivity?: null | string;
  timestamp?: Timestamp;
  website?: string;
  distanceInKm?: number;
  [key: string]: any;
};

export type CountryADIFData = {
  CQZone: number;
  Continent: string;
  Country: string;
  ExactCallsign: boolean;
  GMTOffset: number;
  ITUZone: number;
  Latitude: number;
  Longitude: number;
  Prefix: string;
  prefix: string;
};

export type PotaAutoSpotPayload = {
  activator: string;
  spotter: string;
  frequency: string;
  reference: string;
  mode: string;
  comments: string;
  band: string;
};

export type LogbookSettingsForm = {
  name?: string;
  description?: string;
  logbookStyle?: string;
  defaultLocation?: any;
  radio?: string;
  antenna?: string;
  defaultCallSign?: string;
  callSignType?: string;
  defaultPower?: string;
  contestPower?: string;
  numberOfTransmitters?: string;
  contestBand?: string;
  assisted?: string;
  operator?: string;
  myPark?: string;
  myParks?: Record<string, ParkReferenceModel>;
  locationSource?: string;
  activitiesData?: any[];
  primaryActivity?: string;
  activities?: string[];
  activitiesReferences?: any[];

  // WFD specific fields
  contestObjectives?: any[];
  contestTransmitters?: string;
  contestClass?: string;
  contestSection?: string;
  isWFD?: boolean;

  // lookup service
  defaultLookup?: string;
};

// export type CallsignModel = {
//   callsign: string;
//   class: string;
//   grid: string;
//   latitude: number;
//   longitude: number;
//   fullname: string;
//   firstName: string;
//   middleName: string;
//   lastName: string;
//   street: string;
//   city: string;
//   state: string;
//   zip: string;
//   country: string;
//   status: string;
//   expires: string;
// };

export interface CallsignModel {
  // Callsign Information
  callsign: string; // callsign (e.g. W1AW)
  callsignSearchIndex: Array<string>; // search index for callsign (e.g. W1AW, W1, 1AW)
  class: string; // class (e.g. A=amateur, G=general, E=extra, N=novice)
  type: string; // type (e.g. P=personal, C=club, T=temporary, S=special)
  status: string; // status (e.g. L=licensed, U=unlicensed, E=expired)
  formerCallsign: string; // former callsign (e.g. W1AW)
  formerClass: string; // former class (e.g. A=amateur, G=general, E=extra, N=novice)
  reference: string; // reference (e.g. FCC id, ARRL, QRZ)
  grantDate: string; // date granted
  expiryDate: string; // date expired

  // club specific information
  clubName: string; // club name (e.g. ABC Club)
  trusteeCallsign: string; // trustee callsign (e.g. W1AW)
  trusteeName: string; // club name (e.g. ABC Club)

  // Individual Information
  fullName: string;
  firstName: string; // first name
  lastName: string; // last name
  middleName: string; // middle name
  nameSearchIndex: Array<string>; // search index for name (e.g. John Doe, John, Doe)

  // Location Information
  city: string; // city
  state: string; // state (e.g. Massachusetts)
  stateCode: string; // state code (e.g. MA)
  country: string; // country (e.g. United States)
  countryCode: string; // country code (e.g. USA)
  zip: string; // zip code
  address: string; // street address (e.g. 123 Main St)
  grid: string; // grid square (e.g. FN42)
  latitude: number; // latitude for location
  longitude: number; // longitude for location
  geohash: string; // geohash for location
  ituZone: number; // ITU zone
  cqZone: number; // CQ zone

  // meta
  id: string; // unique identifier
  createdAt: string; // string created
  updatedAt: string; // date updated
  active: boolean; // active status (e.g. true, false)
}

export type DXCCList = {
  country: string;
  continent: string;
  dxccNumber: number;
  flagCode: string;
  countryCode: string;
};

export type SortConfig = {
  column: string;
  order: "asc" | "desc" | "";
  logged?: boolean;
};

export interface ActivitiesMapData {
  id: string;
  reference: string;
  country: string;
  state: string;
  dxccNumber: number;
  lastActivationCall: string;
  lastActivationDate: string;
  grid4: string;
  grid6: string;
  altitude: number;
  latitude: number;
  longitude: number;
  name: string;
  type: string;
  website: string;
  activatorProfilePicture: string;
  activatorCountry: string;
  activatorName: string;
  myActivationCount: number;
  wrlActivationCount: number;
  lastWrlActivationDate: string;
  huntCount: number;
  isLive: boolean;
  distanceInMiles?: number;
  activated?: boolean;
}

export enum Activities {
  POTA = "POTA",
  SOTA = "SOTA",
  WWFF = "WWFF",
  IOTA = "IOTA",
}

export enum ACTIVITY_QUERY_TYPE {
  WRL_ACTIVATION = "WRL_ACTIVATION",
  WRL_ACTIVATORS = "WRL_ACTIVATORS",
  WRL_HUNTERS = "WRL_HUNTERS",
}

//references
/**
 
Operator Class Code	
  A	Advanced       			
	E	Amateur Extra  			
	G	General        			
	N	Novice         			
	P	Technician Plus			
	T	Technician  
  
  

  
HD	License Status				
	A	Active                 			
	C	Canceled               			
	E	Expired                			
	L	Pending Legal Status   			
	P	Parent Station Canceled			
	T	Terminated             			
	X	Term Pending   


  EN	Applicant Type Code				
	Code	Description		Code Active?		
	B 	Amateur Club                  	A		
	C 	Corporation                   	A		
	D 	General Partnership           	A		
	E 	Limited Partnership           	A		
	F 	Limited Liability Partnership 	A		
	G 	Governmental Entity           	A		
	H 	Other                         	A		
	I 	Individual                    	A		
	J 	Joint Venture                 	N		
	L 	Limited Liability Company     A		
	M 	Military Recreation           	A		
	O 	Consortium                    	A		
	P 	Partnership                   	N		
	R 	RACES                         	A		
	T 	Trust                         	A		
	U 	Unincorporated Association   A	    
 */

export interface UserQuota {
  analyticsViewsRemaining: number;
  lastResetDate: string;
  isLocked: boolean;
}

export enum CABRILLO_FIELD_TYPES {
  TEXT = "TEXT",
  DROPDOWN = "DROPDOWN",
  NUMBER = "NUMBER",
}
export type CabrilloHeader = {
  name: string;
  value?: string | string[] | number;
  type?: string;
  dropdownList?: { name: string; code?: string; value?: string }[]; // for dropdowns like ARRL section or power types
  multiSelect?: boolean;
  disabled?: boolean;
};
