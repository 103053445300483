/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import Header from "components/Header";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { logbookTabs } from "constants/NavMenus";
import LogContacts from "./LogContacts";
import LogbookSettings from "./LogBookSettings/LogbookSettings";
import Icons from "assets/Icons";
import useLogBook from "hooks/logBook.hook";
import { Templates } from "constants/Config";
import { globalNavigate } from "routes/GlobalRoutes";
import {
  fetchContestDetails,
  setContestDetails,
} from "store/Actions/contest.actions";
import { getActivityLogo } from "helpers/Utils";
import { Activities } from "types/Models";
import { FaExternalLinkAlt, FaExternalLinkSquareAlt } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import useTheme from "hooks/useTheme.hook";
// import useMap from "hooks/map.hook";

function AddContactLogBook() {
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { theme } = useTheme();

  const [selectedIndex, setSelectedIndex] = useState<string | null>("");

  const [showSingleActivityDropdown, setShowSingleActivityDropdown] =
    useState<boolean>(false);

  const { selectedLogBook } = useLogBook();
  const isDark = theme === "dark";

  const isPota = selectedLogBook?.logbookStyle === Templates.POTA;

  useEffect(() => {
    if (!searchParams.get("tab")) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("tab", "Log Contacts");
      setSearchParams(newSearchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedLogBook?.contestId) {
      dispatch(
        fetchContestDetails({
          contestId: selectedLogBook?.contestId,
          userId: selectedLogBook?.uid,
        })
      );
    } else {
      dispatch(setContestDetails(null));
    }
  }, []);

  useEffect(() => {
    let selectedTab = searchParams.get("tab")
      ? searchParams.get("tab")
      : "Logbook";

    if (searchParams.get("contest_id")) {
      selectedTab = searchParams.get("contest_tab");
    }

    setSelectedIndex(selectedTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const getLogbookName = () => {
    let name = searchParams.get("logbook-name");

    if (selectedLogBook?.name) {
      name = selectedLogBook?.name;
    } else if (isPota) {
      name = `${selectedLogBook?.defaultCallSign} @ `;
      name += Object.keys(selectedLogBook?.myParks || {}).join(", ");
    }

    return name;
  };

  const getTabs = () => {
    if (selectedIndex === "Log Contacts") {
      return <LogContacts />;
    } else if (selectedIndex === "Logbook Settings") {
      return <LogbookSettings />;
    } else {
      return <LogContacts />;
    }
  };

  const onSettingsClickHandler = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (searchParams.get("contest_id")) {
      newSearchParams.set("contest_tab", logbookTabs[1]);
    } else {
      newSearchParams.set("tab", logbookTabs[1]);
    }
    setSearchParams(newSearchParams);
  };

  const onBackClickHandler = () => {
    if (searchParams.get("contest_id")) {
      if (searchParams.get("contest_tab") === logbookTabs[1]) {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete("contest_tab");
        setSearchParams(newSearchParams);
      } else {
        globalNavigate(-1);
      }
    } else {
      if (searchParams.get("tab") === logbookTabs[0]) {
        globalNavigate("/log-contacts");
      } else {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("tab", logbookTabs[0]);
        setSearchParams(newSearchParams);
      }
    }
  };

  return (
    <main
      className={`relative w-full h-full ${
        searchParams.get("contest_id") ? "" : "flex-grow overflow-x-auto"
      }`}
    >
      {searchParams.get("contest_id") ? null : <Header title="Add a Contact" />}
      <div
        className={`w-full flex flex-col items-center pb-7 sm:pb-10 overflow-x-hidden dashboardWrapper ${
          searchParams.get("contest_id") ? "" : "px-3 sm:px-7"
        }`}
      >
        <div className="flex flex-row w-full dark:text-white text-[#2b2e48] py-4 justify-between">
          <div
            className="flex flex-row gap-1 items-center cursor-pointer"
            onClick={onBackClickHandler}
          >
            <img
              src={Icons.BackIcon}
              alt="back button"
              className="invert dark:filter-none dark:invert-0"
            />{" "}
            Back
          </div>
          <div className="flex justify-center items-center text-md md:text-2xl font-Play gap-2">
            {isPota || selectedLogBook?.primaryActivity ? (
              <img
                src={getActivityLogo(
                  selectedLogBook?.primaryActivity || Activities.POTA
                )}
                alt="logbook name"
              />
            ) : null}
            {getLogbookName()}
            <div className="relative">
              {isPota || selectedLogBook?.primaryActivity ? (
                <FiExternalLink
                  color={isDark ? "#fff" : "#151541"}
                  size={20}
                  className="cursor-pointer"
                  onMouseOver={() => setShowSingleActivityDropdown(true)}
                  onMouseLeave={() => setShowSingleActivityDropdown(false)}
                  onFocus={() => setShowSingleActivityDropdown(true)}
                  onClick={() => {
                    if (
                      selectedLogBook?.activitiesData &&
                      selectedLogBook?.activitiesData?.length === 1
                    ) {
                      const park = selectedLogBook?.activitiesData[0];
                      globalNavigate(
                        `/activities/detail?park=${park?.reference}&type=${park?.type}&name=${park?.name}`,
                        {
                          state: park,
                        }
                      );
                    }
                  }}
                />
              ) : null}
              {selectedLogBook?.activitiesData &&
              selectedLogBook?.activitiesData?.length > 1 &&
              showSingleActivityDropdown ? (
                <div
                  className="absolute w-[200px] dark:bg-[#151541] bg-[#e7e7e7] border dark:border-[#a7a7a7] border-[#000] border-solid top-4 z-50"
                  onMouseOver={() => setShowSingleActivityDropdown(true)}
                  onMouseLeave={() => setShowSingleActivityDropdown(false)}
                  onFocus={() => setShowSingleActivityDropdown(true)}
                >
                  {selectedLogBook?.activitiesData.map((park) => (
                    <div
                      key={park.reference}
                      className="flex flex-row items-center gap-2 cursor-pointer border-b dark:border-[#a7a7a7] border-[#000] border-solid py-2 px-3"
                      onClick={() => {
                        if (
                          selectedLogBook?.activitiesData &&
                          selectedLogBook?.activitiesData?.length > 0
                        ) {
                          globalNavigate(
                            `/activities/detail?park=${park?.reference}&type=${park?.type}&name=${park?.name}`,
                            {
                              state: park,
                            }
                          );
                        }
                      }}
                    >
                      <p className="text-sm font-[Play]">{park.reference}</p>
                      <FiExternalLink
                        color={isDark ? "#fff" : "#151541"}
                        size={20}
                        className="cursor-pointer"
                      />
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className="cursor-pointer items-center"
            onClick={onSettingsClickHandler}
          >
            <img
              src={Icons.SettingsIcon}
              className="invert dark:filter-none dark:invert-0"
              alt="settings button"
            />
          </div>
        </div>
        {getTabs()}
      </div>
    </main>
  );
}

export default AddContactLogBook;
