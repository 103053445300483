import React from 'react';

export const LoadingSkeleton = () => {
  return (
    <div className="w-full p-3 space-y-2.5 animate-pulse">
      {/* Stats Header Section Skeleton */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2.5">
        {/* Activity Header Skeleton */}
        <div className="w-full dark:bg-gradient-to-l dark:from-[#1a1d54] dark:to-[#2e2e56] bg-white rounded-sm p-4">
          <div className="flex items-center gap-2">
            <div className="w-10 h-10 rounded-full bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600"></div>
            <div className="flex-1">
              <div className="h-6 w-3/4 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
              <div className="h-4 w-1/2 mt-2 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
            </div>
          </div>
        </div>

        {/* QSO Speedometer Skeleton */}
        <div className="w-full dark:bg-gradient-to-l dark:from-[#1a1d54] dark:to-[#2e2e56] bg-white rounded-sm p-4">
          <div className="flex items-center gap-10">
            <div className="w-14 h-14 rounded-full bg-gradient-to-r from-[#FF6B3D] to-[#FDB777]"></div>
            <div className="flex flex-col justify-center gap-2">
              <div className="h-6 w-32 bg-gradient-to-r from-[#FF6B3D] to-[#FDB777] rounded"></div>
              <div className="h-4 w-24 bg-gradient-to-r from-[#FDB777] to-[#FF6B3D] rounded"></div>
            </div>
          </div>
        </div>

        {/* Duration Skeleton */}
        <div className="w-full dark:bg-gradient-to-l dark:from-[#1a1d54] dark:to-[#2e2e56] bg-white rounded-sm p-4">
          <div className="flex items-center gap-4">
            <div className="w-10 h-10 rounded-full bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600"></div>
            <div className="flex-1">
              <div className="h-6 w-3/4 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
              <div className="h-4 w-1/2 mt-2 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
            </div>
          </div>
        </div>

        {/* Total QSOs Skeleton */}
        <div className="w-full dark:bg-gradient-to-l dark:from-[#1a1d54] dark:to-[#2e2e56] bg-white rounded-sm p-4">
          <div className="space-y-1.5">
            <div className="h-6 w-1/3 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
            <div className="h-4 w-1/4 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
          </div>
        </div>
      </div>

      {/* Band/Mode Charts Skeleton */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2.5">
        {[1, 2].map((index) => (
          <div key={index} className="w-full dark:bg-gradient-to-l dark:from-[#1a1d54] dark:to-[#2e2e56] bg-white rounded-sm p-4">
            <div className="h-[200px] bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
          </div>
        ))}
      </div>

      {/* DX Summary Skeleton */}
      <div className="w-full dark:bg-gradient-to-l dark:from-[#1a1d54] dark:to-[#2e2e56] bg-white rounded-sm p-4">
        <div className="flex justify-between mb-3">
          <div className="h-6 w-24 bg-gradient-to-r from-[#37A4BA] via-[#496780] to-[#CF0971] rounded"></div>
        </div>
        <div className="flex flex-wrap gap-2">
          {[1, 2, 3, 4].map((index) => (
            <div key={index} className="flex items-center gap-2">
              <div className="w-6 h-4 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
              <div className="h-4 w-20 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
            </div>
          ))}
        </div>
      </div>

      {/* Distance & States Summary Skeleton */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2.5">
        {/* Longest Contact Skeleton */}
        <div className="w-full dark:bg-gradient-to-l dark:from-[#1a1d54] dark:to-[#2e2e56] bg-white rounded-sm p-4">
          <div className="space-y-1.5">
            <div className="h-7 w-36 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
            <div className="flex items-center gap-1.5">
              <div className="h-4 w-24 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
              <div className="w-6 h-4 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
              <div className="h-4 w-20 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
            </div>
          </div>
        </div>

        {/* States Summary Skeleton */}
        <div className="w-full dark:bg-gradient-to-l dark:from-[#1a1d54] dark:to-[#2e2e56] bg-white rounded-sm p-4">
          <div className="space-y-1.5">
            <div className="h-7 w-28 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
            <div className="flex flex-wrap gap-1.5">
              {[1, 2, 3, 4, 5].map((index) => (
                <div key={index} className="h-4 w-8 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
              ))}
              <div className="h-4 w-4 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded">...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}; 