export const QSO_MANAGER_PAGE_SIZE = 50;
export const QSO_MANAGER_MAX_OFFSET = 500;
export const QSO_MAX_PAGE_JUMP_SIZE = 5;
export const QSO_IMPORTED_ONLY = "Imported Only";
export const QSO_WRL_LOGGER_ONLY = "WRL Logger Only";
export const IN_PROGRESS = "In Progress";
export const COMPLETE = "Complete";

export const ActivitiesColorMapping: Record<string, string> = {
  POTA: "#12CAA7",
  SOTA: "#FF3B30",
  IOTA: "#FFAC30",
  WWFF: "#66FF30",
};

export const ActivitiesIDMapping: Record<string, number> = {
  SOTA: 777,
  IOTA: 888,
  WWFF: 999,
};

export const rawArrlSections = [
  {
    name: "DX",
    value: "DX",
    country: "DX",
    stateCode: "DX",
    sectionName: "DX",
  },
  {
    name: "CT - Connecticut - USA",
    value: "CT",
    country: "USA",
    stateCode: "CT",
    sectionName: "Connecticut",
  },
  {
    name: "RI - Rhode Island - USA",
    value: "RI",
    country: "USA",
    stateCode: "RI",
    sectionName: "Rhode Island",
  },
  {
    name: "EMA - Eastern Massachusetts - USA",
    value: "EMA",
    country: "USA",
    stateCode: "MA",
    sectionName: "Eastern Massachusetts",
  },
  {
    name: "WMA - Western Massachusetts - USA",
    value: "WMA",
    country: "USA",
    stateCode: "MA",
    sectionName: "Western Massachusetts",
  },
  {
    name: "VT - Vermont - USA",
    value: "VT",
    country: "USA",
    stateCode: "VT",
    sectionName: "Vermont",
  },
  {
    name: "ME - Maine - USA",
    value: "ME",
    country: "USA",
    stateCode: "ME",
    sectionName: "Maine",
  },
  {
    name: "NH - New Hampshire - USA",
    value: "NH",
    country: "USA",
    stateCode: "NH",
    sectionName: "New Hampshire",
  },
  {
    name: "ENY - Eastern New York - USA",
    value: "ENY",
    country: "USA",
    stateCode: "NY",
    sectionName: "Eastern New York",
  },
  {
    name: "NNY - Northern New York - USA",
    value: "NNY",
    country: "USA",
    stateCode: "NY",
    sectionName: "Northern New York",
  },
  {
    name: "NLI - NYC / Long Island - USA",
    value: "NLI",
    country: "USA",
    stateCode: "NY",
    sectionName: "NYC / Long Island",
  },
  {
    name: "SNJ - Southern New Jersey - USA",
    value: "SNJ",
    country: "USA",
    stateCode: "NJ",
    sectionName: "Southern New Jersey",
  },
  {
    name: "NNJ - Northern New Jersey - USA",
    value: "NNJ",
    country: "USA",
    stateCode: "NJ",
    sectionName: "Northern New Jersey",
  },
  {
    name: "WNY - Western New York - USA",
    value: "WNY",
    country: "USA",
    stateCode: "NY",
    sectionName: "Western New York",
  },
  {
    name: "DE - Delaware - USA",
    value: "DE",
    country: "USA",
    stateCode: "DE",
    sectionName: "Delaware",
  },
  {
    name: "MDC - Maryland - DC - USA",
    value: "MDC",
    country: "USA",
    stateCode: "MD",
    sectionName: "Maryland - DC",
  },
  {
    name: "EPA - Eastern Pennsylvania - USA",
    value: "EPA",
    country: "USA",
    stateCode: "PA",
    sectionName: "Eastern Pennsylvania",
  },
  {
    name: "WPA - Western Pennsylvania - USA",
    value: "WPA",
    country: "USA",
    stateCode: "PA",
    sectionName: "Western Pennsylvania",
  },
  {
    name: "AL - Alabama - USA",
    value: "AL",
    country: "USA",
    stateCode: "AL",
    sectionName: "Alabama",
  },
  {
    name: "SFL - Southern Florida - USA",
    value: "SFL",
    country: "USA",
    stateCode: "FL",
    sectionName: "Southern Florida",
  },
  {
    name: "GA - Georgia - USA",
    value: "GA",
    country: "USA",
    stateCode: "GA",
    sectionName: "Georgia",
  },
  {
    name: "TN - Tennessee - USA",
    value: "TN",
    country: "USA",
    stateCode: "TN",
    sectionName: "Tennessee",
  },
  {
    name: "KY - Kentucky - USA",
    value: "KY",
    country: "USA",
    stateCode: "KY",
    sectionName: "Kentucky",
  },
  {
    name: "VA - Virginia - USA",
    value: "VA",
    country: "USA",
    stateCode: "VA",
    sectionName: "Virginia",
  },
  {
    name: "NC - North Carolina - USA",
    value: "NC",
    country: "USA",
    stateCode: "NC",
    sectionName: "North Carolina",
  },
  {
    name: "WCF - West Central Florida - USA",
    value: "WCF",
    country: "USA",
    stateCode: "FL",
    sectionName: "West Central Florida",
  },
  {
    name: "NFL - Northern Florida - USA",
    value: "NFL",
    country: "USA",
    stateCode: "FL",
    sectionName: "Northern Florida",
  },
  {
    name: "PR - Puerto Rico - USA",
    value: "PR",
    country: "USA",
    stateCode: "PR",
    sectionName: "Puerto Rico",
  },
  {
    name: "SC - South Carolina - USA",
    value: "SC",
    country: "USA",
    stateCode: "SC",
    sectionName: "South Carolina",
  },
  {
    name: "VI - US Virgin Islands - USA",
    value: "VI",
    country: "USA",
    stateCode: "VI",
    sectionName: "US Virgin Islands",
  },
  {
    name: "AR - Arkansas - USA",
    value: "AR",
    country: "USA",
    stateCode: "AR",
    sectionName: "Arkansas",
  },
  {
    name: "NTX - North Texas - USA",
    value: "NTX",
    country: "USA",
    stateCode: "TX",
    sectionName: "North Texas",
  },
  {
    name: "LA - Louisiana - USA",
    value: "LA",
    country: "USA",
    stateCode: "LA",
    sectionName: "Louisiana",
  },
  {
    name: "OK - Oklahoma - USA",
    value: "OK",
    country: "USA",
    stateCode: "OK",
    sectionName: "Oklahoma",
  },
  {
    name: "MS - Mississippi - USA",
    value: "MS",
    country: "USA",
    stateCode: "MS",
    sectionName: "Mississippi",
  },
  {
    name: "STX - South Texas - USA",
    value: "STX",
    country: "USA",
    stateCode: "TX",
    sectionName: "South Texas",
  },
  {
    name: "NM - New Mexico - USA",
    value: "NM",
    country: "USA",
    stateCode: "NM",
    sectionName: "New Mexico",
  },
  {
    name: "WTX - West Texas - USA",
    value: "WTX",
    country: "USA",
    stateCode: "TX",
    sectionName: "West Texas",
  },
  {
    name: "EB - East Bay - USA",
    value: "EB",
    country: "USA",
    stateCode: "CA",
    sectionName: "East Bay",
  },
  {
    name: "SDG - San Diego - USA",
    value: "SDG",
    country: "USA",
    stateCode: "CA",
    sectionName: "San Diego",
  },
  {
    name: "LAX - Los Angeles - USA",
    value: "LAX",
    country: "USA",
    stateCode: "CA",
    sectionName: "Los Angeles",
  },
  {
    name: "SF - San Francisco - USA",
    value: "SF",
    country: "USA",
    stateCode: "CA",
    sectionName: "San Francisco",
  },
  {
    name: "ORG - Orange - USA",
    value: "ORG",
    country: "USA",
    stateCode: "CA",
    sectionName: "Orange",
  },
  {
    name: "SJV - San Joaquin Valley - USA",
    value: "SJV",
    country: "USA",
    stateCode: "CA",
    sectionName: "San Joaquin Valley",
  },
  {
    name: "SB - Santa Barbara - USA",
    value: "SB",
    country: "USA",
    stateCode: "CA",
    sectionName: "Santa Barbara",
  },
  {
    name: "SV - Sacramento Valley - USA",
    value: "SV",
    country: "USA",
    stateCode: "CA",
    sectionName: "Sacramento Valley",
  },
  {
    name: "SCV - Santa Clara Valley - USA",
    value: "SCV",
    country: "USA",
    stateCode: "CA",
    sectionName: "Santa Clara Valley",
  },
  {
    name: "PAC - Pacific - USA",
    value: "PAC",
    country: "USA",
    stateCode: "HI",
    sectionName: "Pacific",
  },
  {
    name: "AK - Alaska - USA",
    value: "AK",
    country: "USA",
    stateCode: "AK",
    sectionName: "Alaska",
  },
  {
    name: "NV - Nevada - USA",
    value: "NV",
    country: "USA",
    stateCode: "NV",
    sectionName: "Nevada",
  },
  {
    name: "AZ - Arizona - USA",
    value: "AZ",
    country: "USA",
    stateCode: "AZ",
    sectionName: "Arizona",
  },
  {
    name: "OR - Oregon - USA",
    value: "OR",
    country: "USA",
    stateCode: "OR",
    sectionName: "Oregon",
  },
  {
    name: "EWA - Eastern Washington - USA",
    value: "EWA",
    country: "USA",
    stateCode: "WA",
    sectionName: "Eastern Washington",
  },
  {
    name: "UT - Utah - USA",
    value: "UT",
    country: "USA",
    stateCode: "UT",
    sectionName: "Utah",
  },
  {
    name: "ID - Idaho - USA",
    value: "ID",
    country: "USA",
    stateCode: "ID",
    sectionName: "Idaho",
  },
  {
    name: "WWA - Western Washington - USA",
    value: "WWA",
    country: "USA",
    stateCode: "WA",
    sectionName: "Western Washington",
  },
  {
    name: "MT - Montana - USA",
    value: "MT",
    country: "USA",
    stateCode: "MT",
    sectionName: "Montana",
  },
  {
    name: "WY - Wyoming - USA",
    value: "WY",
    country: "USA",
    stateCode: "WY",
    sectionName: "Wyoming",
  },
  {
    name: "MI - Michigan - USA",
    value: "MI",
    country: "USA",
    stateCode: "MI",
    sectionName: "Michigan",
  },
  {
    name: "WV - West Virginia - USA",
    value: "WV",
    country: "USA",
    stateCode: "WV",
    sectionName: "West Virginia",
  },
  {
    name: "OH - Ohio - USA",
    value: "OH",
    country: "USA",
    stateCode: "OH",
    sectionName: "Ohio",
  },
  {
    name: "IL - Illinois - USA",
    value: "IL",
    country: "USA",
    stateCode: "IL",
    sectionName: "Illinois",
  },
  {
    name: "WI - Wisconsin - USA",
    value: "WI",
    country: "USA",
    stateCode: "WI",
    sectionName: "Wisconsin",
  },
  {
    name: "IN - Indiana - USA",
    value: "IN",
    country: "USA",
    stateCode: "IN",
    sectionName: "Indiana",
  },
  {
    name: "CO - Colorado - USA",
    value: "CO",
    country: "USA",
    stateCode: "CO",
    sectionName: "Colorado",
  },
  {
    name: "MO - Missouri - USA",
    value: "MO",
    country: "USA",
    stateCode: "MO",
    sectionName: "Missouri",
  },
  {
    name: "IA - Iowa - USA",
    value: "IA",
    country: "USA",
    stateCode: "IA",
    sectionName: "Iowa",
  },
  {
    name: "NE - Nebraska - USA",
    value: "NE",
    country: "USA",
    stateCode: "NE",
    sectionName: "Nebraska",
  },
  {
    name: "KS - Kansas - USA",
    value: "KS",
    country: "USA",
    stateCode: "KS",
    sectionName: "Kansas",
  },
  {
    name: "ND - North Dakota - USA",
    value: "ND",
    country: "USA",
    stateCode: "ND",
    sectionName: "North Dakota",
  },
  {
    name: "MN - Minnesota - USA",
    value: "MN",
    country: "USA",
    stateCode: "MN",
    sectionName: "Minnesota",
  },
  {
    name: "SD - South Dakota - USA",
    value: "SD",
    country: "USA",
    stateCode: "SD",
    sectionName: "South Dakota",
  },
  {
    name: "MAR - Maritime - CANADA",
    value: "MAR",
    country: "CANADA",
    stateCode: "NS",
    sectionName: "Maritime",
  },
  {
    name: "SK - Saskatchewan - CANADA",
    value: "SK",
    country: "CANADA",
    stateCode: "SK",
    sectionName: "Saskatchewan",
  },
  {
    name: "NL - Newfoundland / Labrador - CANADA",
    value: "NL",
    country: "CANADA",
    stateCode: "NL",
    sectionName: "Newfoundland / Labrador",
  },
  {
    name: "AB - Alberta - CANADA",
    value: "AB",
    country: "CANADA",
    stateCode: "AB",
    sectionName: "Alberta",
  },
  {
    name: "QC - Quebec - CANADA",
    value: "QC",
    country: "CANADA",
    stateCode: "QC",
    sectionName: "Quebec",
  },
  {
    name: "BC - British Columbia - CANADA",
    value: "BC",
    country: "CANADA",
    stateCode: "BC",
    sectionName: "British Columbia",
  },
  {
    name: "ONE - Ontario East - CANADA",
    value: "ONE",
    country: "CANADA",
    stateCode: "ON",
    sectionName: "Ontario East",
  },
  {
    name: "MB - Manitoba - CANADA",
    value: "MB",
    country: "CANADA",
    stateCode: "MB",
    sectionName: "Manitoba",
  },
  {
    name: "ONN - Ontario North - CANADA",
    value: "ONN",
    country: "CANADA",
    stateCode: "ON",
    sectionName: "Ontario North",
  },
  {
    name: "NT - Northern Territories - CANADA",
    value: "NT",
    country: "CANADA",
    stateCode: "NT",
    sectionName: "Northern Territories",
  },
  {
    name: "ONS - Ontario South - CANADA",
    value: "ONS",
    country: "CANADA",
    stateCode: "ON",
    sectionName: "Ontario South",
  },
  {
    name: "GTA - Greater Toronto Area - CANADA",
    value: "GTA",
    country: "CANADA",
    stateCode: "ON",
    sectionName: "Greater Toronto Area",
  },
  {
    name: "PE - Prince Edward Island - CANADA",
    value: "PE",
    country: "CANADA",
    stateCode: "PE",
    sectionName: "Prince Edward Island",
  },
];

// Sort alphabetically by sectionName, but keep DX at top
export const arrlSections = [
  ...rawArrlSections.filter((item) => item.value === "DX"),
  ...rawArrlSections
    .filter((item) => item.value !== "DX")
    .sort((a, b) =>
      a.sectionName.localeCompare(b.sectionName, undefined, {
        sensitivity: "base",
      })
    ),
];

export const CABRILLO_FIELD_TYPES = {
  DROPDOWN: "DROPDOWN",
  TEXT: "TEXT",
  NUMBER: "NUMBER",
};

export const CABRILLO_OPERATOR_TYPES = [
  { name: "SINGLE-OP", value: "SINGLE-OP" },
  { name: "MULTI-OP", value: "MULTI-OP" },
  { name: "CHECKLOG", value: "CHECKLOG" },
];

export const CABRILLO_CATEGORY_ASSISTED_TYPES = [
  { name: "NON-ASSISTED", value: "NON-ASSISTED" },
  { name: "ASSISTED", value: "ASSISTED" },
];

export const CabrilloAssistedMapping: any = {
  "Non-Assisted": "NON-ASSISTED",
  Assisted: "ASSISTED",
};

export const CABRILLO_POWER_TYPES = [
  { name: "--", value: "" },
  { name: "QRP", value: "QRP" },
  { name: "HIGH", value: "HIGH" },
  { name: "LOW", value: "LOW" },
];

export const CABRILLO_TRANSMITTER_TYPE = [
  { name: "ONE", value: "ONE" },
  { name: "TWO", value: "TWO" },
  { name: "UNLIMITED", value: "UNLIMITED" },
];

export const CABRILLO_CATEGORY_OVERLAY = [
  { name: "ROOKIE", value: "ROOKIE" },
  { name: "TB-WIRES", value: "TB-WIRES" },
  { name: "CLASSIC", value: "CLASSIC" },
  { name: "YOUTH", value: "YOUTH" },
];
export const CABRILLO_CERTIFICATE = [
  { name: "YES", value: "YES" },
  { name: "NO", value: "NO" },
];

export const CABRILLO_CATEGORY_STATION = [
  { name: "DISTRIBUTED", value: "DISTRIBUTED" },
  { name: "FIXED", value: "FIXED" },
  { name: "MOBILE", value: "MOBILE" },
  { name: "PORTABLE", value: "PORTABLE" },
  { name: "ROVER", value: "ROVER" },
  { name: "ROVER-LIMITED", value: "ROVER-LIMITED" },
  { name: "ROVER-UNLIMITED", value: "ROVER-UNLIMITED" },
  { name: "EXPEDITION", value: "EXPEDITION" },
  { name: "HQ", value: "HQ" },
  { name: "SCHOOL", value: "SCHOOL" },
  { name: "EXPLORER", value: "EXPLORER" },
];

export const CABRILLO_CATEGORY_TIME = [
  { name: "6-HOURS", value: "6-HOURS" },
  { name: "8-HOURS", value: "8-HOURS" },
  { name: "12-HOURS", value: "12-HOURS" },
  { name: "24-HOURS", value: "24-HOURS" },
];
