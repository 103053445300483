import TableCol from "components/Table/TableMembershipEntries/TableCol";
import React from "react";
import { TableMembershipEntriesProps } from "types/Component";
import { IoCaretBack, IoCaretForward } from "react-icons/io5";
import useMember from "hooks/member.hook";
import { useDispatch } from "react-redux";
import useConfig from "hooks/config.hook";
import { auth, generateImpersonationToken } from "firebase-config";
import { setImpersonationData } from "store/Actions/profile.actions";
import { signInWithCustomToken } from "firebase/auth";
import { PERMISSIONS } from "constants/Permissions";
import AccessControl from "components/AccessControl";

function TableMembershipEntries({
  lists,
  count,
  filter,
  setFilters,
}: TableMembershipEntriesProps) {
  const { members, getMembersWithPagination } = useMember();

  const dispatch = useDispatch();
  const { handlePrimaryModal } = useConfig();

  const impersonateUser = async (item: any) => {
    try {
      handlePrimaryModal({
        title: `Are you sure you want to impersonate user: ${item.callSign} - ${item.firstName}? Please be aware any changes you make will affect the users account and data!`,
        type: "Impersonate",
        criticality: "danger",
        onCancelPressed: () => handlePrimaryModal(null),
        onSubmitPressed: async () => {
          if (item && item.uid) {
            const result: any = await generateImpersonationToken({
              userId: item.uid,
            });
            if (result.data && result.data.token) {
              dispatch(
                setImpersonationData({
                  adminId: auth.currentUser?.uid,
                  userImpersonated: item.callSign,
                })
              );
              try {
                await signInWithCustomToken(auth, result.data.token);
              } catch (e) {
                dispatch(setImpersonationData(null));
              }
            }
          }
          handlePrimaryModal(null);
        },
        submitText: "Yes I am Sure!",
        cancelText: "Cancel",
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  return (
    <>
      <div className="w-full overflow-y-hidden mt-6">
        <table className="w-[655px] md:w-full">
          <tbody>
            <tr className="bg-[#94a3b883] dark:bg-[#3C3C58] pb-2.5 border-0 border-b border-slate-300 border-solid">
              <th className="text-sm font-[Barlow] font-bold text-center pt-5 pb-2.5 text-white w-[60px]"></th>
              <th className="text-xs font-[Barlow] font-bold text-left px-4 pt-5 pb-2.5 dark:text-white text-[#2b2e48] w-[140px]">
                <div className="flex items-center justify-between">
                  Call sign
                </div>
              </th>
              <th className="text-xs font-[Barlow] font-bold text-left px-4 pt-5 pb-2.5 dark:text-white text-[#2b2e48] w-[210px]">
                <div className="flex items-center justify-between">Name</div>
              </th>
              <th className="text-xs font-[Barlow] font-bold text-left px-4 pt-5 pb-2.5 dark:text-white text-[#2b2e48] w-[100px]">
                <div className="flex items-center justify-center text-center">
                  Country
                </div>
              </th>
              <th className="text-xs font-[Barlow] font-bold pt-5 pb-2.5 dark:text-white text-[#2b2e48] w-[100px]">
                <div className="w-full flex pl-4">State</div>
              </th>
              <th className="text-xs font-[Barlow] font-bold text-left pl-4 pt-5 pb-2.5 dark:text-white text-[#2b2e48] w-[110px]">
                <div className="flex items-center justify-center">
                  Membership Level
                </div>
              </th>
              <th className="text-xs font-[Barlow] font-bold text-left px-4 pt-5 pb-2.5 dark:text-white text-[#2b2e48] w-[90px]">
                <div className="flex items-center">Joined Date</div>
              </th>
              {/* <th className="text-xs font-[Barlow] font-bold text-left pl-4 pr-2 pt-5 pb-2.5 dark:text-white text-[#2b2e48] w-[70px]">
                <div className="flex items-center justify-center">
                  # of QSOs
                </div>
              </th> */}
              <AccessControl
                permission={PERMISSIONS.superAdmin}
                children={
                  <th className="text-xs font-[Barlow] font-bold text-left pl-4 pr-2 pt-5 pb-2.5 text-white w-[70px]">
                    <div className="flex items-center justify-center">
                      Action
                    </div>
                  </th>
                }
              />
            </tr>
            {lists.map((item: any, index: number) => {
              return (
                <TableCol
                  key={index}
                  item={item}
                  className={`${
                    index % 2
                      ? "dark:bg-[#151536] bg-white"
                      : "dark:bg-[#1F1F3F] bg-[#fafafa]"
                  } border-0 border-b border-slate-300 border-solid`}
                  onImpersonateClick={impersonateUser}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      {members && count ? (
        <div className="flex justify-between items-center w-full mt-8">
          {members?.page * members?.users?.length ? (
            <p className="font-[Barlow] text-xs dark:text-white text-[#2b2e48]">
              Showing{" "}
              {count && members && members?.page * 50 >= count
                ? count
                : count && members
                ? members?.page * members?.users?.length
                : null}{" "}
              - {count} of list
            </p>
          ) : null}
          <div className="flex justify-between items-center">
            {members?.page > 1 ? (
              <div
                className="bg-[#0243DD] h-7 w-7 flex justify-center items-center mr-3"
                onClick={() => {
                  getMembersWithPagination({
                    move: -1,
                    page: members?.page - 1,
                    conditions: filter,
                  });
                }}
              >
                <IoCaretBack color="#fff" />
              </div>
            ) : null}
            {/* {[1, 2, 3, 4, 5].map((item) => {
              return (
                <div className="text-[#010A41] bg-[#fff] mx-1 w-7 h-7 flex justify-center items-center font-[Play] text-xs">
                  {item}
                </div>
              );
            })} */}
            {count && members?.users && members?.page * 50 < count ? (
              <div
                className="bg-[#0243DD] h-7 w-7 flex justify-center items-center"
                onClick={() => {
                  getMembersWithPagination({
                    move: 1,
                    page: members?.page + 1,
                    conditions: filter,
                  });
                }}
              >
                <IoCaretForward color="#fff" />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default TableMembershipEntries;
