import { RootState } from "store/Reducers";
import {
  SET_PARK_BY_REFERENCE_LOADING,
  PotaActionPayload,
  SET_PARK_BY_REFERENCE_DATA,
  SET_PARK_REFERENCE_SUGGESTIONS,
  SET_NEARBY_PARK_REFERENCES,
  SET_AUTO_SPOT_DATA,
  SET_ACTIVITIES_MAP,
  SET_FIND_MY_PARK_LOADING,
  SET_ACTIVITIES_LIST_VIEW,
  SET_SINGLE_ACTIVITY_DETAIL,
} from "store/Actions/pota.actions";

import { PotaState } from "types/State";

const defaultState: PotaState = {
  parkReferenceData: null,
  isParkReferenceDataLoading: null,
  parkReferenceSuggestionsData: null,
  nearbyParkReferencesData: null,
  autoSpotData: null,
  activityMap: [],
  isFindMyParkLoading: false,
  activityListView: [],
  singleActivityDetail: null,
};

export default function potaReducer(
  state = defaultState,
  action: PotaActionPayload
) {
  switch (action.type) {
    case SET_PARK_BY_REFERENCE_DATA:
      return {
        ...state,
        parkReferenceData: action.payload
          ? {
              ...(state.parkReferenceData ? state.parkReferenceData : {}),
              [action.payload.key]: action.payload.value,
            }
          : null,
      };
    case SET_FIND_MY_PARK_LOADING:
      return {
        ...state,
        isFindMyParkLoading: action.payload,
      };
    case SET_FIND_MY_PARK_LOADING:
      return {
        ...state,
        isFindMyParkLoading: action.payload,
      };
    case SET_PARK_BY_REFERENCE_LOADING:
      return {
        ...state,
        isParkReferenceDataLoading: action.payload
          ? {
              ...(state.isParkReferenceDataLoading
                ? state.isParkReferenceDataLoading
                : {}),
              [action.payload.key]: action.payload.value,
            }
          : null,
      };
    case SET_PARK_REFERENCE_SUGGESTIONS:
      return {
        ...state,
        parkReferenceSuggestionsData: action.payload
          ? {
              ...(state.parkReferenceSuggestionsData
                ? state.parkReferenceSuggestionsData
                : {}),
              [action.payload.key]: action.payload.value,
            }
          : null,
      };
    case SET_NEARBY_PARK_REFERENCES:
      return {
        ...state,
        nearbyParkReferencesData: action.payload,
      };
    case SET_AUTO_SPOT_DATA:
      return {
        ...state,
        autoSpotData: action.payload,
      };
    case SET_ACTIVITIES_MAP:
      return {
        ...state,
        activityMap: action.payload,
      };
    case SET_ACTIVITIES_LIST_VIEW:
      return {
        ...state,
        activityListView: action.payload,
      };
    case SET_SINGLE_ACTIVITY_DETAIL:
      return {
        ...state,
        singleActivityDetail: action.payload,
      };

    default:
      return state;
  }
}

// GET STATE
export const getParkByReferenceDataSelector = (state: RootState) => {
  return state.pota.parkReferenceData;
};

export const getParkByReferenceLoadingSelector = (state: RootState) => {
  return state.pota.isParkReferenceDataLoading;
};

export const getParkReferenceSuggestionsDataSelector = (state: RootState) => {
  return state.pota.parkReferenceSuggestionsData;
};

export const getNearbyParkReferencesDataSelector = (state: RootState) => {
  return state.pota.nearbyParkReferencesData;
};

export const getAutoSpotDataSelector = (state: RootState) => {
  return state.pota.autoSpotData;
};

export const getActivitiesMapSelector = (state: RootState) => {
  return state.pota.activityMap || [];
};

export const getFindMyParkLoadingSelector = (state: RootState) => {
  return state.pota.isFindMyParkLoading;
};

export const getActivityListViewSelector = (state: RootState) => {
  return state.pota.activityListView;
};

export const getSingleActivityDetailSelector = (state: RootState) => {
  return state.pota.singleActivityDetail;
};
