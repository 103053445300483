import Icons from "assets/Icons";
import useNotification from "hooks/notification.hook";
import { useEffect, useRef, useState } from "react";
import { ShareMapModalProps, ToastTypes } from "types/Component";
import Images from "assets/Images";
import useUpload from "hooks/upload.hook";
import { QSOMapShare } from "types/Models";
import { showToast } from "helpers/Toast";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { serverTimestamp } from "firebase/firestore";
import {
  FaCopy,
  FaDownload,
  FaFacebook,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa6";

import LogBookLayout from "./LogBookLayout";

import { useLogBookStats } from "hooks/useLogBookStats.hook";
import { getAllPaginatedLogBookContactsDataSelector, getSelectedLogBookSelector } from "store/Reducers/logBook.reducer";
import useTheme from "hooks/useTheme.hook";
import { getContactMapModalStatus } from "store/Reducers/config.reducer";
import { setContactMapModalStatus } from "store/Actions/config.actions";

export interface LogBookData {
  userProfile: any;
  qsoStats: {
    total: number;
    duration: string;
    rate: number;
    timestamp: string;
    logBookName: string;
    defaultCallSign: string | undefined;
    activity: {
      name: string;
      reference: string;
      activityType: string;
    };
  };
  bandStats: {
    topBands: { [key: string]: number };
    topBand: string;
    count: number;
    bands: { [key: string]: number };
  };
  modeStats: {
    topModes: { [key: string]: number };
    topMode: string;
    count: number;
    modes: { [key: string]: number };
  };
  dxccEntities: Array<{
    country: string;
    flagCode: string;
  }>;
  equipment: any;
}

interface ActivityStats {
  activityStats: {
    totalQsos: number;
    timespan: number;
    qsoRate: number;
    timestamp: string;
    bandStats: any;
    modeStats: any;
    dxccEntities: any[];
  };
}

export const ShareMapModal = ({ className, shareMapModalType }: ShareMapModalProps) => {
  const userProfile = useSelector(getUserProfileSelector);
  const divRef = useRef(null);
  const [imageUrl, setImageUrl] = useState("");
  const [shareId, setShareId] = useState("");
  const [isCapturing, setIsCapturing] = useState(false);
  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const layoutRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(true);

  const {
    captureSnapshot,
    setScreenshotImage,
    saveQsoMapShareData,
    shareToSocials,
    onDownloadClick,
  } = useUpload();

  const { getLogBookAnalytics, logBookAnalytics, loading } = useLogBookStats();
  let selectedLogbook = useSelector(getSelectedLogBookSelector);
  const getAllPaginatedLogBookContactsData = useSelector(getAllPaginatedLogBookContactsDataSelector);
  const { theme } = useTheme();

  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const dispatch = useDispatch();
  const contactMapModalStatus = useSelector(getContactMapModalStatus);
  const isAllLogbooksContacts = shareMapModalType === 'ALL_LOGBOOKS_CONTACTS';

  const handleMapLoaded = () => {
    setIsMapLoaded(true);
  };

  useEffect(() => {
    if(isAllLogbooksContacts){
      selectedLogbook = null;
    }
    if (userProfile?.uid && (selectedLogbook?.id || true)) {
      getLogBookAnalytics(selectedLogbook?.id || '', userProfile.uid);
    }
  }, [selectedLogbook?.id, userProfile?.uid, selectedLogbook?.contactCount]);

  const logBookInfo = !isAllLogbooksContacts ? selectedLogbook : {};


  const prepareLogBookData = () => {
    if (!logBookAnalytics || !userProfile) return null;

    return {
      userProfile: {
        callSign: userProfile?.callSign,
        name: userProfile?.firstName,
        country: userProfile?.country,
        avatar: userProfile?.profilePic,
        flagCode: userProfile?.flagCode,
        countryCode: userProfile?.countryCode
      },
      qsoStats: {
        total: logBookAnalytics?.summary?.totalQSOs || 0,
        duration: logBookAnalytics?.summary?.duration || 0,
        rate: logBookAnalytics?.summary?.averageQSOsPerHour || 0,
        timestamp: logBookAnalytics?.summary?.endTime || '',
        logBookName: logBookInfo?.name || '',
        defaultCallSign: logBookInfo?.defaultCallSign,
        activity: {
          name: logBookInfo?.activitiesData?.[0]?.name || '',
          reference: logBookInfo?.activitiesData?.[0]?.reference || '',
          activityType: logBookInfo?.primaryActivity || logBookInfo?.activities?.[0] || ''
        }
      },
      bandStats: {
        topBands: logBookAnalytics?.bands?.topBands || [],
        topBand: logBookAnalytics?.bands?.topBand || '',
        count: logBookAnalytics?.bands?.total || 0,
        bands: logBookAnalytics?.bands?.stats || []
      },
      modeStats: {
        topModes: logBookAnalytics?.modes?.topModes || [],
        topMode: logBookAnalytics?.modes?.topMode || '',
        count: logBookAnalytics?.modes?.total || 0,
        modes: logBookAnalytics?.modes?.stats || []
      },
      dxccEntities: logBookAnalytics?.dx?.countries?.map(country => ({
        country: country.name,
        flagCode: country.flagCode
      })),
      equipment: {
        radio: logBookInfo?.radio || logBookInfo?.myRadio || '',
        antenna: logBookInfo?.antenna || logBookInfo?.myAntenna || '',
        power: logBookInfo?.defaultPower || ''
      }
    };
  };

  useEffect(() => {
    if (logBookAnalytics && !loading) {
      console.log('Map loaded', isMapLoaded)
      // Wait for both layout and map to be ready
      const timer = setTimeout(() => {
        if (isMapLoaded || (logBookAnalytics?.summary?.totalQSOs === 0)) {
          setIsLayoutReady(true);
        }
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [logBookAnalytics, loading, isMapLoaded]);

  // Trigger capture when layout is ready
  useEffect(() => {
    if (isLayoutReady && !shareId && !isCapturing) {
      setIsCapturing(true);
      captureLayout();
    }
  }, [isLayoutReady]);

  const captureLayout = async () => {
    if (!isLayoutReady || !layoutRef.current) return;
    
    try {
      const firebaseURl = await captureSnapshot(layoutRef, false);
      if (firebaseURl) {
        setImageUrl(firebaseURl);
        const data: QSOMapShare = {
          imageUrl: firebaseURl,
          uid: userProfile?.uid || "",
          callSign: userProfile?.callSign || "",
          timestamp: serverTimestamp(),
        };
        const docId = await saveQsoMapShareData(data);
        if (docId) {
          setShareId(docId);
        }
      }
    } catch (e) {
      showToast({
        message: "Sorry, something went wrong. Please try again.",
        type: ToastTypes.WARN,
      });
    } finally {
      setIsCapturing(false);
      setIsProcessing(false);
    }
  };

  const handleClose = () => {
    setScreenshotImage("");
    setIsProcessing(false);
    setIsCapturing(false);
    setIsLayoutReady(false);
    setImageUrl("");
    setShareId("");
    dispatch(setContactMapModalStatus(false));
  };

  if (!contactMapModalStatus) return null;

  return (
    <section
      className="absolute w-screen h-screen top-0 left-0 z-[1000]"
      ref={divRef}
    >
      <img
        src={Images.SpaceBg}
        className="absolute top-0 left-0 z-0 h-full w-full object-cover"
        alt="background"
      />
      <div className="h-full w-full flex flex-col items-center glassmorphism py-2 px-[5%]">
        {/* Processing indicator */}
        <div
          className="absolute top-4 right-5 cursor-pointer z-[1001]"
          onClick={handleClose}
        >
          <img
            src={Icons.GlowingClose}
            className={`h-8 w-8 ${theme !== 'dark' ? 'filter invert' : ''}`}
            alt="close"
          />
        </div>

        {isProcessing && (
          <div className="absolute top-4 right-20 flex items-center gap-2 bg-[#1A1C5F]/80 px-3 py-2 rounded-md z-50">
            <div className="w-4 h-4 border-2 border-[#17f9da] border-t-transparent rounded-full animate-spin" />
            <span className="text-[#17f9da] text-sm font-medium">
              Preparing share options...
            </span>
          </div>
        )}
        {/* Share options - shown when processing complete */}
        <div className="mb-2 flex justify-center h-[30px]">
          {!isProcessing && imageUrl && (
            <>
              <div className="flex items-center">
                <p className="font-[Play] dark:text-white text-[#010A41] text-lg font-bold">
                  Share Link Ready:{" "}
                </p>
                <input
                  disabled
                  value={`${process.env.REACT_APP_SHARE_URL}qsomap/${shareId}`}
                  className="ml-2 bg-[#151540] text-white font-[Play] text-base p-1 rounded-md  border border-[#16F8DA]"
                />
              </div>
              <div className="flex items-center px-4 rounded-[10px] w-full md:w-[440px] z-10 mt-3 mb-3 justify-center">
                <div
                  className="w-[25px] h-[25px] rounded-[4px] bg-[#1877F2] flex items-center justify-center mr-2 cursor-pointer"
                  onClick={() => {
                    shareToSocials(
                      "facebook",
                      `${process.env.REACT_APP_SHARE_URL}qsomap/${shareId}`,
                      "Check out this QSO map I made on World Radio League",
                      "#WorldRadioLeague"
                    );
                  }}
                >
                  <FaFacebook size={16} color="#fff" />
                </div>
                <div
                  className="w-[25px] h-[25px] rounded-[4px] bg-[#1DA1F2] flex items-center justify-center mr-2 cursor-pointer"
                  onClick={() => {
                    shareToSocials(
                      "twitter",
                      `${process.env.REACT_APP_SHARE_URL}qsomap/${shareId}`,
                      "Check out this QSO map I made on World Radio League",
                      "#WorldRadioLeague"
                    );
                  }}
                >
                  <FaTwitter size={16} color="#fff" />
                </div>
                <div
                  className="w-[25px] h-[25px] rounded-[4px] bg-[#2867B2] flex items-center justify-center mr-2 cursor-pointer"
                  onClick={() => {
                    shareToSocials(
                      "linkedin",
                      `${process.env.REACT_APP_SHARE_URL}qsomap/${shareId}`,
                      "Check out this QSO map I made on World Radio League",
                      "#WorldRadioLeague"
                    );
                  }}
                >
                  <FaLinkedin size={16} color="#fff" />
                </div>
                <div
                  className="w-[90px] h-[25px] rounded-[4px] bg-[#1A1C5F] flex items-center justify-center mr-2 cursor-pointer border-white border-[0.5px]"
                  onClick={() => {
                    shareToSocials(
                      "linkcopy",
                      `${process.env.REACT_APP_SHARE_URL}qsomap/${shareId}`,
                      "Check out this QSO map I made on World Radio League",
                      "#WorldRadioLeague"
                    );
                  }}
                >
                  <span className="text-[10px] mr-1 text-white">Copy Link</span>
                  <FaCopy size={16} color="#fff" />
                </div>
                <div
                  className="w-[120px] h-[25px] rounded-[4px] bg-[#1A1C5F] flex items-center justify-center mr-2 cursor-pointer border-white border-[0.5px]"
                  onClick={() => {
                    onDownloadClick(
                      `WorldRadioLeague_QSOMap_${userProfile?.callSign || ""}`
                    );
                  }}
                >
                  <span className="text-[10px] mr-1 text-white">
                    Download Image
                  </span>
                  <FaDownload size={16} color="#fff" />
                </div>
              </div>
            </>
          )}
        </div>

        <div className="relative w-full h-full flex justify-center items-center">
          {/* LogBookLayout with its own ref */}
          <div ref={layoutRef} className="block w-full h-full">
            <LogBookLayout 
              logBookData={prepareLogBookData() as LogBookData} 
              theme={theme} 
              onMapLoaded={handleMapLoaded}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShareMapModal;
