import { ChangeEvent, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setScreenImage } from "store/Actions/config.actions";
import { setLoadingImageUploadStatus } from "store/Actions/loading.action";
import { uploadImg } from "store/Actions/uploadFiles.actions";
import { getIsUploadImageLoadingSelector } from "store/Reducers/loading.reducer";

import UploadService from "services/upload.service";
import LogBookService from "services/logBook.service";
import { fetchContactDetail } from "store/Actions/logBook.actions";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import { QSOMapShare } from "types/Models";
const htmlToImage = require("html-to-image");

// Maximum file size for profile images - 5MB
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
// Allowed file types for profile images
const ALLOWED_FILE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/svg+xml',
];

const useUpload = () => {
  const dispatch = useDispatch();
  const inputRef = useRef<any>(null);
  const isLoadingImg = useSelector(getIsUploadImageLoadingSelector);
  const [percent, setPercent] = useState(0);
  const [captureInProgress, setCaptureInProgress] = useState(false);
  const [imageString, setImageString] = useState<string | null>(null);

  const [imgFile, setImgFile] = useState<File | null | undefined>(null);
  const [downloadUrl, setDownloadUrl] = useState<string>();
  const handleImageFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target?.files;
    if (files && files.length > 0) {
      const file = files[0];
      
      // Validate file type
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        showToast({
          message: "Invalid file type. Only image files (JPEG, PNG, GIF, WebP, SVG) are allowed.",
          type: ToastTypes.ERROR,
        });
        // Reset the input to allow selecting the same file again
        if (inputRef.current) {
          inputRef.current.value = '';
        }
        return;
      }
      
      // Validate file size
      if (file.size > MAX_FILE_SIZE) {
        showToast({
          message: `File is too large. Maximum size allowed is ${MAX_FILE_SIZE / (1024 * 1024)}MB.`,
          type: ToastTypes.ERROR,
        });
        // Reset the input to allow selecting the same file again
        if (inputRef.current) {
          inputRef.current.value = '';
        }
        return;
      }
      
      setImgFile(file);
      // handleUpload(file);
    } else {
      setImgFile(null);
    }
  };

  const setScreenshotImage = (data: string) => {
    dispatch(setScreenImage(data));
  };

  const captureSnapshot = async (
    divRef: any,
    isDirect: boolean = false,
    setLoading?: any
  ) => {
    if (divRef.current) {
      try {
        setCaptureInProgress(true);
        await new Promise(resolve => setTimeout(resolve, 500)); // Force browser to properly render everything
        const BROKEN_IMAGE_PLACEHOLDER =
          "data:image/gif;base64,R0lGODlhGQAZAPQAAISChKWipcbT7+fr/8bf51KuOdbf93vDc87b9/f3997n/2u2Y9bf/5SSlJzHtcbT9////+/z/1KyOdbj94zHjNbb9/f3/2u6Y9bj/5yenLXP1sbX9wAAAAAAAAAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS41LjAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+ICAgPD94cGFja2V0IGVuZD0idyI/PgH//v38+/r5+Pf29fTz8vHw7+7t7Ovq6ejn5uXk4+Lh4N/e3dzb2tnY19bV1NPS0dDPzs3My8rJyMfGxcTDwsHAv769vLu6ubi3trW0s7KxsK+urayrqqmop6alpKOioaCfnp2cm5qZmJeWlZSTkpGQj46NjIuKiYiHhoWEg4KBgH9+fXx7enl4d3Z1dHNycXBvbm1sa2ppaGdmZWRjYmFgX15dXFtaWVhXVlVUU1JRUE9OTUxLSklIR0ZFRENCQUA/Pj08Ozo5ODc2NTQzMjEwLy4tLCsqKSgnJiUkIyIhIB8eHRwbGhkYFxYVFBMSERAPDg0MCwoJCAcGBQQDAgEAACwAAAAAGQAZAAAF/2DgWGRpmlkTaIkFvWIkzzSdZY42JS8UBIqJcEicKBSZAGSSIfB8AaHBMBlYB5OpMAlJaAhOyy+LKLsgljKCyn1pMruxATGI9CARxZqZ0fgDKg5yCHZ3EAN7KQ2LjA1JVWeGiGVCV1aAjwOGPZN0dTNijlFzaqWlWlQKA5hRCBuvsLGnWgqsEwgCsLmxurAIE7a4rwLEucW7u78EGmO4x8/QAr8uj87PfhrRCmfV0djRmi/dxA4OC+foF34CE4bjAuUFBRL05zkICj0JCd0a5vQAAx4wZEuAPwoLAgYs18NCBFsEHBxQqPDJCyyAfiw7kJCiBAcE9H0BpiJARI8AQyL2IKCOZIAMFCZ6dLDvRYKYEig4SPHywAWUGu4kAHiAwqIQADs=";
        // sleep for 1 second to allow the image to render
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const dataUrl = await htmlToImage.toJpeg(divRef.current, {
          quality: 0.95,
          imagePlaceholder: BROKEN_IMAGE_PLACEHOLDER,
          includeQueryParams: true,
        });
        setImageString(dataUrl);
        setCaptureInProgress(false);

        if (!isDirect) {
          dispatch(setLoadingImageUploadStatus(true));
          if (setLoading) {
            setLoading(true);
          }
          const res = await UploadService.handleUploadImageString(dataUrl);
          return res;
        } else {
          return dataUrl;
        }
      } catch (e) {}
      setCaptureInProgress(false);

      dispatch(setLoadingImageUploadStatus(false));
      return null;
    }
  };

  const handleUpload = (file: any) => {
    // Validate file type
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      showToast({
        message: "Invalid file type. Only image files (JPEG, PNG, GIF, WebP, SVG) are allowed.",
        type: ToastTypes.ERROR,
      });
      return;
    }
    
    // Validate file size
    if (file.size > MAX_FILE_SIZE) {
      showToast({
        message: `File is too large. Maximum size allowed is ${MAX_FILE_SIZE / (1024 * 1024)}MB.`,
        type: ToastTypes.ERROR,
      });
      return;
    }

    let data = {
      file,
      setPercent,
      setDownloadUrl,
      setImgFile,
    };
    dispatch(uploadImg(data));
  };

  const shareOnSocialMedia = async (
    ref: any,
    social: string,
    contactId?: string,
    shareImage?: string
  ) => {
    let imageUrl;
    if (shareImage) {
      imageUrl = shareImage;
    } else {
      imageUrl = await captureSnapshot(ref);
    }
    if (imageUrl && contactId) {
      try {
        if (!shareImage) {
          await LogBookService.editContact(contactId, { shareImage: imageUrl });
        }
        dispatch(setLoadingImageUploadStatus(false));
        dispatch(fetchContactDetail(contactId));
        const shareLink = `${process.env.REACT_APP_SHARE_URL}contact/${contactId}`;
        const shareQuote =
          "Check out this contact I made on World Radio League";
        const shareHashtag = "#WorldRadioLeague";

        shareToSocials(social, shareLink, shareQuote, shareHashtag);
      } catch (e) {
        console.error("Error during social media sharing:", e);
      }
    }
  };

  const shareToSocials = (
    social: string,
    shareLink: string,
    shareQuote: string,
    shareHashtag: string
  ) => {
    let shareUrl = "";

    switch (social.toLowerCase()) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareLink
        )}&quote=${encodeURIComponent(shareQuote)}&hashtag=${encodeURIComponent(
          shareHashtag
        )}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          shareLink
        )}&text=${encodeURIComponent(shareQuote + " " + shareHashtag)}`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          shareLink
        )}`;
        break;
      case "linkcopy":
        navigator.clipboard.writeText(shareLink);
        showToast({
          message: "Share link copied to clipboard!",
          type: ToastTypes.SUCCESS,
        });
        break;
      default:
        console.error("Unsupported social media platform");
        return;
    }

    if (social !== "linkcopy") {
      window.open(shareUrl, "_blank");
    }
  };

  const handleButtonClick = () => {
    inputRef.current?.click();
  };

  const saveQsoMapShareData = async (data: QSOMapShare) => {
    const docId = await LogBookService.shareQsoMap(data);
    return docId;
  };

  const onDownloadClick = (filename: string, url?: string) => {
    const source = url || imageString;
    if (source) {
      downloadImage(source, filename);
    }
  };

  const downloadImage = (base64Data: string, filename = "image.jpeg") => {
    // Assuming base64Data is the complete base64 string including the data URL scheme
    const blob = b64toBlob(base64Data.split(",")[1], "image/jpeg"); // Splitting to get the base64 content
    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  };

  const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  return {
    percent,
    inputRef,
    handleButtonClick,
    imgFile,
    handleImageFileUpload,
    downloadUrl,
    handleUpload,
    captureSnapshot,
    isLoadingImg,
    setScreenshotImage,
    shareOnSocialMedia,
    captureInProgress,
    setCaptureInProgress,
    saveQsoMapShareData,
    shareToSocials,
    onDownloadClick,
  };
};

export default useUpload;
