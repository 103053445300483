import Icons from "assets/Icons";
import TitledInput from "components/Inputs/TitledInput";
import { Tooltip } from "components/Tooltip";
import { showToast } from "helpers/Toast";
import {
  getParkTypeName,
  suggestionsFormatter,
  validateNumberOfSelectedActivities,
} from "helpers/Utils";
import usePota from "hooks/pota.hook";
import { FC, useEffect, useState } from "react";
import { ToastTypes } from "types/Component";
import { Activities, ParkReferenceModel } from "types/Models";
import { useDebouncedCallback } from "use-debounce";

export const TheirParkField: FC<any> = ({
  setValues,
  values,
  errors,
  touched,
  primaryActivity,
}) => {
  const [formData, setFormData] = useState<any>({});
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const {
    isParkReferenceDataLoading,
    parkReferenceSuggestionsData,
    getParkByReference,
    getParReferenceSuggestions,
    setParReferenceSuggestions,
    setParkByReference,
    nearbyParkReferencesData,
  } = usePota();
  useEffect(() => {
    if (Object.keys(values?.theirParks || {}).length) {
      setFormData((prev: any) => ({ ...prev, theirParks: values?.theirParks }));
    } else if (
      !values?.theirParks ||
      !Object.keys(values?.theirParks || {}).length
    ) {
      // clear their parks data if not present/when contact is logged
      setFormData({});
    }
  }, [values?.theirParks]);

  const debouncedParkReferenceChangeHandler = useDebouncedCallback(
    (searchString) => getParReferenceSuggestions(searchString, primaryActivity),
    1000
  );

  const debouncedGetParkByReference = useDebouncedCallback(
    (searchString) => getParkByReference(searchString, primaryActivity),
    200
  );

  const resetParkReferenceSuggestions = (key: string) => {
    setTimeout(() => setParReferenceSuggestions({ key, value: null }), 200);
  };

  const hideParkSuggestions = () => {
    setTimeout(() => setShowSuggestions(false), 200);
  };

  const showParkSuggestions = (key: string, setValues: any) => {
    if (!showSuggestions) return {};

    return {
      suggestions: formData?.[key]
        ? parkReferenceSuggestionsData?.[key]
        : nearbyParkReferencesData,
      suggestionsFormatter,
      onSuggestionClick: (suggestion: ParkReferenceModel) => {
        // Check if theirParks has more than 5 keys);
        if (
          !validateNumberOfSelectedActivities(primaryActivity || "", {
            activitiesData: Object.values(formData?.theirParks || {}),
          })
        ) {
          return; // Stop further execution
        }

        // after setting value remove suggestions
        resetParkReferenceSuggestions(key);

        setValues((prev: any) => {
          return {
            ...prev,
            theirParks: {
              ...prev.theirParks,
              [suggestion?.reference]: { ...suggestion, type: primaryActivity },
            },
            theirParkKey: "",
          };
        });

        // set formdata
        setFormData((prev: any) => {
          return {
            ...prev,
            theirParks: {
              ...prev.theirParks,
              [suggestion?.reference]: { ...suggestion, type: primaryActivity },
            },
            theirParkKey: "",
          };
        });

        // hide suggestions
        hideParkSuggestions();
      },
    };
  };

  const renderSelectedParks = (setValues: any) => {
    const filteredActivity = Object.values(formData?.theirParks || {}).filter(
      (activity: any) => {
        const activityType = activity?.type || Activities.POTA;
        return activityType === primaryActivity;
      }
    );
    if (!filteredActivity.length) return null;

    const removePark = (key: string) => {
      //remove park from form data as well as key
      setFormData((prev: any) => {
        const { [key]: omit, ...rest } = prev.theirParks;
        return { ...prev, theirParks: rest };
      });

      // also update formit
      setValues((prev: any) => {
        const { [key]: omit, ...rest } = prev.theirParks;
        return { ...prev, theirParks: rest };
      });
    };

    return (
      <div className="flex flex-row dark:text-white text-[#2b2e48] text-[11px] gap-2 -mt-[10px] flex-wrap">
        {filteredActivity.map((activity: any, index) => (
          <div
            key={index}
            className="flex flex-row rounded-[10px] border border-neutral-400 gap-2 px-2 py-1 items-center bg-neutral-400 bg-opacity-25 "
          >
            <Tooltip
              message={`${activity.reference} - ${
                activity.parktypeDesc || activity.name
              }`}
              className="w-[180px]"
            >
              <div>{`${activity.reference}`}</div>
            </Tooltip>

            <img
              src={Icons.CrossIcon}
              className="h-3 w-3 invert dark:filter-none dark:invert-0"
              alt="close"
              onClick={() => removePark(activity.reference)}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className=" min-w-[40%]">
      <TitledInput
        setText={(e) => {
          setValues((prev: any) => {
            return {
              ...prev,
              theirParkKey: e.target.value.toUpperCase(),
            };
          });

          setFormData((prev: any) => ({
            ...prev,
            theirParkKey: e.target.value.toUpperCase(),
          }));

          if (e.target.value.length > 1) {
            debouncedParkReferenceChangeHandler({
              key: "theirParkKey",
              value: e.target.value.toUpperCase().trim(),
            });
          }

          // reset park data on change
          setParkByReference({ key: "theirParkKey", value: null });
        }}
        onBlur={() => {
          if (values.theirParkKey) {
            debouncedGetParkByReference({
              value: values.theirParkKey,
              key: "theirParkKey",
            });
          }

          // remove suggestions on blur
          resetParkReferenceSuggestions("theirParkKey");

          // hide suggestions
          hideParkSuggestions();
        }}
        onFocus={() => setShowSuggestions(true)}
        isLoading={!!isParkReferenceDataLoading?.theirParkKey}
        name="theirParkKey"
        placeHolder={`Type the name of the ${getParkTypeName(
          primaryActivity
        )} here`}
        //   width="w-[68.5%] sm:w-[30.5%] md:w-[20%] xl:w-[20%]"
        tabindex={15}
        value={values.theirParkKey}
        error={
          errors?.theirParks && touched?.theirParks
            ? errors?.theirParks
            : undefined
        }
        containerClass={"m-0"}
        {...showParkSuggestions("theirParkKey", setValues)}
        className="!bg-white dark:!bg-opacity-10 h-[40px] mt-[0px]"
      />

      {renderSelectedParks(setValues)}
    </div>
  );
};
