/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { UserAvatar } from "components/UserAvatar";
import { CABRILLO_FIELD_TYPES } from "../../../constants";

import { globalNavigate } from "routes/GlobalRoutes";
import { CabrilloHeader } from "types/Models";
import { useState } from "react";
import { FaCaretDown } from "react-icons/fa6";
import { RiArrowDownSLine } from "react-icons/ri";

interface EasyEditorProps {
  headers: CabrilloHeader[];
  setHeaders: (headers: CabrilloHeader[]) => void;
}

function EasyEditor({ headers, setHeaders }: EasyEditorProps) {
  const [dropdownVisible, setDropdownVisible] = useState<string | null>(null);

  const getFieldByType = (header: CabrilloHeader) => {
    const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
      const newHeaders = headers.map((h) => {
        if (h.name === header.name) {
          if (header.type === CABRILLO_FIELD_TYPES.NUMBER) {
            return {
              ...h,
              value: (e.target as HTMLInputElement).value.replace(/\D/g, ""),
            };
          } else {
            return { ...h, value: e.target.value };
          }
        }
        return h;
      });
      setHeaders(newHeaders);
    };

    if (
      header.type === CABRILLO_FIELD_TYPES.TEXT ||
      header.type === CABRILLO_FIELD_TYPES.NUMBER
    ) {
      return (
        <td className="text-sm font-[Barlow] font-normal text-left py-2">
          <input
            type="text"
            className="text-sm font-[Barlow] text-left bg-[#fff] dark:bg-[#1F1F48] py-2 pl-4 m-1 w-full dark:text-[#17F9DA] text-blue-900"
            value={header.value}
            disabled={header.disabled}
            placeholder="Click to enter..."
            pattern={
              header.type === CABRILLO_FIELD_TYPES.NUMBER ? "[0-9]*" : undefined
            }
            onChange={handleChange}
          />
        </td>
      );
    }

    if (header.type === CABRILLO_FIELD_TYPES.DROPDOWN && !header.multiSelect) {
      return (
        <td className="text-sm font-[Barlow] font-normal text-left py-2">
          <select
            value={header.value}
            disabled={header.disabled}
            onChange={handleChange}
            className="bg-[#fff] dark:bg-[#1F1F48] text-sm py-2 px-3 w-full dark:text-[#17F9DA] text-blue-900"
          >
            {header.dropdownList?.map((option) => (
              <option key={option.name} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>
        </td>
      );
    }

    // if (header.type === CABRILLO_FIELD_TYPES.DROPDOWN && header.multiSelect) {
    //   return (
    //     <div
    //       className="relative w-full  h-[30px] flex  justify-items-start items-center rounded-md my-3 ml-2 cursor-pointer"
    //       onClick={(e) => {
    //         setDropdownVisible(
    //           dropdownVisible === header.name ? null : header.name
    //         );
    //         e.stopPropagation();
    //       }}
    //     >
    //       <div className="flex justify-between w-full">
    //         {Array.isArray(header.value) && header.value.length > 0 ? (
    //           <span className="font-[Play] dark:text-[#17F9DA] text-blue-900 text-center ml-2 text-xs ">
    //             {header.value?.join(", ")}
    //           </span>
    //         ) : null}
    //         <RiArrowDownSLine className="dark:text-[#17F9DA] text-blue-900 mr-2" />
    //       </div>

    //       {/* Dropdown */}
    //       {dropdownVisible === header.name && (
    //         <div
    //           className="absolute top-10 left-0 max-w-max max-h-[300px] bg-[#444467] z-10 shadow-lg p-2 overflow-y-auto"
    //           onClick={() => {
    //             setDropdownVisible(
    //               dropdownVisible === header.name ? null : header.name
    //             );
    //           }}
    //         >
    //           {header.dropdownList?.map((column) => (
    //             <div
    //               key={column.name}
    //               className="flex items-center py-1 no-select"
    //             >
    //               <input
    //                 id={`checkbox-${column.name}`}
    //                 type="checkbox"
    //                 checked={
    //                   Array.isArray(header.value) &&
    //                   header.value?.includes(column.name || "")
    //                 }
    //                 onChange={() => {
    //                   const currentValues = Array.isArray(header.value)
    //                     ? [...header.value]
    //                     : [];
    //                   const index = currentValues.indexOf(column.name);

    //                   const updatedValues =
    //                     index > -1
    //                       ? [
    //                           ...currentValues.slice(0, index),
    //                           ...currentValues.slice(index + 1),
    //                         ]
    //                       : [...currentValues, column.name];

    //                   setHeaders(
    //                     headers.map((h) =>
    //                       h.name === header.name
    //                         ? { ...h, value: updatedValues }
    //                         : h
    //                     )
    //                   );
    //                 }}
    //                 className="mr-2"
    //               />
    //               <label
    //                 htmlFor={`checkbox-${column.name}`}
    //                 className="text-white font-Play text-sm whitespace-nowrap"
    //               >
    //                 {column.name}
    //               </label>
    //             </div>
    //           ))}
    //         </div>
    //       )}
    //     </div>
    //   );
    // }

    return (
      <td className="text-sm font-[Barlow] font-normal text-left py-2">—</td>
    );
  };

  return (
    <div className="w-full  overflow-x-auto">
      <table className="w-full min-w-max table-auto">
        <tbody>
          <tr className="bg-[#94a3b883] dark:bg-[#3C3C58] pb-2.5 border-0 border-b border-slate-300 border-solid">
            <th className="text-sm font-[Barlow] pl-4 font-bold text-left pt-3 pb-2 dark:text-white text-[#2b2e48]">
              Header Field Name
            </th>
            <th className="text-sm font-[Barlow] font-bold text-left pt-3 pb-2 dark:text-white text-[#2b2e48]">
              Value
            </th>
          </tr>
        </tbody>
        {headers?.map((header, index) => (
          <tr
            key={index}
            className="border-0 border-b  border-slate-300 border-solid dark:bg-[#1F1F48] bg-white cursor-pointer dark:text-white"
          >
            <td className="pl-4 text-sm font-[Barlow] font-normal text-left py-2">
              {header.name}
            </td>
            {getFieldByType(header)}
            {/* <td className="text-sm font-[Barlow] font-normal text-left py-2">
              {header.value}
            </td> */}
          </tr>
        ))}
      </table>
    </div>
  );
}

export default EasyEditor;
