import React, { useCallback, useEffect, useState } from "react";
import Header from "components/Header";
import Icons from "assets/Icons";

import { globalNavigate } from "routes/GlobalRoutes";
import Images from "assets/Images";
import { ActivitiesColorMapping } from "constants/index";
import Activities from ".";
import {
  getActivityIcon,
  getCountryName,
  getFirstName,
  getFlatActivityIcon,
} from "helpers/Utils";
import CountryDxccFlag from "components/CountryDxccFlag";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { mapStylesDark, mapStylesLight } from "constants/MapStyles";
import useTheme from "hooks/useTheme.hook";
import RouteLoading from "components/Loading/RouteLoading";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import ActivityTable from "./ActivityTable";
import BarChartComponent from "components/Charts/BarChart/BarChartBasic";
import useActivity from "hooks/activity.hook";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSingleActivityLoadingSelector } from "store/Reducers/loading.reducer";

import SkeletonLoading from "components/SkeletonLoading";
import { getSingleActivityDetailSelector } from "store/Reducers/pota.reducer";
import { UserAvatar } from "components/UserAvatar";
import { BiCaretLeft, BiCaretRight } from "react-icons/bi";
import moment from "moment";
import Warning from "components/Warning";
const MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API;
const googleLibraries: any[] = ["geometry", "marker", "maps", "drawing"];
function SingleActivityDetail() {
  const [searchParams] = useSearchParams();

  const onBackClickHandler = () => {
    globalNavigate("/activities");
  };
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: MAP_API_KEY!,
    libraries: googleLibraries,
  });
  const [map, setMap] = useState<google.maps.Map>();
  const { theme } = useTheme();
  const isDark = theme === "dark";
  const mapRef = React.useRef<any>();
  const [center, setCenter] = React.useState<any>(null);
  const onLoad = useCallback((map: google.maps.Map) => setMap(map), []);
  const {
    chartMonths,
    getActivityDetails,
    onYearMoveBackward,
    onYearMoveForward,
    filterYear,
    graphLoading,
    graphData,
    onCreateLogbook,
    getActivityYearGraph,

    getTopActivations,
    topActivationLoading,
    wrlTopActivations,

    getTopActivators,
    topActivatorLoading,
    wrlTopActivators,

    getTopHunters,
    topHunterLoading,
    wrlTopHunter,
  } = useActivity();
  const reference: string | null = searchParams.get("park");
  const type: string | null = searchParams.get("type");
  const name: string | null = searchParams.get("name");
  const location = useLocation();
  const parkData = location?.state || null;
  const loading = useSelector(getSingleActivityLoadingSelector);
  // const loading = true;

  const activityDetail = useSelector(getSingleActivityDetailSelector);

  useEffect(() => {
    getActivityDetails(reference || "");
    getTopActivations(reference || "");
    getTopActivators(reference || "");
    getTopHunters(reference || "");
  }, []);

  useEffect(() => {
    getActivityYearGraph(reference || "", filterYear);
  }, [filterYear]);

  if (!reference || !type || !name || !parkData) {
    globalNavigate("/activities");
    return null;
  }

  const renderSkeletonLoading = (itemLoading?: boolean) => {
    return (
      <SkeletonLoading
        baseColor={isDark ? "#191D54" : "#e7e7e7"}
        highlightColor={isDark ? "#2B2C54" : "#d7d7d7"}
        loading={itemLoading !== undefined ? itemLoading : loading}
        className="w-full h-full"
        borderRadius={10}
      />
    );
  };

  const navigateToUserProfile = (callSign: string) => {
    globalNavigate(`/profile/${callSign}`);
  };

  return (
    <main className={`relative w-full h-full flex-grow overflow-x-auto`}>
      <Header title="Add a Contact" />
      <div
        className={`w-full flex flex-col items-center pb-7 sm:pb-10 overflow-x-hidden dashboardWrapper`}
      >
        <div className="flex flex-row w-full dark:text-white text-[#2b2e48] pt-4 justify-between">
          <div
            className="flex flex-row gap-1 items-center cursor-pointer ml-3"
            onClick={onBackClickHandler}
          >
            <img
              src={Icons.BackIcon}
              alt="back button"
              className="invert dark:filter-none dark:invert-0"
            />{" "}
            Back
          </div>
          <div className="flex justify-center items-center text-md md:text-2xl font-Play gap-2">
            <div className="flex flex-col items-center">
              <div className="flex items-center">
                <h1 className="font-[Play] text-[30px] font-bold">
                  {reference}
                </h1>
                <div
                  className="border-2 rounded-full flex items-center justify-center ml-3 px-4 h-6"
                  style={{ borderColor: ActivitiesColorMapping[type] }}
                >
                  <img
                    src={getFlatActivityIcon(type)}
                    className="w-4 h-3.5 mr-2 filter invert dark:invert-0"
                    alt="activity logo"
                  />
                  <span className="text-[12px] font-[Play]">{type}</span>
                </div>
              </div>
              <div className="flex items-center">
                <h5 className="font-[Play] text-[20px]">{name}</h5>
                <span className="mx-3">-</span>
                <div className="flex items-center mb-3 sm:mb-0 mr-0 sm:mr-5 xl:mr-16">
                  <CountryDxccFlag
                    countryCode={
                      parkData?.country || parkData?.entityName || ""
                    }
                    flagCode={""}
                  />
                  <p className="text-base xl:text-lg font-[Play] ml-2 dark:text-white text-[#2b2e48]">
                    {parkData?.state || parkData?.locationName}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div></div>
          {/* <div className="items-center rounded-l-full flex w-[120px] h-[40px] bg-gradient-to-r from-[#828BFF] to-[#8AEAFF]">
            <img
              src={Icons.NewPark}
              alt="new park icon"
              className="ml-4 mr-3"
            />
            <p className="font-[Play] text-[15px] font-bold text-[#010A41]">
              NEW
            </p>
          </div> */}
        </div>
        <Warning message="This page only displays activities made on WRL platform." />
        <div className="w-full flex px-7 flex-wrap">
          <div className="md:w-1/2 lg:w-1/2 w-full sm:w-full">
            <div className="dark:bg-[#151541] bg-[#fff] py-2 px-3">
              <p className="font-[Play] font-bold text-[20px] dark:text-white mb-3">
                {name}
              </p>
              <div className="flex flex-wrap">
                <div className="w-full md:w-1/4 lg:w-1/4 md:pr-3 pr-0 md:mb-0 mb-3 ">
                  <div className="relative bg-gradient-to-r dark:from-[#2B2C54] dark:to-[#191D54] from-[#e7e7e7] to-[#e9e9e9] w-full h-full py-2 px-3 rounded-lg mt-3 md:mt-0">
                    <span className="font-[Barlow] dark:text-[#AEB9E1] mb-3">
                      Total Activations
                    </span>
                    <h1 className="font-[Play] dark:text-white font-bold text-[32px]">
                      {activityDetail?.totalActivationsCount || 0}
                    </h1>
                    {renderSkeletonLoading()}
                  </div>
                </div>
                <div className="w-full md:w-1/4 lg:w-1/4 md:pr-3 pr-0 md:mb-0 mb-3">
                  <div className="relative bg-gradient-to-r dark:from-[#2B2C54] dark:to-[#191D54] from-[#e7e7e7] to-[#e9e9e9] w-full h-full py-2 px-3 rounded-lg mt-3 md:mt-0">
                    <span className="font-[Barlow] dark:text-[#AEB9E1] mb-3">
                      Total QSOs
                    </span>
                    <h1 className="font-[Play] dark:text-white font-bold text-[32px]">
                      {activityDetail?.totalQSOCount || 0}
                    </h1>
                    {renderSkeletonLoading()}
                  </div>
                </div>
                {/* <div className="w-full md:w-1/2 lg:w-1/2 px-3 rounded-lg">
                  <SkeletonLoading
                    baseColor="#191D54"
                    highlightColor="#2B2C54"
                    loading={loading}
                    className="w-full h-full"
                    borderRadius={10}
                    children={null}
                  />
                </div> */}

                <div className="relative bg-gradient-to-r dark:from-[#2B2C54] dark:to-[#191D54] from-[#e7e7e7] to-[#e9e9e9] w-full md:w-1/2 lg:w-1/2 py-2 px-3 rounded-lg mt-3 md:mt-0">
                  <div className="flex flex-col">
                    <span className="font-[Barlow] dark:text-[#AEB9E1] mb-3">
                      Last Activated By:
                    </span>
                    <div className="flex">
                      <div
                        className="inline-flex items-center cursor-pointer"
                        onClick={() => {
                          navigateToUserProfile(
                            activityDetail?.lastActivatedUser?.callSign || ""
                          );
                        }}
                      >
                        <div className="relative cursor-pointer">
                          {activityDetail?.lastActivatedUser?.profilePic ? (
                            <img
                              className="w-[40px] h-[40px] cursor-pointer rounded-full bg-[#f2f2f2]"
                              src={
                                activityDetail?.lastActivatedUser?.profilePic
                              }
                              alt=""
                            />
                          ) : (
                            <UserAvatar className="h-8 w-8 cursor-pointer" />
                          )}
                          <div className="absolute bottom-0 right-0 cursor-pointer">
                            <CountryDxccFlag
                              countryCode={getCountryName(
                                activityDetail?.lastActivatedUser?.country || ""
                              )}
                              flagCode={""}
                              imageClassName="w-[15px] h-[15px]"
                            />
                          </div>
                        </div>
                      </div>
                      <h1
                        className="dark:text-white text-[24px] font-[Barlow] font-bold ml-3 cursor-pointer"
                        onClick={() => {
                          navigateToUserProfile(
                            activityDetail?.lastActivatedUser?.callSign || ""
                          );
                        }}
                      >
                        {getFirstName(
                          activityDetail?.lastActivatedUser?.name || ""
                        )}
                        , {activityDetail?.lastActivatedUser?.callSign}
                      </h1>
                    </div>
                    <span className="font-[Barlow] font-bold dark:text-[#fff] mb-3 ml-1 cursor-pointer">
                      {activityDetail?.lastActivatedUser?.date}
                    </span>
                  </div>
                  <SkeletonLoading
                    baseColor={isDark ? "#191D54" : "#e7e7e7"}
                    highlightColor={isDark ? "#2B2C54" : "#d7d7d7"}
                    loading={loading}
                    className="w-full h-full"
                    borderRadius={10}
                  />
                </div>
              </div>
            </div>
            <div className="dark:bg-[#151541] bg-[#fff] mt-3 py-2 px-3">
              <p className="font-[Play] font-bold text-[20px] dark:text-white mb-3">
                Your Stats
              </p>
              <div className="flex flex-wrap">
                <div className="w-full md:w-1/3 lg:w-1/3 md:pr-3 pr-0 md:mb-0 mb-3">
                  <div className="relative bg-gradient-to-r dark:from-[#2B2C54] dark:to-[#191D54] from-[#e7e7e7] to-[#e9e9e9] w-full h-full py-2 px-3 rounded-lg mt-3 md:mt-0">
                    <span className="font-[Barlow] dark:text-[#AEB9E1] mb-3">
                      Activations
                    </span>
                    <h1 className="font-[Play] dark:text-white font-bold text-[32px]">
                      {activityDetail?.totalUserActivationCount || 0}
                    </h1>
                    {renderSkeletonLoading()}
                  </div>
                </div>
                <div className="w-full md:w-1/3 lg:w-1/3 md:pr-3 pr-0 md:mb-0 mb-3">
                  <div className="relative bg-gradient-to-r dark:from-[#2B2C54] dark:to-[#191D54] from-[#e7e7e7] to-[#e9e9e9] w-full h-full py-2 px-3 rounded-lg mt-3 md:mt-0">
                    <span className="font-[Barlow] dark:text-[#AEB9E1] mb-3">
                      Activated QSOs
                    </span>
                    <h1 className="font-[Play] dark:text-white font-bold text-[32px]">
                      {activityDetail?.totalUserQSOActivationCount || 0}
                    </h1>
                    {renderSkeletonLoading()}
                  </div>
                </div>
                <div className="w-full md:w-1/3 lg:w-1/3 md:pr-3 pr-0 md:mb-0 mb-3">
                  <div className="relative bg-gradient-to-r dark:from-[#2B2C54] dark:to-[#191D54] from-[#e7e7e7] to-[#e9e9e9] w-full h-full py-2 px-3 rounded-lg mt-3 md:mt-0">
                    <span className="font-[Barlow] dark:text-[#AEB9E1] mb-3">
                      Hunted QSOs
                    </span>
                    <h1 className="font-[Play] dark:text-white font-bold text-[32px]">
                      {activityDetail?.totalUserHuntsCount || 0}
                    </h1>
                    {renderSkeletonLoading()}
                  </div>
                </div>
              </div>
            </div>
            <div className="relative dark:bg-[#151541] bg-[#fff] mt-3 py-2 px-3">
              <p className="font-[Play] font-bold text-[16px] dark:text-white mb-2">
                Latitude Longitude:
              </p>
              <p className="dark:text-white mb-4 text-sm font-light">
                ({parkData?.latitude}, {parkData?.longitude}) ({parkData?.grid6}
                )
              </p>

              <p className="font-[Play] font-bold text-[16px] dark:text-white mb-2">
                Website
              </p>
              <a
                className="text-[#1E90FF] mb-4 text-sm font-light cursor-pointer"
                href={parkData?.website}
                target="_blank"
                rel="noreferrer"
              >
                {parkData?.website}
              </a>

              <p className="font-[Play] font-bold text-[16px] dark:text-white mb-2">
                First WRL Activation:
              </p>
              <div className="flex">
                <div className="inline-flex items-center">
                  <div className="relative">
                    {activityDetail?.firstActivatedUser?.profilePic ? (
                      <img
                        className="w-[25px] h-[25px] cursor-pointer rounded-full bg-[#f2f2f2]"
                        src={activityDetail?.firstActivatedUser?.profilePic}
                        alt=""
                        onClick={() => {
                          navigateToUserProfile(
                            activityDetail?.firstActivatedUser?.callSign || ""
                          );
                        }}
                      />
                    ) : (
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          navigateToUserProfile(
                            activityDetail?.firstActivatedUser?.callSign || ""
                          );
                        }}
                      >
                        <UserAvatar className="h-6 w-6" />
                      </div>
                    )}
                    <div className="absolute bottom-0 right-0">
                      <CountryDxccFlag
                        countryCode={getCountryName(
                          activityDetail?.firstActivatedUser?.country || ""
                        )}
                        flagCode={""}
                        imageClassName="w-[10px] h-[10px]"
                      />
                    </div>
                  </div>
                </div>
                <p className="dark:text-white text-[15px] font-[Barlow] ml-3">
                  {activityDetail?.firstActivatedUser?.callSign || "--"},
                  {activityDetail?.firstActivatedUser?.date || "--"}
                </p>
              </div>
              {renderSkeletonLoading()}
            </div>
          </div>
          <div className="md:w-1/2 lg:w-1/2 w-full sm:w-full md:px-3 px-0">
            <div className="dark:bg-[#151541] bg-[#fff] py-2 md:px-3 px-0 h-full mt-4 md:mt-0">
              <p className="dark:text-white mb-4 text-sm font-light text-center mt-2">
                ({parkData?.latitude}, {parkData?.longitude}) ({parkData?.grid6}
                )
              </p>
              <div className="flex justify-center">
                <div
                  className={`bg-gradient-to-r from-[#37A4BA] via-[#496780] to-[#CF0971] w-[90%] h-[244px] rounded-3xl flex justify-center items-center shadow-lg`}
                >
                  <div
                    className={` w-[calc(100%-2px)] h-[calc(100%-2px)] rounded-3xl flex justify-center items-center font-Play bg-[black] overflow-hidden`}
                  >
                    {!isLoaded ? (
                      <RouteLoading />
                    ) : (
                      <div className="w-full h-full">
                        <GoogleMap
                          ref={mapRef}
                          mapContainerClassName="map-container no-close-button-on-info-windows"
                          center={{
                            lat: parkData?.latitude,
                            lng: parkData?.longitude,
                          }}
                          onLoad={onLoad}
                          zoom={10}
                          options={{
                            styles: [
                              ...(theme === "dark"
                                ? mapStylesDark
                                : mapStylesLight),
                              {
                                featureType: "poi", // Points of Interest (landmarks, etc.)
                                elementType: "all",
                                stylers: [{ visibility: "off" }], // Hide all POIs
                              },
                            ],
                          }}
                        >
                          <MarkerF
                            position={{
                              lat: parkData?.latitude || 0,
                              lng: parkData?.longitude || 0,
                            }}
                            onClick={() => {}}
                            icon={{
                              url: getActivityIcon(type),
                              scaledSize: new window.google.maps.Size(40, 40),
                            }}
                          ></MarkerF>
                        </GoogleMap>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex mt-6 justify-center mb-3">
                <div className="w-[220px] mr-3">
                  <a
                    target="_blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${parkData?.latitude},${parkData?.longitude}`}
                    rel="noreferrer"
                  >
                    <BorderButtonSecondary
                      text={`Open in Maps`}
                      onClick={() => {}}
                      className="sm:mb-0 cursor-pointer"
                    />
                  </a>
                </div>

                <div className="w-[220px]">
                  <BorderButtonSecondary
                    text={`Start Activation`}
                    onClick={() => {
                      onCreateLogbook && onCreateLogbook(parkData);
                    }}
                    className="sm:mb-0 cursor-pointer"
                  />
                </div>
              </div>
              <div className="relative px-3 md:px-0">
                <ActivityTable
                  title={"Latest WRL Activations"}
                  data={activityDetail?.latestWrlActivations || []}
                  showTotal={false}
                />
                <SkeletonLoading
                  baseColor={isDark ? "#191D54" : "#e7e7e7"}
                  highlightColor={isDark ? "#2B2C54" : "#d7d7d7"}
                  loading={loading}
                  className="w-full h-full"
                  borderRadius={10}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full px-7 mt-4">
          <div className="dark:bg-[#151541] bg-[#fff] py-2 md:px-3 px-0 h-full mt-4 md:mt-0">
            <div className="flex flex-wrap">
              <div className="relative w-full md:w-1/3 lg:w-1/3 md:pr-3 pr-0 md:mb-0 mb-3 px-3 md:px-0">
                <ActivityTable
                  title={"Top WRL # of Activations"}
                  showTotal={true}
                  data={wrlTopActivations}
                />
                {renderSkeletonLoading(topActivationLoading)}
              </div>

              <div className="relative w-full md:w-1/3 lg:w-1/3 md:pr-3 pr-0 md:mb-0 mb-3 px-3 md:px-0">
                <ActivityTable
                  title={"Top WRL # of Activator QSOs"}
                  showTotal={true}
                  data={wrlTopActivators}
                />
                {renderSkeletonLoading(topActivatorLoading)}
              </div>
              <div className="relative w-full md:w-1/3 lg:w-1/3 md:pr-3 pr-0 md:mb-0 mb-3 px-3 md:px-0">
                <ActivityTable
                  title={"Top WRL # of Hunter QSOs"}
                  showTotal={true}
                  data={wrlTopHunter}
                />
                {renderSkeletonLoading(topHunterLoading)}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full px-7 mt-4">
          <div className="relative dark:bg-[#151541] bg-[#fff] py-2 md:px-3 px-3 h-full mt-4 md:mt-0">
            <div className="flex justify-between items-center">
              <p className="font-[Play] text-[20px] dark:text-white mt-4">
                # of WRL QSOs by Month
              </p>
              <div className="flex items-center mr-3">
                <BiCaretLeft
                  color="#17F9DA"
                  className="cursor-pointer"
                  onClick={onYearMoveBackward}
                />
                <h1 className="font-[Play] mx-3 text-[20px] dark:text-white">
                  {filterYear}
                </h1>
                <BiCaretRight
                  color={
                    `${filterYear}` === moment().format("YYYY")
                      ? "#a7a7a7"
                      : "#17F9DA"
                  }
                  className="cursor-pointer"
                  onClick={onYearMoveForward}
                />
              </div>
            </div>
            <div className="flex-1">
              <BarChartComponent labels={chartMonths} data={graphData} />
            </div>
            {renderSkeletonLoading(graphLoading)}
          </div>
        </div>
      </div>
    </main>
  );
}

export default SingleActivityDetail;
