import Quill from "quill";

const BlockEmbed: any = Quill.import("blots/block/embed");

class ImageBlot extends BlockEmbed {
  static create(value: { url: string; width: string; height: string; alignment: string }) {
    const node = super.create();

    // Wrapper div for alignment
    const wrapperDiv = node;
    wrapperDiv.style.width = "100%";
    wrapperDiv.style.height = value.height || "400";
    wrapperDiv.style.display = "flex";
    wrapperDiv.style.justifyContent = value.alignment || "center"; // Default alignment

    // Create the image element
    const img = document.createElement("img");
    img.setAttribute("src", value.url);
    img.setAttribute("width", value.width || "400");
    img.setAttribute("height", value.height || "400");
    img.style.objectFit = "contain";

    // Append the image inside wrapper
    wrapperDiv.appendChild(img);

    return wrapperDiv;
  }

  static value(node: HTMLElement) {
    const img = node.querySelector("img");
    const alignment= node?.style.justifyContent || "center";

    return {
      url: img?.getAttribute("src") || "",
      width: img?.getAttribute("width") || "400",
      height: img?.getAttribute("height") || "400",
      alignment: alignment || "center",
    };
  }
}

// Register the custom blot
ImageBlot.blotName = "customImage";
ImageBlot.tagName = "div";

Quill.register(ImageBlot);

export default ImageBlot;
