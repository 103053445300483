import { PrimaryModalDataProps } from "types/State";

export const MENU_VISIBILITY_STATUS = "MENU_VISIBILITY_STATUS";
export const SET_IS_COLLAPSED = "SET_IS_COLLAPSED";
export const SET_PRIMARY_MODAL_DATA = "SET_PRIMARY_MODAL_DATA";
export const SET_SCREENSHOT_IMAGE = "SET_SCREENSHOT_IMAGE";
export const UPLOAD_SCREENSHOT_IMAGE = "UPLOAD_SCREENSHOT_IMAGE";
export const SHOW_SIGNIN_HELP = "SHOW_SIGNIN_HELP";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const SPOTTING_COPIED = "SPOTTING_COPIED";
export const CONTACT_MAP_MODAL_STATUS = "CONTACT_MAP_MODAL_STATUS";
export const SET_STATS_MODAL_STATUS = "SET_STATS_MODAL_STATUS";
export const SHARE_MAP_MODAL_TYPE = "SHARE_MAP_MODAL_TYPE";
export const SET_WEB_CONFIG = "SET_WEB_CONFIG";

export interface ConfigActionPayload {
  type: string;
  payload: any;
}

export function setMenuVisibility(data: boolean): ConfigActionPayload {
  return {
    type: MENU_VISIBILITY_STATUS,
    payload: data,
  };
}

export function storeScreenShotImage(data: string): ConfigActionPayload {
  return {
    type: UPLOAD_SCREENSHOT_IMAGE,
    payload: data,
  };
}

export function setPrimaryModalData(
  data: PrimaryModalDataProps | null
): ConfigActionPayload {
  return {
    type: SET_PRIMARY_MODAL_DATA,
    payload: data,
  };
}

export const setScreenImage = (data: string) => {
  return {
    type: SET_SCREENSHOT_IMAGE,
    payload: data,
  };
};

export const showSignInHelp = (data: boolean) => {
  return {
    type: SHOW_SIGNIN_HELP,
    payload: data,
  };
};

export const addContactSuccess = (data: boolean) => {
  return {
    type: ADD_CONTACT_SUCCESS,
    payload: data,
  };
};

export const setSpottingCopied = (data: boolean) => {
  return {
    type: SPOTTING_COPIED,
    payload: data,
  };
};

export const setContactMapModalStatus = (status: boolean): ConfigActionPayload => {
  return {
    type: CONTACT_MAP_MODAL_STATUS,
    payload: status,
  };
};

export const setStatsModalStatus = (status: boolean) => ({
  type: SET_STATS_MODAL_STATUS,
  payload: status
});

export const setShareMapModalType = (status: string): ConfigActionPayload => {
  return {
    type: SHARE_MAP_MODAL_TYPE,
    payload: status,
  };
};

export const setWebConfig = (data: any): ConfigActionPayload => {
  return {
    type: SET_WEB_CONFIG,
    payload: data,
  };
};