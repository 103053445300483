import React from "react";
import { LeaderBoardFilterControlsProps } from "types/Component";
import { LEADER_BOARD_MODE } from "types/Functions";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { getISOWeek } from "helpers/Utils";

const LeaderBoardFilterControls = ({
  selectedMode,
  setSelectedMode,
  increaseWeek,
  decreaseWeek,
  currentWeek,
}: LeaderBoardFilterControlsProps) => {
  return (
    <div className="dark:bg-[#272750] bg-white flex w-full rounded-full mb-4 justify-center py-1">
      <div className="cursor-pointer mr-20 justify-center items-center flex flex-col">
        <h3
          className={`${
            selectedMode === LEADER_BOARD_MODE.WEEKLY
              ? "dark:text-[#17F9DA] text-[#1e40af]"
              : "dark:text-white text-[#2b2e48]"
          } font-[Play] font-bold text-[18px]`}
          onClick={() => {
            setSelectedMode(LEADER_BOARD_MODE.WEEKLY);
          }}
        >
          Weekly
        </h3>
        { selectedMode === LEADER_BOARD_MODE.WEEKLY && (
          <div className="flex items-center">
            {parseInt(currentWeek) <= 1 ? null : (
              <FaCaretLeft
                className={`${
                  selectedMode === LEADER_BOARD_MODE.WEEKLY
                    ? "dark:text-[#17F9DA] text-[#1e40af]"
                    : "dark:text-white text-[#2b2e48]"
                } cursor-pointer`}
                onClick={decreaseWeek}
              />
            )}

            <p
              className={`${
                selectedMode === LEADER_BOARD_MODE.WEEKLY
                  ? "dark:text-[#17F9DA] text-[#1e40af]"
                  : "dark:text-white text-[#2b2e48]"
              } font-[Play] font-bold mx-3`}
            >
              {currentWeek}
            </p>
            {parseInt(currentWeek) >= getISOWeek() ? null : (
              <FaCaretRight
                className={`${
                  selectedMode === LEADER_BOARD_MODE.WEEKLY
                    ? "dark:text-[#17F9DA] text-[#1e40af]"
                    : "dark:text-white text-[#2b2e48]"
                } cursor-pointer`}
                onClick={increaseWeek}
              />
            )}
          </div>
        )}
      </div>
      <div
        className="cursor-pointer"
        onClick={() => {
          setSelectedMode(LEADER_BOARD_MODE.ALL_TIME);
        }}
      >
        <h3
          className={`${
            selectedMode === LEADER_BOARD_MODE.ALL_TIME
              ? "dark:text-[#17F9DA] text-[#1e40af]"
              : "dark:text-white text-[#2b2e48]"
          } font-[Play] font-bold text-[18px]`}
        >
          All Time
        </h3>
      </div>
    </div>
  );
};

export default LeaderBoardFilterControls;
