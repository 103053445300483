import { useEditContact } from "hooks/useEditContact.hook";
import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import {
  isActivityRelatedField,
  objectToString,
  parseActivityFieldsToString,
} from "helpers/Utils";
import { bulkExportContacts } from "firebase-config";
import { useSelector } from "react-redux";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { useUserActivity } from "hooks/useUserActivity.hook";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import ActivityIndicator from "components/Loading/ActivityIndicator";

export const ConfirmChangesModal = () => {
  const userProfile = useSelector(getUserProfileSelector);

  const {
    confirmChangesModalData,
    editContactModalData,
    setConfirmChangesModal,
    bulkUpdateContact,
  } = useEditContact();

  const { userActivityStatus, userActivityType, showExportAllContacts } =
    useUserActivity();

  if (!confirmChangesModalData) return null;
  const selectedRows = editContactModalData?.selectedRows || [];
  const qsoFilters = editContactModalData?.qsoFilters || {};
  const totalQSOs = editContactModalData?.totalQSOs || 0;
  const allSelected = editContactModalData?.allSelected || false;

  const exportAllContacts = async () => {
    const uid = userProfile?.uid;
    const callSign = userProfile?.callSign;
    let response = null;

    if (userActivityStatus === "In Progress") {
      showToast({
        message: `${userActivityType} is in progress. Please complete the activity first.`,
        type: ToastTypes.WARN,
      });
      return;
    }

    response = await bulkExportContacts({
      filter: {
        uid,
      },
      formate: "adif",
      callSign,
      env: process.env.NODE_ENV,
      uid,
      exportAll: true,
    });

    if (response && response?.data) {
      const fileUrl: any = response?.data; // Replace with your file URL
      const link = document.createElement("a");
      link.href = fileUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up
    }
  };
  return (
    <main className="absolute w-full h-full z-50 glassmorphism">
      <div className="w-full h-full flex flex-col items-center p-4 md:p-20 overflow-x-hidden">
        <div className="bg-gradient-to-br from-fuchsia-500 via-violet-600 to-violet-900 p-[1px] inline-flex relative w-full shadow-lg">
          <div className="flex flex-col md:flex-row justify-between w-full bg-[#0B0B38] p-6 text-white">
            <div className="flex flex-col items-center w-full gap-6">
              <div className=" text-center">
                <div className="font-bold font-Play text-xl">
                  Bulk Update Confirmation
                </div>
                <div>
                  <span className="text-[#17f9da] font-bold mr-2">
                    {allSelected ? totalQSOs : selectedRows?.length}
                  </span>
                  QSOs will be updated with these details
                </div>
              </div>

              <div className="w-[90%] lg:w-[60%] overflow-y-hidden mb-4">
                <table className="bg-white w-full">
                  <tbody>
                    <tr className="bg-[#3C3C58] pb-2.5 border-0 border-b border-slate-300 border-solid">
                      <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 text-white">
                        Field Name
                      </th>
                      <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 text-white flex items-center">
                        New Value
                      </th>
                    </tr>
                    {Object.keys(confirmChangesModalData).length > 0
                      ? Object.keys(confirmChangesModalData).map(
                          (item, index) => {
                            return (
                              <tr
                                key={item}
                                className={`${
                                  index % 2 ? "bg-[#151536]" : "bg-[#1F1F3F]"
                                } border-0 border-b border-slate-300 border-solid cursor-pointer`}
                              >
                                <td className="pl-4 text-white  text-xs font-[Barlow] font-normal py-3">
                                  <div className="flex">
                                    <span>{item}</span>
                                  </div>
                                </td>
                                <td className="pl-4 py-3 text-[#17F9DA] text-xs font-[Barlow] font-normal border-l">
                                  {isActivityRelatedField(item)
                                    ? parseActivityFieldsToString(
                                        confirmChangesModalData[item],
                                        item
                                      )
                                    : typeof confirmChangesModalData[item] ===
                                      "object"
                                    ? objectToString(
                                        confirmChangesModalData[item]
                                      )
                                    : confirmChangesModalData[item]}
                                </td>
                              </tr>
                            );
                          }
                        )
                      : null}
                  </tbody>
                </table>
              </div>

              {showExportAllContacts ? (
                userActivityStatus === "In Progress" &&
                userActivityType === "exportAllContacts" ? (
                  <div className="border border-[#00FF87] rounded-[8px] w-[90%] md:w-[70%] lg:w-[45%] bg-[#010a41] px-4 py-2 flex flex-row items-center justify-between gap-4">
                    <div className="text-xs font-medium">
                      {userActivityType} is in progress.
                    </div>
                    <div>
                      <ActivityIndicator size={16} />
                    </div>
                  </div>
                ) : (
                  <div className="border border-[#00FF87] rounded-[8px] w-[90%] md:w-[70%] lg:w-[45%] bg-[#010a41] px-4 py-2 flex flex-row gap-4">
                    <div className="text-xs font-medium">
                      We{" "}
                      <span className="text-[#17f9da] font-bold">HIGHLY</span>{" "}
                      recommend Export ADIF on all of your contacts before
                      making a bulk update
                    </div>
                    <button
                      className="text-xs bg-[#1b255d] w-[200px] rounded-[4px]"
                      onClick={exportAllContacts}
                    >
                      Export Full ADIF
                    </button>
                  </div>
                )
              ) : null}

              <div className="flex flex-row gap-4 border border-[#ff0000] rounded-[8px] w-[90%] md:w-[70%] lg:w-[45%] bg-[#010a41] px-4 py-1">
                <img src={Icons.RedWarning} alt="" />

                <div>
                  <div className="text-[17px] font-bold font-['Barlow']">
                    WARNING!
                  </div>
                  <div className="text-xs font-medium">
                    This action cannot be undone.
                  </div>
                </div>
              </div>

              <div className="w-full py-10 flex justify-center gap-4">
                <div
                  className="flex items-end w-full sm:w-[50%] md:w-[40%] lg:w-[24.5%]"
                  tabIndex={53}
                >
                  <BorderButtonSecondary
                    isLoading={userActivityStatus === "In Progress"}
                    text={"Update Contacts"}
                    onClick={() => {
                      bulkUpdateContact({
                        payload: confirmChangesModalData,
                        selectedRows,
                        qsoFilters,
                      });
                    }}
                    childClassName="text-sm"
                  />
                </div>
                <div
                  className="flex items-end w-full sm:w-[50%] md:w-[40%] lg:w-[24.5%]"
                  tabIndex={53}
                >
                  <BorderButtonSecondary
                    // isLoading={isSavingOnLoading}
                    text={"Cancel"}
                    onClick={() => setConfirmChangesModal(null)}
                    childClassName="text-sm"
                  />
                </div>
              </div>
            </div>

            <div
              className="absolute top-2 right-2 cursor-pointer"
              onClick={() => setConfirmChangesModal(null)}
              tabIndex={55}
            >
              <img
                src={Icons.GlowingClose}
                className="h-8 w-8 bg-gray-300 rounded-full dark:bg-transparent"
                alt="close"
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
