import React, { useEffect, useRef, useState } from "react";
import Entries from "components/Entries";
import ContactMap from "components/Map/ContactMap";
import TopTabNav from "components/TopTab/TopTabNav";
import useLogBook from "hooks/logBook.hook";
import { Formik, Form } from "formik";
import CustomValidation from "helpers/Validation";
import useProfile from "hooks/profile.hook";
import { globalNavigate } from "routes/GlobalRoutes";
import { CONTEST, LOG_CONTACT_ROUTE } from "constants/Links";
import { getCurrentDate, getCurrentUTCtime } from "helpers/Date/Time";
import { useLocation, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserContestRanking } from "store/Actions/contest.actions";
import {
  getContestDetailsSelector,
  getUserContestRankingSelector,
} from "store/Reducers/contest.reducer";
import { fetchLogbookDetails } from "store/Actions/logBook.actions";
import {
  findBandAndModeForFrequency,
  findStateName,
  getDxDataFromCountry,
  getLogBookErrorMessages,
  getLogBookErrorTitle,
  getLogbookErrorColors,
} from "helpers/Utils";
import ContactSpotting from "./ContactSpotting";
import { fetchSpot } from "store/Actions/spotting.action";
import DefaultWrl from "components/LogbookTemplates/DefaultWrl";
import { Templates } from "constants/Config";
import N4MLLog from "components/LogbookTemplates/N4MLLog";
import POTALog from "components/LogbookTemplates/POTALog";
import { storeUserProfileDataByCallSign } from "store/Actions/guest.action";
import { AddContactLogPopup } from "./AddContactLogPopup";
import usePota from "hooks/pota.hook";
import { LogBookStats } from "./LogBookStats";
import { LogBookQuickSettings } from "./LogBookQuickSettings";
import { useSortAndFilter } from "hooks/useSortAndFilter";
import { ParkReferenceModel, SpotModel } from "types/Models";
import DefaultCompact from "components/LogbookTemplates/DefaultCompact";
import {
  getAddContactSuccessSelector,
  getSpottingCopiedSelector,
} from "store/Reducers/config.reducer";
import {
  addContactSuccess,
  setSpottingCopied,
} from "store/Actions/config.actions";
import { useProgrammaticPopup } from "hooks/usePopupWindow";
import Images from "assets/Images";
import ContestObjectives from "./Contest/ContestObjectives";
import WFDTemplate from "components/LogbookTemplates/WFD";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "firebase-config";
import LogbookStatsTab from "./LogbookStatsTab";
var isEqual = require("lodash.isequal");

function LogContacts() {
  const {
    getCoordinatesListener,
    userProfile,
    fetchCallSignProfileData,
    guestUserProfile,
  } = useProfile();
  const {
    userCallSign,
    setUserGrid,
    setUserCallSign,
    selectedTabIndex,
    setSelectedTabIndex,
    selectedLogBook,
    saveLogBookContact,
    isSavingOnLoading,
    time,
    setTime,
    currentTime,
    setCurrentTime,
    setCurrentDate,
    currentDate,
    userMode,
    setUserMode,
    onFrequencyBlur,
    userBand,
    setUserBand,
    userGrid,
    myRadio,
    myAntenna,
    getUserFromHamApiData,
    isCallSignDataLoading,
    callSignData,
    setCallSignUserData,
    handleClearAddContactForm,
    setStopRealTimeChange,
    userTimeInput,
    userDateInput,
    setUserTimeInput,
    userLogbookCollectionListnerById,
    setMyAntenna,
    setMyRadio,
    editLogbook,
    getPaginatedLogBookContactsData,
    allPaginatedLogbookContacts,
    isLoading,
    checkLocationPermission,
    getUserCountryFromGrid,
    getAddContactLogPopupSummaryData,
    fetchAddContactLogPopupSummary,
    saveAddContactLogPopupSummary,
    setCallSign,
    power,
    setPower,
    theirPark,
    setTheirPark,
    userLogbookListener,
    selectedSecondaryField,
    setSelectedSecondaryField,
    debounceChangeFrequencyCATControl,
    setUserDateInput,
    // TEMP FOR CQ CONTEST
    logBookSerialNumber,
    setLogBookSerialNumber,
  } = useLogBook();
  const dispatch = useDispatch();
  const [duplicateInfo, setDuplicateInfo] = useState<any>(null);

  useEffect(() => {
    if (selectedLogBook?.serialNumber) {
      setLogBookSerialNumber(selectedLogBook?.serialNumber + 1);
    }
  }, [selectedLogBook?.serialNumber]);

  useEffect(() => {
    const unsbscribe = userLogbookListener();
    return () => {
      if (unsbscribe) {
        unsbscribe();
      }

      closePopup();
    };
  }, []);

  const addContactLogPopupSummary =
    getAddContactLogPopupSummaryData(selectedLogBook);
  const userContestRanking = useSelector(getUserContestRankingSelector);
  const contestDetail = useSelector(getContestDetailsSelector);
  const contactSuccess = useSelector(getAddContactSuccessSelector);

  const spottingCopied = useSelector(getSpottingCopiedSelector);

  const prevFromRadioRef = useRef<any>(null);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setSpottingCopied(false));
    }, 500);
  }, [spottingCopied]);

  let [searchParams, setSearchParams] = useSearchParams();
  const [freqFromRadio, setFreqFromRadio] = React.useState<any>(null);

  const formikRef = React.useRef<any>();
  const callSignRef = React.useRef<any>();
  const location = useLocation();

  const { setParkByReference, setParReferenceSuggestions } = usePota();

  const {
    setSortConfig,
    filters,
    setFilters,
    applyChanges,

    filterCountry,
    setFilterCountry,
    filterRegion,
    setFilterRegion,
    filterBand,
    setFilterBand,
    filterMode,
    setFilterMode,
    spottedCountry,
    setSpottedCountry,
    spottedRegion,
    setSpottedRegion,
    operatingNearMe,
    setOperatingNearMe,
    dxOnly,
    setDxOnly,
    spottedNearMe,
    setSpottedNearMe,
    potaOnly,
    setPotaOnly,
    wrlOnly,
    setWrlOnly,
    logged,
    setLogged,
  } = useSortAndFilter({ column: "", order: "", logged: true }, []);
  const initialData = {
    theirCallsign: "",
    time: time,
    date: currentDate,
    operator: "",
    qth: "",
    rstSent: userMode === "CW" ? "599" : "59",
    rstRCVD: userMode === "CW" ? "599" : "59",
    notes: "",
    state: "",
    country: "",
    grid: "",
    frequency: "",
    band: "",
    userQth: "",
    userMode: "",
    myCallSign: userCallSign,
    userGrid: selectedLogBook?.defaultLocation
      ? selectedLogBook?.defaultLocation?.gridsquare || userGrid
      : userGrid,
    power: selectedLogBook?.defaultPower || "",
    theirName: "",
    myRadio: myRadio ? myRadio : "",
    myAntenna: myAntenna ? myAntenna : "",
    adiImported: false,
    contestId:
      searchParams.get("contest_id") || selectedLogBook?.contestId || "",
    contestName: selectedLogBook?.contest?.name || "",
    exchangeOne: "",
    exchangeTwo: "",
    exchangeThree: "",
    exchangeFour: "",
    myPark: "",
    theirPark: "",
    dxccNumber: 0,
    continent: "",
    flagCode: "",
    countryCode: "",
    myRadioId: "",
    myAntennaId: "",
    mySavedLocationId: "",
  };

  //issue here is the useEffect is being executed for changes on location while we only need the changes inside location.state?.spotData
  // resulting in the useEffect being executed multiple times, we need to add a condition to check if the spotData has changed
  const prevSpotDataRef = useRef(null);
  useEffect(() => {
    if (!isEqual(prevSpotDataRef.current, location.state?.spotData)) {
      setTimeout(() => {
        if (location.state?.spotData) {
          const item: SpotModel = location.state?.spotData;
          if (formikRef.current) {
            let theirParks: any = null;
            if (item?.activityNumber) {
              const parkData: ParkReferenceModel = {
                entityId: 0,
                parkId: 0,
                parktypeDesc: "",
                referencePrefix: item?.activityNumber
                  ? item?.activityNumber.split("-")[0]
                    ? item?.activityNumber.split("-")[0]
                    : ""
                  : "",
                name: item?.activityName || "",
                grid6: item?.grid || "",
                grid4: item?.grid || "",
                longitude: item?.coordinates?.longitude || 0,
                latitude: item?.coordinates?.latitude || 0,
                reference: item?.activityNumber || "",
                locationName: item.state || "",
                parktype: "",
                entityName: item?.country || "",
              };
              theirParks = { [`${item.activityNumber}`]: parkData };
            }

            formikRef.current.setValues((prev: any) => {
              let data = {
                ...prev,
              };
              const stateName = findStateName(
                item?.country || "",
                item?.state || ""
              );
              data.theirCallsign = item.callSign || "";
              data.operator = item.name || "";
              data.state = stateName || item.state || "";
              data.country = item.country || "";
              data.grid = item.grid || "";
              data.frequency = item.frequency || "";
              data.band = item.band || "";
              data.userMode = item.mode || "";
              data.theirName = item.name || "";
              data.theirPark = "";
              data.spotSource = item.source || "";
              data.spottedContact = true;
              data.qth = item.state || "";
              data.theirParkKey = item.activityNumber || "";
              data.theirParks = theirParks;

              if (item.country) {
                const dxccData = getDxDataFromCountry(item.country || "");
                data.dxccNumber = dxccData?.dxccNumber || "";
                data.continent = dxccData?.continent || "";
                data.flagCode = dxccData?.flagCode || "";
                data.countryCode = dxccData?.countryCode || "";
              }

              return data;
            });
            setTheirPark(item.activityNumber || "");
            setUserBand(item.band || "");
            setUserMode(item.mode || "");

            debounceChangeFrequencyCATControl(item.frequency);

            // Get user data from ham api and show in CallSign popup
            const searchString = item.callSign;
            if (searchString) {
              getUserFromHamApiData(
                searchString,
                formikRef.current.setValues,
                true
              );
            }
            callSignBlurPopupHandler(searchString);
            dispatch(setSpottingCopied(true));
          } else {
          }
        }
      }, 1000);
      // Update the reference to the current spotData
      prevSpotDataRef.current = location.state?.spotData;
    }
  }, [location.state?.spotData]);

  useEffect(() => {
    if (userMode === "CW" || userMode === "RTTY") {
      formikRef.current.setValues((prev: any) => {
        const rstSent =
          prev.rstSent === "599" || prev.rstSent === "59"
            ? "599"
            : prev.rstSent;
        const rstRCVD =
          prev.rstRCVD === "599" || prev.rstRCVD === "59"
            ? "599"
            : prev.rstRCVD;

        let data = {
          ...prev,
          rstSent,
          rstRCVD,
        };
        return data;
      });
    } else {
      formikRef.current.setValues((prev: any) => {
        const rstSent =
          prev.rstSent === "599" || prev.rstSent === "59" ? "59" : prev.rstSent;
        const rstRCVD =
          prev.rstRCVD === "599" || prev.rstRCVD === "59" ? "59" : prev.rstRCVD;

        let data = {
          ...prev,
          rstSent,
          rstRCVD,
        };
        return data;
      });
    }
  }, [userMode]);

  useEffect(() => {
    dispatch(fetchSpot([]));
    dispatch(storeUserProfileDataByCallSign(null));
    saveAddContactLogPopupSummary(null);
    setParkByReference(null);
    setParReferenceSuggestions(null);

    // focus on callsign field, when component is mounted
    callSignRef?.current?.focus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let resetHandler: any = function () {};

  useEffect(() => {
    setSelectedTabIndex(
      searchParams.get("contact-tab")
        ? searchParams.get("contact-tab")!
        : "entries"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    setCallSignUserData(null);
    if (searchParams.get("logbook-id")) {
      getCoordinatesListener();
      // getLogbookContact(searchParams.get("logbook-id")!);
      getPaginatedLogBookContactsData({
        logbookId: searchParams.get("logbook-id")!,
      });
    } else {
      globalNavigate(LOG_CONTACT_ROUTE);
    }
    const timeInterval = setInterval(() => {
      setCurrentTime(getCurrentUTCtime);
      setCurrentDate(getCurrentDate);
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const userLogbookUnsubscribe = userLogbookCollectionListnerById(
      searchParams.get("logbook-id")!
    );

    return () => {
      if (userLogbookUnsubscribe) {
        userLogbookUnsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchParams.get("contest_id")) {
      dispatch(fetchLogbookDetails(selectedLogBook?.id || ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const contestId =
      searchParams.get("contest_id") || selectedLogBook?.contestId;

    if (contestId) {
      dispatch(
        getUserContestRanking({
          contestId,
          userId: selectedLogBook?.uid,
          contactCount: selectedLogBook?.isWFDLogBook
            ? selectedLogBook?.contestTotalPoints || 0
            : selectedLogBook?.contactCount || 0,
          contactCountFieldName: selectedLogBook?.isWFDLogBook
            ? "contestTotalPoints"
            : "contactCount",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLogBook, searchParams]);

  useEffect(() => {
    try {
      if (userProfile?.uid) {
        const docRef = doc(db, "CatControl", userProfile.uid);
        const unsubscribe = onSnapshot(docRef, (doc) => {
          if (doc?.exists()) {
            const data = doc.data();
            if (data?.fromRadio) {
              const freq = parseFloat(data.fromRadio);

              if (!isNaN(freq)) {
                const freqNumber = parseFloat(freq.toFixed(5));

                // Check if fromRadio has actually changed
                if (prevFromRadioRef.current !== freqNumber) {
                  prevFromRadioRef.current = freqNumber; // Update previous value
                  setFreqFromRadio(freqNumber.toFixed(5));
                  const bandMode = findBandAndModeForFrequency(freqNumber);
                  if (bandMode) {
                    setUserBand(bandMode.band);
                    setUserMode(bandMode.mode);
                    if (formikRef.current?.setValues) {
                      formikRef.current.setValues((prev: any) => ({
                        ...prev,
                        userMode: bandMode.mode,
                        band: bandMode.band,
                      }));
                    }
                  }
                }
              }
            }
          }
        });

        return () => {
          unsubscribe();
        };
      }
    } catch (e) {}
  }, [userProfile]);

  useEffect(() => {
    if (filters.length > 0) {
      applyChanges(filters);
    }
  }, [allPaginatedLogbookContacts]);

  const onUpgradeClick = () => {
    globalNavigate("/membership");
  };

  const onCancelClick = () => {
    editLogbook({
      newLogBookData: {
        errorCode: null,
      },
      logBookId: searchParams.get("logbook-id") || selectedLogBook?.id || "",
    });
  };

  const endSuccessAnimation = () => {
    dispatch(addContactSuccess(false));
  };
  const callSignBlurPopupHandler = (callSignArg: string) => {
    // clear previous data
    setCallSignUserData(null);
    saveAddContactLogPopupSummary(null);
    dispatch(storeUserProfileDataByCallSign(null));

    setCallSign(callSignArg);

    // if (formikRef.current) {
    //   formikRef.current.setValues((prev: any) => {
    //     let data = {
    //       ...prev,
    //       country: "",
    //       grid: "",
    //       dxccNumber: "",
    //       continent: "",
    //       flagCode: "",
    //       countryCode: "",
    //       state: "",
    //       theirParks: null,
    //       theirParkData: null,
    //       theirParkKey: "",
    //     };
    //     return data;
    //   });
    // }

    if (callSignArg) {
      // fetch new data
      fetchCallSignProfileData(callSignArg);
      fetchAddContactLogPopupSummary(callSignArg);
    }
  };
  const onViewContactsWithWarning = () => {
    globalNavigate(
      `/log-contacts/qso-manager?adiError=true&logBookId=${selectedLogBook?.id}`
    );
  };
  const getLogbookImportError = () => {
    if (selectedLogBook?.adiFile) {
      if (selectedLogBook.importStatus) {
        if (selectedLogBook.errorCode) {
          return (
            <div
              className={`w-[100%] ${
                getLogbookErrorColors(selectedLogBook.errorCode)[0]
              } px-4 py-2 rounded-md mb-6`}
            >
              <p
                className={`${
                  getLogbookErrorColors(selectedLogBook.errorCode)[1]
                } font-[Barlow] font-semibold`}
              >
                {getLogBookErrorTitle(selectedLogBook.errorCode)}
              </p>
              <p
                className={`${
                  getLogbookErrorColors(selectedLogBook.errorCode)[2]
                } font-[Barlow]`}
              >
                {getLogBookErrorMessages(selectedLogBook.errorCode)}
              </p>
              <div className="flex justify-end mt-2">
                <button
                  className={`mr-10 ${
                    getLogbookErrorColors(selectedLogBook.errorCode)[1]
                  }`}
                  onClick={onCancelClick}
                >
                  Dismiss
                </button>
                {selectedLogBook.errorCode === "MISSING_REQUIRED_FIELDS" ? (
                  <button
                    className="text-[#A16207]"
                    onClick={onViewContactsWithWarning}
                  >
                    View Contacts with Warnings
                  </button>
                ) : null}
                {selectedLogBook.errorCode === "QUOTA_EXCEEDED" ? (
                  <button className="text-[#166434]" onClick={onUpgradeClick}>
                    Upgrade
                  </button>
                ) : null}
              </div>
            </div>
          );
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  const getTabLayout = () => {
    if (selectedTabIndex === "entries") {
      return (
        <Entries
          lists={
            allPaginatedLogbookContacts && allPaginatedLogbookContacts.contacts
              ? allPaginatedLogbookContacts.contacts
              : []
          }
          editable={true}
          pagination={true}
          missingFields={
            selectedLogBook?.importStatus === "Complete With Errors"
          }
          paginateContact={getPaginatedLogBookContactsData}
          paginatedData={allPaginatedLogbookContacts}
          logbookId={searchParams.get("logbook-id") || ""}
          isLoading={isLoading}
          userProfile={userProfile}
          showSort={true}
          searchable
          showExchange={
            !!selectedLogBook?.contestId && !!selectedLogBook?.isWFDLogBook
          }
          exchangeOne={contestDetail?.exchangeOne || ""}
          exchangeTwo={contestDetail?.exchangeTwo || ""}
          exchangeThree={contestDetail?.exchangeThree || ""}
          exchangeFour={contestDetail?.exchangeFour || ""}
          isCompact={true}
          isWFDLogBook={selectedLogBook?.isWFDLogBook}
        />
      );
    } else if (selectedTabIndex === "qso") {
      return (
        <ContactMap
          className="w-full h-[500px] relative"
          containerClassName="w-full"
          showControls={true}
          showShare={false} // KD here
          restrictBounds={false}
        />
      );
    } else if (selectedTabIndex === "spotting") {
      return (
        <ContactSpotting
          callSignBlurPopupHandler={callSignBlurPopupHandler}
          getUserFromHamApiData={getUserFromHamApiData}
          setValues={formikRef.current ? formikRef.current.setValues : null}
          setMode={setUserMode}
          setBand={setUserBand}
          setTheirPark={setTheirPark}
          setSortConfig={setSortConfig}
          filters={filters}
          setFilters={setFilters}
          applyChanges={applyChanges}
          filterCountry={filterCountry}
          setFilterCountry={setFilterCountry}
          filterRegion={filterRegion}
          setFilterRegion={setFilterRegion}
          filterBand={filterBand}
          setFilterBand={setFilterBand}
          filterMode={filterMode}
          setFilterMode={setFilterMode}
          spottedCountry={spottedCountry}
          setSpottedCountry={setSpottedCountry}
          spottedRegion={spottedRegion}
          setSpottedRegion={setSpottedRegion}
          operatingNearMe={operatingNearMe}
          setOperatingNearMe={setOperatingNearMe}
          dxOnly={dxOnly}
          setDxOnly={setDxOnly}
          spottedNearMe={spottedNearMe}
          setSpottedNearMe={setSpottedNearMe}
          potaOnly={potaOnly}
          setPotaOnly={setPotaOnly}
          wrlOnly={wrlOnly}
          setWrlOnly={setWrlOnly}
          logged={logged}
          setLogged={setLogged}
          debounceChangeFrequencyCATControl={debounceChangeFrequencyCATControl}
        />
      );
    } else if (selectedTabIndex === "objectives") {
      return <ContestObjectives />;
    } else if (selectedTabIndex === "stats") {
      return <LogbookStatsTab />;
    }
  };

  const getLoggerLayout = (
    setValues: any,
    values: any,
    errors: any,
    handleChange: any,
    touched: any,
    submitForm: any,
    initialData: any,
    setTouched: any,
    onLogPress: any
  ) => {
    if (selectedLogBook?.logbookStyle === Templates.N4ML) {
      return (
        <N4MLLog
          setValues={setValues}
          values={values}
          errors={errors}
          handleChange={handleChange}
          touched={touched}
          submitForm={submitForm}
          initialData={initialData}
          setTouched={setTouched}
          onLogPress={onLogPress}
          callSignBlurPopupHandler={callSignBlurPopupHandler}
          userMode={userMode}
          setUserMode={setUserMode}
          setUserBand={setUserBand}
          selectedLogBook={selectedLogBook}
          isSavingOnLoading={isSavingOnLoading}
          date={userDateInput}
          setDate={setUserDateInput}
          setTime={setTime}
          currentTime={currentTime}
          currentDate={currentDate}
          onFrequencyBlur={onFrequencyBlur}
          getUserFromHamApiData={getUserFromHamApiData}
          isCallSignDataLoading={isCallSignDataLoading}
          callSignData={
            (callSignData as any)?.callSignMatch === false
              ? "NOT_FOUND"
              : callSignData
          }
          handleClearAddContactForm={handleClearAddContactForm}
          setStopRealTimeChange={setStopRealTimeChange}
          userTimeInput={userTimeInput}
          setUserTimeInput={setUserTimeInput}
          setUserDateInput={setUserDateInput}
          userDateInput={userDateInput}
          checkLocationPermission={checkLocationPermission}
          callSignRef={callSignRef}
          contactSuccess={contactSuccess}
          endSuccessAnimation={endSuccessAnimation}
          spottingCopied={spottingCopied}
          debounceChangeFrequencyCATControl={debounceChangeFrequencyCATControl}
          freqFromRadio={freqFromRadio}
          setDuplicateInfo={setDuplicateInfo}
          logBookSerialNumber={logBookSerialNumber}
          setLogBookSerialNumber={setLogBookSerialNumber}
          {...{ selectedSecondaryField, setSelectedSecondaryField }}
        />
      );
    } else if (selectedLogBook?.logbookStyle === Templates.ALL) {
      return (
        <DefaultWrl
          setValues={setValues}
          values={values}
          errors={errors}
          handleChange={handleChange}
          touched={touched}
          submitForm={submitForm}
          initialData={initialData}
          setTouched={setTouched}
          onLogPress={onLogPress}
          callSignBlurPopupHandler={callSignBlurPopupHandler}
          userMode={userMode}
          setUserMode={setUserMode}
          userBand={userBand}
          setUserBand={setUserBand}
          setUserCallSign={setUserCallSign}
          selectedLogBook={selectedLogBook}
          isSavingOnLoading={isSavingOnLoading}
          date={userDateInput}
          setDate={setUserDateInput}
          setTime={setTime}
          currentTime={currentTime}
          currentDate={currentDate}
          onFrequencyBlur={onFrequencyBlur}
          userGrid={userGrid}
          myRadio={myRadio}
          myAntenna={myAntenna}
          getUserFromHamApiData={getUserFromHamApiData}
          isCallSignDataLoading={isCallSignDataLoading}
          callSignData={
            (callSignData as any)?.callSignMatch === false
              ? "NOT_FOUND"
              : callSignData
          }
          handleClearAddContactForm={handleClearAddContactForm}
          setStopRealTimeChange={setStopRealTimeChange}
          userTimeInput={userTimeInput}
          setUserTimeInput={setUserTimeInput}
          userDateInput={userDateInput}
          setUserDateInput={setUserDateInput}
          setMyAntenna={setMyAntenna}
          setMyRadio={setMyRadio}
          checkLocationPermission={checkLocationPermission}
          getUserCountryFromGrid={getUserCountryFromGrid}
          power={power}
          setPower={setPower}
          setUserGrid={setUserGrid}
          callSignRef={callSignRef}
          contactSuccess={contactSuccess}
          endSuccessAnimation={endSuccessAnimation}
          spottingCopied={spottingCopied}
          debounceChangeFrequencyCATControl={debounceChangeFrequencyCATControl}
          freqFromRadio={freqFromRadio}
          setDuplicateInfo={setDuplicateInfo}
          logBookSerialNumber={logBookSerialNumber}
          setLogBookSerialNumber={setLogBookSerialNumber}
          {...{ selectedSecondaryField, setSelectedSecondaryField }}
        />
      );
    } else if (selectedLogBook?.logbookStyle === Templates.POTA) {
      return (
        <POTALog
          setValues={setValues}
          values={values}
          errors={errors}
          handleChange={handleChange}
          touched={touched}
          submitForm={submitForm}
          initialData={initialData}
          setTouched={setTouched}
          onLogPress={onLogPress}
          callSignBlurPopupHandler={callSignBlurPopupHandler}
          userMode={userMode}
          setUserMode={setUserMode}
          userBand={userBand}
          setUserBand={setUserBand}
          setUserCallSign={setUserCallSign}
          selectedLogBook={selectedLogBook}
          isSavingOnLoading={isSavingOnLoading}
          date={userDateInput}
          setDate={setUserDateInput}
          setTime={setTime}
          currentTime={currentTime}
          currentDate={currentDate}
          getUserFromHamApiData={getUserFromHamApiData}
          isCallSignDataLoading={isCallSignDataLoading}
          callSignData={
            (callSignData as any)?.callSignMatch === false
              ? "NOT_FOUND"
              : callSignData
          }
          handleClearAddContactForm={handleClearAddContactForm}
          setStopRealTimeChange={setStopRealTimeChange}
          userTimeInput={userTimeInput}
          setUserTimeInput={setUserTimeInput}
          userDateInput={userDateInput}
          setUserDateInput={setUserDateInput}
          checkLocationPermission={checkLocationPermission}
          setTheirPark={setTheirPark}
          theirPark={theirPark}
          callSignRef={callSignRef}
          getUserCountryFromGrid={getUserCountryFromGrid}
          contactSuccess={contactSuccess}
          endSuccessAnimation={endSuccessAnimation}
          spottingCopied={spottingCopied}
          debounceChangeFrequencyCATControl={debounceChangeFrequencyCATControl}
          freqFromRadio={freqFromRadio}
          setDuplicateInfo={setDuplicateInfo}
          logBookSerialNumber={logBookSerialNumber}
          setLogBookSerialNumber={setLogBookSerialNumber}
          {...{ selectedSecondaryField, setSelectedSecondaryField }}
        />
      );
    } else if (selectedLogBook?.logbookStyle === Templates.WFD) {
      return (
        <WFDTemplate
          setValues={setValues}
          values={values}
          errors={errors}
          handleChange={handleChange}
          touched={touched}
          submitForm={submitForm}
          initialData={initialData}
          setTouched={setTouched}
          onLogPress={onLogPress}
          callSignBlurPopupHandler={callSignBlurPopupHandler}
          userMode={userMode}
          setUserMode={setUserMode}
          userBand={userBand}
          setUserBand={setUserBand}
          setUserCallSign={setUserCallSign}
          selectedLogBook={selectedLogBook}
          isSavingOnLoading={isSavingOnLoading}
          date={userDateInput}
          setDate={setUserDateInput}
          setTime={setTime}
          currentTime={currentTime}
          currentDate={currentDate}
          getUserFromHamApiData={getUserFromHamApiData}
          isCallSignDataLoading={isCallSignDataLoading}
          callSignData={
            (callSignData as any)?.callSignMatch === false
              ? "NOT_FOUND"
              : callSignData
          }
          handleClearAddContactForm={handleClearAddContactForm}
          setStopRealTimeChange={setStopRealTimeChange}
          userTimeInput={userTimeInput}
          setUserTimeInput={setUserTimeInput}
          userDateInput={userDateInput}
          setUserDateInput={setUserDateInput}
          checkLocationPermission={checkLocationPermission}
          setTheirPark={setTheirPark}
          theirPark={theirPark}
          callSignRef={callSignRef}
          getUserCountryFromGrid={getUserCountryFromGrid}
          contactSuccess={contactSuccess}
          endSuccessAnimation={endSuccessAnimation}
          spottingCopied={spottingCopied}
          debounceChangeFrequencyCATControl={debounceChangeFrequencyCATControl}
          freqFromRadio={freqFromRadio}
          setDuplicateInfo={setDuplicateInfo}
          logBookSerialNumber={logBookSerialNumber}
          setLogBookSerialNumber={setLogBookSerialNumber}
          {...{ selectedSecondaryField, setSelectedSecondaryField }}
        />
      );
    } else {
      return (
        <DefaultCompact
          setValues={setValues}
          values={values}
          errors={errors}
          handleChange={handleChange}
          touched={touched}
          submitForm={submitForm}
          initialData={initialData}
          setTouched={setTouched}
          onLogPress={onLogPress}
          callSignBlurPopupHandler={callSignBlurPopupHandler}
          userMode={userMode}
          setUserMode={setUserMode}
          userBand={userBand}
          setUserBand={setUserBand}
          setUserCallSign={setUserCallSign}
          selectedLogBook={selectedLogBook}
          isSavingOnLoading={isSavingOnLoading}
          date={userDateInput}
          setDate={setUserDateInput}
          setTime={setTime}
          currentTime={currentTime}
          currentDate={currentDate}
          getUserFromHamApiData={getUserFromHamApiData}
          isCallSignDataLoading={isCallSignDataLoading}
          callSignData={
            (callSignData as any)?.callSignMatch === false
              ? "NOT_FOUND"
              : callSignData
          }
          handleClearAddContactForm={handleClearAddContactForm}
          setStopRealTimeChange={setStopRealTimeChange}
          userTimeInput={userTimeInput}
          setUserTimeInput={setUserTimeInput}
          userDateInput={userDateInput}
          setUserDateInput={setUserDateInput}
          checkLocationPermission={checkLocationPermission}
          setTheirPark={setTheirPark}
          theirPark={theirPark}
          callSignRef={callSignRef}
          getUserCountryFromGrid={getUserCountryFromGrid}
          contactSuccess={contactSuccess}
          endSuccessAnimation={endSuccessAnimation}
          spottingCopied={spottingCopied}
          debounceChangeFrequencyCATControl={debounceChangeFrequencyCATControl}
          freqFromRadio={freqFromRadio}
          setDuplicateInfo={setDuplicateInfo}
          logBookSerialNumber={logBookSerialNumber}
          setLogBookSerialNumber={setLogBookSerialNumber}
          {...{ selectedSecondaryField, setSelectedSecondaryField }}
        />
      );
    }
  };

  const { openPopup, closePopup } = useProgrammaticPopup();

  const openPopupWindow = () => {
    openPopup(
      <section className="relative w-screen h-screen flex overflow-hidden dark:bg-[#151541] bg-white">
        <img
          src={Images.SpaceBg}
          className="absolute top-0 left-0 z-0 h-full w-full object-cover"
          alt="background"
        />
        <ContactSpotting
          callSignBlurPopupHandler={callSignBlurPopupHandler}
          getUserFromHamApiData={getUserFromHamApiData}
          setValues={formikRef.current ? formikRef.current.setValues : null}
          setMode={setUserMode}
          setBand={setUserBand}
          setTheirPark={setTheirPark}
          setSortConfig={setSortConfig}
          filters={filters}
          setFilters={setFilters}
          applyChanges={applyChanges}
          filterCountry={filterCountry}
          setFilterCountry={setFilterCountry}
          filterRegion={filterRegion}
          setFilterRegion={setFilterRegion}
          filterBand={filterBand}
          setFilterBand={setFilterBand}
          filterMode={filterMode}
          setFilterMode={setFilterMode}
          spottedCountry={spottedCountry}
          setSpottedCountry={setSpottedCountry}
          spottedRegion={spottedRegion}
          setSpottedRegion={setSpottedRegion}
          operatingNearMe={operatingNearMe}
          setOperatingNearMe={setOperatingNearMe}
          dxOnly={dxOnly}
          setDxOnly={setDxOnly}
          spottedNearMe={spottedNearMe}
          setSpottedNearMe={setSpottedNearMe}
          potaOnly={potaOnly}
          setPotaOnly={setPotaOnly}
          wrlOnly={wrlOnly}
          setWrlOnly={setWrlOnly}
          logged={logged}
          setLogged={setLogged}
          showAddSpotCTA={false}
          debounceChangeFrequencyCATControl={debounceChangeFrequencyCATControl}
        />
      </section>,
      "Spotting"
    );
  };

  const openMapPopup = () => {
    openPopup(
      <section className="relative w-screen h-screen flex overflow-hidden">
        <img
          src={Images.SpaceBg}
          className="absolute top-0 left-0 z-0 h-full w-full object-cover"
          alt="background"
        />
        <ContactMap
          className="w-full h-[500px] relative"
          containerClassName="w-full"
          showControls={false}
          showShare={false}
        />
      </section>,
      "QSO Map"
    );
  };

  const openStatsPopup = () => {
    openPopup(
      <section className="relative w-screen h-screen flex overflow-hidden">
        <div className="relative z-10 w-full h-full overflow-y-auto carousel-scrollbar">
          <div className="w-[80%] md:w-[60%] mx-auto h-auto max-h-full py-4">
            <LogbookStatsTab
              isPopup={true}
              onBandModeChartReady={() => {
                // Force another resize event after charts are ready
                setTimeout(() => {
                  window.dispatchEvent(new Event("resize"));
                }, 100);
              }}
            />
          </div>
        </div>
      </section>,
      "LogBook Stats"
    );
  };

  return (
    <div className="h-screen w-full">
      <div className="flex flex-col lg:flex-row w-full h-full gap-4">
        <div className="w-full lg:w-[50%]">
          {getLogbookImportError()}
          <LogBookQuickSettings />
          <Formik
            initialValues={initialData}
            validationSchema={
              selectedLogBook?.logbookStyle === Templates.POTA
                ? CustomValidation.AddPOTAContactValidationSchema
                : CustomValidation.AddContactValidationSchema
            }
            onSubmit={async (values, { setTouched, setValues }) => {
              const cb = () => {
                callSignRef?.current?.focus();
                resetHandler(initialData, setTouched, setValues);
              };
              saveLogBookContact(values, cb);
            }}
            innerRef={formikRef}
          >
            {({
              errors,
              touched,
              handleChange,
              submitForm,
              values,
              setValues,
              setTouched,
            }) => {
              const onLogPress = () => {
                resetHandler = handleClearAddContactForm;
                submitForm();
              };
              const handleKeyDown = (event: any) => {
                if (event.key === "Enter") {
                  event.preventDefault();

                  onLogPress();
                }
              };
              return (
                <Form
                  className="flex flex-col md:flex-row justify-between w-full dark:bg-[#151541] bg-white px-3 py-5"
                  onKeyDown={handleKeyDown}
                >
                  {getLoggerLayout(
                    setValues,
                    values,
                    errors,
                    handleChange,
                    touched,
                    submitForm,
                    initialData,
                    setTouched,
                    onLogPress
                  )}
                </Form>
              );
            }}
          </Formik>
          <AddContactLogPopup
            data={{
              duplicateInfo: duplicateInfo,
              addContactLogPopupSummary,
              userProfile: guestUserProfile,
              theirCallSign: formikRef?.current?.values?.theirCallsign || "",
              flagCode: formikRef?.current?.values?.flagCode || "",
            }}
          />

          {/* LOGBOOK STATS */}
          <LogBookStats />

          {searchParams.get("contest_id") || contestDetail?.id ? (
            <>
              <div className="flex flex-col sm:flex-row  justify-between w-full mt-4">
                <div className="font-[Play] dark:text-white text-[#2b2e48] text-lg font-bold mb-3">
                  Total Points: &nbsp;
                  <span className="text-[#FF6F46]">
                    {selectedLogBook?.isWFDLogBook
                      ? selectedLogBook?.contestTotalPoints
                      : selectedLogBook?.contactCount}
                  </span>
                </div>
                <div className="font-[Play] dark:text-white text-[#2b2e48] text-lg font-bold mb-3">
                  Total QSO: &nbsp;
                  <span className="text-[#FF6F46]">
                    {selectedLogBook?.contactCount || 0}
                  </span>
                </div>
                <div className="font-[Play] dark:text-white text-[#2b2e48] text-lg font-bold mb-3">
                  Current Ranking: &nbsp;
                  <span className="text-[#FF6F46]">{userContestRanking}</span>
                </div>
              </div>
              <div className="flex justify-center items-center p-4">
                <div className="w-[220px]">
                  <BorderButtonSecondary
                    isLoading={false}
                    text={"View Scoring Breakdown"}
                    onClick={() => {
                      globalNavigate(
                        `${CONTEST}/user-logbook-detail?contest_name=${contestDetail?.name}&contest_id=${contestDetail?.id}&user_id=${userProfile?.uid}&tab=entries`
                      );
                    }}
                    childClassName="text-sm"
                  />
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className="w-full lg:w-[50%] lg:h-full lg:overflow-y-auto">
          <TopTabNav
            setSelectedTabIndex={(tab) => {
              const newSearchParams = new URLSearchParams(searchParams);
              newSearchParams.set("contact-tab", tab);
              setSearchParams(newSearchParams);
            }}
            selectedTabIndex={selectedTabIndex}
            firstIndexText={`Log(${selectedLogBook?.contactCount || 0})`}
            secondIndexText={`Map`}
            thirdIndexText={`Spotting`}
            fourthIndexText={`Stats`}
            fifthIndexText={
              contestDetail?.objectives?.length ? "Objectives" : ""
            }
            showExternal={true}
            openSpottingPopup={openPopupWindow}
            openMapPopup={openMapPopup}
            openStatsPopup={openStatsPopup}
          />
          {getTabLayout()}
        </div>
      </div>
    </div>
  );
}

export default LogContacts;
