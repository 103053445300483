import DropDown from "components/DropDown/DropDown";
import { mapDownDown, mapImported } from "constants/Config";
import { ContactMapControlsProps } from "types/Component";
import BorderButtonSquare from "components/Button/BorderButtonSquare";
import { useDispatch, useSelector } from "react-redux";
import { getContactMapModalStatus } from "store/Reducers/config.reducer";
import { setContactMapModalStatus, setShareMapModalType } from "store/Actions/config.actions";
import useNotification from "hooks/notification.hook";

function ContactMapControls({
  setShowImported,
  setShowLimit,
  showLimit,
  captureSnapshot,
  shareMapModalType
}: ContactMapControlsProps) {
  const dispatch = useDispatch();
  const contactMapModalStatus = useSelector(getContactMapModalStatus);

  const handleModalStatus = (status: boolean) => {
    dispatch(setContactMapModalStatus(status));
  };

  return (
    <div className="relative w-full">
      <div className="flex items-center justify-between my-3">
        <div className="flex">
          <BorderButtonSquare
            text={"Share Map"}
            onClick={() => {
              dispatch(setShareMapModalType(shareMapModalType!));
              if (!contactMapModalStatus) {
                handleModalStatus(true);
              } else if (captureSnapshot) {
                captureSnapshot();
              }
            }}
            className="mb-0 sm:mb-0 md:mb-0 dark:bg-[#010A41] bg-[#17f9da]"
          >
            {/* <img onClick={(e) => { e.stopPropagation(); alert(); }} className="h-5 w-5" src={Icons.External} alt="" /> */}
          </BorderButtonSquare>
        </div>
        <div className="flex items-center">
          <div className="flex mr-2 items-center">
            <p className="text-black dark:text-[#17F9DA] font-[Play] font-sm">
              Show Contacts
            </p>
            <DropDown
              list={mapDownDown}
              value={showLimit}
              onChange={(e: any) => {
                setShowLimit(e.target.value ? parseInt(e.target.value) : 200);
              }}
              width={"w-[40%] sm:w-[20%] md:w-[80px]"}
              className="ml-2"
            />
          </div>
          <div className="flex mr-2 items-center">
            <p className="text-black dark:text-[#17F9DA] font-[Play] font-sm">
              Show Imported
            </p>
            <DropDown
              placeHolder={"Yes"}
              list={mapImported}
              onChange={(e: any) => {
                setShowImported(e.target.value ? false : true);
              }}
              width={"w-[40%] sm:w-[20%] md:w-[80px]"}
              className="ml-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactMapControls;
