import { useField } from "formik";
import { useEffect, useState, useMemo } from "react";
import {
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { RiInformationLine } from "react-icons/ri";
import { TitledRegionPickerProps } from "types/Component";
import { LocationMappings } from "constants/LocationMappings";

function TitledRegionPicker({
  placeHolder,
  name,
  type,
  text,
  setText,
  className,
  width,
  title,
  error,
  props,
  value,
  tabindex,
  country,
  info,
  containerClass,
  valueType
}: TitledRegionPickerProps) {
  const [infoOpen, setInfoOpen] = useState(false);
  const [regionsAvailable, setRegionsAvailable] = useState(false);
  const [field] = useField({
    name: name ? name : "",
  });
  const [regionMappings, setRegionMappings] = useState<{
    codeToName: Record<string, string>;
    nameToCode: Record<string, string>;
  }>({ codeToName: {}, nameToCode: {} });
  
  // Find the appropriate normalized value based on mappings
  const normalizedValue = useMemo(() => {
    if (!value || !country || Object.keys(regionMappings.codeToName).length === 0) {
      return value || "";
    }
    
    // If the value is a code (typically 2 characters), convert to name
    if (value.length <= 3 && regionMappings.codeToName[value.toUpperCase()]) {
      return regionMappings.codeToName[value.toUpperCase()];
    }
    
    // If the value might be a name but in wrong case/format
    for (const [fullName, code] of Object.entries(regionMappings.nameToCode)) {
      if (fullName.toLowerCase() === value.toLowerCase()) {
        return fullName; // Return with correct casing
      }
    }
    
    return value;
  }, [value, country, regionMappings]);

  useEffect(() => {
    if (country) {
      // Find country data based on country name or code
      const effectiveCountry = LocationMappings?.[country as string] || country;
      const countryData = CountryRegionData.find(
        ([name, code]) => name === effectiveCountry || code === effectiveCountry
      );

      if (countryData && countryData[2]) {
        // Create mappings between region codes and names
        const codeToName: Record<string, string> = {};
        const nameToCode: Record<string, string> = {};
        
        const regions = countryData[2].split("|");
        regions.forEach((region) => {
          const [name, code] = region.split("~");
          if (name && code) {
            codeToName[code] = name;
            nameToCode[name] = code;
          }
        });
        
        setRegionMappings({ codeToName, nameToCode });
        setRegionsAvailable(regions.length > 0);
      } else {
        setRegionsAvailable(false);
        setRegionMappings({ codeToName: {}, nameToCode: {} });
      }
    } else {
      setRegionsAvailable(true); // Default to true if no country is selected
      setRegionMappings({ codeToName: {}, nameToCode: {} });
    }
  }, [country]);

  return (
    <div
      className={`${width ? width : "w-full"} ${
        containerClass ? containerClass : ""
      }`}
    >
      <div className="flex items-center">
        <label className="font-[Barlow] font-bold text-sm dark:text-white text-[#2b2e48] truncate">
          {title}
        </label>

        {info ? (
          <div
            onMouseEnter={info ? () => setInfoOpen(true) : () => {}}
            onMouseLeave={info ? () => setInfoOpen(false) : () => {}}
          >
            <RiInformationLine color="#999" className="ml-1" />
          </div>
        ) : null}
      </div>
      {regionsAvailable ? (
        <RegionDropdown
          {...field}
          name={name}
          country={country ? LocationMappings?.[country as string] ? LocationMappings?.[country as string] : country : ""}
          value={normalizedValue}
          onChange={(txt) => setText(txt)}
          classes={`rcrs-region h-8 bg-transparent px-3 text-sm font-[Barlow] text-[#999] mt-1.5 w-full ${
            className ? className : ""
          } ${error ? "!border !border-orange-700 !border-solid" : ""}  border border-[#dcdcdd] dark:border-none`}
          {...valueType ? {valueType}:{}}
        />
      ) : (
        <input
          {...field}
          onChange={(event) => {
            setText(event.target.value);
          }}
          value={normalizedValue}
          name={name}
          title={title}
          placeholder={"Their State"}
          tabIndex={tabindex}
          className={
            `h-8 bg-transparent px-3 text-sm font-[Barlow] text-[#999] mt-1.5 w-full
            ${className ? className : ""}`
          }
        />
      )}

      {info && infoOpen ? (
        <div className="absolute top-[50%] left-0 right-0 z-[1000] px-[4px] py-[5px] lg:px-[6px] xl:px-[10px]  text-[12px] font-[Play] bg-[#524A6F] text-white">
          {info}
        </div>
      ) : null}
    </div>
  );
}

export default TitledRegionPicker;
