import { db, auth, storage } from "firebase-config";
import { UserProfile } from "types/Models";
import { UserDataTable } from "constants/Collections";
import { doc, setDoc } from "firebase/firestore";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable,
  uploadString,
} from "firebase/storage";
import { generateUniqueFileName } from "helpers/Utils";

interface UploadServiceType {
  uploadImgFile(profileData: UserProfile): Promise<boolean>;
  handleUploadImage(data: any): Promise<string | null>;
  handleUploadAwardImage(data: any): Promise<string | null>;
  handleUploadContestImage(data: any, route?: string): Promise<string | null>;
  handleUploadImageString(string: string): Promise<string | null>;
  handleAdifFile(
    data: any,
    name: string
  ): Promise<{ original: string; duplicate: string } | null>;
}

const UploadService: UploadServiceType = {
  uploadImgFile: async (profileData) => {
    try {
      const uid: string | undefined = auth.currentUser?.uid;
      if (uid) {
        const cityRef = doc(db, UserDataTable, uid);
        await setDoc(cityRef, profileData, { merge: true });
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },
  handleUploadImage: async (file) => {
    return new Promise(async (resolve, reject) => {
      const storageRef = ref(
        storage,
        `/profilePictures/${generateUniqueFileName(file.name)}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const percent = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
          // setPercent(percent);
        },
        (err) => {
          // setPercent(0);

          reject(null);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((url: string) => {
              resolve(url);
            })
            .catch((err) => {
              reject(null);
            });
          // setPercent(0);
        }
      );
    });
  },
  handleUploadAwardImage: async (file) => {
    return new Promise(async (resolve, reject) => {
      const storageRef = ref(
        storage,
        `/awardsImages/${generateUniqueFileName(file.name)}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const percent = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
          // setPercent(percent);
        },
        (err) => {
          // setPercent(0);

          reject(null);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((url: string) => {
              resolve(url);
            })
            .catch((err) => {
              reject(null);
            });
          // setPercent(0);
        }
      );
    });
  },
  handleUploadContestImage: async (file, route) => {
    return new Promise(async (resolve, reject) => {
      const storageRef = ref(
        storage,
        `/${route ? route : "contest"}/${file.name}_${new Date().getTime()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const percent = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
          // setPercent(percent);
        },
        (err) => {
          // setPercent(0);

          reject(null);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((url: string) => {
              resolve(url);
            })
            .catch((err) => {
              reject(null);
            });
          // setPercent(0);
        }
      );
    });
  },
  handleUploadImageString: async (file) => {
    return new Promise(async (resolve, reject) => {
      const storageRef = ref(storage, `/screenshot/${new Date().getTime()}`);

      uploadString(storageRef, file, "data_url")
        .then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then((url) => {
              resolve(url);
            })
            .catch((err) => {
              reject(null);
            });
        })
        .catch((e: any) => {
          reject(null);
        });
    });
  },
  handleAdifFile: async (file, name) => {
    try {
      // Create references for the original and duplicate files
      const storageRefOriginal = ref(storage, `/adiFiles/${name}`);
      const duplicateName = `COPY_${name}`;
      const storageRefDuplicate = ref(storage, `/adiFiles/${duplicateName}`);

      // Upload both files concurrently
      const [fileUploadOriginal, fileUploadDuplicate] = await Promise.all([
        uploadBytes(storageRefOriginal, file),
        uploadBytes(storageRefDuplicate, file),
      ]);

      // Get download URLs for both files
      const [downloadUrlOriginal, downloadUrlDuplicate] = await Promise.all([
        getDownloadURL(fileUploadOriginal.ref),
        getDownloadURL(fileUploadDuplicate.ref),
      ]);

      // Return both URLs
      return {
        original: downloadUrlOriginal,
        duplicate: downloadUrlDuplicate,
      };
    } catch (e) {
      console.log("UPLOAD FAILED : ", e);
      return null;
    }
  },
};
export default UploadService;
