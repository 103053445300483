export const ClassCodes: any = {
  A: "Advanced",
  E: "Amateur Extra",
  G: "General",
  N: "Novice",
  P: "Technician Plus",
  T: "Technician",
};

export const LOOKUP_PROVIDERS = {
  WRL: "WRL",
  QRZ: "QRZ",
}

export  const LOOKUP_OPTIONS = [
  {
    label: LOOKUP_PROVIDERS.QRZ,
    value: LOOKUP_PROVIDERS.QRZ,
  },
  {
    label: `Default ${LOOKUP_PROVIDERS.WRL}`,
    value: LOOKUP_PROVIDERS.WRL,
  },
]