import React, { FC, useState } from "react";
import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { LOOKUP_OPTIONS, LOOKUP_PROVIDERS } from "constants/Callsign";
import useQRZLookup from "hooks/useQRZLookup.hook";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";

type ModalProps = {
  onClose: () => void;
};

export const LookupModal: FC<ModalProps> = ({ onClose }) => {
  const { selectedLogbook, saveLogbookSettings } =
    useLogbookSetting();

  const [defaultLookup, setDefaultLookup] = useState<string>(
    selectedLogbook?.defaultLookup || LOOKUP_PROVIDERS.WRL
  );


  const { qrzSessionKey } = useQRZLookup();

  const onSaveClickHandler = () => {
    if (defaultLookup === LOOKUP_PROVIDERS.QRZ && !qrzSessionKey){
      showToast({
        message: "Please authenticate with QRZ first",
        type: ToastTypes.WARN,
      });
      return;
    }

    saveLogbookSettings({ defaultLookup });
    onClose();
  };

  return (
    <section className="absolute w-full h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[20%] lg:px-[25%] overflow-scroll">
      <div className=" w-full ">
        <div className="w-full mt-20 bg-gradient-to-r from-[#37A4BA] via-[#496780] to-[#CF0971] p-[1px]">
          <div className="flex flex-col w-full dark:bg-[#151541] bg-white dark:text-white text-[#2b2e48] font-[Play] relative px-[10%] py-[10%] gap-8">
            <div className="flex w-full flex-wrap relative">
              <LogbookSettingDropdown
                list={LOOKUP_OPTIONS}
                title="Default Lookup Service:"
                value={defaultLookup || ""}
                setValue={(value) => {
                    setDefaultLookup(value);
                }}
                className="w-[80%] sm:w-[60%] md:w-[60%]"
              />
            </div>

            <div className="flex w-full justify-center">
              <BorderButtonSecondary
                // isLoading={loading || logbookSettingLoading}
                text={"Save"}
                onClick={onSaveClickHandler}
                childClassName="text-sm"
                className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3"
              />
            </div>
            <button
              className="absolute -top-2 -right-3 md:-right-3 cursor-pointer"
              onClick={onClose}
            >
              <img src={Icons.GlowingClose} className="h-8 w-8 dark:filter-none dark:invert-0" alt="close" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
