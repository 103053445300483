import Icons from "assets/Icons";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import { getShortDistanceUnit } from "helpers/Utils";
import { convertDistance } from "helpers/Utils";
import { useLogBookStats } from "hooks/useLogBookStats.hook";
import { useUserSettings } from "hooks/useUserSettings.hook";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getSelectedLogBookSelector } from "store/Reducers/logBook.reducer";

export const LogBookStats = () => {
  const { getLogBookAnalytics, logBookAnalytics, loading } = useLogBookStats();
  const selectedLogbook = useSelector(getSelectedLogBookSelector);
  const { settings } = useUserSettings();

  useEffect(() => {
    if (selectedLogbook?.uid && selectedLogbook?.id) {
      getLogBookAnalytics(selectedLogbook?.id, selectedLogbook?.uid);
    }
  }, [selectedLogbook?.id, selectedLogbook?.uid, selectedLogbook?.contactCount]);

  if (!logBookAnalytics && !loading) return null;
  const analytics = logBookAnalytics;

  if(loading){
    return <LoadingSkeleton />;
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 dark:text-white text-[#2b2e48] w-full justify-between gap-4 mt-6 text-xs">
      <div className="flex flex-1 flex-row gap-4 px-6 py-3 dark:bg-[#151541] bg-white rounded-[5px] items-center">
        <img src={Icons.QsoPerMinIcon} alt="icon" className="invert dark:filter-none dark:invert-0" />
        {loading ? (
          <div className="flex flex-col gap-0">
            <ActivityIndicator size={12} />
          </div>
        ) : (
          <div className="flex flex-col gap-0">
            <div className="dark:text-[#17F9DA] text-[#1e40af] font-Play font-bold">
              {analytics?.summary?.averageQSOsPerHour?.toFixed(2)} QSO / hour
            </div>
            <div className="flex flex-row gap-1 font-Play font-bold">
              <div className="dark:text-[#17F9DA] text-[#1e40af]">{analytics?.summary?.lastQSO?.timeSince}</div>{" "}
              since last QSO
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-1 flex-row gap-4 px-6 py-3 dark:bg-[#151541] bg-white rounded-[5px] items-center">
        <img src={Icons.LocationPinIcon} alt="icon" className="invert dark:filter-none dark:invert-0" />
        {loading ? (
          <div className="flex flex-col gap-0">
            <ActivityIndicator size={12} />
          </div>
        ) : (
          <div className="flex flex-col gap-0">
            <div className="flex flex-row font-Play font-bold gap-1">
              DX Entities worked:<div className="dark:text-[#17F9DA] text-[#1e40af]">{analytics?.entities?.total}</div>
            </div>
            <div className="flex flex-row font-Play font-bold gap-1">
              Gridsquares worked:<div className="dark:text-[#17F9DA] text-[#1e40af]">{analytics?.gridsquares?.total}</div>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-1 flex-row gap-4 px-6 py-3 dark:bg-[#151541] bg-white rounded-[5px] items-center">
        <img src={Icons.DistanceIcon} alt="icon" className="invert dark:filter-none dark:invert-0" />
        {loading ? (
          <div className="flex flex-col gap-0">
            <ActivityIndicator size={12} />
          </div>
        ) : (
          <div className="flex flex-col gap-0">
            <div className="font-Play font-bold">Longest Contact</div>
            <div className="flex flex-row gap-1 font-Play font-bold">
              <div className="dark:text-[#17F9DA] text-[#1e40af]">
                {analytics?.distance?.max?.distance && convertDistance(analytics?.distance?.max?.distance, settings.distanceUnit).toFixed(1)}
              </div>{" "} {getShortDistanceUnit(settings.distanceUnit)}{" "}
              {analytics?.distance?.max?.callsign}
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-1 flex-row gap-4 px-6 py-3 dark:bg-[#151541] bg-white rounded-[5px] items-center">
        <img src={Icons.RankIcon} alt="icon" className="invert dark:filter-none dark:invert-0" />
        {loading ? (
          <div className="flex flex-col gap-0">
            <ActivityIndicator size={12} />
          </div>
        ) : (
          <div className="flex flex-col gap-0">
            <div className="flex flex-row font-Play font-bold gap-1">
              Your QSOs Today:
              {/* <div className="dark:text-[#17F9DA] text-[#1e40af]">-</div> */}
            </div>
            <div className="flex flex-row gap-1 font-Play font-bold">
              <div className="dark:text-[#17F9DA] text-[#1e40af]">{analytics?.summary?.totalQSOsToday || 0} QSOs</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export const LoadingSkeleton = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 dark:text-white text-[#2b2e48] w-full justify-between gap-4 mt-6 text-xs animate-pulse">
      {/* QSO Rate Card */}
      <div className="flex flex-1 flex-row gap-4 px-6 py-3 dark:bg-[#151541] bg-white rounded-[5px] items-center">
        <div className="w-6 h-6 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
        <div className="flex flex-col gap-2">
          <div className="h-4 w-24 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
          <div className="h-4 w-32 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
        </div>
      </div>

      {/* Entities & Gridsquares Card */}
      <div className="flex flex-1 flex-row gap-4 px-6 py-3 dark:bg-[#151541] bg-white rounded-[5px] items-center">
        <div className="w-6 h-6 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <div className="h-4 w-28 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
            <div className="h-4 w-8 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
          </div>
          <div className="flex items-center gap-2">
            <div className="h-4 w-32 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
            <div className="h-4 w-8 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
          </div>
        </div>
      </div>

      {/* Longest Contact Card */}
      <div className="flex flex-1 flex-row gap-4 px-6 py-3 dark:bg-[#151541] bg-white rounded-[5px] items-center">
        <div className="w-6 h-6 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
        <div className="flex flex-col gap-2">
          <div className="h-4 w-24 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
          <div className="flex items-center gap-2">
            <div className="h-4 w-16 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
            <div className="h-4 w-20 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
          </div>
        </div>
      </div>

      {/* QSOs Today Card */}
      <div className="flex flex-1 flex-row gap-4 px-6 py-3 dark:bg-[#151541] bg-white rounded-[5px] items-center">
        <div className="w-6 h-6 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
        <div className="flex flex-col gap-2">
          <div className="h-4 w-28 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
          <div className="h-4 w-20 bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 rounded"></div>
        </div>
      </div>
    </div>
  );
};

