import { call, put, take, takeLatest } from "redux-saga/effects";

import { authErrorHandler } from "helpers/ErrorHandler/auth";
import { UserProfile } from "types/Models";
import {
  LEADER_BOARD_BY_WEEK,
  LEADER_BOARD_MODE_LOADING,
  LOADING_DISTANCE_LEADERBOARD,
  LOADING_DX_LEADERBOARD,
  LOADING_LEADERBOARD_STATUS,
  LOADING_LEADERBOARD_STATUS_BY_MODE,
  LOADING_STATE_LEADER_BOARD,
} from "store/Actions/loading.action";
import {
  GET_USERS_DATA_FOR_LEADERBOARD,
  GET_USERS_DATA_FOR_LEADERBOARD_WITH_MODE,
  SAVE_USERS_DATA_FOR_LEADERBOARD,
  SAVE_USERS_DATA_FOR_LEADERBOARD_WITH_MODE,
  GET_LEADER_BOARD_BY_WEEK,
  SET_WEEK_LEADERS,
  GET_LEADER_BOARD_BY_WEEK_BY_MODE,
  SET_WEEK_LEADER_MODE,
  GET_DISTANCE_LEADERBOARD,
  SET_DISTANCE_LEADERBOARD,
  GET_DX_LEADERBOARD,
  SET_DX_LEADERBOARD,
  GET_STATE_LEADER,
  SET_STATE_LEADER,
} from "store/Actions/leaderBoard.action";
import LeaderBoardService from "services/leaderBoard.service";
import {
  getDXLeaderBoard,
  getStateLeaderBoard,
  getUserDistanceLeaderBoard,
  getUserLeaderBoardByModeWeek,
  getUserLeaderBoardDataByWeek,
} from "firebase-config";

//Workers
export function* workerGetLeaderBoardData() {
  yield put({
    type: LOADING_LEADERBOARD_STATUS,
    payload: true,
  });
  try {
    const usersData: UserProfile[] | string = yield call(() =>
      LeaderBoardService.getUserLeaderBoardData()
    );
    if (usersData) {
      yield put({
        type: SAVE_USERS_DATA_FOR_LEADERBOARD,
        payload: usersData,
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_LEADERBOARD_STATUS,
    payload: false,
  });
}

export function* workerGetLeaderBoardUsersDataByMode(payload: string) {
  yield put({
    type: LOADING_LEADERBOARD_STATUS_BY_MODE,
    payload: true,
  });
  try {
    const usersData: UserProfile[] | string = yield call(() =>
      LeaderBoardService.getUserLeaderBoardDataByMode(payload)
    );
    if (usersData) {
      yield put({
        type: SAVE_USERS_DATA_FOR_LEADERBOARD_WITH_MODE,
        payload: usersData,
      });
    } else if (usersData === null) {
      yield put({
        type: SAVE_USERS_DATA_FOR_LEADERBOARD_WITH_MODE,
        payload: [],
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_LEADERBOARD_STATUS_BY_MODE,
    payload: false,
  });
}

export function* workerGetLeaderBoardByWeek(action: { payload: any }) {
  yield put({
    type: LEADER_BOARD_BY_WEEK,
    payload: true,
  });
  try {
    const usersData: { data: any } = yield call(() =>
      getUserLeaderBoardDataByWeek(action.payload)
    );

    if (usersData) {
      yield put({
        type: SET_WEEK_LEADERS,
        payload: usersData.data || [],
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LEADER_BOARD_BY_WEEK,
    payload: false,
  });
}

export function* workerGetLeaderBoardModeByWeek(action: { payload: any }) {
  yield put({
    type: LEADER_BOARD_MODE_LOADING,
    payload: true,
  });
  try {
    const userData: { data: any } = yield call(() =>
      getUserLeaderBoardByModeWeek({
        week: action.payload.week,
        mode: action.payload.mode,
        state: action.payload.state,
        country: action.payload.country,
      })
    );

    yield put({
      type: SET_WEEK_LEADER_MODE,
      payload: userData.data,
    });
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LEADER_BOARD_MODE_LOADING,
    payload: false,
  });
}

export function* workerGetDistanceLeaderBoard(action: { payload: number }) {
  yield put({
    type: LOADING_DISTANCE_LEADERBOARD,
    payload: true,
  });
  try {
    const usersData: { data: any } = yield call(() =>
      getUserDistanceLeaderBoard(action.payload)
    );

    yield put({
      type: SET_DISTANCE_LEADERBOARD,
      payload: usersData.data,
    });
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_DISTANCE_LEADERBOARD,
    payload: false,
  });
}

export function* workerGetDXLeaderBoard(action: { payload: any }) {
  yield put({
    type: LOADING_DX_LEADERBOARD,
    payload: true,
  });
  try {
    const usersData: { data: any } = yield call(() =>
      getDXLeaderBoard(action.payload)
    );

    yield put({
      type: SET_DX_LEADERBOARD,
      payload: usersData.data,
    });
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_DX_LEADERBOARD,
    payload: false,
  });
}

export function* workerGetStateLeader(payload: any) {
  yield put({
    type: LOADING_STATE_LEADER_BOARD,
    payload: true,
  });
  try {
    const usersData: { data: any } = yield call(() =>
      getStateLeaderBoard(payload)
    );

    yield put({
      type: SET_STATE_LEADER,
      payload: usersData.data,
    });
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_STATE_LEADER_BOARD,
    payload: false,
  });
}

//Watchers
export function* watchGetLeaderBoardData() {
  while (true) {
    yield take(GET_USERS_DATA_FOR_LEADERBOARD);
    yield call(workerGetLeaderBoardData);
  }
}

export function* watchGetUsersDataForLeaderBoardByMode() {
  while (true) {
    const { payload } = yield take(GET_USERS_DATA_FOR_LEADERBOARD_WITH_MODE);
    yield call(workerGetLeaderBoardUsersDataByMode, payload);
  }
}

export function* watchGetLeaderBoardByWeek() {
  yield takeLatest(GET_LEADER_BOARD_BY_WEEK as any, workerGetLeaderBoardByWeek);
}

export function* watchGetLeaderBoardModeByWeek() {
  yield takeLatest(GET_LEADER_BOARD_BY_WEEK_BY_MODE as any, workerGetLeaderBoardModeByWeek);
}

export function* watchGetDistanceLeaderBoard() {
  yield takeLatest(GET_DISTANCE_LEADERBOARD as any, workerGetDistanceLeaderBoard);
}

export function* watchGetDXLeaderBoard() {
  yield takeLatest(GET_DX_LEADERBOARD as any, workerGetDXLeaderBoard);
}

export function* watchGetStateLeader() {
  while (true) {
    const { payload } = yield take(GET_STATE_LEADER);
    yield call(workerGetStateLeader, payload);
  }
}
