import { showToast } from "helpers/Toast";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QrzService from "services/qrz.service";
import {
  setQRZIntegrationData,
  setQRZIntegrationError,
  setQRZSessionKey,
} from "store/Actions/callsign.actions";
import {
  getQRZIntegrationDataSelector,
  getQRZIntegrationErrorSelector,
  getQRZSessionKeySelector,
} from "store/Reducers/callsign.reducer";
import { ToastTypes } from "types/Component";

function useQRZLookup() {
  const disptach = useDispatch();
  const qrzIntegrationData = useSelector(getQRZIntegrationDataSelector);
  const qrzError = useSelector(getQRZIntegrationErrorSelector);
  const qrzSessionKey = useSelector(getQRZSessionKeySelector);
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const initQrzIntegrationData = async (userId: string) => {
    fetchQRZIntegrationData(userId).then((data) => {
      disptach(setQRZIntegrationData(data));

      if (data?.username && data?.password) {
        initiateQRZSession(data.username, data.password);
      } else {
        disptach(setQRZSessionKey(""));
      }
    });
  };

  const initiateQRZSession = async (username: string, password: string) => {
    try {
      const sessionKey = await QrzService.authenticate(username, password);
      disptach(setQRZIntegrationError(""));
      disptach(setQRZSessionKey(sessionKey));
      disptach(
        setQRZIntegrationData({
          username,
          password,
        })
      );
    } catch (error: any) {
      disptach(setQRZIntegrationError(error?.message || "Invalid"));
      disptach(setQRZSessionKey(""));
      return null;
    }
  };

  const unlinkQRZIntegration = async () => {
    try {
      setLoading(true);
      await QrzService.storeQRZIntegrationData({ username: "", password: "" });
      disptach(setQRZIntegrationData(null));
      disptach(setQRZSessionKey(""));

      // await unlinkLookup();

      setLoading(false);
      setShowConfirmation(false);
      showToast({
        message: "QRZ integration unlinked",
        type: ToastTypes.SUCCESS,
      });
    } catch (error) {
      setLoading(false);
      showToast({
        message: "Failed to unlink QRZ integration",
        type: ToastTypes.WARN,
      });
      return null;
    }
  };

  const storeQRZIntegrationData = async (payload: any) => {
    try {
      // validate the payload
      if (!payload.username || !payload.password) {
        showToast({
          message: "Username and password are required",
          type: ToastTypes.WARN,
        });
        return null;
      }

      setLoading(true);
      disptach(setQRZIntegrationError(""));

      await QrzService.storeQRZIntegrationData(payload);

      await initiateQRZSession(payload.username, payload.password);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast({
        message: "Failed to store QRZ integration data",
        type: ToastTypes.WARN,
      });
      return null;
    }
  };

  const fetchQRZIntegrationData = async (userId: string) => {
    try {
      const data = await QrzService.fetchQRZIntegrationData(userId);
      return data;
    } catch (error) {
      showToast({
        message: "Failed to fetch QRZ integration data",
        type: ToastTypes.WARN,
      });
      return null;
    }
  };

  return {
    qrzIntegrationData,
    storeQRZIntegrationData,
    fetchQRZIntegrationData,
    initQrzIntegrationData,
    qrzError,
    qrzSessionKey,
    unlinkQRZIntegration,
    loading,
    showConfirmation,
    setShowConfirmation,
  };
}

export default useQRZLookup;
