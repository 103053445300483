import Icons from "assets/Icons";
import BorderButtonPrimary from "components/Button/BorderButtonPrimary";
import RouteLoading from "components/Loading/RouteLoading";
import { UserAvatar } from "components/UserAvatar";
import { ClassCodes } from "constants/Callsign";
import { findCountryCode } from "helpers/Utils";
import { useCallsign } from "hooks/callsign.hook";
import { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useParams } from "react-router-dom";
import { InviteModal } from "./InviteModal";

export const CallsignProfile = () => {
  let { callSignFromParam } = useParams();
  const { callsignSuggestionsData, fetchCallSignData, callsignDataLoading } = useCallsign();
  const [callsignData, setCallsignData] = useState<any>(null);

  const [showInviteModal, setShowInviteModal] = useState(false);

  useEffect(() => {
    if (callSignFromParam) {
      fetchCallSignData(callSignFromParam, "FirestoreDB");
    }
  }, [callSignFromParam]);
  
  useEffect(() => {
    if (callsignSuggestionsData && Array.isArray(callsignSuggestionsData) && callsignSuggestionsData.length > 0) {
      if (callSignFromParam && callsignSuggestionsData?.[0]?.callsign === callSignFromParam) {
        setCallsignData(callsignSuggestionsData?.[0] || null);
      } 
    }
  }, [callsignSuggestionsData]);

  if (callsignDataLoading) return <RouteLoading noBg />;

  if (!callsignData) {
    return (
      <div className="px-4 py-8 sm:p-8 lg:p-14 dashboardWrapper overflow-x-hidden">
        <p className="text-5xl text-white font-[Barlow] font-bold text-center mt-14">
          SORRY!
        </p>
        <p className="text-base text-white font-[Barlow] text-center">
          Unfortunately this user is not available.
        </p>
      </div>
    );
  }

  const renderInfo = (
    label: string,
    value: string | number,
    dir: "col" | "row" = "row"
  ) => {
    return (
      <div className={`flex flex-${dir} font-Barlow`}>
        <div className="flex-1 font-semibold text-[#17F9DA]">{label}</div>
        <div className="flex-[2]">{value}</div>
      </div>
    );
  };

  if (showInviteModal)
    return (
      <InviteModal
        theirCallsign={callsignData?.callsign}
        onClose={() => setShowInviteModal(false)}
      />
    );

  return (
    <div className="px-4 py-8 sm:p-8 lg:p-14 dashboardWrapper overflow-x-hidden">
      <div className="rounded-[20px] sm:rounded-[25px] md:rounded-[50px] bg-[#0B0B39] flex flex-col md:flex-row justify-between pl-5 sm:pl-6 pr-5 sm:pr-10">
        <div className="relative mt-3 flex flex-col items-center">
          <div className="absolute w-full flex justify-center">
            <img
              // className="w-full object-fill md:w-[196.8px] h-[295.2px] lg:w-[246px] lg:h-[369px]"
              className={`w-[170px] object-fill md:w-[196.8px] lg:w-[246px] h-[330px] lg:h-[311px]`}
              src={Icons.GradientRectanglePic}
              alt="bg"
            />
          </div>
          {/* <div className="bg-[#161638] h-[272px] w-[217px] lg:h-[340px] lg:w-[272px] rounded-[24px] flex flex-col items-center pt-8 lg:pt-11 relative mt-3.5"> */}
          <div
            className={`bg-[#161638] h-[308px] lg:h-[285px] w-[240px] lg:w-[272px] rounded-[24px] flex flex-col items-center pt-8 lg:pt-8 relative mt-3.5`}
          >
            <UserAvatar className="w-36 h-36 lg:w-44 lg:h-44" isCurrentUser />
            <p className="font-[Play] font-bold text-white bg-[#FFFFFF14] w-[194.4px] h-[41px] lg:w-[243px] flex justify-center items-center text-base mt-5">
              {ClassCodes[callsignData?.class]
                ? ClassCodes[callsignData?.class]
                : callsignData?.class}
            </p>

            <div className={`flex items-center mb-2  mt-[10px] `}>
              <p className="text-[13px] text-[#17F9DA] font-[Play] font-bold">
                Not a member yet
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center mt-7 mb-6">
            {/* send invite button here */}
          </div>
        </div>
        <div className="profileDesc pt-7 sm:pt-12 relative">
          <p className="font-[Barlow] text-white text-base lg:text-2xl">
            {callsignData?.callsign}
          </p>
          <h1 className="font-[Barlow] font-bold text-white text-xl lg:text-3xl">
            {callsignData.fullName}
          </h1>
          <div className="flex flex-col mt-8 mb-9 gap-2">
            {callsignData?.country ? (
              <div className="flex items-center mb-3 sm:mb-0 mr-0 sm:mr-5 xl:mr-16 items-center gap-2">
                {findCountryCode({ name: callsignData?.country || "" }) ? (
                  <ReactCountryFlag
                    countryCode={
                      findCountryCode({ name: callsignData.country || "" })
                        .alpha2
                    }
                    svg
                    style={{
                      width: "1.8em",
                      height: "1.8em",
                    }}
                    title={callsignData?.country || ""}
                  />
                ) : null}

                <p className="text-base xl:text-lg font-[Play] ml-2 font-bold text-white">
                  {`${callsignData?.country} - ${callsignData?.state}`}
                </p>
              </div>
            ) : null}

            <div className="flex flex-col text-white text-xs w-full sm:w-[80%] lg:w-[50%]">
              {renderInfo("Grid Square", callsignData?.grid)}
              {renderInfo("Latitude", callsignData?.latitude)}
              {renderInfo("Longitude", callsignData?.longitude)}
              {renderInfo(
                "Address",
                `${callsignData?.address} ${callsignData?.city}, ${callsignData?.state} ${callsignData?.zip}`
              )}
            </div>

            <div className="flex flex-row text-white text-xs w-full sm:w-[80%] lg:w-[50%] px-4 py-2 bg-white bg-opacity-10 gap-6">
              {renderInfo(
                "License Expiration Date",
                callsignData?.expiryDate,
                "col"
              )}
              {renderInfo("Status", callsignData?.status, "col")}
            </div>

            <div className="flex text-white font-Barlow mt-2">
              This user is not part of the world radio league!
            </div>
          </div>
          <div className="relative md:absolute md:top-11 right-0 lg:top-0 lg:relative xl:absolute xl:top-11 mb-10 xl:mb-0 h-[41px] w-full sm:w-[180px]">
            <BorderButtonPrimary
              className=""
              text="Invite to Join"
              onClick={() => setShowInviteModal(true)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
