import React, { useEffect } from "react";
import Header from "components/Header";
import TabNavGlowContest from "components/TabNavGlow/TabNavGlowContest";
import { contestTabs } from "constants/NavMenus";
import { useSearchParams } from "react-router-dom";
import ContestRules from "./ContestRules";
import ContestLeaderboard from "./ContestLeaderboard";
import ContestMap from "./ContestHome/ContestMap";
import useContest from "hooks/contest.hook";
import { globalNavigate } from "routes/GlobalRoutes";
import AddContactLogBook from "../LogBook/AddContactLogBook";
import RouteLoading from "components/Loading/RouteLoading";
import { getContestStatus } from "helpers/Utils";
import { getSelectedLogBookSelector } from "store/Reducers/logBook.reducer";
import { useDispatch, useSelector } from "react-redux";
import { CONTEST_STATUS } from "types/Functions";
import Hint from "components/Hint";
import ContestHome from "./ContestHome/ContestHome";
import ContestStatistics from "./ContestHome/ContestStatistics";
import ContestHeader from "./components/ContestHeader";
import { setContestContactData } from "store/Actions/contest.actions";

function ContestDetail() {
  let [searchParams, setSearchParams] = useSearchParams();
  const selectedLogBook = useSelector(getSelectedLogBookSelector);
  const dispatch = useDispatch();

  const {
    fetchContestDetail,
    contestDetail,
    contestStatus,
    contestantDetails,
    setSelectedLogbook,
    isContestantDetailLoading,
    fetchContestLeaderBoard,
    isContestDetailLoading,
    saveContestDetail,
    fetchContestContacts,
  } = useContest();

  useEffect(() => {
    const contestId = searchParams.get("contest_id");

    if (contestId) {
      fetchContestDetail(contestId);
      fetchContestLeaderBoard(contestId);
    } else {
      globalNavigate("/contest");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("contest_id")]);


  useEffect(() => {
    const contestId = searchParams.get("contest_id");

    if(searchParams.get("tab") === "contest_home" && contestId) {
      // Fetch every 1.5 minutes after initial load
      const intervalId = setInterval(() => {
        fetchContestDetail(contestId);
        fetchContestLeaderBoard(contestId);
      }, 60 * 1000); // 1.5 minutes in milliseconds
      
      // Cleanup: Unsubscribe the interval when the component unmounts or when `contestId` changes
      return () => {
        clearInterval(intervalId);
      };
    }

  },[searchParams]);

  useEffect(() => {
    if ( contestDetail && contestDetail?.id && !contestDetail?.isWFDContest) {
      fetchContestContacts(contestDetail?.id!);

      const intervalId = setInterval(() => {
        fetchContestContacts(contestDetail?.id!);
      }, 60 * 1000); // 1.5 minutes in milliseconds
      
      // Cleanup: Unsubscribe the interval when the component unmounts or when `contestId` changes
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [contestDetail?.id]);

  useEffect(() => {
    dispatch(setContestContactData([]));
    return () => {
      saveContestDetail(null);
    };
  }, []);

  useEffect(() => {
    if (contestStatus === CONTEST_STATUS.UPCOMING) {
      setSearchParams((prev: any) => {
        return {
          ...prev,
          tab: "contest_rules",
          contest_id: searchParams.get("contest_id"),
        };
      });
    } else {
      setSearchParams((prev: any) => {
        return {
          ...prev,
          tab: "contest_home",
          contest_id: searchParams.get("contest_id"),
        };
      });
    }
  }, [contestStatus]);

  const getLayout = () => {
    if (searchParams.get("tab") === "contest_home") {
      return contestDetail ? <ContestHome /> : null;
    } else if (searchParams.get("tab") === "contest_rules") {
      if (isContestDetailLoading) {
        return <RouteLoading noBg />;
      } else {
        return contestDetail ? (
          <ContestRules
            contestDetail={contestDetail}
            contestStatus={contestStatus}
          />
        ) : null;
      }
    } else if (searchParams.get("tab") === "real_time_leaderboard") {
      return contestDetail ? (
        <ContestLeaderboard
          contestDetail={contestDetail}
          contestStatus={contestStatus}
        />
      ) : null;
    } else if (searchParams.get("tab") === "real_time_map") {
      return (
        <div className="flex px-8 py-4 h-[100px]">
          <ContestMap
            contestId={contestDetail?.id || ""}
            containerClass="!flex-col !gap-8"
            hideCTA
            noOfContacts={50}
          />
        </div>
      );
    } else if (searchParams.get("tab") === "log_contest_contacts") {
      return (
        <div className="flex flex-col px-8">
          {getContestStatus(selectedLogBook?.contest) ===
          CONTEST_STATUS.UPCOMING ? (
            <div className="w-full flex justify-center">
              <Hint
                warning
                text={
                  "You are outside of the contest window. Only contacts logged during the designated contest time will count for the contest."
                }
              />
            </div>
          ) : null}
          <AddContactLogBook />
        </div>
      );
    } else if (searchParams.get("tab") === "contest_statistics") {
      {
        return contestDetail ? (
          <div className="flex flex-col px-8 py-4">
            <ContestStatistics />
          </div>
        ) : null;
      }
    } else {
      return contestDetail ? (
        <ContestRules
          contestDetail={contestDetail}
          contestStatus={contestStatus}
        />
      ) : null;
    }
  };

  return (
    <main className="relative w-full flex-grow overflow-x-auto h-full flex flex-col items-center">
      <Header title={contestDetail?.name || ""} />
      <div className="w-full">
        <ContestHeader />

        <div className="flex mx-8  dark:bg-[#000030] bg-white border border-[#fff] border-opacity-30">
          <TabNavGlowContest
            selectedIndex={
              searchParams.get("tab")
                ? searchParams.get("tab")
                : contestTabs[0]?.text
            }
            isUserEnrolled={contestantDetails?.isUserEnrolled}
            contestantDetailLoading={isContestantDetailLoading}
            contestStatus={contestStatus || ""}
            setSelectedIndex={(index) => {
              if (index === "log_contest_contacts") {
                setSelectedLogbook({
                  id: contestantDetails?.enrolledUser?.logBookId,
                  name: contestantDetails?.enrolledUser?.logbookName,
                  uid: contestantDetails?.enrolledUser?.uid,
                  contestId: contestDetail?.id,
                  contest: {
                    name: contestDetail?.name || "",
                    startDate: contestDetail?.startDate,
                    endDate: contestDetail?.endDate,
                    image: contestDetail?.image || "",
                    shortDescription: contestDetail?.shortDescription || "",
                  },
                });
                setSearchParams((prev: any) => {
                  return {
                    ...prev,
                    tab: index,
                    contest_id: searchParams.get("contest_id"),
                    "logbook-id": contestantDetails?.enrolledUser?.logBookId,
                    "logbook-name":
                      contestantDetails?.enrolledUser?.logbookName,
                  };
                });
              } else {
                setSearchParams((prev: any) => {
                  return {
                    ...prev,
                    tab: index,
                    contest_id: searchParams.get("contest_id"),
                  };
                });
              }
            }}
          />
        </div>

        {getLayout()}
      </div>
    </main>
  );
}

export default ContestDetail;
