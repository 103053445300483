import Entries from "components/Entries";
import TopTabNav from "components/TopTab/TopTabNav";
import useLogBook from "hooks/logBook.hook";
import React, { useEffect, useState } from "react";
import { ToastTypes, UserProfileLogBookProps } from "types/Component";
import UserContactMapComp from "components/Map/UserContactMap";
import UserProfileChart from "components/UserProfileChart";
import useProfile from "hooks/profile.hook";
import { useSearchParams } from "react-router-dom";
import { UserAvatar } from "components/UserAvatar";
import { RiInformationLine } from "react-icons/ri";
import { calculateProfileAnalytics } from "firebase-config";
import { showToast } from "helpers/Toast";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import ContactMap from "components/Map/ContactMap";

const UserProfileLogBook = ({ userData }: UserProfileLogBookProps) => {
  const {
    selectedTabIndex,
    setSelectedTabIndex,
    allUserContactsData,
    allPaginatedUserContactsData,
    handleModes,
    modesData,
    bandsData,
    bandsTotalAmount,
    modesTotalAmount,
    workedUserCountries,
    workedUserGridSquare,
    isLoading,
  } = useLogBook();
  let [searchParams, setSearchParams] = useSearchParams();

  const [profileAnalytics, setProfileAnalytics] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const {
    userProfile,
    callSignFromParam,
    getPaginatedUserContactsDataWithPagination,
  } = useProfile();

  const toast = (message: string) => {
    showToast({
      message: message,
      type: ToastTypes.ERROR,
    });
  };

  const getProfileAnalytics = async () => {
    try {
      setLoading(true);
      const profileAnalytics = await calculateProfileAnalytics({
        userId: userData?.uid,
      });

      setProfileAnalytics(profileAnalytics?.data);
      handleModes(profileAnalytics?.data as any);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast("Failed to get profile analytics");
    }
  };

  useEffect(() => {
    if (userData) {
      // handleModes(userData);
      getProfileAnalytics();
    }

    return () => {
      setProfileAnalytics(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    setSelectedTabIndex(
      searchParams.get("contact-tab")
        ? searchParams.get("contact-tab")!
        : "entries"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <div className="w-full">
      <div className="rounded-[20px] sm:rounded-[25px] md:rounded-[20px] dark:bg-[#0B0B39] bg-white flex flex-col md:flex-row justify-between items-center pr-3 pl-3 sm:pl-5 sm:pr-2">
        <div className="w-full md:w-[47%] flex flex-col sm:flex-row justify-between items-center">
          <div className="w-full sm:w-auto py-5 flex flex-col items-center">
            <p className="dark:text-white text-[#2b2e48] font-bold text-base text-center mb-2">
              {userData?.callSign}
            </p>
            {userData?.profilePic ? (
              <img
                className="rounded-full object-cover profileLogbookPic"
                src={userData?.profilePic}
                alt="profile pic"
              />
            ) : (
              <UserAvatar className="profileLogbookPic" isCurrentUser />
            )}
          </div>
          <div className="w-full sm:w-[67%]">
            <div className="flex justify-between items-center w-full">
              <p className="dark:text-white text-[#2b2e48] font-[Play] text-base font-bold">
                TOTAL LOGGED QSOs
              </p>
              {!loading ? (
                <p className="dark:text-white text-[#2b2e48] font-[Play] font-bold text-base">
                  :
                  {profileAnalytics?.loggedContacts
                    ? profileAnalytics?.loggedContacts
                    : 0}
                </p>
              ) : (
                <div>
                  <ActivityIndicator size={8} />
                </div>
              )}
            </div>
            {profileAnalytics?.importedContacts ? (
              <div className="flex justify-between items-center w-full">
                <p className="dark:text-white text-[#2b2e48] font-[Play] font-bold text-sm xl:text-base">
                  TOTAL IMPORTED QSOs
                </p>
                {!loading ? (
                  <p className="dark:text-white text-[#2b2e48] font-[Play] font-bold text-sm xl:text-base">
                    :
                    {profileAnalytics?.importedContacts
                      ? profileAnalytics?.importedContacts
                      : 0}
                  </p>
                ) : (
                  <div>
                    <ActivityIndicator size={8} />
                  </div>
                )}
              </div>
            ) : null}
            <div className="flex justify-between items-center w-full relative">
              <p className="dark:text-white text-[#2b2e48] font-[Play] text-sm xl:text-base flex items-center gap-1">
                # OF COUNTRIES WORKED{" "}
              </p>
              {!loading ? (
                <p className="dark:text-white text-[#2b2e48] font-[Play] font-bold text-sm xl:text-base">
                  :{profileAnalytics?.dxccEntitiesWorkedCount || 0}
                </p>
              ) : (
                <div>
                  <ActivityIndicator size={8} />
                </div>
              )}
            </div>
            <div className="flex justify-between items-center w-full relative">
              <p className="dark:text-white text-[#2b2e48] font-[Play] text-sm xl:text-base flex items-center gap-1">
                # OF GRID SQUARES WORKED
              </p>
              {!loading ? (
                <p className="dark:text-white text-[#2b2e48] font-[Play] font-bold text-sm xl:text-base">
                  :{profileAnalytics?.gridsquaresWorkedCount || 0}
                </p>
              ) : (
                <div>
                  <ActivityIndicator size={8} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full md:w-[51%] my-5 md:my-0 flex justify-between">
          <UserProfileChart
            data={bandsData}
            totalAmount={bandsTotalAmount}
            title="QSOs By Band"
            listSurfix="m"
          />
          <UserProfileChart
            data={modesData}
            totalAmount={modesTotalAmount}
            title="QSOs By Mode"
          />
        </div>
      </div>
      <TopTabNav
        setSelectedTabIndex={(tab) => {
          setSearchParams(() => {
            return {
              tab: searchParams?.get("tab") || "Logbook",
              "contact-tab": tab,
            };
          });
        }}
        selectedTabIndex={selectedTabIndex}
        firstIndexText={`${
          userProfile?.callSign !== callSignFromParam
            ? "Most Recent Contacts"
            : allPaginatedUserContactsData
            ? `Log(${allPaginatedUserContactsData?.count})`
            : "Log"
        }`}
        secondIndexText={`QSO Map`}
        className="mt-6"
      />
      {selectedTabIndex === "entries" ? (
        <Entries
          lists={
            userProfile?.callSign !== callSignFromParam
              ? userProfile?.admin
                ? allPaginatedUserContactsData?.contacts || []
                : allPaginatedUserContactsData?.contacts?.slice(0, 30) || []
              : allPaginatedUserContactsData?.contacts
              ? allPaginatedUserContactsData?.contacts
              : []
          }
          isLoading={isLoading}
          pagination={
            userProfile?.callSign === callSignFromParam || userProfile?.admin
              ? true
              : false
          }
          guestUser={userProfile?.callSign !== callSignFromParam}
          editable={true}
          searchable={true}
          paginateContact={getPaginatedUserContactsDataWithPagination}
          paginatedData={allPaginatedUserContactsData}
          userProfile={userData || userProfile}
          showSort={userProfile?.callSign === callSignFromParam ? true : false}
        />
      ) : (
        <ContactMap
          className="w-full h-[500px] relative"
          containerClassName="w-full"
          userId={userData?.uid}
          showControls={userProfile?.callSign === callSignFromParam ? true : false}
          showShare={false}
          restrictBounds={false}
          shareMapModalType='ALL_LOGBOOKS_CONTACTS'
        />
      )}
    </div>
  );
};

export default UserProfileLogBook;
