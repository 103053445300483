import React, { useEffect, useState } from "react";
import InputWithButton from "components/Inputs/InputWithButton";
import TitledInput from "components/Inputs/TitledInput";
import TitledTimeInput from "components/Inputs/TitledTimeInput";
import DropDownTitled from "components/DropDown/DropDownTitled";
import { AllBandsOption, Modes } from "constants/ArrayVariables";
import TitledInputGradient from "components/Inputs/TitledInputGradient";
import { useDispatch, useSelector } from "react-redux";
import { getContestDetailsSelector } from "store/Reducers/contest.reducer";
import { useSearchParams } from "react-router-dom";
import useLogbookSetting from "hooks/logbookSetting.hook";
import usePota from "hooks/pota.hook";
import { useDebouncedCallback } from "use-debounce";
import Icons from "assets/Icons";
import { SelectableButtonsGroup } from "components/SelectableButtonsGroup";
import {
  convertBandValue,
  findBandAndModeForFrequency,
  formatBandValue,
  getDxDataFromCountry,
  setTabIndexForTimeInputs,
  validateClassValue,
  validateSectionValue,
} from "helpers/Utils";
import { Form, Formik } from "formik";
import { LocationMappings } from "constants/LocationMappings";
import SearchCountryPicker from "components/Inputs/SearchCountryPicker";
import TitledRegionPicker from "components/Inputs/TitledRegionPicker";
import moment from "moment";
import AnimatedPrimaryButton from "components/Button/AnimatedPrimaryButton";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { useSpottingActions } from "hooks/useSpottingActions";
import useSpotting from "hooks/spotting.hook";
import { SPOT_ACTION_TYPE } from "types/Functions";
import { Activities } from "types/Models";
import { TheirParkField } from "pages/Dashboard/LogBook/EditContact/TheirParksField";
import useTheme from "hooks/useTheme.hook";
import { useDuplicateCheck } from "hooks/useDuplicateCheck";
import { setQuickSettingsCurrentTab } from "store/Actions/logBook.actions";
import { WFDClassOptions, WFDSectionOptions } from "constants/Config";
import { FaEdit } from "react-icons/fa";

const WFDTemplate = ({
  setValues,
  values,
  errors,
  handleChange,
  touched,
  initialData,
  setTouched,
  onLogPress,
  display,
  callSignBlurPopupHandler,
  userMode,
  setUserMode,
  userBand,
  setUserBand,
  setUserCallSign,
  selectedLogBook,
  isSavingOnLoading,
  date,
  setDate,
  setTime,
  currentTime,
  getUserFromHamApiData,
  isCallSignDataLoading,
  callSignData,
  handleClearAddContactForm,
  setStopRealTimeChange,
  userTimeInput,
  setUserTimeInput,
  checkLocationPermission,
  theirPark,
  setTheirPark,
  callSignRef,
  getUserCountryFromGrid,
  selectedSecondaryField,
  setSelectedSecondaryField,
  contactSuccess,
  endSuccessAnimation,
  spottingCopied,
  setDuplicateInfo,
  currentDate,
}: any) => {
  const contestDetail = useSelector(getContestDetailsSelector);
  let [searchParams] = useSearchParams();
  const [comments, setComments] = useState("");
  const { updateLogbookSettings } = useLogbookSetting();

  const { duplicateContactInfo, setDuplicateContactInfo } = useDuplicateCheck(
    values,
    {
      isWFDLogBook: contestDetail?.isWFDContest,
      logBookId: selectedLogBook?.id,
    }
  );

  const dispatch = useDispatch();
  const { getParkByReference, autoSpotData, setAutoSpotData, addPotaAutoSpot } =
    usePota();
  const { theme } = useTheme();

  const {
    theirCallSign,
    setTheirCallSign,
    frequency,
    setFrequency,
    mode,
    setMode,
    comments: spotComment,
    setComments: setSpotComment,
    saveSpot,
    onFrequencyBlur: onSpotFrequencyBlur,
  } = useSpotting();

  const { isLoading, successState, handleSaveSpot, resetSuccessState } =
    useSpottingActions({ saveSpot });

  useEffect(() => {
    onSpotFrequencyBlur();
  }, [frequency]);

  useEffect(() => {
    if (duplicateContactInfo) {
      setDuplicateInfo(duplicateContactInfo);
    } else {
      setDuplicateInfo(null);
    }
  }, [duplicateContactInfo]);

  useEffect(() => {
    if (autoSpotData?.frequency) {
      const { frequency, mode } = autoSpotData;

      const freqNumber = parseFloat(frequency);
      const bandMode = findBandAndModeForFrequency(freqNumber);

      if (bandMode) {
        setValues((prev: any) => {
          return {
            ...prev,
            userMode: mode || bandMode?.mode,
            band: bandMode?.band,
            frequency,
          };
        });
        setUserMode(mode || bandMode?.mode);
        setUserBand(bandMode?.band);
      }

      setAutoSpotData(null);
    }
  }, [autoSpotData]);

  const debouncedGetParkByReference = useDebouncedCallback(
    (searchString) => getParkByReference(searchString),
    200
  );

  const onFrequencyBlur = () => {
    if (!values.frequency && (values.band || values.userMode)) {
    } else {
      const freqNumber = parseFloat(values.frequency);
      const bandMode = findBandAndModeForFrequency(freqNumber);

      if (bandMode) {
        setValues((prev: any) => {
          return { ...prev, userMode: bandMode?.mode, band: bandMode?.band };
        });
        setUserMode(bandMode?.mode);
        setUserBand(bandMode?.band);
      } else {
        setUserMode("");
        setUserBand("");
        setDuplicateContactInfo(null);
      }
    }
  };

  useEffect(() => {
    if (selectedSecondaryField === 1) {
      setTabIndexForTimeInputs("13", "14", "15");
    }
  }, [selectedSecondaryField]);

  const renderSecondaryFields = () => {
    if (selectedSecondaryField === 1) {
      return (
        <div className="flex items-center w-full gap-3">
          <TitledTimeInput
            setText={(e) => {
              setStopRealTimeChange(true);
              setUserTimeInput(e);
              setTime(e);
              setValues((prev: any) => {
                return { ...prev, time: e };
              });
            }}
            name="time"
            // title="Time (UTC)"
            placeHolder="00:00 AM"
            className="!bg-white dark:!bg-opacity-10 h-[40px] mt-[0px]"
            width="w-[68.5%] sm:w-[30.5%] md:w-[28%] xl:w-[25%]"
            type="time"
            value={userTimeInput ? values.time : currentTime}
            error={errors?.time && touched?.time ? errors?.time : undefined}
            containerClass={"mb-[22px]"}
            tabindex={9}
          />
          <TitledInput
            setText={(e) => {
              setValues((prev: any) => {
                return { ...prev, date: e.target.value };
              });
              setDate(e.target.value);
            }}
            name="date"
            // title="Date"
            placeHolder="dd/mm/yyyy"
            tabindex={9}
            width="w-[68.5%] sm:w-[30.5%] md:w-[28%] xl:w-[25%]"
            type="date"
            value={date ? date : currentDate}
            className="!bg-white dark:!bg-opacity-10 h-[40px] mt-[0px]"
            error={errors?.date && touched?.date ? errors?.date : undefined}
            props={
              {
                // max: new Date().toJSON().slice(0, 10),
              }
            }
            // containerClass={display ? "pointer-events-none" : ""}
          />
          <button
            type="button"
            tabIndex={9}
            className="flex items-center justify-center mb-[20px] p-2 bg-blue-600  h-[40px] w-[40px]"
            onClick={() => setSelectedSecondaryField(0)}
          >
            <img src={Icons.SendIcon} alt="" />
          </button>
        </div>
      );
    } else if (selectedSecondaryField === 2) {
      return (
        <div className="flex items-start w-full gap-3">
          <TheirParkField {...{ values, setValues, touched, errors }} />
          {/* <button
            type="button"
            className="flex items-center justify-center mb-[20px] p-2 bg-blue-600  h-[40px] w-[40px]"
            onClick={() => setSelectedSecondaryField(0)}
            tabIndex={16}
          >
            <img src={Icons.SendIcon} alt="" />
          </button> */}
        </div>
      );
    } else if (selectedSecondaryField === 3) {
      const addPotaAutoSpotHandler = () => {
        const parkReference = Object.keys(selectedLogBook?.myParks || {})?.[0];

        if (parkReference && values?.myCallSign && values?.frequency) {
          // add spot
          addPotaAutoSpot(
            {
              activator: values?.myCallSign,
              spotter: values?.myCallSign,
              reference: parkReference,
              comments: comments,
              frequency: `${parseFloat(values?.frequency) * 1000}`,
              mode: values?.userMode,
              band: values?.band,
            },
            selectedLogBook?.id
          );
        }

        setSelectedSecondaryField(0);
        setComments("");
      };

      return (
        <div>
          <div className="flex flex-row gap-x-3 items-center w-full flex-wrap">
            <InputWithButton
              setText={(e) => {
                handleChange(e);
                setValues((prev: any) => ({
                  ...prev,
                  frequency: e.target.value,
                }));
              }}
              title="Frequency"
              name="frequency"
              placeHolder="Frequency"
              onFieldBlur={onFrequencyBlur}
              width="w-[46%] sm:w-[20.5%] md:w-[20%]"
              value={values.frequency}
              btnTxt="MHz"
              fitBtn
              onBtnClick={() => {}}
              error={
                errors?.frequency && touched?.frequency
                  ? errors?.frequency
                  : undefined
              }
              className="!bg-white dark:!bg-opacity-10 h-[40px]"
              btnHeight="h-[40px]"
              containerClass="-mt-[25px]"
              tabindex={11}
            />
            <DropDownTitled
              onChange={(e) => {
                handleChange(e);
                setUserBand(e.target.value);
              }}
              name="band"
              info="The band you made the contact on."
              value={userBand ? userBand : ""}
              title="Band"
              placeHolder="Band"
              tabindex={11}
              className={"mb-1"}
              width="w-[46.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%]"
              lists={AllBandsOption}
              getTitle={(item) =>
                convertBandValue(item.name, item.unit) + item.unit
              }
              getValue={(item) => item.name}
              error={errors?.band && touched?.band ? errors?.band : undefined}
              containerClass=""
            />

            <DropDownTitled
              onChange={(e) => {
                handleChange(e);
                // setUserMode(e.target.value);
                setValues((prev: any) => ({
                  ...prev,
                  userMode: e.target.value,
                }));
              }}
              name="userMode"
              value={values.userMode}
              title="Mode"
              placeHolder="Mode"
              tabindex={11}
              width="w-[46%] sm:w-[20.5%] md:w-[15%]"
              lists={Modes}
              error={
                errors?.userMode && touched?.userMode
                  ? errors?.userMode
                  : undefined
              }
              containerClass="-mt-[25px]"
              className="!bg-white dark:!bg-opacity-10 h-[40px]"
            />
            <TitledInput
              setText={(e) => {
                setComments(e.target.value);
              }}
              title="Comment"
              name="comments"
              placeHolder="Comments"
              onBlur={() => {}}
              width="w-[46%] sm:w-[30.5%] md:w-[30%]"
              value={comments}
              className="!bg-white dark:!bg-opacity-10 h-[40px] mb-1"
              tabindex={11}
            />
            <button
              type="button"
              className="flex items-center justify-center mb-[20px] md:mb-0 p-2 bg-blue-600  h-[40px] w-[40px]"
              onClick={addPotaAutoSpotHandler}
              tabIndex={11}
            >
              <img src={Icons.SendIcon} alt="" />
            </button>
            {selectedLogBook?.lastSpottedAt ? (
              <p className="text-white font-[Play] text-sm">
                POTA Auto Spot sent{" "}
                <span className="text-[#17F9DA]">
                  {moment(
                    new Date(selectedLogBook?.lastSpottedAt?.seconds * 1000)
                  ).fromNow()}
                </span>{" "}
                on{" "}
                <span className="text-[#17F9DA]">
                  {selectedLogBook?.lastSpottedFrequency}/
                  {selectedLogBook?.lastSpottedBand}
                </span>{" "}
                {selectedLogBook?.lastSpottedComment
                  ? selectedLogBook?.lastSpottedComment.substring(0, 10)
                  : ""}
              </p>
            ) : null}
          </div>
          {/* <div className="flex gap-1">
            <span className="text-white text-xs">Last AutoSpot sent</span>
            <span className="text-[#17F9DA] text-xs font-bold">
              5 min ago 14.250MHz SSB
            </span>
          </div> */}
        </div>
      );
    } else if (selectedSecondaryField === 4) {
      const powerSaveHandler = () => {
        updateLogbookSettings({ defaultPower: values?.power });
        setSelectedSecondaryField(0);
      };

      return (
        <div className="flex items-center w-full gap-3">
          <TitledInput
            setText={(e) => {
              setValues((prev: any) => {
                return {
                  ...prev,
                  power: e.target.value,
                };
              });
            }}
            name="power"
            placeHolder="Power (W)"
            width="w-[68.5%] sm:w-[30.5%] md:w-[20%] xl:w-[20%]"
            value={values.power}
            className="!bg-white dark:!bg-opacity-10 h-[40px] mt-[0px]"
            tabindex={11}
            error={errors?.power && touched?.power ? errors?.power : undefined}
            containerClass={display ? "pointer-events-none" : ""}
          />
          <button
            type="button"
            className="flex items-center justify-center mb-[20px] p-2 bg-blue-600  h-[40px] w-[40px]"
            onClick={powerSaveHandler}
            tabIndex={11}
          >
            <img src={Icons.SendIcon} alt="" />
          </button>
        </div>
      );
    } else if (selectedSecondaryField === 5) {
      return (
        <div className="flex items-center w-full gap-3">
          <TitledInput
            setText={(e) => {
              setValues((prev: any) => {
                return {
                  ...prev,
                  notes: e.target.value,
                };
              });
            }}
            name="notes"
            placeHolder="Notes"
            value={values.notes}
            width="w-[68.5%] sm:w-[50.5%] md:w-[50%] xl:w-[50%]"
            className="!bg-white dark:!bg-opacity-10 h-[40px] mt-[0px]"
            tabindex={12}
            error={errors?.notes && touched?.notes ? errors?.notes : undefined}
            containerClass={display ? "pointer-events-none" : ""}
          />
          <button
            type="button"
            className="flex items-center justify-center mb-[20px] p-2 bg-blue-600  h-[40px] w-[40px]"
            onClick={() => setSelectedSecondaryField(0)}
            tabIndex={12}
          >
            <img src={Icons.SendIcon} alt="" />
          </button>
        </div>
      );
    } else if (selectedSecondaryField === 7) {
      return (
        <div className="flex items-center w-full gap-3">
          <div className="flex flex-col lg:flex-row items-center gap-1 w-full">
            <Formik initialValues={{}} onSubmit={() => {}}>
              <Form className="flex flex-row gap-3 flex-wrap w-full">
                <SearchCountryPicker
                  name="country"
                  title="Their Country"
                  info="Country of the other operator."
                  tabindex={10}
                  value={values?.country}
                  countryCode={values?.countryCode}
                  placeHolder="Country"
                  dxccNumber={values?.dxccNumber || ""}
                  width={
                    values?.country && values?.country?.length
                      ? "w-[46.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[27.5%]"
                      : "w-[46.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[27.5%]"
                  }
                  containerClass={display ? "pointer-events-none" : ""}
                  setText={(val) => {
                    const dxccData = getDxDataFromCountry(val);
                    setValues((prev: any) => {
                      return {
                        ...prev,
                        country: val,
                        grid: val !== values?.country ? "" : values?.grid,
                        dxccNumber: dxccData?.dxccNumber || "",
                        continent: dxccData?.continent || "",
                        flagCode: dxccData?.flagCode || "",
                        countryCode: dxccData?.countryCode || "",
                        state: val !== values?.country ? "" : values?.state,
                      };
                    });
                  }}
                />

                {values?.country && values?.country.length ? (
                  <TitledRegionPicker
                    setText={(val) => {
                      setValues((prev: any) => {
                        return {
                          ...prev,
                          state: val,
                          grid: val !== values?.state ? "" : values?.grid,
                        };
                      });
                    }}
                    name="state"
                    title="Their State"
                    info="State of the other operator."
                    placeHolder="State"
                    tabindex={10}
                    width="w-[46.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[20%]"
                    className={
                      "mb-1 dark:!bg-[#23234C] !bg-[#f1f3f4] dark:!text-white !text-[#2b2e48]"
                    }
                    value={values?.state}
                    error={
                      errors?.state && touched?.state
                        ? errors?.state
                        : undefined
                    }
                    country={
                      LocationMappings[`${values?.country}`] || values?.country
                    }
                    containerClass={display ? "pointer-events-none" : ""}
                  />
                ) : null}
                <TitledInput
                  setText={(e) => {
                    const value = e.target.value;
                    setValues((prev: any) => ({
                      ...prev,
                      grid: value,
                    }));
                  }}
                  name="grid"
                  title="Their Grid"
                  value={values?.grid}
                  placeHolder="Grid"
                  className="bg-[#23234C]"
                  width="w-[46%] sm:w-[30.5%] md:w-[20%]"
                  containerClass=""
                  onBlur={() => {
                    if (values.grid) {
                      getUserCountryFromGrid(values.grid, setValues);
                    }
                  }}
                  tabindex={10}
                />
                <button
                  type="button"
                  className="flex items-center justify-center mt-[24px] p-2 bg-blue-600  h-[36px] w-[36px]"
                  onClick={() => setSelectedSecondaryField(-1)}
                  tabIndex={10}
                >
                  <img src={Icons.SendIcon} alt="" />
                </button>
              </Form>
            </Formik>
          </div>
        </div>
      );
    } else if (selectedSecondaryField === 8) {
      return (
        <>
          <div className="flex flex-row gap-x-3 items-center w-full flex-wrap">
            <InputWithButton
              setText={(value) => setFrequency(value.target.value)}
              title="Frequency"
              name="frequency"
              placeHolder="Frequency"
              onFieldBlur={onSpotFrequencyBlur}
              width="w-[46%] sm:w-[20.5%] md:w-[20%]"
              value={frequency}
              btnTxt="MHz"
              fitBtn
              onBtnClick={() => {}}
              className="!bg-white dark:!bg-opacity-10 h-[40px]"
              btnHeight="h-[40px]"
              containerClass="-mt-[25px]"
            />

            <DropDownTitled
              onChange={(e) => {
                setMode(e.target.value);
              }}
              name="userMode"
              value={mode}
              title="Mode"
              placeHolder="Mode"
              tabindex={6}
              width="w-[46%] sm:w-[20.5%] md:w-[15%]"
              lists={Modes}
              containerClass="-mt-[20px]"
              className="mb-1 !bg-white dark:!bg-opacity-10 h-[40px]"
            />
            <TitledInput
              setText={(e) => {
                setSpotComment(e.target.value);
              }}
              title="Comment"
              name="comments"
              placeHolder="Comments"
              onBlur={() => {}}
              width="w-[46%] sm:w-[30.5%] md:w-[30%]"
              value={spotComment}
              className="!bg-white dark:!bg-opacity-10 h-[40px] mb-1"
            />
            <AnimatedPrimaryButton
              text=""
              onClick={() => {
                setSelectedSecondaryField(8);
                handleSaveSpot(SPOT_ACTION_TYPE.SPOT_ME);
              }}
              className="flex items-center justify-center mb-[20px] md:mb-0 p-2 bg-blue-600  h-[40px] w-[40px]"
              childClassName="h-[37px] w-[37px] bg-transparent"
              childHeight="w-8 h-18"
              isLoading={isLoading}
              successState={successState}
              endAnimation={() => resetSuccessState()}
              icon={<img src={Icons.SendIcon} alt="" />}
              height="h-[40px]"
              selected={false}
              transparentBg={true}
            />
          </div>
        </>
      );
    } else if (selectedSecondaryField === 9) {
      return (
        <>
          <div className="flex flex-row gap-x-3 items-center w-full flex-wrap">
            <TitledInput
              setText={(value) =>
                setTheirCallSign(value.target.value.toUpperCase())
              }
              title="Their Callsign"
              name="theirCallSign"
              placeHolder="Their Callsign"
              onBlur={() => {}}
              width="w-[46%] sm:w-[20.5%] md:w-[20%]"
              value={theirCallSign}
              className="!bg-white dark:!bg-opacity-10 h-[40px] mb-1"
            />

            <InputWithButton
              setText={(value) => setFrequency(value.target.value)}
              title="Frequency"
              name="frequency"
              placeHolder="Frequency"
              onFieldBlur={onSpotFrequencyBlur}
              width="w-[46%] sm:w-[20.5%] md:w-[20%]"
              value={frequency}
              btnTxt="MHz"
              fitBtn
              onBtnClick={() => {}}
              className="!bg-white dark:!bg-opacity-10 h-[40px]"
              btnHeight="h-[40px]"
              containerClass="-mt-[25px]"
            />

            <DropDownTitled
              onChange={(e) => {
                setMode(e.target.value);
              }}
              name="userMode"
              value={mode}
              title="Mode"
              placeHolder="Mode"
              tabindex={6}
              width="w-[46%] sm:w-[20.5%] md:w-[15%]"
              lists={Modes}
              containerClass="-mt-[20px]"
              className="mb-1 !bg-white dark:!bg-opacity-10 h-[40px]"
            />
            <TitledInput
              setText={(e) => {
                setSpotComment(e.target.value);
              }}
              title="Comment"
              name="comments"
              placeHolder="Comments"
              onBlur={() => {}}
              width="w-[46%] sm:w-[30.5%] md:w-[30%]"
              value={spotComment}
              className="!bg-white dark:!bg-opacity-10 h-[40px] mb-1"
            />
            <AnimatedPrimaryButton
              text=""
              onClick={() => {
                setSelectedSecondaryField(9);
                handleSaveSpot(SPOT_ACTION_TYPE.SPOT_THEM);
              }}
              className="flex items-center justify-center mb-[20px] md:mb-0 p-2 bg-blue-600  h-[40px] w-[40px]"
              childClassName="h-[37px] w-[37px] bg-transparent"
              childHeight="w-8 h-18"
              isLoading={isLoading}
              successState={successState}
              endAnimation={() => resetSuccessState()}
              icon={<img src={Icons.SendIcon} alt="" />}
              height="h-[40px]"
              selected={false}
              transparentBg={true}
            />
          </div>
        </>
      );
    }

    return null;
  };

  const debouncedCallsignInformation = useDebouncedCallback((searchString) => {
    if (searchString) {
      getUserFromHamApiData(searchString, setValues);
    } else {
      handleClearAddContactForm(initialData, setTouched, setValues);
    }

    callSignBlurPopupHandler(searchString);

    setTheirCallSign(searchString); // Set their callsign to Spot Them field
  }, 800);

  const theirCallSignChangeHandler = (e: any) => {
    const theirCallSign = e.target.value.toUpperCase().trim();
    setValues((prev: any) => {
      return {
        ...prev,
        theirCallsign: theirCallSign,
      };
    });
    debouncedCallsignInformation(theirCallSign);
  };

  const getTheirParkLabel = () => {
    const theirParks = Object.keys(values?.theirParks || {});
    if (theirParks) {
      if (theirParks.length > 1) {
        return "Multiple Parks";
      } else if (theirParks.length === 1) {
        return theirParks[0];
      }
      return "Park to Park";
    }
  };

  const getButtonItems = () => {
    const items = [
      {
        id: 1,
        icon: Icons.clockWhiteIcon,
        label: `${userTimeInput ? values.time : currentTime || "00:00:00"} z`,
        tabIndex: 9,
      },
      {
        id: 2,
        icon: Icons.parkToParkWhite,
        label: "Their Park Number",
        tabIndex: 9,
      },
      // {
      //   id: 3,
      //   icon: Icons.parkWhite,
      //   label: "POTA Autospot",
      //   tabIndex: 10,
      // },
      {
        id: 7,
        icon: Icons.LocationPinIcon,
        label: values?.grid || "Their Location",
        tabIndex: 10,
      },
      {
        id: 4,
        icon: Icons.powerWhite,
        label: values.power ? `${values.power} Watts` : "Power",
        tabIndex: 11,
      },
      {
        id: 5,
        icon: Icons.notesWhite,
        label: "Notes",
        tabIndex: 12,
      },
      {
        id: 8,
        label: "Spot Me",
        tabIndex: 13,
      },
      {
        id: 9,
        label: "Spot Them",
        tabIndex: 14,
      },
      // Hide tags for now
      // {
      //   id: 6,
      //   icon: Icons.tagsWhite,
      //   label: "Tags",
      // },
    ];
    if (
      selectedLogBook?.activities &&
      selectedLogBook?.activities.length > 0 &&
      selectedLogBook?.activities.includes(Activities.POTA)
    ) {
      // insert POTA Autospot button to the second position
      items.splice(2, 0, {
        id: 3,
        icon: Icons.parkWhite,
        label: "POTA Autospot",
        tabIndex: 10,
      });
    }
    return items;
  };

  return (
    <div className="w-full">
      <div className="flex flex-wrap gap-x-4">
        <TitledInput
          setText={theirCallSignChangeHandler}
          name="theirCallsign"
          title="Their Callsign"
          placeHolder="Their Callsign"
          tabindex={1}
          status={
            duplicateContactInfo && callSignData !== "NOT_FOUND" && callSignData
              ? `DUPE | ${formatBandValue(duplicateContactInfo.band)}`
              : callSignData !== "NOT_FOUND" && callSignData
              ? "Callsign Match"
              : null
          }
          statusBg={
            duplicateContactInfo && callSignData !== "NOT_FOUND" && callSignData
              ? "bg-gradient-to-r from-[#9B8647] to-[#C7B36D]"
              : callSignData !== "NOT_FOUND" && callSignData
              ? "bg-gradient-to-r from-[#8A24CA] to-[#651FFF]"
              : "bg-gradient-to-r from-[#FD9D82] to-[#FF6F46]"
          }
          isLoading={isCallSignDataLoading}
          width="w-[46.5%] sm:w-[20%] md:w-[18%] lg:w-[30%] xl:w-[22%]"
          value={values.theirCallsign}
          className={"mb-1.5"}
          error={
            errors?.theirCallsign && touched?.theirCallsign
              ? errors?.theirCallsign
              : undefined
          }
          containerClass={display ? "pointer-events-none" : ""}
          props={{ innerRef: callSignRef }}
          flashField={spottingCopied}
        />
        <TitledInputGradient
          setText={(e) => {
            handleChange(e);
            const exValue = e.target.value || "";
            setValues((prev: any) => ({
              ...prev,
              exchangeOne: exValue?.toUpperCase(),
            }));
          }}
          name="exchangeOne"
          title={contestDetail?.exchangeOne}
          placeHolder="Class"
          value={values.exchangeOne}
          width={"w-[46.5%] sm:w-[20%] md:w-[18%] lg:w-[20%] xl:w-[22%]"}
          className={"placeholder-[#000] border-[0.5px] border-white"}
          tabindex={2}
          {...(values?.exchangeOne?.length
            ? validateClassValue(values.exchangeOne)
              ? {
                  status: "ok",
                  statusBg: "bg-gradient-to-r from-[#5f9b47] to-[#297935]",
                }
              : {
                  status: "x",
                  statusBg: "bg-gradient-to-r from-[#9b4752] to-[#792930]",
                }
            : {})}
        />

        <TitledInputGradient
          setText={(e) => {
            handleChange(e);
            const exValue = e.target.value || "";
            setValues((prev: any) => ({
              ...prev,
              exchangeTwo: exValue?.toUpperCase(),
            }));
          }}
          name="exchangeTwo"
          title={contestDetail?.exchangeTwo}
          placeHolder="Section"
          value={values.exchangeTwo}
          width={"w-[46.5%] sm:w-[20%] md:w-[18%] lg:w-[20%] xl:w-[22%]"}
          className={"placeholder-[#000] border-[0.5px] border-white"}
          tabindex={3}
          {...(values?.exchangeTwo?.length
            ? validateSectionValue(values.exchangeTwo)
              ? {
                  status: "ok",
                  statusBg: "bg-gradient-to-r from-[#5f9b47] to-[#297935]",
                }
              : {
                  status: "x",
                  statusBg: "bg-gradient-to-r from-[#9b4752] to-[#792930]",
                }
            : {})}
        />

        <InputWithButton
          setText={(e) => {
            handleChange(e);
            setValues((prev: any) => ({
              ...prev,
              frequency: e.target.value,
            }));

            if (!e.target.value) {
                setUserMode("");
                setUserBand("");
                setDuplicateContactInfo(null);
            }
          }}
          title="Frequency"
          name="frequency"
          placeHolder="Frequency"
          onFieldBlur={() => {
            onFrequencyBlur();
            setFrequency(values.frequency); // Set frequency to Spot Frequency field
          }}
          width="w-[46.5%] sm:w-[21.5%] md:w-[18%] lg:w-[30%] xl:w-[24%]"
          value={values.frequency}
          btnTxt="MHz"
          fitBtn
          onBtnClick={() => {}}
          error={
            errors?.frequency && touched?.frequency
              ? errors?.frequency
              : undefined
          }
          className="mb-1"
          btnHeight="h-8"
          containerClass={display ? "pointer-events-none " : ""}
          tabindex={4}
          flashField={spottingCopied}
        />
        <DropDownTitled
          onChange={(e) => {
            handleChange(e);
            setUserBand(e.target.value);
          }}
          name="band"
          info="The band you made the contact on."
          value={userBand ? userBand : ""}
          title="Band"
          placeHolder="Band"
          tabindex={5}
          className={"mb-1"}
          width="w-[46.5%] sm:w-[23.5%] md:w-[18.7%] lg:w-[22%] xl:w-[20%]"
          lists={AllBandsOption}
          getTitle={(item) =>
            convertBandValue(item.name, item.unit) + item.unit
          }
          getValue={(item) => item.name}
          error={errors?.band && touched?.band ? errors?.band : undefined}
          containerClass={display ? "pointer-events-none " : ""}
        />

        <DropDownTitled
          onChange={(e) => {
            handleChange(e);
            setUserMode(e.target.value);
            setValues((prev: any) => ({
              ...prev,
              userMode: e.target.value,
            }));
          }}
          name="userMode"
          info="The mode of operation you are using on your radio."
          value={userMode ? userMode : ""}
          title="Mode"
          placeHolder="Mode"
          tabindex={6}
          className={"mb-1"}
          width="w-[46.5%] sm:w-[15.5%] md:w-[13%] lg:w-[22%] xl:w-[20%]"
          lists={Modes}
          error={
            errors?.userMode && touched?.userMode ? errors?.userMode : undefined
          }
          containerClass={display ? "pointer-events-none " : ""}
        />

        <div className="flex items-end w-[46.5%] sm:w-[19.5%] md:w-[15%] lg:w-[35%] xl:w-[22%] mt-4 md:mt-1 mb-1 md:mb-4 lg:my-6">
          <AnimatedPrimaryButton
            isLoading={isSavingOnLoading}
            text={"Log Contact"}
            onClick={() => {
              // setUserBand("");
              // setUserMode("");
              // setDate(getCurrentDate());
              onLogPress && onLogPress();
            }}
            childClassName="text-sm dark:text-white text-[#2b2e48] w-[calc(100%-3px)] h-[calc(100%-3px)]"
            className={`${
              display ? "pointer-events-none " : ""
            } rounded-full bg-gradient-to-r from-[#37A4BA] via-[#496780] to-[#CF0971] w-full`}
            tabIndex={7}
            successState={contactSuccess}
            endAnimation={endSuccessAnimation}
          />
        </div>
        <div className="flex items-end w-[46.5%] sm:w-[19.5%] md:w-[15%] lg:w-[35%] xl:w-[22%] mt-4 md:mt-1 mb-1 md:mb-4 lg:my-6 ">
          <BorderButtonSecondary
            isLoading={false}
            text={"Clear"}
            onClick={() =>
              handleClearAddContactForm(
                initialData,
                setTouched,
                setValues,
                true
              )
            }
            tabIndex={8}
            childClassName="text-sm"
            className={display ? "pointer-events-none" : ""}
          />
        </div>
      </div>

      <div className="mb-4 flex items-center cursor-pointer" onClick={() => {}}>
        <p className=" dark:text-white text-[#2b2e48] font-[Play] text-md font-bold">
          Your Exchange:{" "}
          {`${selectedLogBook.contestTransmitters || ""}${
            selectedLogBook.contestClass || ""
          } ${selectedLogBook.contestSection || ""} `}
        </p>
        <FaEdit
          color={theme === "dark" ? "#fff" : "#2b2e48"}
          className="ml-3"
          onClick={() => {
            dispatch(
              setQuickSettingsCurrentTab({
                id: 6,
              })
            );
          }}
        />
      </div>

      {/* POta secondary fields */}
      <SelectableButtonsGroup
        initialSelected={selectedSecondaryField}
        toggle
        disabled={display}
        actions={getButtonItems()}
        onButtonChange={(action) => {
          if (action && (action.id as number) > 0) {
            setSelectedSecondaryField(action.id as number);
          } else {
            setSelectedSecondaryField(-1);
          }
        }}
      />

      {selectedSecondaryField > 0 ? (
        <div className="flex w-full bg-indigo-500 bg-opacity-10  mt-4 items-center p-4">
          {renderSecondaryFields()}
        </div>
      ) : null}
    </div>
  );
};

export default WFDTemplate;
