import { ConfigState } from "types/State";
import { RootState } from "store/Reducers";
import {
  ConfigActionPayload,
  MENU_VISIBILITY_STATUS,
  SET_PRIMARY_MODAL_DATA,
  SET_SCREENSHOT_IMAGE,
  SHOW_SIGNIN_HELP,
  ADD_CONTACT_SUCCESS,
  SPOTTING_COPIED,
  CONTACT_MAP_MODAL_STATUS,
  SET_STATS_MODAL_STATUS,
  SHARE_MAP_MODAL_TYPE,
} from "store/Actions/config.actions";

const defaultState: ConfigState = {
  isMenuOpen: false,
  primaryModalData: null,
  screenShotImage: "",
  showSignInHelp: false,
  addContactSuccess: false,
  spottingCopied: false,
  contactMapModalStatus: false,
  statsModalStatus: false,
  shareMapModalType: "",
  webConfig: null,
};

export default function configReducer(
  state = defaultState,
  action: ConfigActionPayload
) {
  switch (action.type) {
    case MENU_VISIBILITY_STATUS:
      return {
        ...state,
        isMenuOpen: action.payload,
      };
    case SET_PRIMARY_MODAL_DATA:
      return {
        ...state,
        primaryModalData: action.payload,
      };
    case SET_SCREENSHOT_IMAGE:
      return {
        ...state,
        screenShotImage: action.payload,
      };
    case SHOW_SIGNIN_HELP:
      return {
        ...state,
        showSignInHelp: action.payload,
      };
    case ADD_CONTACT_SUCCESS:
      return {
        ...state,
        addContactSuccess: action.payload,
      };
    case SPOTTING_COPIED:
      return {
        ...state,
        spottingCopied: action.payload,
      };
    case CONTACT_MAP_MODAL_STATUS:
      return {
        ...state,
        contactMapModalStatus: action.payload,
      };
    case SET_STATS_MODAL_STATUS:
      return {
        ...state,
        statsModalStatus: action.payload,
      };
    case SHARE_MAP_MODAL_TYPE:
      return {
        ...state,
        shareMapModalType: action.payload,
      };
    case "SET_WEB_CONFIG":
      return {
        ...state,
        webConfig: action.payload,
      };
    default:
      return state;
  }
}

// GET STATE
export const getIsMenuOpenSelector = (state: RootState) => {
  return state.config.isMenuOpen;
};

export const getPrimaryModalSelector = (state: RootState) => {
  return state.config.primaryModalData;
};

export const getScreenShotImageSelector = (state: RootState) => {
  return state.config.screenShotImage;
};

export const getShowSignInHelpSelector = (state: RootState) => {
  return state.config.showSignInHelp;
};

export const getAddContactSuccessSelector = (state: RootState) => {
  return state.config.addContactSuccess || false;
};

export const getSpottingCopiedSelector = (state: RootState) => {
  return state.config.spottingCopied || false;
};

export const getContactMapModalStatus = (state: RootState) => {
  return state.config.contactMapModalStatus;
};

export const getStatsModalStatus = (state: RootState) => {
  return state.config.statsModalStatus;
};

export const getShareMapModalType = (state: RootState) => {
  return state.config.shareMapModalType;
};

export const getWebConfig = (state: RootState) => {
  return state.config.webConfig;
};