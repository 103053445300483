import {
  db,
  auth,
  adminDisableUser,
  adminEnableUser,
  adminUpdateSubscription,
  deleteAccount,
} from "firebase-config";
import { UserProfile, UserAwards, FeaturedMember, UserQuota } from "types/Models";
import {
  Awards,
  LogBookContactTable,
  UserAwards as UserAwardsCollection,
  UserDataTable,
} from "constants/Collections";
import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  Timestamp,
  where,
  writeBatch,
  updateDoc,
} from "firebase/firestore";
import { CoordinatesParams } from "store/Actions/profile.actions";
import { getUidWithMostRecords } from "helpers/Utils";

interface ProfileServiceType {
  getUserProfileData(uid: string, distance?: any): Promise<UserProfile | null>;
  createUserProfileData(profileData: UserProfile): Promise<boolean>;
  editUserProfileData(profileData: UserProfile): Promise<boolean>;
  storeCoordinate(coordinates: CoordinatesParams): Promise<boolean>;
  fetchUserAwards(uid: string): Promise<UserAwards[] | null>;
  checkCallSignAvailability(callSign: string): Promise<any>;
  sendWordPressLogin(): Promise<boolean>;
  setFeaturedAward(awardFeaturedValue: UserAwards): Promise<boolean>;
  fetchFeaturedMembers(): Promise<UserProfile[]>;
  fetchAward(): Promise<UserAwards[] | null>;
  addUserAwards(awards: UserAwards[]): Promise<boolean>;
  removeUserAward(awardId: string): Promise<boolean>;
  deleteUser(uid: string): Promise<boolean>;
  disableUser(uid: string): Promise<boolean>;
  enableUser(uid: string): Promise<boolean>;
  updateSubscriptionLevel(payload: any): Promise<boolean>;
  updateAnalyticsQuota(userId: string): Promise<UserQuota>;
  getAnalyticsQuota(userId: string): Promise<UserQuota>;
  getUserProfileByCallsign(callSign: string): Promise<UserProfile | null>;
}

const ProfileService: ProfileServiceType = {
  createUserProfileData: async (profileData) => {
    try {
      const uid: string | undefined = auth.currentUser?.uid;
      if (uid) {
        const userRef = doc(db, UserDataTable, uid);
        await setDoc(userRef, profileData, { merge: true });
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },
  getUserProfileData: async (uid, distance) => {
    const userRef = doc(db, UserDataTable, uid);
    const userProfile: DocumentData = await getDoc(userRef);
    if (userProfile.exists()) {
      const userData: UserProfile = userProfile.data();
      if (distance) {
        userData.distance = distance;
      }
      return userData;
    } else {
      return null;
    }
  },
  getUserProfileByCallsign: async (callSign) => {
    try {
      const q = query(
        collection(db, UserDataTable),
        where("callSign", "==", callSign)
      );
      const querySnapshot = await getDocs(q);

      if(querySnapshot.size === 0) {
        return null;
      }

      const userProfile = querySnapshot.docs[0].data() as UserProfile;

      return normalizeResponse(userProfile);

    } catch (e) {
      return null;
    }
  },
  editUserProfileData: async (profileData) => {
    try {
      const uid: string | undefined = auth.currentUser?.uid;
      if (uid) {
        const userRef = doc(db, UserDataTable, uid);
        await setDoc(userRef, profileData, { merge: true });
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log("EDIT PROFILE ERROR : ", e);
      return false;
    }
  },
  storeCoordinate: async (coordinates) => {
    try {
      const uid: string | undefined = auth.currentUser?.uid;
      if (uid) {
        const userRef = doc(db, UserDataTable, uid);
        await setDoc(
          userRef,
          {
            coordinates: coordinates,
          },
          { merge: true }
        );
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },
  fetchUserAwards: async (uid) => {
    try {
      if (uid) {
        const q = query(
          collection(db, UserAwardsCollection),
          where("uid", "==", uid),
          orderBy("timestamp", "desc")
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot !== null || querySnapshot !== undefined) {
          const awards: UserAwards[] = querySnapshot.docs.map((doc) => {
            return {
              id: doc.id,
              ...(doc.data() as UserAwards),
            };
          });
          return awards;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  },
  checkCallSignAvailability: async (callSign) => {
    try {
      if (callSign) {
        const arr: UserProfile[] = [];
        const q = query(
          collection(db, UserDataTable),
          where("callSign", "==", callSign)
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot !== null || querySnapshot !== undefined) {
          querySnapshot.forEach((doc) => {
            let data: UserProfile = {
              uid: doc.id,
              ...doc.data(),
            };
            arr.push(data);
          });
          if (arr.length) {
            return { available: false, data: arr[0] };
          } else {
            return { available: true };
          }
        } else {
          return { available: false };
        }
      } else {
        return { available: false };
      }
    } catch (e) {
      return { available: false };
    }
  },
  sendWordPressLogin: async () => {
    try {
      const idToken: string | undefined = await auth.currentUser?.getIdToken(
        true
      );
      if (idToken) {
        fetch(`${process.env.REACT_APP_WORDPRESS}?auth=${idToken}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {})
          .catch((e) => {});
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },
  setFeaturedAward: async (awardFeaturedValue) => {
    try {
      const featuredValue =
        awardFeaturedValue?.featured === true ? false : true;
      const userRef = doc(
        db,
        UserAwardsCollection,
        awardFeaturedValue.id ? awardFeaturedValue.id : ""
      );
      await setDoc(
        userRef,
        {
          featured: featuredValue,
        },
        { merge: true }
      );
      return true;
    } catch (e) {
      return false;
    }
  },
  fetchFeaturedMembers: async () => {
    let users: FeaturedMember[] = [];
    try {
      const today = new Date();
      const dayOfWeek = today.getUTCDay();
      const startOfWeek = new Date(today);
      startOfWeek.setUTCHours(0, 0, 0, 0);
      startOfWeek.setUTCDate(today.getUTCDate() - dayOfWeek);
      const startOfWeekTimestamp = Timestamp.fromDate(startOfWeek);
      const contactsCollection = collection(db, LogBookContactTable);
      const q = query(
        contactsCollection,
        where("contactTimeStamp", ">=", startOfWeekTimestamp),
        orderBy("contactTimeStamp"),
        limit(50)
      );
      const querySnapshot = await getDocs(q);
      const records = querySnapshot.docs.map((doc) => doc.data());
      const uid = getUidWithMostRecords(records);
      const userData: UserProfile | null =
        await ProfileService.getUserProfileData(uid);
      if (userData) {
        users.push({ ...userData, message: "MOST QSOs THIS WEEK" });
      }
      const userCollection = collection(db, UserDataTable);

      const userQuery = query(
        userCollection,
        where("subscriptionStatus", "==", "active"),
        orderBy("subscriptionCreatedAt", "desc"),
        limit(3)
      );
      const userQuerySnapshot = await getDocs(userQuery);
      const foundingMemberUsersRecord: FeaturedMember[] =
        userQuerySnapshot.docs.map((doc) => {
          return {
            message: "NEW FOUNDING MEMBER",
            ...doc.data(),
          };
        });

      users.push(...foundingMemberUsersRecord);
      return users;
    } catch (e) {
      return users;
    }
  },
  fetchAward: async () => {
    try {
      const awardRef = query(collection(db, Awards));
      const award = await getDocs(awardRef);
      const awardData: any[] = [];
      // eslint-disable-next-line array-callback-return
      award.docs.map((doc) => {
        awardData.push({ id: doc.id, ...doc.data() });
      });
      return awardData as UserAwards[];
    } catch (e) {
      return null;
    }
  },
  addUserAwards: async (awards) => {
    try {
      const batch = writeBatch(db);

      awards.forEach((award) => {
        const awardRef = doc(collection(db, UserAwardsCollection));
        batch.set(awardRef, award);
      });
      await batch.commit();
      return true;
    } catch (e) {
      return false;
    }
  },
  removeUserAward: async (awardId) => {
    try {
      const awardRef = doc(db, UserAwardsCollection, awardId);
      await deleteDoc(awardRef);
      return true;
    } catch (e) {
      return false;
    }
  },
  deleteUser: async (uid) => {
    try {
      await deleteAccount({
        userId: uid,
        env: process.env.NODE_ENV,
      });
      return true;
    } catch (e) {
      return false;
    }
  },
  disableUser: async (uid) => {
    try {
      await adminDisableUser({ userId: uid });
      return true;
    } catch (e) {
      return false;
    }
  },
  enableUser: async (uid) => {
    try {
      await adminEnableUser({ userId: uid });
      return true;
    } catch (e) {
      return false;
    }
  },
  updateSubscriptionLevel: async (payload) => {
    try {
      await adminUpdateSubscription({
        userId: payload.userId,
        subscriptionLevel: payload.subscriptionLevel,
      });
      return true;
    } catch (e) {
      return false;
    }
  },
  updateAnalyticsQuota: async (userId: string): Promise<UserQuota> => {
    try {
      const userRef = doc(db, UserDataTable, userId);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data() as UserProfile;
      
      let quota = userData?.quota || {
        analyticsViewsRemaining: 6,
        lastResetDate: new Date().toISOString(),
        isLocked: false
      };

      // Check if we need to reset quota (monthly)
      const lastReset = new Date(quota.lastResetDate);
      const now = new Date();
      if (now.getMonth() !== lastReset.getMonth() || now.getFullYear() !== lastReset.getFullYear()) {
        quota = {
          analyticsViewsRemaining: 6,
          lastResetDate: now.toISOString(),
          isLocked: false
        };
      }

      // Decrement views if not locked
      if (!quota.isLocked && quota.analyticsViewsRemaining > 0) {
        quota.analyticsViewsRemaining -= 1;
        quota.isLocked = quota.analyticsViewsRemaining === 0;
      }

      // Update user profile with new quota
      await updateDoc(userRef, { quota });
      return quota;
    } catch (error) {
      console.error('Error updating analytics quota:', error);
      throw error;
    }
  },
  getAnalyticsQuota: async (userId: string): Promise<UserQuota> => {
    try {
      const userRef = doc(db, UserDataTable, userId);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data() as UserProfile;
      
      return userData?.quota || {
        analyticsViewsRemaining: 6,
        lastResetDate: new Date().toISOString(),
        isLocked: false
      };
    } catch (error) {
      console.error('Error getting analytics quota:', error);
      throw error;
    }
  }
};

export default ProfileService;

function normalizeResponse(response: any) {
  return UserProfileResponseTransformer(response);
}

function UserProfileResponseTransformer(response: any) {
  const lookup = response;
  
  let responseMapping = {};
  
  responseMapping ={
    hamdb: {
      callsign:{
        country: lookup?.country ?? "NOT_FOUND",
        ituZone: lookup?.ituZone ?? undefined,
        cqZone: lookup?.cqZone ?? undefined,
        dxcc: lookup?.dxcc ?? "",
        state: lookup?.state ?? "",
        ...lookup?.coordinates ? parseCoordinates(lookup?.coordinates): {},
        fullname: `${lookup?.firstName ?? ""} ${lookup?.lastName ?? ""}`,
        fname: lookup?.firstName ?? "",
        name: lookup?.lastName ?? "",
        addr1: lookup?.address ?? "",
        addr2: lookup?.city ?? "",
        call: lookup?.callSign ?? "",
        grid: lookup?.gridSquare ?? "NOT_FOUND",
        image: lookup?.profilePic ?? "",
      },
      messages: {
        status: "FOUND"
      },
      version: "1"
    },
  }
  
  return responseMapping;
}

const parseCoordinates = (coordinates: any) => {
  const { latitude, longitude } = coordinates;

  if (!latitude && !longitude) {
    return {
      lat: "NOT_FOUND",
      lon: "NOT_FOUND",
    };
  }

  return {
    lat: parseFloat(latitude),
    lon: parseFloat(longitude),
  };
}