/* eslint-disable react/display-name */
import React, {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  RefObject,
  useCallback,
  useState,
} from "react";
import "quill/dist/quill.snow.css";
import Quill from "quill";
import "./EditorStyles.css"; // Import your custom CSS file here
import "./CustomIframeBlot"; // Import the custom blot
import "./CustomImageBlot"; // Import the custom blot
import useTheme from "hooks/useTheme.hook";
import UploadService from "services/upload.service";
import { toast } from "react-toastify";
import ActivityIndicator from "components/Loading/ActivityIndicator";

interface EditorProps {
  readOnly: boolean;
  defaultValue?: any; // Adjust type based on actual defaultValue type
  onTextChange?: (...args: any[]) => void;
  onSelectionChange?: (...args: any[]) => void;
  label: string;
}

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],
  ["link", "image", "video", "formula"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ["clean"],
  [{ iframe: "🌐" }], // remove formatting button
];

const Editor = forwardRef<Quill | null, EditorProps>(
  ({ readOnly, defaultValue, onTextChange, onSelectionChange, label }, ref) => {
    const { theme } = useTheme();
    const isDark = theme === "dark";
    const containerRef: RefObject<HTMLDivElement> = useRef(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);

    const [showModal, setShowModal] = useState(false);
    const [iframeOptions, setIframeOptions] = useState({
      url: "",
      width: "600",
      height: "400",
      alignment: "center",
    });

    const [showImageModal, setShowImageModal] = useState(false);
    const [imageOptions, setImageOptions] = useState({
      file: null as File | null,
      previewUrl: "",
      uploadProgress: false,
      width: "400",
      height: "400",
      alignment: "center",
    });

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    }, [onTextChange, onSelectionChange]);

    useEffect(() => {
      if (ref && "current" in ref) {
        ref.current?.enable(!readOnly);
      }
    }, [ref, readOnly]);

    useEffect(() => {
      const container = containerRef.current;
      if (!container) return;

      const editorContainer = container.querySelector(".quill-editor");
      if (!editorContainer) {
        const newEditorContainer = document.createElement("div");
        newEditorContainer.className = "quill-editor";
        container.appendChild(newEditorContainer);
        const quill = new Quill(newEditorContainer, {
          theme: "snow",
          modules: {
            toolbar: {
              container: toolbarOptions,
              handlers: {
                image: function () {
                  const input = document.createElement("input");
                  input.setAttribute("type", "file");
                  input.setAttribute("accept", "image/*");
                  input.click();

                  input.onchange = () => {
                    if (!input.files?.length) return;
                    const file = input.files[0];

                    setShowImageModal(true);
                    setImageOptions({
                      file,
                      previewUrl: URL.createObjectURL(file),
                      uploadProgress: false,
                      width: "400",
                      height: "400",
                      alignment: "center",
                    });
                  };
                },

                iframe: function () {
                  setShowModal(true); // Show modal on iframe button click
                },
              },
            },
          },
        });

        if (ref && "current" in ref) {
          ref.current = quill;
        }

        if (defaultValueRef.current) {
          quill.setContents(defaultValueRef.current);
        }

        quill.on(Quill.events.TEXT_CHANGE, (...args) => {
          onTextChangeRef.current?.(...args);
        });

        quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
          onSelectionChangeRef.current?.(...args);
        });

        return () => {
          quill.off(Quill.events.TEXT_CHANGE);
          quill.off(Quill.events.SELECTION_CHANGE);
        };
      }
    }, []);

    const handleImageUpload = async () => {
      if (!imageOptions.file) return;

      try {
        // Insert a temporary loading placeholder
        const quill = typeof ref === "function" ? null : ref?.current;
        if (!quill) return;
        const range = quill.getSelection();

        setImageOptions((prev) => ({ ...prev, uploadProgress: true }));

        // Upload the image and track progress
        const response = await UploadService.handleUploadImage(
          imageOptions.file
        );

        if (response) {
          quill.insertEmbed(range?.index || 0, "customImage", {
            url: response,
            width: imageOptions.width,
            height: imageOptions.height,
            alignment: imageOptions.alignment,
          });
          // Close modal
          setShowImageModal(false);
          setImageOptions({
            file: null,
            previewUrl: "",
            uploadProgress: false,
            width: "400",
            height: "400",
            alignment: "center",
          });
        } else {
          toast.error("Failed to upload image");
        }
      } catch (error) {
        toast.error("Failed to upload image");
      }
    };

    const handleModalSubmit = () => {
      const { url, width, height, alignment } = iframeOptions;

      if (!url) {
        alert("URL is required");
        return;
      }

      if (ref && "current" in ref && ref.current) {
        const quill = ref.current;
        const range = quill.getSelection(true);
        quill.insertEmbed(range?.index || 0, "iframe", {
          url: url,
          width: width,
          height: height,
          alignment: alignment,
        });
      }

      setShowModal(false); // Close modal
      setIframeOptions({
        url: "",
        width: "600",
        height: "400",
        alignment: "center",
      });
    };

    return (
      <>
        <p className="dark:text-[#17F9DA] text-[#1e40af] text-xs font-[Play] mb-1.5">
          {label}
        </p>
        <div
          ref={containerRef}
          className={
            isDark ? "editor-container-dark" : "editor-container-light"
          }
        ></div>

        {/* Modal */}
        {showModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h3>Embed Iframe</h3>
              <label>
                URL:
                <input
                  type="text"
                  value={iframeOptions.url}
                  placeholder="i.e www.example.com"
                  onChange={(e) =>
                    setIframeOptions((prev) => ({
                      ...prev,
                      url: e.target.value,
                    }))
                  }
                />
              </label>
              <label>
                Width:
                <input
                  type="text"
                  value={iframeOptions.width}
                  onChange={(e) =>
                    setIframeOptions((prev) => ({
                      ...prev,
                      width: e.target.value,
                    }))
                  }
                />
              </label>
              <label>
                Height:
                <input
                  type="text"
                  value={iframeOptions.height}
                  onChange={(e) =>
                    setIframeOptions((prev) => ({
                      ...prev,
                      height: e.target.value,
                    }))
                  }
                />
              </label>
              <label>
                Alignment:
                <select
                  value={iframeOptions.alignment}
                  onChange={(e) =>
                    setIframeOptions((prev) => ({
                      ...prev,
                      alignment: e.target.value,
                    }))
                  }
                >
                  <option value="left">Left</option>
                  <option value="center">Center</option>
                  <option value="right">Right</option>
                </select>
              </label>
              <button type="button" onClick={handleModalSubmit}>
                Insert Iframe
              </button>
              <button type="button" onClick={() => setShowModal(false)}>
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* Image Upload Modal */}
        {showImageModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h3>Upload Image</h3>

              {/* Image Preview */}
              <div
                style={{
                  display: "flex",
                  maxHeight: "300px",
                }}
              >
                <img
                  src={imageOptions.previewUrl}
                  alt="Preview"
                  style={{
                    objectFit: "contain",
                    margin: "8px 0px",
                  }}
                />
              </div>

              {/* Image Options */}
              <label>
                Width:
                <input
                  type="number"
                  value={imageOptions.width}
                  onChange={(e) =>
                    setImageOptions((prev) => ({
                      ...prev,
                      width: e.target.value,
                    }))
                  }
                />
              </label>

              <label>
                Height:
                <input
                  type="number"
                  value={imageOptions.height}
                  onChange={(e) =>
                    setImageOptions((prev) => ({
                      ...prev,
                      height: e.target.value,
                    }))
                  }
                />
              </label>

              <label>
                Alignment:
                <select
                  value={imageOptions.alignment}
                  onChange={(e) =>
                    setImageOptions((prev) => ({
                      ...prev,
                      alignment: e.target.value,
                    }))
                  }
                >
                  <option value="left">Left</option>
                  <option value="center">Center</option>
                  <option value="right">Right</option>
                </select>
              </label>

              {imageOptions.uploadProgress ? (
                <div>
                  <ActivityIndicator size={12} />
                </div>
              ) : (
                <>
                  <button
                    type="button"
                    disabled={imageOptions.uploadProgress}
                    onClick={handleImageUpload}
                  >
                    Upload Image
                  </button>
                  <button
                    type="button"
                    disabled={imageOptions.uploadProgress}
                    onClick={() => setShowImageModal(false)}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
);

export default Editor;
