import React, { useEffect } from 'react';
import Icons from 'assets/Icons';
import { DynamicFilterModal, MainFilterModal, AppliedFilters } from 'components/DropDown/DropDown/DynamicFilter';

type FilterType = "boolean" | "multiBoolean" | "multiSelect" | "textSearch" | "autoComplete" | "radio" | "comparison" | "custom";

export interface FilterConfig {
    field: string;
    title?: string;
    type?: FilterType;
    displayValue?: string;
    value?: any;
    hasOptions?: boolean; // Indicating if boolean filter should have "Yes/No" options
    options?: any[];
    fetchAPI?: string;
    customComponent?: React.FC<any>;
    width?: number;
    height?: number;
    initialValues?: any;
    visible?: boolean;
}

interface FilterProps {
    appliedFilters: FilterConfig[];
    filterConfig: FilterConfig[];
    setFiltersConfig: React.Dispatch<React.SetStateAction<FilterConfig[]>>;
    dropdownVisible: { [key: string]: boolean };
    toggleDropdown: (key: string) => void;
    toggleAllDropdowns: (excludeKey?: string) => void;
    handleSelectFilter: (filter: any) => void;
    selectedFilter: FilterConfig | null;
    handleRemoveFilter: (filter: any) => void;
    handleApplyFilter: (field: string, value: any) => void;
    modalPosition: { top: number; left: number };
    setSelectedFilter: React.Dispatch<React.SetStateAction<FilterConfig | null>>;
    handleEditFilter: (filter: FilterConfig, values: any, event: React.MouseEvent<HTMLSpanElement>) => void;
    text?: string;
}

const Filter: React.FC<FilterProps> = ({
    appliedFilters,
    filterConfig,
    setFiltersConfig,
    dropdownVisible,
    toggleDropdown,
    toggleAllDropdowns,
    handleSelectFilter,
    selectedFilter,
    handleRemoveFilter,
    handleApplyFilter,
    modalPosition,
    setSelectedFilter,
    handleEditFilter,
    text = "Filter",
}) => {

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            // The dropdown wrapper and button are being used to check if the click was outside
            const dropdownWrapper = document.querySelector('.dropdown-wrapper');
            const dropdownButton = document.querySelector('.dropdown-button');

            // Check if the click target is neither inside the dropdown nor the dropdown button
            if (
                dropdownWrapper &&
                dropdownButton &&
                !dropdownWrapper.contains(event.target as Node) &&
                !dropdownButton.contains(event.target as Node)
            ) {
                toggleAllDropdowns();
                setSelectedFilter(null);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    
    return (
        <div className="flex items-center space-x-4 my-2">
            <div
                className="flex flex-wrap flex-shrink-0 items-center relative dropdown-button no-select mr-4 md:mr-0"
                onClick={(e) => {
                    e.stopPropagation();
                    toggleAllDropdowns('filters');
                    toggleDropdown('filters');
                }}
            >
                <img className="h-6 w-6 invert dark:filter-none dark:invert-0" src={Icons.FilterIcon} alt="icon" />
                {appliedFilters?.length === 0 && (
                    <span className="dark:text-white text-[#2b2e48] font-[Play] text-base ml-2">{text}</span>
                )}

                {/* Main Filter Modal */}
                {dropdownVisible['filters'] && (
                    <MainFilterModal
                        onSelectFilter={handleSelectFilter}
                        filterConfig={filterConfig}
                        appliedFilters={appliedFilters}
                    />
                )}

                {/* Dynamic Filter Modal */}
                {selectedFilter && (
                    <DynamicFilterModal
                        filterConfig={filterConfig}
                        setFiltersConfig={setFiltersConfig}
                        filter={selectedFilter}
                        onApplyFilter={handleApplyFilter}
                        onRemoveFilter={handleRemoveFilter}
                        initialValues={appliedFilters.find(f => f.field === selectedFilter.field)?.value || []}
                        onClose={() => setSelectedFilter(null)}
                        modalPosition={modalPosition}
                    />
                )}
            </div>

            {/* Applied Filters */}
            <AppliedFilters
                filters={appliedFilters}
                onRemoveFilter={handleRemoveFilter}
                onEditFilter={handleEditFilter}
                filterConfig={filterConfig}
            />
        </div>
    );
};

export default Filter;