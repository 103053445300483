import React, { useCallback, useEffect, useState } from "react";
import Header from "components/Header";
import { UserAvatar } from "components/UserAvatar";
import Images from "assets/Images";
import { LogBookContactModel } from "types/Models";
import moment from "moment";
import { globalNavigate } from "routes/GlobalRoutes";

interface ActivityTableProps {
  title: string;
  data?: any[];
  showTotal: boolean;
}

function ActivityTable({ title, data, showTotal }: ActivityTableProps) {
  return (
    <div className="w-full  overflow-x-auto">
      <p className="font-[Play] font-bold text-[16px] dark:text-white mb-3 mt-4">
        {title}
      </p>
      <table className="w-full min-w-max table-auto">
        <tbody>
          <tr className="bg-[#94a3b883] dark:bg-[#3C3C58] pb-2.5 border-0 border-b border-slate-300 border-solid">
            <th className="text-sm font-[Barlow] font-bold text-center pt-3 pb-2 dark:text-white text-[#2b2e48]">
              No.
            </th>
            <th className="text-sm font-[Barlow] font-bold pt-3 pb-2 dark:text-white text-[#2b2e48]"></th>

            <th className="text-sm font-[Barlow] font-bold text-center pt-3 pb-2 dark:text-white text-[#2b2e48]">
              Callsign
            </th>
            <th className="text-sm font-[Barlow] font-bold text-center pt-3 pb-2 dark:text-white text-[#2b2e48]">
              Name
            </th>

            {showTotal ? (
              <th className="text-sm font-[Barlow] font-bold pt-3 pb-2 dark:text-white text-[#2b2e48] ">
                <p className=" pl-3 text-left">Total</p>
              </th>
            ) : (
              <th className="text-sm font-[Barlow] font-bold pt-3 pb-2 dark:text-white text-[#2b2e48] ">
                <p className=" pl-3 text-left">Date</p>
              </th>
            )}
          </tr>
        </tbody>
        {data?.map((contact, index) => (
          <tr
            key={index}
            className="border-0 border-b border-slate-300 border-solid dark:bg-[#1F1F48] bg-white cursor-pointer dark:text-white"
            onClick={() => {
              globalNavigate(`/profile/${contact.callSign}`);
            }}
          >
            <td className="text-sm font-[Barlow] font-normal text-center py-1">
              {index + 1}.
            </td>
            <td
              className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-2 "
              onClick={() => {}}
            >
              <div className=" h-full flex justify-center items-center">
                {contact.profilePic ? (
                  <img
                    className="w-5 h-5 rounded-full object-cover"
                    src={contact.profilePic}
                    alt=""
                  />
                ) : (
                  <UserAvatar className="w-7 h-7" />
                )}
              </div>
            </td>
            <td className="text-sm font-[Barlow] font-normal text-center py-2">
              {contact.callSign}
            </td>

            <td className="text-sm font-[Barlow] font-normal text-center py-2">
              {contact.name}
            </td>
            {showTotal ? (
              <td className="text-sm font-[Barlow] font-normal text-center py-2">
                {contact.numberOfActivation}
              </td>
            ) : (
              <td className="text-sm font-[Barlow] font-normal text-center py-2">
                {contact.date}
              </td>
            )}
          </tr>
        ))}
      </table>
    </div>
  );
}

export default ActivityTable;
